import React, { useEffect, useState } from "react"
import Page_heading from "../../../../utilities/Page_heading"
import Flex_box from "../../../../utilities/flex_box"
import survey_pic from "../../../../assets/Image.png"
import { theme_bg, theme_color } from "../../../../utilities/colors"
import { useLocation, useNavigate } from "react-router-dom"
import {
  gd_action_api,
  getGDbyStudyId_api,
  GetGDbyStudyId_api,
  GetSurveyByStudyId_api,
} from "../../../../apis/apis"
// import Respondent_Table from "./Respondent_Table";
import GD_Request_table from "./GD_Request_table"
import { useDispatch } from "react-redux"
import { alert_action } from "../../../../redux_prog/actions/base_action"

const GD_Block = ({ study_id, study_detail, state }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [gd_data, setgd_data] = useState()

  const get_gd_details = () => {
    getGDbyStudyId_api({ StudyId: study_id })
      .then((res) => {
        setgd_data(res.data.Res[0])
      })
      .catch((err) => {
        console.log("====================================")
        console.log(err)
        console.log("====================================")
      })
  }

  const gd_action = () => {
    gd_action_api({
      gd_id: gd_data?._id,
      started: gd_data?.started == false ? true : false,
    })
      .then((res) => {
        if (res.data.status == 1) {
          dispatch(
            alert_action({
              type: "success",
              title: "Group started",
              msg: gd_data?.groupdiscussionname + " is started!",
            }),
          )
          setgd_data({
            ...gd_data,
            started: gd_data?.started == false ? true : false,
          })
        }
      })
      .catch((err) => {
        console.warn(err)
      })
  }

  const { _id, groupdiscussionname, discussionabout, date, time, audio, video } = gd_data || {}

  useEffect(get_gd_details, [])

  return (
    <>
      <div
        style={{
          padding: "20px 0px",
        }}
      >
        <Page_heading label={"Group Discussion"} />
        <Flex_box
          className="responsive_width_35"
          style={{
            width: "",
            flexDirection: "column",
            alignItems: "start",
            padding: "12px",
            boxSizing: "border-box",
            boxShadow: "0px 0px 30px #A6A6A633",
            borderRadius: "10px",
            marginTop: "20px",
          }}
        >
          <b style={{ fontSize: "18px", marginTop: "10px" }}>{groupdiscussionname}</b>
          <p
            style={{
              marginTop: "8px",
              fontSize: "15px",
            }}
          >
            <span style={{ fontWeight: 600 }}>Start Date & Time </span>: {date + " - " + time}
          </p>
          <span style={{ fontSize: "12px", marginTop: "-6px" }}>{discussionabout}</span>
          <Flex_box
            style={{
              // width: "",
              gap: 25,
              // justifyContent: "start",
              padding: "20px 0px 10px 0px",
            }}
          >
            <button
              id="btn_comp"
              style={{
                fontWeight: "600",
                color: theme_color,
                backgroundColor: theme_bg,
                border: "2px solid " + theme_color,
              }}
              onClick={() => {
                navigate("/create-gd", {
                  state: {
                    gd_data,
                    studyid: study_id,
                  },
                })
              }}
            >
              Edit
            </button>

            <button
              id="btn_comp"
              style={{ fontWeight: "600" }}
              onClick={() => {
                if (gd_data?.started) {
                  navigate("/chat-comp", {
                    state: {
                      gd_data,
                      study_detail,
                    },
                  })
                } else {
                  gd_action()
                }
              }}
            >
              {gd_data?.started ? "Join Group Discussion" : "Start"}
            </button>
          </Flex_box>
        </Flex_box>
      </div>
      <GD_Request_table study_detail={study_detail} study_id={study_id} gd_data={gd_data} />
    </>
  )
}

export default GD_Block
