import React, { useEffect, useState } from "react";
import pdf_icon from "../../../../assets/bxs-file-pdf.svg";
import share_icon from "../../../../assets/share.svg";
import { theme_bg } from "../../../../utilities/colors.js";
import Flex_box from "../../../../utilities/flex_box.jsx";
import Progress_bar from "../../../../utilities/Progress_bar.jsx";
import { get_industry_name } from "../../../../utilities/select_arr.js";
import edit_icon from "../../../../assets/edit.svg";
import { theme_color } from "../../../../utilities/colors.js";
import { useNavigate } from "react-router-dom";
import {
  close_study_api,
  get_user_study_invitation_api,
  page_url,
  study_inv_action_api,
} from "../../../../apis/apis.js";
import {
  alert_action,
  loading_action,
} from "../../../../redux_prog/actions/base_action.js";
import { useDispatch, useSelector } from "react-redux";
import { get_percentage } from "../../../../utilities/Get_percentage.js";
import Print_Report from "../Print_Report/index.jsx";
import { get_subscription_data_count_and_kyc } from "../../../../utilities/utilities.js";
import TopUp_Modal from "./TopUp_Modal/index.jsx";
import poll_icon from "../../../../assets/poll_icon_blue.png";
import Researchers_Modal from "./Researchers_Modal/index.jsx";
import Button from "../../../../utilities/Button.jsx";
import FA_Icon_box from "../../../../utilities/FA_Icon_box.jsx";

const Study_header = ({ state, study_detail, type }) => {
  console.warn({ state });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user_data } = useSelector((state) => state?.authReducer);
  const {
    description,
    studyname,
    studyoninductry,
    totalrespondents,
    groupdiscussionname,
    _id,
  } = study_detail;

  const [modalVisible, setmodalVisible] = useState(false);
  const [res_modalVisible, setres_modalVisible] = useState(false);

  const surveys_lookup = study_detail?.surveys_lookup || [];
  const surveyres_lookup = surveys_lookup[0]?.surveyres_lookup || [];
  const max_resp = parseInt(totalrespondents);
  const totalResponse = surveyres_lookup?.length;
  const totalResponse_perc = get_percentage(totalResponse, max_resp) + "%";
  const completedResponse =
    surveyres_lookup?.length > 0 &&
    surveyres_lookup?.filter((el) => {
      return el?.iscompleted == true;
    })?.length;
  const completedResponse_perc =
    get_percentage(completedResponse, max_resp) + "%";

  const del_study = () => {
    dispatch(
      alert_action({
        type: "success",
        title: "Close?",
        msg: "Do you want to Close this study?",
        is_confirm: true,
        onSuccess: () => {
          console.warn(study_detail);

          dispatch(loading_action(true));
          close_study_api({
            StudyId: _id,
            survey_id: surveys_lookup[0]?._id,
          })
            .then((res) => {
              dispatch(loading_action(false));
              if (res.data?.status == 2) {
                setmodalVisible(true);
              } else {
                dispatch(
                  alert_action({
                    type: "success",
                    title: "Success",
                    msg: "Study closed successfully!",
                    // msg: "Thank you for completing this study, your incentive will be credited to your account once the study is closed!",
                  })
                );
                get_subscription_data_count_and_kyc(dispatch, user_data);
                navigate(-1);
              }
            })
            .catch((err) => {
              dispatch(loading_action(false));
              console.log(err);
            });
        },
      })
    );
  };

  const study_inv_action = (status) => {
    study_inv_action_api({
      researcher_id: user_data?._id,
      study_id: study_detail?._id,
      status,
    })
      .then((res) => {
        get_user_study_invitation();
        dispatch(
          alert_action({
            type: "success",
            title: "Sucsess",
            msg:
              "Invitation " +
              (status == 1 ? "accepted" : "rejected") +
              " successfully!",
          })
        );
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const [study_inv_status, setstudy_inv_status] = useState(0);

  const get_user_study_invitation = () => {
    get_user_study_invitation_api({
      study_id: study_detail?._id,
      researcher_id: user_data?._id,
    })
      .then((res) => {
        setstudy_inv_status(res.data.res_inv[0]?.status);
      })
      .catch((Err) => {
        console.warn(Err);
      });
  };

  useEffect(() => {
    if (state?.study_invitation && study_detail?._id) {
      get_user_study_invitation();
    }
  }, [study_detail]);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: theme_bg,
        padding: "20px",
        boxSizing: "border-box",
        borderRadius: "20px",
      }}
    >
      <Flex_box
        style={{
          justifyContent: "space-between",
        }}
      >
        <Flex_box
          style={{
            width: "",
            gap: 10,
          }}
        >
          <b
            style={{
              fontSize: "22px",
            }}
          >
            {groupdiscussionname || studyname}
          </b>
          <button
            onClick={() => {
              const new_obj = {
                ...study_detail,
                iscomplete: study_detail?.iscomplete ? "true" : "false",
                __v: "0",
              };

              navigate("/create-study", {
                state: {
                  study_detail: new_obj,
                },
              });
            }}
            id="btn_comp"
            style={{
              width: "30px",
              height: "30px",
              backgroundColor: theme_color,
              boxShadow: "0px 2px 6px #0000000D",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={edit_icon}
              style={{
                height: "15px",
              }}
            />
          </button>
        </Flex_box>
        <Flex_box
          style={{
            width: "",
            gap: 20,
            maxWidth: "50%",
          }}
        >
          <button
            id="btn_comp"
            style={{
              width: "40px",
              height: "40px",
              backgroundColor: "#fff",
              boxShadow: "0px 2px 6px #0000000D",
              paddingTop: "7px",
            }}
            onClick={() => {
              navigate("/survey-analysis", {
                state: {
                  study_id: study_detail?._id,
                  studyname: study_detail?.studyname,
                },
              });
            }}
          >
            <img
              src={poll_icon}
              style={{
                height: "15px",
              }}
            />
          </button>
          <button
            id="btn_comp"
            style={{
              width: "40px",
              height: "40px",
              backgroundColor: "#fff",
              boxShadow: "0px 2px 6px #0000000D",
              paddingTop: "7px",
              // padding:"0px"
            }}
            onClick={() => {
              if (type == "gd") {
                navigator.clipboard
                  .writeText(page_url + "study-details?" + _id)
                  .then((res) => {
                    alert("Link copied successfully!");
                  })
                  .catch((err) => {
                    console.warn(err);
                  });
              } else {
                navigator.clipboard
                  .writeText(page_url + "take-survey?" + _id)
                  .then((res) => {
                    alert(
                      "Survey link copied successfully! People with whom you share this can give survey!"
                    );
                  })
                  .catch((err) => {
                    console.warn(err);
                  });
              }
            }}
          >
            <img
              src={share_icon}
              style={{
                height: "15px",
              }}
            />
          </button>
          <Print_Report studyname={studyname} study_id={study_detail?._id} />
          {study_detail?.isClosed || state?.study_invitation ? null : (
            <Button
              label={"Researchers"}
              style={{
                padding: "8px 0px",
                width: "120px",
              }}
              onClick={() => {
                setres_modalVisible(true);
              }}
            />
          )}
          {state?.study_invitation && (
            <Flex_box
              style={{
                width: "",
                gap: 10,
              }}
            >
              <FA_Icon_box
                className={"fa-solid fa-check"}
                style={{
                  backgroundColor: study_inv_status == 1 ? "green" : "#fff",
                  border: "2px solid green",
                  color: study_inv_status != 1 ? "green" : "#fff",
                }}
                onClick={() => {
                  study_inv_action(1);
                }}
              />

              <FA_Icon_box
                className={"fa-solid fa-xmark"}
                style={{
                  backgroundColor: study_inv_status == 2 ? "red" : "#fff",
                  border: "2px solid red",
                  color: study_inv_status != 2 ? "red" : "#fff",
                }}
                onClick={() => {
                  study_inv_action(2);
                }}
              />
            </Flex_box>
          )}
        </Flex_box>
      </Flex_box>
      <span
        style={{
          fontSize: "15px",
        }}
      >
        <span style={{ fontWeight: 600 }}>Study on </span>:{" "}
        {get_industry_name(studyoninductry)}
      </span>
      <p
        style={{
          fontSize: "15px",
          marginTop: "8px",
        }}
      >
        <span style={{ fontWeight: 700 }}>
          {totalResponse}/{max_resp}{" "}
        </span>{" "}
        <span style={{ fontWeight: 500 }}>Respondents</span>
      </p>
      <Flex_box
        style={{
          gap: 20,
          justifyContent: "start",
        }}
      >
        <div
          style={{
            width: "70%",
          }}
        >
          <span style={{ fontSize: "11px" }}>{description}</span>
          <br />
          <button
            id="btn_comp"
            style={{
              marginTop: "20px",
            }}
            onClick={() => {
              if (study_detail?.isClosed == false) {
                del_study();
              }
            }}
            disabled={study_detail?.isClosed}
          >
            {study_detail?.isClosed ? "Closed" : "Close Study"}
          </button>
        </div>
        {type == "survey" && (
          <div
            style={{
              width: "29%",
              backgroundColor: "#fff",
              borderRadius: "10px",
              padding: "15px",
              boxSizing: "border-box",
            }}
          >
            <Flex_box
              style={{
                justifyContent: "start",
                alignItems: "",
                gap: 10,
              }}
            >
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                }}
              >
                {totalResponse_perc}
              </span>
              <Flex_box
                style={{
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "start",
                  gap: 8,
                }}
              >
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "700",
                  }}
                >
                  Response rate
                </span>
                <Progress_bar
                  style={{ width: "90%" }}
                  progress={totalResponse_perc}
                />
              </Flex_box>
            </Flex_box>
            <Flex_box
              style={{
                justifyContent: "start",
                alignItems: "",
                gap: 10,
                marginTop: "15px",
              }}
            >
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                }}
              >
                {completedResponse_perc}
              </span>
              <Flex_box
                style={{
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "start",
                  gap: 8,
                }}
              >
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "700",
                  }}
                >
                  Completion rate
                </span>
                <Progress_bar
                  style={{ width: "90%" }}
                  progress={completedResponse_perc}
                />
              </Flex_box>
            </Flex_box>
          </div>
        )}
      </Flex_box>
      <TopUp_Modal
        modalVisible={modalVisible}
        setmodalVisible={setmodalVisible}
      />

      <Researchers_Modal
        modalVisible={res_modalVisible}
        setmodalVisible={setres_modalVisible}
        study_id={study_detail?._id}
        type={type == "gd" ? "GD" : "Survey"}
        type_id={
          type == "gd" ? study_detail?.gds_lookup : study_detail?.surveys_lookup
        }
        study_name={studyname}
      />
    </div>
  );
};

export default Study_header;
