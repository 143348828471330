import React from "react";
import Flex_box from "../../../../utilities/flex_box";
import share_icon from "../../../../assets/Icon open-share-boxed.svg";
import { get_industry_name } from "../../../../utilities/select_arr";
import { useSelector } from "react-redux";
import Button from "../../../../utilities/Button";
import { get_transcripts_api } from "../../../../apis/apis";
import * as XLSX from "xlsx";

const Chat_Header = ({
  gd_data,
  study_detail,
  setpoll_form_modal,
  setv_modal_visible,
  seta_modal_visible,
}) => {
  const { user_data } = useSelector((state) => state?.authReducer);

  const createAndDownloadFile = (textContent, fileName) => {
    // const textContent =
    //   "Hello, this is the content of your text file!\nHello, this is the content of your text file!";
    // const fileName = "example.txt";

    // Create a Blob with the text content
    const blob = new Blob([textContent], { type: "text/plain" });

    // Generate a URL for the Blob
    const fileUrl = URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;

    // Trigger a click event to download the file
    link.click();

    // Clean up the URL object
    URL.revokeObjectURL(fileUrl);
  };

  const get_transcripts = () => {
    get_transcripts_api({
      g_id: gd_data?._id,
    })
      .then((res) => {
        const new_arr_text = res.data.trans_res
          ?.map((el) => {
            return (
              el?.createdAt?.split("T")[0] +
              " " +
              el?.createdAt?.split("T")[1]?.slice(0, 5) +
              " " +
              el?.user_lookup[0]?.firstname +
              " " +
              el?.user_lookup[0]?.lastname +
              ": " +
              el?.msg +
              "\n\n"
            );
          })
          .join("");
        // ?.replace(",", "");

        const new_arr = res.data.trans_res?.map((el) => {
          return {
            User:
              el?.user_lookup[0]?.firstname +
              " " +
              el?.user_lookup[0]?.lastname,
            Message: el?.msg,
            Time: el?.createdAt,
          };
        });

        const worksheet = XLSX.utils.json_to_sheet(new_arr);

        // Create a workbook and add the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Generate an Excel file and trigger the download
        XLSX.writeFile(workbook, "Transcripts_"+gd_data?.groupdiscussionname+".xlsx");
        createAndDownloadFile(
          new_arr_text,
          "Transcripts_" + gd_data?.groupdiscussionname + ".txt"
        );
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  return (
    <Flex_box
      className="remove_scrollbar"
      style={{
        justifyContent: "space-between",
      }}
    >
      <b
        style={{
          fontSize: "22px",
        }}
      >
        {gd_data?.groupdiscussionname}
      </b>
      <Flex_box
        style={{
          width: "",
          gap: 10,
        }}
      >
        {user_data?.role != 3 && (
          <Button
            style={{
              width: "120px",
              padding: "7px 0px",
            }}
            label={"Transcripts"}
            onClick={get_transcripts}
          />
        )}
        <button
          id="btn_comp"
          style={{
            width: "40px",
            height: "40px",
            // padding:"0px"
          }}
        >
          <img
            src={share_icon}
            style={{
              height: "15px",
              marginTop: "-8px",
              marginLeft: "5px",
            }}
          />
        </button>
      </Flex_box>
    </Flex_box>
  );
};

export default Chat_Header;
