import React from "react"
import CanvasJSReact from "@canvasjs/react-charts"
import Flex_box from "../../../../utilities/flex_box"

var CanvasJS = CanvasJSReact.CanvasJS
var CanvasJSChart = CanvasJSReact.CanvasJSChart

const Graph_Comp = ({ graph_data, type, label, width }) => {
  const options = {
    animationEnabled: true,
    backgroundColor: "transparent",
    theme: "light2", //"light1", "dark1", "dark2"
    title: {
      text: "",
    },
    axisY: {
      includeZero: true,
    },
    data: [
      {
        type: type || "bar", //change type to bar, line, area, pie, etc
        //indexLabel: "{y}", //Shows y value on all Data Points
        indexLabelFontColor: "#5A5757",
        indexLabelPlacement: "outside",
        dataPoints: graph_data,
      },
    ],
  }

  if (graph_data?.length > 0) {
    return (
      <div
        className="responsive_width_35 study_graph_box_cont"
        style={{
          marginTop: "5px",
          width: width || "",
        }}
      >
        <Flex_box>
          <b
            style={{
              fontSize: "23px",
              color: "#000",
            }}
          >
            {label}
          </b>
        </Flex_box>
        <CanvasJSChart options={options} containerProps={{ width: "100%", height: "300px" }} />
      </div>
    )
  }
  return null
}

export default Graph_Comp
