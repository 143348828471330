export const set_temp_user_action = (payload) => ({
    type: 'SET_TEMP_USER',
    payload
});


export const set_app_balance_action = (payload) => ({
    type: 'SET_APP_BALANCE',
    payload
});


