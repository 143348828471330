import axios from "axios"

// export const page_url = "http://localhost:3000/#/"
export const page_url = "https://nextolive.in/#/";

//export const BASE_URL = 'http://165.73.244.191:5006/api/';

// export const BASE_URL = "http://localhost:5006/api/"
// export const BASE_URL = "https://nextolive.in:5006/api/";
// export const BASE_URL = "http://165.73.244.191:5006/api/";
export const BASE_URL = "https://smartq.nextolive.in/api/";

// export const image_url = "http://localhost:5006/uploads/"
// export const image_url = "https://nextolive.in:5006/uploads/";
// export const image_url = "http://165.73.244.191:5006/uploads/";
export const image_url = "https://smartq.nextolive.in/uploads/";

// export const agora_app_id = "5bb5312ea3d6442793fa0ff00903d662";
export const agora_app_id = "51be5789f05c4329b08255df23707b9c"
export const agora_app_certificate = "a88034ca9f174cb0a62e7df53d046648"

const axios_post = (url, data, token, method) => {
  let config = {
    method: method || "post",
    maxBodyLength: Infinity,
    url: BASE_URL + url,
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
    },
    data: data || {},
  }

  return axios.request(config)
}

const axios_get = (url) => {
  return axios.get(BASE_URL + url)
}

export const user_list_api = (data) => {
  return axios_get("auth/getRespondentList", data)
}

export const get_eligibility_questions_api = () => {
  return axios_get("respondent/getEligibilityQuestions")
}

export const signup_api = (data) => {
  // console.log(data);
  const f_data = new FormData()
  f_data.append("profilepictuer", data?.profilepictuer)
  f_data.append("Email", data?.Email)
  f_data.append("Password", data?.Password)
  f_data.append("UserName", data?.UserName)
  f_data.append("FirstName", data?.FirstName)
  f_data.append("LastName", data?.LastName)
  f_data.append("Role", data?.Role)
  f_data.append("country", data?.country)
  f_data.append("otp", data?.otp)
  f_data.append("index", data?.index)
  if (data?.sec) {
    f_data.append("sec", data?.sec)
  }
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: BASE_URL + "auth/Signup",
    headers: {
      //   "Content-Type": "application/json",
      "Content-Type": "multipart/form-data",
      //   ...f_data.getHeaders(),
    },
    data: f_data,
  }

  return axios.request(config)
}

export const login_api = (data) => {
  return axios_post("auth/login", data)
}

export const verify_otp_api = (data) => {
  return axios_post("auth/VeryfiOtp", data)
}

export const create_business_detail_api = (data) => {
  return axios_post("auth/CreateBusinessDetail", data)
}

export const create_study_api = (data) => {
  return axios_post("auth/CreateStudy", data)
}

export const update_study_api = (data) => {
  return axios_post("auth/UpdateStudy", data)
}

export const create_survey_api = (data) => {
  // debugger;
  return axios_post("auth/CreateSurvey", data)
}

export const BusinessDetailDetailById_api = (data) => {
  return axios_post("auth/BusinessDetailDetailById", data)
}

export const StudyList_api = (data) => {
  return axios_post("respondent/StudyList", data)
}

export const StudyDetails_api = (data, token) => {
  return axios_post("auth/StudyDetailById", data, token)
}

export const resp_StudyDetails_api = (data, token) => {
  return axios_post("respondent/StudyDetailById", data, token)
}

export const GetSurveyById_api = (data) => {
  return axios_post("auth/GetSurveyById", data)
}

export const GetSurveyByStudyId_api = (data) => {
  return axios_post("auth/GetSurveyByStudyId", data)
}

export const GetGDbyStudyId_api = (data) => {
  return axios_post("respondent/getGD", data)
}

export const get_survey_api = (data) => {
  return axios_post("auth/getSurveyList", data)
}

export const get_gd_api = (data) => {
  return axios_post("auth/getGDList", data)
}

export const get_survey_analysis_api = (StudyId) => {
  return axios_post("auth/getSurveyResultAnalysis", {
    StudyId,
  })
}

export const create_GD_api = (data) => {
  return axios_post("auth/CreateGroupDiscussion", data)
}

export const close_study_api = (data) => {
  return axios_post("auth/CloseStudy", data)
}

export const send_notification_api = (data) => {
  return axios_post("auth/sendNotification", data)
}

//---------Respondant APIs------------//

export const give_survey_response_api = (data) => {
  // debugger;
  return axios_post("respondent/CreateSurveyRes", data)
}

// export const get_resp_balance_api = (data) => {
//   // debugger;
//   return axios_post("respondent/MyBalancnDataByUserId", data);
// };

export const send_otp_api = (data) => {
  return axios_post("respondent/sendOTPToForgotPassword", data)
}

export const reset_password_api = (data) => {
  return axios_post("auth/ForgotPassword", data)
}

export const get_survey_resp_api = (SurveyId) => {
  return axios_post("respondent/getSurveyQuestionResponse", {
    SurveyId,
  })
}

export const get_resp_noti_api = (data) => {
  return axios_post("respondent/getNotificationByRespondentId", data)
}

export const set_survey_touched_api = (data) => {
  return axios_post("respondent/setSurveyTouched", data)
}

export const get_resp_studies_api = (data) => {
  return axios_post("respondent/getCompletedOngoingSurvey", data)
}

export const check_eligibility_question_response_api = (data) => {
  return axios_post("respondent/checkEligibilityQuestionResponse", data)
}

export const get_rewards_api = (data) => {
  return axios_post("respondent/getRewards", data)
}

export const update_user_detail_api = (data) => {
  return axios_post("respondent/updateuser", data)
}

export const update_researcher_detail_api = (data) => {
  // debugger;
  return axios_post("auth/updateUser", data)
}

export const get_survey_respondents_api = (data) => {
  return axios_post("auth/getSurveyRespondentList", data)
}

export const getGDbyStudyId_api = (data) => {
  return axios_post("auth/getGDbyStudyId", data)
}

export const update_GD_api = (data) => {
  return axios_post("auth/UpdateGroupDiscussion", data)
}

export const store_message_api = (data, role) => {
  if (role != "2") {
    return axios_post("respondent/store_message", data)
  } else {
    return axios_post("auth/store_message", data)
  }
}

export const get_room_messages_api = (data, role) => {
  if (role != "2") {
    return axios_post("respondent/get_room_messages", data)
  } else {
    return axios_post("auth/get_room_messages", data)
  }
}

export const create_poll_api = (data) => {
  return axios_post("auth/create_poll", data)
}

export const get_polls_api = (data) => {
  return axios_post("auth/get_polls", data)
}

export const poll_answer_api = (data) => {
  return axios_post("auth/poll_answer", data)
}

export const send_gd_join_request_api = (data) => {
  return axios_post("respondent/send_gd_join_request", data)
}

export const send_kyc_review_request_api = (data) => {
  return axios_post("respondent/send_kyc_review_request", data)
}

export const gd_request_action_api = (data) => {
  return axios_post("auth/gd_request_action", data)
}

export const get_gd_requests_api = (data) => {
  return axios_post("auth/get_gd_requests", data)
}

export const delete_message_api = (data, role) => {
  if (role != "2") {
    return axios_post("respondent/delete_message", data)
  } else {
    return axios_post("auth/delete_message", data)
  }
}

export const create_temp_user_api = (data) => {
  return axios_post("auth/create_temp_user", data)
}

export const get_user_survey_responses_api = (data) => {
  return axios_post("auth/get_user_survey_responses", data)
}

export const submit_ans_api = (data) => {
  return axios_post("respondent/submit_ans", data)
}

export const get_survey_resp_count_api = (data, role) => {
  if (role != "2") {
    return axios_post("respondent/get_survey_resp_count", data)
  } else {
    return axios_post("auth/get_survey_resp_count", data)
  }
}

export const get_gd_member_count_api = (data) => {
  return axios_post("respondent/get_gd_member_count", data)
}

export const generate_token_api = (data, role) => {
  if (role != "2") {
    return axios_post("respondent/generate_agora_token", data)
  } else {
    return axios_post("auth/generate_agora_token", data)
  }
}

export const buy_subscription_api = (data) => {
  return axios_post("auth/buy_subscription", data)
}

export const update_subscription_api = (data) => {
  return axios_post("auth/update_user_subscription", data)
}

export const get_subscriptions_api = (data) => {
  return axios_post("auth/get_subscriptions", data)
}

export const send_otp_email_api = (data) => {
  return axios_post("auth/send_otp_email", data)
}

export const get_resp_balance_api = (data) => {
  return axios_post("respondent/get_resp_balance", data)
}

export const verifyotp_api = (data) => {
  return axios_post("auth/verifyotp", data)
}

export const upload_kyc_docs_api = (data) => {
  return axios_post("auth/upload_kyc_docs", data)
}

export const get_kyc_docs_api = (data) => {
  return axios_post("auth/get_kyc_docs", data)
}

export const del_kyc_docs_api = (data) => {
  return axios_post("auth/del_docs", data)
}

export const check_kyc_status_api = (data) => {
  return axios_post("auth/check_kyc_status", data)
}

export const check_study_sec_available_api = (data) => {
  return axios_post("respondent/check_study_sec_available", data)
}

export const GetActiveSubscriptionById_api = (data) => {
  return axios_post("auth/GetActiveSubscriptionById", data)
}

export const logUserActivity_api = (data) => {
  return axios_post("auth/logUserActivity", data)
}

export const send_subscription_confirmationAPI = (data) => {
  return axios_post("auth/SendSubConfirmMail", data)
}

export const CheckOtherSubAndMakeDeactivateAPI = (data) => {
  return axios_post("auth/CheckOtherSubAndMakeDeactivate", data)
}

export const get_sub_data_api = (data) => {
  return axios_post("auth/getSubscriptionDataByUserId", data)
}

export const get_total_study_data_api = (data) => {
  return axios_post("auth/getTotalStudyDataByUserId", data)
}

export const get_total_gd_data_api = (data) => {
  return axios_post("auth/getTotalGDDataByUserId", data)
}

export const GetUserSubscriptionsById_api = (data) => {
  return axios_post("auth/Get_UserSubscriptions_ById", data)
}

export const get_balance_api = (data) => {
  return axios_get("auth/CheckBalance", data)
}

export const get_countries_api = (data) => {
  return axios_get("respondent/GetCountries", data)
}

export const get_product_types_api = (code) => {
  return axios_get("respondent/GetProductTypes?code=" + code)
}

export const get_operators_api = (data) => {
  return axios_get("respondent/GetOperators", data)
}

export const get_variation_codes_api = (product_type_id) => {
  return axios_get("respondent/GetVariationCodes?operator_id=5&product_type_id=" + product_type_id)
}

export const purchase_product_api = (data) => {
  return axios_post("respondent/PurchaseProduct", data)
}

export const check_sub_data_count_api = (data) => {
  return axios_post("auth/check_sub_data_count", data)
}

export const add_top_up_api = (data) => {
  return axios_post("auth/top_up_api", data)
}

export const get_voucher_history_api = (data) => {
  return axios_post("respondent/get_voucher_history", data)
}

export const add_contact_msg_api = (data) => {
  return axios_post("landing/add-contact-data", data)
}

export const generate_unique_usernames_api = (data) => {
  return axios_post("auth/generate_unique_usernames", data)
}

export const get_survey_user_resp_analysis_data_api = (StudyId) => {
  return axios_post("auth/get_survey_user_resp_analysis_data", {
    StudyId,
  })
}

export const get_study_demographics_data_api = (StudyId) => {
  return axios_post("auth/get_study_demographics_data", {
    StudyId,
  })
}

export const get_input_answers_api = (StudyId) => {
  return axios_post("auth/get_input_answers", { StudyId })
}

export const get_researcher_inc_history_api = (data) => {
  return axios_post("auth/get_researcher_inc_history", data)
}

export const upload_image_api = (data) => {
  return axios_post("auth/upload_image", data)
}

export const gd_action_api = (data) => {
  return axios_post("auth/gd-action", data)
}

export const leave_gd_api = (data) => {
  return axios_post("respondent/leave-gd", data)
}

export const researcher_assignment_api = (data) => {
  return axios_post("auth/researcher_assignment", data)
}

export const upd_researcher_assignment_api = (data) => {
  return axios_post("auth/upd_researcher_assignment", data)
}

export const get_assigned_researchers_api = (data) => {
  return axios_post("auth/get_assigned_researchers", data)
}

export const get_researchers_api = (data) => {
  return axios_post("auth/get_researchers", data)
}

export const add_custom_quota_api = (data) => {
  return axios_post("auth/add_quota_request", data)
}

export const report_msg_api = (data) => {
  return axios_post("auth/report_msg", data)
}

export const add_voucher_history_api = (data) => {
  return axios_post("respondent/add_voucher_history", data)
}

export const get_landing_page_data_api = (data) => {
  return axios_post("admin/get_landing_page_data", data);
};

export const report_appeal_api = (data) => {
  return axios_post("auth/report_appeal", data)
}

export const get_report_appeals_api = (data) => {
  return axios_post("auth/get_report_appeals", data)
}

export const get_blocked_status_api = (data) => {
  return axios_post("auth/get_blocked_status", data)
}

export const send_res_email_invitation_api = (data) => {
  return axios_post("auth/send_res_email_invitation", data)
}

export const get_res_email_invitation_api = (data) => {
  return axios_post("auth/get_res_email_invitation", data)
}

export const study_inv_action_api = (data) => {
  return axios_post("auth/study_inv_action", data)
}

export const get_user_study_invitation_api = (data) => {
  return axios_post("auth/get_user_study_invitation", data)
}

export const create_transcripts_api = (data) => {
  return axios_post("auth/create_transcripts", data)
}

export const get_transcripts_api = (data) => {
  return axios_post("auth/get_transcripts", data)
}




