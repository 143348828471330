import React, { useEffect, useMemo, useState } from "react";
import Flex_box from "./flex_box";
import Sidebar from "../screens/Nav_Comp/Sidebar";
import Navbar from "../screens/Nav_Comp/Navbar";
import Footer from "../screens/Nav_Comp/Footer";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "./Modal";
import Button from "./Button";
import Text_Input from "./Text_Input";
// import { dispatch } from "d3";
import {
  get_blocked_status_api,
  get_report_appeals_api,
  report_appeal_api,
} from "../apis/apis";
import { set_user_action } from "../redux_prog/actions/auth_action";
import { alert_action } from "../redux_prog/actions/base_action";

const Layout = (props) => {
  const style = props.style || {};
  const dispatch = useDispatch();
  const { main_page_only, no_nav_func_flag } = props;

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  const [appeal, setappeal] = useState("");
  const [inp_disabled, setinp_disabled] = useState(false);
  // const [blocked, setblocked] = useState(false)

  const { user_data } = useSelector((state) => state?.authReducer);

  const report_appeal = () => {
    if (appeal) {
      report_appeal_api({
        user_id: user_data?._id,
        appeal: appeal,
      })
        .then((res) => {
          // console.warn(res);
          dispatch(
            alert_action({
              type: "success",
              title: "Success",
              msg: "Report appealed successfully!",
            })
          );
        })
        .catch((Err) => {
          console.warn(Err);
        });
    } else {
      dispatch({
        type: "error",
        title: "Empty Field",
        msg: "Please enter appeal text",
      });
    }
  };

  const get_report_appeals = () => {
    get_blocked_status_api({ user_id: user_data?._id })
      .then((res) => {
        dispatch(
          set_user_action({
            ...user_data,
            blocked: res.data.blocked_res[0]?.blocked,
          })
        );
        if (res.data.blocked_res[0]?.blocked == true) {
          get_report_appeals_api({
            user_id: user_data?._id,
          })
            .then((res) => {
              if(res.data.appeal_res[0].appeal){
                setinp_disabled(true);
                setappeal(res.data.appeal_res[0].appeal);
              }
              dispatch(
                set_user_action({
                  ...user_data,
                  blocked: true,
                })
              );
            })
            .catch((err) => {
              console.warn(err);
            });
        } else {
          dispatch(
            set_user_action({
              ...user_data,
              blocked: false,
            })
          );
        }
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  useEffect(get_report_appeals, []);

  // const layout_memo = useMemo(() => {
  // alert(user_data?.blocked);
  if (user_data?.blocked) {
    return (
      <Modal isOpen={true} onClose={() => {}}>
        <Flex_box
          style={{
            flexDirection: "column",
          }}
        >
          <h3>Your account has been blocked by the admin!!</h3>
          <Text_Input
            value={appeal}
            onChange={(e) => {
              setappeal(e.target.value);
            }}
            cont_style={{
              width: "100%",
            }}
            placeholder={"Enter your appeal here!"}
            disabled={inp_disabled}
          />
          <Button
            label={inp_disabled ? "Appeal already sent!" : "Appeal"}
            style={{
              marginTop: "10px",
            }}
            onClick={report_appeal}
            disabled={inp_disabled}
          />
        </Flex_box>
      </Modal>
    );
  }

  return (
    <Flex_box
      className="removescrollbar"
      style={{
        justifyContent: "start",
        // paddingBottom:"50px"
      }}
    >
      {!main_page_only && <Sidebar no_sidebar={props.no_sidebar} />}
      <div
        className="comp_container"
        style={{
          position: "relative",
          width: main_page_only ? "100%" : "",
        }}
      >
        {!main_page_only && (
          <Navbar no_nav_func_flag={no_nav_func_flag || false} />
        )}
        <div
          className="removescrollbar"
          style={{
            width: "100%",
            height: main_page_only ? "100vh" : "78.5vh",
            overflowY: "auto",
            boxSizing: "border-box",
            paddingBottom: "10px",
            ...style,
          }}
        >
          {props.children}
        </div>
        {!main_page_only && <Footer />}
      </div>
    </Flex_box>
  );
  // }, [user_data?.blocked]);

  // alert(user_data?.blocked);
  // return layout_memo;
};

export default Layout;
