import React, { useState } from "react";
import Flex_box from "../../../utilities/flex_box";
import { theme_bg, theme_color } from "../../../utilities/colors";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  buy_subscription_api,
  send_subscription_confirmationAPI,
  CheckOtherSubAndMakeDeactivateAPI,
  update_subscription_api,
} from "../../../apis/apis";
import { addDays } from "../../../utilities/Date_Util";
import { set_user_action } from "../../../redux_prog/actions/auth_action";
import { alert_action } from "../../../redux_prog/actions/base_action";
import TopUp_Modal from "../Subscriptions/Custom_Quota_box";

const Pricing_Box = ({
  price,
  label,
  subtext,
  duration,
  content,
  _id,
  item,
  coin,
  max_respondents,
  study,
  call,
  isActive,
  active_sub,
  modal_box,
  func,
  setisModalVisible
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user_data } = useSelector((state) => state?.authReducer);
  const [modalVisible, setmodalVisible] = useState(false);

  const Duration_arr = duration?.split("/");
  const duration_suffix = Duration_arr[0] > 1 ? "s" : "";

  const inc_days =
    Duration_arr[1] === "Year"
      ? Duration_arr[0] * 365
      : Duration_arr[1] === "Month"
      ? Duration_arr[0] * 30
      : Duration_arr[0] * 7;
      const enterprise_flag = label?.toLowerCase()?.includes("enterprise");

  const buy_subscription = () => {
    if (modal_box) {
      const user_sub_data = {
        plan_id: _id,
        user_id: user_data?._id,
        transaction_id: `7634ui78f357`,
        exp_date: addDays(inc_days),
        coin_limit: coin,
        study_count: study,
        video_call_hrs: call,
        max_respondents,
        status: item?.status,
      };
      func({
        user_sub_data,
        item,
      });
    } else {
      if (!active_sub) {
        const check_user_sub_data = {
          plan_id: _id,
          user_id: user_data?._id,
          transaction_id: `7634ui78f357`,
          coin_limit: coin,
          status: "true",
          exp_date: addDays(inc_days),
        };

        CheckOtherSubAndMakeDeactivateAPI(check_user_sub_data)
          .then((deactivateRes) => {
            console.log("Deactivation API Response:", deactivateRes.data);
          })
          .catch((err) => {
            console.error("Error in deactivation API:", err);
          });

        if (user_data?.sub_id) {
          const user_sub_data = {
            exp_date: addDays(inc_days),
            coin_limit: coin + user_data?.coin_limit,
            study_count: study + user_data?.study_count,
            video_call_hrs: call + user_data?.video_call_hrs,
            max_respondents:
              max_respondents + (user_data?.max_respondents || 0),
            status: item?.status,
            plan_id: _id,
            user_id: user_data?._id,
            transaction_id: `7634ui78f357`,
          };
          update_subscription_api({ user_sub_data, _id: user_data?.sub_id })
            .then((res) => {
              if (res.data.status == 1) {
                dispatch(
                  set_user_action({
                    ...user_data,
                    subscription_data: res.data.Res,
                    user_sub_lookup: [
                      {
                        ...res.data.Res,
                        sub_lookup: [item],
                      },
                    ],
                    ...user_sub_data,
                  })
                );
                dispatch(
                  alert_action({
                    type: "success",
                    title: "Success",
                    msg: "You can now access the features of SmartQ application!",
                  })
                );
                navigate("/my-surveys");
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          const user_sub_data = {
            plan_id: _id,
            user_id: user_data?._id,
            transaction_id: `7634ui78f357`,
            exp_date: addDays(inc_days),
            coin_limit: coin,
            study_count: study,
            video_call_hrs: call,
            max_respondents,
            status: item?.status,
          };
          buy_subscription_api({ user_sub_data })
            .then((res) => {
              if (res.data.status == 1) {
                dispatch(
                  set_user_action({
                    ...user_data,
                    subscription_data: res.data.Res,
                    user_sub_lookup: [
                      {
                        ...res.data.Res,
                        sub_lookup: [item],
                      },
                    ],
                    sub_id: res?.data?.Res?._id,
                    ...user_sub_data,
                    used_coins: 0,
                    created_studies: 0,
                    used_video_call_hrs: 0,
                    total_respondants: 0,
                  })
                );
                dispatch(
                  alert_action({
                    type: "success",
                    title: "Success",
                    msg: "You can now access the features of SmartQ application!",
                  })
                );
                navigate("/my-surveys");
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
      } else {
        dispatch(
          alert_action({
            type: "error",
            title: "Subscription already active",
            msg: "You already have an active subscription!",
          })
        );
      }
    }
    // buy_subscription_api({ user_sub_data })
    //   .then((res) => {
    //     if (res.data.status === 1) {
    //       dispatch(
    //         set_user_action({
    //           ...user_data,
    //           subscription_data: res.data.Res,
    //           user_sub_lookup: [
    //             {
    //               ...res.data.Res,
    //               sub_lookup: [item],
    //             },
    //           ],
    //         })
    //       );
    //       dispatch(
    //         alert_action({
    //           type: "success",
    //           title: "Success",
    //           msg: "You can now access the features of SmartQ application!",
    //         })
    //       );
    //       navigate("/dashboard");
    //       // Call SendSubConfirmMail API
    //       send_subscription_confirmationAPI(user_sub_data)
    //         .then((emailRes) => {
    //           console.log("Email sent successfully:", emailRes.data);
    //         })
    //         .catch((emailErr) => {
    //           console.error("Error sending confirmation email:", emailErr);
    //         });
    //     }
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });
  };


  return (
    <>
      <Flex_box
        className="responsive_width liststartstyle"
        style={{
          width: "",
          flexDirection: "column",
          lineHeight: 0.9,
          boxShadow: "0px 10px 20px #0000000D",
          padding: "20px 10px",
        }}
      >
        <span
          style={{
            fontSize: "25px",
            fontWeight: 700, // Making it bolder
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)", // Adding subtle shadow
            letterSpacing: "1px", // Slightly increasing letter spacing
            color: "#333", // Choosing a strong color (you can customize this)
          }}
        >
          {enterprise_flag? label:""}
        </span>
        <br />
        <span
          style={{
            display: "inline-block",
            fontSize: "24px",
            fontWeight: 700,
            color: "#000",
            textShadow: "1px 1px 2px rgba(0, 0, 0, 0.3)",
          }}
        >
          {price}/{subtext}
        </span>
        <span style={{ display: "none" }}>{coin}</span>

        <Flex_box
          style={{
            flexDirection: "column",
            backgroundColor: theme_color,
            textAlign: "center",
            borderRadius: "10px",
            color: "#fff",
            gap: 15,
            padding: "25px 10px",
            boxSizing: "border-box",
            marginTop: "20px",
          }}
        >
          <span style={{ fontSize: "28px", fontWeight: 600 }}>
            {Duration_arr[0] + " " + Duration_arr[1] + duration_suffix}
          </span>
          <div
            style={{ lineHeight: 1.5 }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
          {/* <span>{gdparticipants}</span> */}

          <button
            id="btn_comp"
            style={{
              fontWeight: 600,
              backgroundColor: isActive ? "#00c853" : "#fff", // Green for active subscription
              color: isActive ? "#fff" : "#000", // White text if active
              marginTop: "20px",
              cursor: isActive ? "default" : "pointer", // Default cursor if active
            }}
            onClick={() => {
              if (enterprise_flag) {
                setmodalVisible(true);
              } else {
                if (modal_box || !isActive) buy_subscription(); // Prevent subscription action if already active
              }
            }}
            disabled={isActive} // Disable button if active
          >
            {isActive ? "Currently Active" : "Subscribe now"}{" "}
            {/* Conditionally render button text */}
          </button>
        </Flex_box>
      </Flex_box>
      <TopUp_Modal
        modalVisible={modalVisible}
        setmodalVisible={setmodalVisible}
        plan_id={_id}
        func={func}
        modal_box={true}
        item={item}
      />
    </>
  );
};

export default Pricing_Box;
