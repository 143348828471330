import React, { useEffect, useState } from "react";
import { theme_color } from "../../../utilities/colors";
import logo_white from "../../../assets/Group 40944@2x.png";
import Flex_box from "../../../utilities/flex_box";
import Button from "../../../utilities/Button";
// import Nav_Link from "../Nav_Comp/Nav_Link";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./Sidebar";
import ham_menu from "../../../assets/ham_menu.png";
import { useMediaQuery } from "@mui/material";
import { sidebar_action } from "../../../redux_prog/actions/base_action";
// import "./Sidebar/sidebar.css";

const Nav_Comp = ({ open_modal,top_value }) => {
  const visible = useSelector((state) => state?.baseReducer?.sidebar_open);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const max_w_500 = useMediaQuery("(max-width:500px)");
  const { pathname } = useLocation();

  const head_comp = document.getElementById("nav_header");
  const topvalue = head_comp?.getBoundingClientRect();

 


  const Nav_Link = ({ label, to }) => {
    return (
      <li class="nav-item">
        <Link
          class={"nav-link " + (to == pathname ? "active" : "")}
          aria-current="page"
          to={to}
        >
          {label}
        </Link>
      </li>
    );
  };

  return (
    <>
      <header id="nav_header">
        <nav
          class={
            "navbar navbar-expand-lg navbar-light fixed-top " +
            (top_value ? "bg-white border border-b" : "bg-transparent")
          }
          style={{
            zIndex: 500,
          }}
        >
          <div class="container">
            <div class="navbar-brand"
            style={{
              cursor:"pointer"
            }}
            onClick={()=>{
              navigate("/")
            }}>
              <img src="assets/images/logo.png" width="200px" />
            </div>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
                {/* <Nav_Link id="nav_link_1" label="Home" to="/" />
            <Nav_Link id="nav_link_2" label="Abouts Us" to="/about-us" />
            <Nav_Link id="nav_link_3" label="Features" to={"/features"} />
            <Nav_Link id="nav_link_4" label="Pricing" to={"/pricing"} />
            <Nav_Link id="nav_link_5" label="Support" to={"/support"} /> */}
                <Nav_Link label={"Home"} to="/" />
                <Nav_Link label={"About Us"} to="/about-us" />
                <Nav_Link label={"Features"} to="/features" />
                <Nav_Link label={"Pricing"} to="/pricing" />
                <Nav_Link label={"Support"} to="/support" />
              </ul>
              <ul class="list-unstyled mb-0">
                <li
                  class="nav-item"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={open_modal}
                >
                  <span class="default-btn clip-btn">
                    <span>Sign up / Login</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Nav_Comp;
