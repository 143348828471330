import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Flex_box from "../../../utilities/flex_box";
import { theme_color } from "../../../utilities/colors";
import Study_Box from "./Study_Box";
import { StudyList_api, get_survey_api } from "../../../apis/apis";
import { useDispatch, useSelector } from "react-redux";
import {
  alert_action,
  loading_action,
} from "../../../redux_prog/actions/base_action";
import Button from "../../../utilities/Button";
import Study_Pagination from "../../../utilities/Study_Pagination";

const Dash_comp = () => {
  const dispatch = useDispatch();
  const [selected_tab, setselected_tab] = useState(1);
  const [study_list, setstudy_list] = useState([]);
  const { user_data } = useSelector((state) => state?.authReducer);

  const [ongoing_list, setongoing_list] = useState([]);
  const [completed_list, setcompleted_list] = useState([]);
  const [pageIndex, setpageIndex] = useState(0);
  const [limit, setlimit] = useState(10);
  const get_study_list = () => {
    dispatch(loading_action(true));
    get_survey_api({
      UserId: user_data?._id,
      pageIndex,
      limit,
    })
      .then((res) => {
        // console.log("survey list", res);
        dispatch(loading_action(false));
        if (res.data.status == 1) {
          setstudy_list(res.data.Res);

          setongoing_list(
            res.data.Res.filter((el) => {
              return (
                !el.study_lookup[0]?.iscomplete && !el.study_lookup[0]?.isClosed
              );
            })
          );

          setcompleted_list(
            res.data.Res.filter((el) => {
              return (
                el.study_lookup[0]?.iscomplete == true ||
                el.study_lookup[0]?.isClosed
              );
            })
          );
        }
        console.log(res);
      })
      .catch((err) => {
        dispatch(loading_action(false));
        dispatch(
          alert_action({
            type: "error",
            title: "Error",
            msg: "Some error occurred!",
          })
        );
      });
  };

  useEffect(get_study_list, [pageIndex, limit]);

  return (
    <Tabs
      style={{
        padding: "15px 0px 15px 23px",
      }}
    >
      <Flex_box>
        <Tab
          onClick={() => {
            setselected_tab(1);
          }}
          style={{
            width: "50%",
            borderBottom: selected_tab == 1 ? "2px solid " + theme_color : "",
            textAlign: "center",
            padding: "10px 0px 20px 0px",
            opacity: selected_tab == 1 ? 1 : 0.5,
            color: theme_color,
            fontWeight: selected_tab == 1 ? 800 : 400,
            fontSize: "18px",
          }}
        >
          ONGOING STUDIES
        </Tab>
        <Tab
          onClick={() => {
            setselected_tab(2);
          }}
          style={{
            width: "50%",
            borderBottom: selected_tab == 2 ? "2px solid " + theme_color : "",
            textAlign: "center",
            padding: "10px 0px 20px 0px",
            opacity: selected_tab == 2 ? 1 : 0.5,
            color: theme_color,
            fontWeight: selected_tab == 2 ? 800 : 400,
            fontSize: "18px",
          }}
        >
          COMPLETED STUDIES
        </Tab>
      </Flex_box>

      <Study_Pagination
        arr={selected_tab == 1 ? ongoing_list : completed_list}
        pageIndex={pageIndex}
        setpageIndex={setpageIndex}
      />

      <TabPanel
        style={{
          padding: "10px 0px 20px 0px",
          width: "100%",
        }}
      >
        <Flex_box
          style={{
            flexWrap: "wrap",
            justifyContent: "start",
            gap: 20,
            maxHeight: "51vh",
            overflowY: "auto",
            alignItems: "",
          }}
        >
          {ongoing_list?.length > 0 &&
            ongoing_list?.map((el, index) => {
              return <Study_Box progress={"30%"} item={el} />;
            })}
        </Flex_box>
      </TabPanel>
      <TabPanel
        style={{
          padding: "20px 0px",
          width: "100%",
        }}
      >
        <Flex_box
          style={{
            flexWrap: "wrap",
            justifyContent: "start",
            gap: 20,
            maxHeight: "51vh",
            overflowY: "auto",
            alignItems: "",
          }}
        >
          {completed_list?.length > 0 &&
            completed_list?.map((el, index) => {
              return <Study_Box progress={"100%"} item={el} />;
            })}
        </Flex_box>
      </TabPanel>
    </Tabs>
  );
};

export default Dash_comp;
