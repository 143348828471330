import React, { useEffect, useState } from "react";
import Layout from "../../../utilities/Layout";
import Intro_Comp from "./Intro_Comp";
import Question_Box from "./Question_Box";
import Survey_Completed from "./Survey_Completed";
import Eligibility_Question from "./Eligibility_Question";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  alert_action,
  loading_action,
} from "../../../redux_prog/actions/base_action";
import {
  get_user_survey_responses_api,
  GetSurveyByStudyId_api,
  StudyDetails_api,
} from "../../../apis/apis";

const Take_Survey = () => {
  const state = useLocation();
  const dispatch = useDispatch();
  const study_id = state?.search?.split("?")[1];
  const navigate = useNavigate();

  const { user_data } = useSelector((state) => state?.authReducer);

  const [eligibility_question, seteligibility_question] = useState(false);
  const [intro_completed, setintro_completed] = useState(false);
  const [survey_completed, setsurvey_completed] = useState(false);
  const [survey_details, setsurvey_details] = useState({});
  const { surveydata, questionsdata } = state?.state || {};
  const [last_question, setlast_question] = useState("");
  const [q_responses, setq_responses] = useState([]);

  const { temp_user } = useSelector((state) => state?.appReducer);

  const get_survey_details = () => {
    dispatch(loading_action(true));
    GetSurveyByStudyId_api({ studyid: study_id, study_data: true })
      .then((res) => {
        if (res.data.status == 1) {
          if (temp_user?._id) {
            if (res.data.Res?.surveydata?.study_lookup[0]?.avail_for_non_reg) {
              setsurvey_details(res.data.Res);
              get_user_survey_responses(res.data.Res?.surveydata);
            } else {
              navigate("/login");
            }
          } else {
            setsurvey_details(res.data.Res);
            get_user_survey_responses(res.data.Res?.surveydata);
          }
        } else {
          dispatch(
            alert_action({
              title: `No Questions`,
              type: "error",
              msg: "There are no questions in study!",
            })
          );
        }
        dispatch(loading_action(false));
      })
      .catch((Err) => {
        dispatch(loading_action(false));
        console.log("====================================");
        console.log(Err);
        console.log("====================================");
      });
  };

  const get_user_survey_responses = (surveydata) => {
    get_user_survey_responses_api({
      user_id: temp_user?._id || user_data?._id,
      survey_id: surveydata?._id,
    })
      .then((res) => {
        const q_data = res.data.question;
        if (q_data?.length > 0) {
          setintro_completed(true);
          const q_res_data = q_data[q_data?.length - 1]?.question_res_lookup;
          questionsdata?.map((el, index) => {
            if (
              el?.QuestionData?._id ==
              q_res_data[0]
                .next_question
            ) {
              // console.warn(el?.QuestionData)
              setlast_question(index);
            } else {
              console.warn("-");
            }
          });

          setq_responses(
            [].concat(
              ...q_data.map((el) => {
                return el.question_res_lookup.map((li)=>{
                  return {
                    ...li,
                    q_res_details:res.data.Res.filter((elem)=>{
                      return elem?.question?._id == li.questionid
                    })
                  }
                });
              })
            )
          );
          
          // setlast_question(q_res_data[q_res_data?.length - 1]?.questionid);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (user_data?._id) {
      get_user_survey_responses(surveydata);
    } else {
      if (temp_user?._id) {
        get_survey_details();
      } else {
        navigate("/add-temp-user?" + study_id);
      }
      // dispatch(
      //   alert_action({
      //     type: "error",
      //     title: "Unregistered User",
      //     msg: "You are not registered on the yaarnbox, please register yourself as a respondent!",
      //   })
      // );
      // navigate("/login");
    }
  }, [study_id, user_data]);

  return (
    <Layout
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "start",
        paddingTop: "40px",
      }}
      main_page_only={study_id}
    >
      {!intro_completed && !eligibility_question && (
        <Intro_Comp
          survey_details={survey_details}
          seteligibility_question={seteligibility_question}
          setintro_completed={setintro_completed}
          study_id={study_id}
        />
      )}
      {!intro_completed && eligibility_question && (
        <Eligibility_Question
          survey_details={survey_details}
          setintro_completed={setintro_completed}
        />
      )}
      ,
      {intro_completed && !survey_completed && (
        <Question_Box
          survey_details={survey_details}
          setintro_completed={setintro_completed}
          setsurvey_completed={setsurvey_completed}
          study_id={study_id}
          last_question={last_question}
          q_responses={q_responses}
          setq_responses={setq_responses}
          get_user_survey_responses={get_user_survey_responses}
        />
      )}
      {survey_completed && (
        <Survey_Completed
          survey_details={survey_details}
          setsurvey_completed={setsurvey_completed}
        />
      )}
    </Layout>
  );
};

export default Take_Survey;
