import React, { useEffect, useState } from "react";
import Flex_box from "../../../utilities/flex_box";
import Layout from "../../../utilities/Layout";
import Subscription_Box from "./Subscription_Box";
import { get_subscriptions_api } from "../../../apis/apis";

const Select_Sub_Page = () => {
  const [subscriptions, setsubscriptions] = useState([]);
  const get_subscriptions = () => {
    get_subscriptions_api()
      .then((res) => {
        setsubscriptions(res.data.Res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const first_sub = subscriptions?.length > 0 ? subscriptions[0] : "";

  useEffect(get_subscriptions, []);

  return (
    <Layout no_nav_func_flag={true} no_sidebar={true}>
      <Flex_box
        className="liststartstyle"
        style={{
          flexDirection: "column",
          justifyContent: "start",
          position: "fixed ",
          top: "120px",
          right: "0px",
          height: "85vh",
          width: "100vw",
          lineHeight: 1.8,
          paddingTop: "20px",
        }}
      >
        <b style={{ fontSize: "24px" }}>Subscriptions</b>
        <span style={{ fontSize: "14px" }}>
          Unlock unlimited access to all the features
        </span>

        <Flex_box
          style={{
            gap: 10,
            marginTop: "25px",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {subscriptions.map((sub, index) => (
            <Subscription_Box
              key={sub._id} // Use a unique key
              label={sub.Name}
              subtext={"from $" + sub.Amount + "/" + sub.Cycle}
              content_arr={[
                <div dangerouslySetInnerHTML={{ __html: sub.Description }} />,
              ]}
              btn_text={"See Pricing"}
              path={"/sub-price"}
              data={subscriptions}
              sub_data={sub}
            />
          ))}
          {/* <Subscription_Box
            label={"Continue Free"}
            subtext={<span>&nbsp;</span>}
            content_arr={[
              <span>100 Minutes voice call</span>,
              <span>100 Minutes of video call</span>,
              <span>&nbsp;</span>,
              <span>&nbsp;</span>,
            ]}
            btn_text={"Continue"}
            path={"/dashboard"}
          /> */}
        </Flex_box>
      </Flex_box>
    </Layout>
  );
};

export default Select_Sub_Page;
