import React, { useState } from "react";
import Text_Input from "../../../../../utilities/Text_Input";
import Select_box from "../../../../../utilities/Select_box";
import Flex_box from "../../../../../utilities/flex_box";
import { useLocation, useNavigate } from "react-router-dom";
import { theme_bg, theme_color } from "../../../../../utilities/colors";
import File_input from "../../../../../utilities/File_input";
import upload_img from "../../../../../assets/Group 33339.png";
import MCQ_Box from "./MCQ_Box";
import {
  create_survey_api,
  send_notification_api,
} from "../../../../../apis/apis";
import { useDispatch, useSelector } from "react-redux";
import { alert_action } from "../../../../../redux_prog/actions/base_action";
import Button from "../../../../../utilities/Button";
import { convert_into_base64 } from "../../../../../utilities/Image_conv";
import { get_array } from "../../../../../utilities/utilities";
import { Tooltip } from "@mui/material";

const Preview = () => {
  const r_state = useLocation();
  console.warn(r_state);

  const navigate = useNavigate();

  const question_data = localStorage.getItem("question_data")
    ? JSON.parse(localStorage.getItem("question_data"))
    : {};

  const [q_data, setq_data] = useState(question_data);

  const { SurveyData, Questions = [], image } = q_data || {};

  const { user_data } = useSelector((state) => state?.authReducer) || {};
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);

  const [editibleQuestion, setEditibleQuestion] = useState(null);
  const [updateSaveButtonActive, setUpdateSaveButtonActive] = useState(false);
  const [questions, setQuestions] = useState(Questions);

  const create_survey = () => {
    const ques_data = {
      ...q_data,
      Questions:q_data.Questions.sort((a,b)=>a.set - b.set)
    };
// console.warn({ques_data});

    setloading(true);
    create_survey_api({
      ...ques_data,
      SurveyData: {
        ...SurveyData,
        userid: user_data?._id,
      },
      image: image?.split(",")[1],
      study_sec: r_state?.state?.sec,
      study_name: r_state?.state?.studyname,
    })
      .then((res) => {
        // // debugger;
        setloading(false);
        localStorage.removeItem("question_data");
        // try {
        // send_notification_api({
        //   UserId: user_data?._id,
        //   formId: "45345345fcgdfg",
        //   toId: [],
        //   msg:
        //     "You have been invited to " +
        //     r_state?.state?.studyname +
        //     " study",
        //   title: "Study Invitation",
        //   type: "Survey",
        //   notificatonTypeId: SurveyData?.studyid,
        //   sec: r_state?.state?.sec,
        //   sender_type: 1,
        // });
        // } catch (error) {
        //   console.log(error);
        // }
        navigate("/my-surveys");
        dispatch(
          alert_action({
            type: "success",
            title: "Success",
            msg: "Survey created successfully!",
          })
        );
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const upd_local_survey_data = (obj) => {
    setq_data({
      ...q_data,
      ...obj,
    });
    localStorage.setItem(
      "question_data",
      JSON.stringify({
        ...q_data,
        ...obj,
      })
    );
  };

  const handelEditibleQuestion = (index) => {
    setEditibleQuestion(index);
  };

  const handelSaveEdit = () => {
    upd_local_survey_data({
      Questions: questions,
    });
    setUpdateSaveButtonActive(false);
    setEditibleQuestion(null);
  };

  const handelCandelEdit = (index) => {
    setEditibleQuestion(null);
    setQuestions(q_data.Questions);
  };

  const handelUpdateOption = (e, questionIndex, optionIndex) => {
    setUpdateSaveButtonActive(true);
    questions[questionIndex].Option[optionIndex].optiontitle = e.target.value;
    setQuestions([...questions]);
  };

  const handelUpdateSubOption = (
    e,
    questionIndex,
    optionIndex,
    suboptionIndex
  ) => {
    setUpdateSaveButtonActive(true);
    questions[questionIndex].Option[optionIndex].sub_options[
      suboptionIndex
    ].optiontitle = e.target.value;
    setQuestions([...questions]);
  };

  const handelUpdateQuestion = (e, questionIndex) => {
    setUpdateSaveButtonActive(true);
    questions[questionIndex].questiontitle = e.target.value;
    setQuestions([...questions]);
  };

  const handelUpdateSub_text = (e, questionIndex) => {
    setUpdateSaveButtonActive(true);
    questions[questionIndex].sub_text = e.target.value;
    setQuestions([...questions]);
  };

  const handelUpdatemax_rating = (e, questionIndex) => {
    setUpdateSaveButtonActive(true);
    questions[questionIndex].max_rating = e.target.value;
    setQuestions([...questions]);
  };

  const add_option = (questionIndex, q_type) => {
    const sub_op_obj =
      q_type == 4
        ? {
            sub_options: [
              {
                optiontitle: "",
                answer: false,
              },
            ],
          }
        : {};

    const lastOption =
      questions[questionIndex].Option[
        questions[questionIndex].Option.length - 1
      ];
    if (lastOption && lastOption.optiontitle) {
      const newQuestion = {
        ...questions[questionIndex],
        Option: [
          ...questions[questionIndex].Option,
          {
            optiontitle: "",
            answer: false,
            dependent_question: 0,
            ...sub_op_obj,
          },
        ],
      };
      const newQuestions = [
        ...questions.slice(0, questionIndex),
        newQuestion,
        ...questions.slice(questionIndex + 1),
      ];
      setQuestions(newQuestions);
    } else {
      dispatch(
        alert_action({
          type: "error",
          title: "Empty option",
          msg: "Please fill the option first!",
        })
      );
    }
  };

  const add_sub_option = (questionIndex, option_index) => {
    // debugger;
    const options = questions[questionIndex].Option;
    const lastOption =
      options[option_index].sub_options[
        options[option_index].sub_options.length - 1
      ];
    if (lastOption && lastOption.optiontitle) {
      const newQuestion = {
        ...options[option_index],
        sub_options: [
          ...options[option_index].sub_options,
          {
            optiontitle: "",
            answer: false,
          },
        ],
      };
      const newOptions = [
        ...options.slice(0, option_index),
        newQuestion,
        ...options.slice(option_index + 1),
      ];
      setQuestions(
        questions?.map((li, li_ind) => {
          if (questionIndex == li_ind) {
            return {
              ...li,
              Option: newOptions,
            };
          } else {
            return li;
          }
        })
      );
    } else {
      dispatch(
        alert_action({
          type: "error",
          title: "Empty option",
          msg: "Please fill the option first!",
        })
      );
    }
  };

  const handelRemoveOption = (q_ind, o_ind) => {
    setQuestions(
      questions.map((el, index) => {
        if (index == q_ind) {
          return {
            ...el,
            Option: el?.Option?.filter((li, ind) => {
              return ind != o_ind;
            }),
          };
        } else {
          return el;
        }
      })
    );
    setUpdateSaveButtonActive(true);
  };

   const handleEndFlag = (q_ind, o_ind,flag)=>{
    setQuestions(
      questions.map((el, index) => {
        if (index == q_ind) {
          return {
            ...el,
            Option: el?.Option?.map((li, ind) => {
              // return ind != o_ind;
              if(ind == o_ind){
return {
  ...li,
  end_flag:flag
}
              }
              else {
                return li
              }
            }),
          };
        } else {
          return el;
        }
      })
    );
    setUpdateSaveButtonActive(true);
  }
  const handelRemoveSubOption = (q_ind, o_ind, s_o_ind) => {
    setQuestions(
      questions.map((el, index) => {
        if (index == q_ind) {
          return {
            ...el,
            Option: el?.Option?.map((li, el_ind) => {
              if (o_ind == el_ind) {
                return {
                  ...li,
                  sub_options: el?.sub_options?.filter((li, ind) => {
                    return ind != s_o_ind;
                  }),
                };
              } else {
                return li;
              }
            }),
          };
        } else {
          return el;
        }
      })
    );
    setUpdateSaveButtonActive(true);
  };

  const handelSelectedQuestion = (s_no, ind, val) => {
    // console.log({ allQuestions, id: item.serial_no, ind, val });

    setQuestions(
      questions.map((el) => {
        if (el.serial_no == s_no) {
          return {
            ...el,
            Option: el.Option?.map((li, index) => {
              if (index == ind) {
                return {
                  ...li,
                  dependent_question: val,
                  switch_to_set: 0,
                  // switch_to:0,
                };
              } else {
                return {
                  ...li,
                  // dependent_question: 0
                };
              }
            }),
          };
        } else {
          return el;
        }
      })
    );
  };

  const handelSelectedSet = (s_no, ind, val) => {
    // console.log({ allQuestions, id: item.serial_no, ind, val });

    setQuestions(
      questions.map((el) => {
        if (el.serial_no == s_no) {
          return {
            ...el,
            Option: el.Option?.map((li, index) => {
              if (index == ind) {
                return {
                  ...li,
                  switch_to_set: val,
                  switch_to: 0,
                  dependent_question: 0,
                };
              } else {
                return {
                  ...li,
                  // dependent_question: 0
                };
              }
            }),
          };
        } else {
          return el;
        }
      })
    );
  };

  const transformed_q_Data = questions?.reduce((acc, question) => {
    // Find the existing set in the accumulator
    let setGroup = acc.find((group) => group.set == question.set);

    if (!setGroup) {
      // If the set group doesn't exist, create it
      setGroup = { set: question.set, questions: [] };
      acc.push(setGroup);
    }

    // Add the current question to the appropriate set group
    setGroup.questions.push(question);

    return acc;
  }, []);

  return (
    <div
      style={{
        padding: "20px",
        boxSizing: "border-box",
        // maxHeight: "77vh",
        height: "72.5vh",
        overflowY: "auto",
      }}
    >
      <div
        className="removescrollbar responsive_width_35"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}
      >
        <span
          style={{
            fontSize: "26px",
            fontStyle: "italic",
            fontWeight: "700",
          }}
        >
          Preview survey
        </span>
        <File_input
          onChange={(e) => {
            convert_into_base64(e[0], (base64) => {
              upd_local_survey_data({
                image: base64,
              });
            });
          }}
          renderItem={
            image ? (
              <img
                src={image}
                style={{
                  height: "130px",
                  width: "240px",
                  border: "2px dashed " + theme_color,
                  borderRadius: 10,
                }}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "start",
                  gap: 10,
                }}
              >
                <span style={{ fontSize: "10px", marginLeft: "10px" }}>
                  {"Uplaod Survey Image"}
                </span>
                <button
                  style={{
                    background: theme_bg,
                    border: "2px dashed " + theme_color,
                    borderRadius: 10,
                    padding: "35px 0px",
                    width: "240px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={upload_img}
                    style={{
                      height: "50px",
                    }}
                  />
                </button>
              </div>
            )
          }
        />
        <Text_Input
          label={"Survey Title"}
          cont_style={{
            width: "100%",
          }}
          input_style={
            {
              // width: "35vw"
            }
          }
          value={SurveyData?.surveytitle}
          onChange={(e) => {
            upd_local_survey_data({
              SurveyData: {
                ...SurveyData,
                surveytitle: e.target.value,
              },
            });
          }}
        />
        <Text_Input
          label={"Survey Description"}
          cont_style={{
            width: "100%",
          }}
          input_style={{
            height: "60px",
          }}
          textArea={true}
          value={SurveyData?.surveydescription}
          onChange={(e) => {
            upd_local_survey_data({
              SurveyData: {
                ...SurveyData,
                surveydescription: e.target.value,
              },
            });
          }}
        />

        <div className="responsive_width_35">
          <b>Questions</b>
        </div>
        {transformed_q_Data?.length > 0 && (
          <>
            {transformed_q_Data?.map((li) => {
              return (
                <>
                  <Flex_box>
                    <h3
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      Set {li?.set}
                    </h3>
                  </Flex_box>
                  {li?.questions?.length > 0 &&
                    li?.questions?.map((el, index) => {
                      const related =
                        el?.Option?.filter((li) => {
                          return (
                            li?.dependent_question > 0 || li?.switch_to_set > 0
                          );
                        })?.length > 0;

                      const question_list = questions
                        .filter((li) => {
                          return (
                            li?.serial_no != el?.serial_no && li.set == el.set
                          );
                        })
                        ?.map((li) => {
                          return {
                            label: li?.questiontitle,
                            value: li?.serial_no,
                          };
                        });

                      const st_list = get_array(
                        transformed_q_Data?.length - li.set
                      )?.map((elem, elem_index) => {
                        return {
                          label: "Set " + (parseInt(li.set) + 1 + elem_index),
                          value: parseInt(li.set) + 1 + elem_index,
                        };
                      });

                      return (
                        <>
                          {index != editibleQuestion ? (
                            <MCQ_Box
                              item={el}
                              index={index}
                              key={index}
                              q_data={q_data}
                              setq_data={setq_data}
                              setQuestions={setQuestions}
                              image={image}
                              handelEditibleQuestion={handelEditibleQuestion}
                            />
                          ) : (
                            <>
                              {/* Update Question Box */}
                              <Flex_box
                                className="responsive_width_35"
                                style={{
                                  padding: "15px",
                                  paddingRight: "0px",
                                  // paddingTop: "0px",
                                  borderRadius: "8px",
                                  flexDirection: "column",
                                  width: "",
                                  justifyContent: "start",
                                  alignItems: "start",
                                  boxShadow: "0px 0px 10px #0000000D",
                                }}
                              >
                                <Text_Input
                                  label={"Question"}
                                  input_style={{
                                    width: "35vw",
                                  }}
                                  value={el?.questiontitle}
                                  onChange={(e) => {
                                    // upd_question_data("questiontitle", e.target.value);
                                    handelUpdateQuestion(e, index);
                                  }}
                                />
                                <Text_Input
                                  label={"Hint"}
                                  input_style={{
                                    width: "35vw",
                                    // marginTop:"19px"
                                  }}
                                  cont_style={{
                                    marginTop: "10px",
                                  }}
                                  value={el?.sub_text}
                                  onChange={(e) => {
                                    // upd_question_data("questiontitle", e.target.value);
                                    handelUpdateSub_text(e, index);
                                  }}
                                />
                                {el?.questiontype == 5 && (
                                  <Text_Input
                                    label={"Max Rating"}
                                    input_style={{
                                      width: "35vw",
                                      // marginTop:"19px"
                                    }}
                                    cont_style={{
                                      marginTop: "10px",
                                    }}
                                    value={el?.max_rating}
                                    onChange={(e) => {
                                      handelUpdatemax_rating(e, index);
                                    }}
                                  />
                                )}
                                {el?.questiontype != 4 &&
                                  el?.Option?.length > 0 &&
                                  el?.Option.map((li, ind) => {
                                    return (
                                      <>
                                        <div
                                          // className="responsive_width_35"
                                          style={{
                                            marginTop: ind == 0 ? "10px" : "",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 8,
                                            position: "relative",
                                            width: "29vw",
                                          }}
                                        >
                                          <Text_Input
                                            label={
                                              ind == 0
                                                ? "Update options below"
                                                : ""
                                            }
                                            placeholder={"Option " + (ind + 1)}
                                            value={li?.optiontitle}
                                            onChange={(e) => {
                                              handelUpdateOption(e, index, ind);
                                            }}
                                            input_style={{
                                              width: "28vw",
                                            }}
                                          />
                                          {related && (
                                            <Flex_box
                                              style={{
                                                width: "",
                                                gap: 10,
                                                marginTop: "18px",
                                              }}
                                            >
                                              <Select_box
                                                options={question_list}
                                                placeholder={"Switch to"}
                                                //  value={selectedQuestion}
                                                onChange={(e) => {
                                                  handelSelectedQuestion(
                                                    el.serial_no,
                                                    ind,
                                                    e.target.value
                                                  );
                                                }}
                                                input_style={{
                                                  height: "46px",
                                                  // width:"30%"
                                                }}
                                                cont_style={{
                                                  // marginTop: "10px",
                                                  width: "10vw",
                                                }}
                                                value={li.dependent_question}
                                              />
                                              <Select_box
                                                options={st_list}
                                                placeholder={"Switch to Set"}
                                                //  value={selectedQuestion}
                                                onChange={(e) => {
                                                  handelSelectedSet(
                                                    el.serial_no,
                                                    ind,
                                                    e.target.value
                                                  );
                                                }}
                                                input_style={{
                                                  height: "46px",
                                                  // width:"30%"
                                                }}
                                                cont_style={{
                                                  // marginTop: "10px",
                                                  width: "10vw",
                                                }}
                                                value={li.switch_to_set}
                                              />
                                            </Flex_box>
                                          )}
                                          {el?.Option?.length > 1 && (
                                            <Flex_box
                                            style={{
                                              width:"",
                                              gap:10
                                            }}
                                            >
 <div
                                              onClick={() =>
                                                handelRemoveOption(index, ind)
                                              }
                                              style={{
                                                position: "absolute",
                                                top: index == 0 ? "50%" : "30%",
                                                right: related
                                                  ? "20px"
                                                  : "-58px",
                                                color: "red",
                                                fontSize: "20px",
                                                cursor: "pointer",
                                                border: "1px solid red",
                                                padding: "0px 5px 0px 6px",
                                                borderRadius: "50%",
                                              }}
                                            >
                                              <i class="fa-solid fa-xmark"></i>
                                            </div>
                                            {
                                                                el?.questiontype == 3 &&
                                                                <div
                                                                style={{
                                                                  position: "absolute",
                                                                  top: index == 0 ? "50%" : "30%",
                                                                  right: "-98px",
                                                                }}
                                                                >
                                            
                                                                  <Tooltip title={li.end_flag?"Survey will end here":"Click to make the survey end here"} >
                                                                <div
                                                                  onClick={() => handleEndFlag(index, ind,li.end_flag?false:true)}
                                                                  style={{
                                                                    color: "red",
                                                                    fontSize: "20px",
                                                                    cursor: "pointer",
                                                                    border: "1px solid red",
                                                                    padding: "0px 5px 0px 6px",
                                                                    borderRadius: "50%",
                                                                  }}
                                                                >
                                                                  <i class="fa-solid fa-ban"></i>
                                                                </div>
                                                                </Tooltip>
                                                                </div>
                                                              }
                                            </Flex_box>
                                           
                                          )}
                                        </div>
                                      </>
                                    );
                                  })}

                                {el?.questiontype == 4 &&
                                  el?.Option?.length > 0 &&
                                  el?.Option.map((li, ind) => {
                                    return (
                                      <>
                                        <div
                                          // className="responsive_width_35"
                                          style={{
                                            marginTop: "10px",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 8,
                                            position: "relative",
                                            width: "29vw",
                                          }}
                                        >
                                          <Text_Input
                                            label={"Update options below"}
                                            placeholder={"Option " + (ind + 1)}
                                            value={li?.optiontitle}
                                            onChange={(e) => {
                                              handelUpdateOption(e, index, ind);
                                            }}
                                            input_style={{
                                              width: "28vw",
                                              // marginTop: index == 0 ? "10px" : "",
                                            }}
                                          />
                                          {el?.Option?.length > 1 && (
                                            <div
                                              onClick={() =>
                                                handelRemoveOption(index, ind)
                                              }
                                              style={{
                                                position: "absolute",
                                                top: index == 0 ? "50%" : "30%",
                                                right: "-58px",
                                                color: "red",
                                                fontSize: "20px",
                                                cursor: "pointer",
                                                border: "1px solid red",
                                                padding: "0px 5px 0px 6px",
                                                borderRadius: "50%",
                                              }}
                                            >
                                              <i class="fa-solid fa-xmark"></i>
                                            </div>
                                          )}
                                        </div>
                                        {li.sub_options?.length > 0 &&
                                          li?.sub_options?.map(
                                            (elem, ind_el) => {
                                              return (
                                                <div
                                                  // className="responsive_width_35"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginTop:
                                                      ind_el == 0 ? "10px" : 0,
                                                    position: "relative",
                                                    width: "29vw",
                                                  }}
                                                >
                                                  <Text_Input
                                                    label={
                                                      ind_el == 0
                                                        ? "Update sub options below"
                                                        : ""
                                                    }
                                                    placeholder={
                                                      "Sub-Option " +
                                                      (ind_el + 1)
                                                    }
                                                    value={elem?.optiontitle}
                                                    onChange={(e) => {
                                                      handelUpdateSubOption(
                                                        e,
                                                        index,
                                                        ind,
                                                        ind_el
                                                      );
                                                    }}
                                                    input_style={{
                                                      width: "25vw",
                                                      marginTop:
                                                        index == 0
                                                          ? "10px"
                                                          : "",
                                                    }}
                                                  />
                                                  {
                                                    // Option?.length > 1 &&
                                                    // <a
                                                    //   onClick={() =>
                                                    //   }
                                                    //   style={{
                                                    //     position: "relative",
                                                    //     top: "12px",
                                                    //     left: "8px",
                                                    //     color: "black",
                                                    //     fontSize: "20px",
                                                    //   }}
                                                    // >
                                                    //   <i class="fa-solid fa-plus"></i>
                                                    // </a>
                                                  }

                                                  <div
                                                    style={{
                                                      position: "absolute",
                                                      top:
                                                        ind_el == 0
                                                          ? "50%"
                                                          : "30%",
                                                      right: "-58px",
                                                      display: "flex",
                                                      alignItems: "center",
                                                      gap: 8,
                                                    }}
                                                  >
                                                    {
                                                      // Option?.length > 1 &&
                                                      <div
                                                        onClick={() =>
                                                          add_sub_option(
                                                            index,
                                                            ind
                                                          )
                                                        }
                                                        style={{
                                                          color: "#000",
                                                          fontSize: "20px",
                                                          cursor: "pointer",
                                                          border:
                                                            "1px solid #000",
                                                          padding:
                                                            "0px 5px 0px 6px",
                                                          borderRadius: "50%",
                                                        }}
                                                      >
                                                        <i class="fa-solid fa-plus"></i>
                                                      </div>
                                                    }
                                                    {li.sub_options?.length >
                                                      1 && (
                                                      <div
                                                        onClick={() =>
                                                          handelRemoveSubOption(
                                                            index,
                                                            ind,
                                                            ind_el
                                                          )
                                                        }
                                                        style={{
                                                          color: "red",
                                                          fontSize: "20px",
                                                          cursor: "pointer",
                                                          border:
                                                            "1px solid red",
                                                          padding:
                                                            "0px 5px 0px 6px",
                                                          borderRadius: "50%",
                                                        }}
                                                      >
                                                        <i class="fa-solid fa-xmark"></i>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                      </>
                                    );
                                  })}
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "10px",
                                  }}
                                >
                                  {!updateSaveButtonActive ? (
                                    <Button
                                      id="btn_comp"
                                      style={{
                                        fontWeight: 600,
                                        marginRight: "10px",
                                        backgroundColor: "red",
                                      }}
                                      onClick={() => {
                                        handelCandelEdit();
                                      }}
                                      label={"Cancel"}
                                      loading={loading}
                                    />
                                  ) : (
                                    <button
                                      id="btn_comp"
                                      style={{
                                        // marginTop: "40px",
                                        backgroundColor: theme_color,
                                        // border: "2px solid " + theme_color,
                                        color: "#fff",
                                        fontWeight: 600,
                                        padding: "8px",
                                        minHeight: "42px",
                                      }}
                                      onClick={() => {
                                        // navigate("/add-survey-questions");
                                        handelSaveEdit();
                                      }}
                                    >
                                      Save
                                    </button>
                                  )}
                                  {el?.Option?.length > 0 && (
                                    <button
                                      id="btn_comp"
                                      style={{
                                        backgroundColor: theme_bg,
                                        border: "2px solid " + theme_color,
                                        color: theme_color,
                                        fontWeight: 600,
                                        padding: "8px",
                                        width: "150px",
                                      }}
                                      onClick={() => {
                                        add_option(index, el?.questiontype);
                                      }}
                                    >
                                      Add Option
                                    </button>
                                  )}
                                </div>
                              </Flex_box>
                              {/* -------------------------------------------- */}
                            </>
                          )}
                        </>
                      );
                    })}
                </>
              );
            })}
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                id="btn_comp"
                style={{
                  fontWeight: 600,
                  marginRight: "10px",
                }}
                onClick={create_survey}
                label={"Publish"}
                loading={loading}
              />

              <button
                id="btn_comp"
                style={{
                  // marginTop: "40px",
                  backgroundColor: theme_bg,
                  border: "2px solid " + theme_color,
                  color: theme_color,
                  fontWeight: 600,
                  padding: "8px",
                  minHeight: "42px",
                }}
                onClick={() => {
                  navigate("/add-survey-questions");
                }}
              >
                Add more question
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Preview;
