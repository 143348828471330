import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Study_Box from "./Study_Box";
import Flex_box from "../../../../utilities/flex_box";
import { theme_color } from "../../../../utilities/colors";
import { useDispatch, useSelector } from "react-redux";
import {
  alert_action,
  loading_action,
} from "../../../../redux_prog/actions/base_action";
import { StudyList_api, get_resp_studies_api } from "../../../../apis/apis";
import Study_Pagination from "../../../../utilities/Study_Pagination";

const My_studies_comp = () => {
  const [selected_tab, setselected_tab] = useState(1);
  const [study_list, setstudy_list] = useState([]);
  const [completed_study_list, setcompleted_study_list] = useState([]);
  const dispatch = useDispatch();
  const { user_data } = useSelector((state) => state?.authReducer);

  const [pageIndex, setpageIndex] = useState(0);

  const get_study_list = () => {
    dispatch(loading_action(true));
    let body = {
      UserId: user_data?._id,
      pageIndex,
      limit: 10,
    };
    get_resp_studies_api(body)
      .then((res) => {
        // debugger;
        dispatch(loading_action(false));
        if (res.data.status == 1) {
          setstudy_list(res.data.Res.ongoingStudy);
          setcompleted_study_list(res.data.Res.completedStudy);
        }
        console.log(res);
      })
      .catch((err) => {
        dispatch(loading_action(false));
        dispatch(
          alert_action({
            type: "error",
            title: "Error",
            msg: "Some error occurred!",
          })
        );
      });
  };

  useEffect(get_study_list, [pageIndex]);

  return (
    <Tabs>
      <Flex_box>
        <Tab
          onClick={() => {
            setselected_tab(1);
          }}
          style={{
            width: "50%",
            borderBottom: selected_tab == 1 ? "2px solid " + theme_color : "",
            textAlign: "center",
            padding: "10px 0px 20px 0px",
            opacity: selected_tab == 1 ? 1 : 0.5,
            color: theme_color,
            fontWeight: selected_tab == 1 ? 800 : 400,
            fontSize: "18px",
          }}
        >
          ONGOING STUDIES
        </Tab>
        <Tab
          onClick={() => {
            setselected_tab(2);
          }}
          style={{
            width: "50%",
            borderBottom: selected_tab == 2 ? "2px solid " + theme_color : "",
            textAlign: "center",
            padding: "10px 0px 20px 0px",
            opacity: selected_tab == 2 ? 1 : 0.5,
            color: theme_color,
            fontWeight: selected_tab == 2 ? 800 : 400,
            fontSize: "18px",
          }}
        >
          COMPLETED STUDIES
        </Tab>
      </Flex_box>
      <Study_Pagination
        arr={selected_tab == 1 ? study_list : completed_study_list}
        pageIndex={pageIndex}
        setpageIndex={setpageIndex}
      />
      <TabPanel
        style={{
          padding: "20px 0px",
          width: "100%",
        }}
      >
        <Flex_box
          style={{
            flexWrap: "wrap",
            justifyContent: "start",
            gap: 20,
            maxHeight: "74vh",
            overflowY: "auto",
          }}
        >
          {study_list?.length > 0 &&
            study_list?.map((item, index) => {
              return (
                <Study_Box
                  key={index}
                  progress={"100%"}
                  item={item}
                  type={item.type == "gd" ? 2 : item.type == "survey" ? 1 : 0}
                ongoing={true}
                />
              );
            })}
        </Flex_box>
      </TabPanel>
      <TabPanel
        style={{
          padding: "20px 0px",
          width: "100%",
        }}
      >
        <Flex_box
          style={{
            flexWrap: "wrap",
            justifyContent: "start",
            gap: 20,
            maxHeight: "74vh",
            overflowY: "auto",
          }}
        >
          {completed_study_list?.length > 0 &&
            completed_study_list?.map((item, index) => {
              return (
                <Study_Box
                  key={index}
                  item={item}
                  progress={"100%"}
                  type={item.type == "gd" ? 2 : item.type == "survey" ? 1 : 0}
                  completed={true}
                />
              );
            })}
        </Flex_box>
      </TabPanel>
    </Tabs>
  );
};

export default My_studies_comp;
