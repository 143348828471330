import { useDispatch, useSelector } from "react-redux";
import {
  add_contact_msg_api,
  check_kyc_status_api,
  check_sub_data_count_api,
} from "../apis/apis";
import { set_user_action } from "../redux_prog/actions/auth_action";
// import Flex_box from "./flex_box"

export const get_subscription_data_count_and_kyc = async (
  dispatch,
  user_data
) => {
  const sub_res = await check_sub_data_count_api({
    user_id: user_data?._id,
  });
  const used_coins = sub_res.data?.used_coins;
  const created_studies = sub_res.data?.created_studies;
  const used_video_call_hrs = sub_res.data?.used_video_call_hrs;
  const total_respondants = sub_res.data?.total_respondants;
  const coin_limit = sub_res.data?.coin_limit;
  const study_count = sub_res.data?.study_count;
  const video_call_hrs = sub_res.data?.video_call_hrs;
  const max_respondents = sub_res.data?.max_respondents;
  const exp_date = sub_res.data?.exp_date;

  const kyc_res = await check_kyc_status_api({
    user_id: user_data?._id,
  });

  const kyc_verified = kyc_res.data.Res > 0;

  dispatch(
    set_user_action({
      ...user_data,
      used_coins,
      created_studies,
      used_video_call_hrs,
      total_respondants,
      exp_date,
      coin_limit,
      study_count,
      video_call_hrs,
      max_respondents,
      kyc_verified,
    })
  );

  const return_obj = {};
  return_obj.coin_flag = used_coins >= sub_res.data?.coin_limit;
  return_obj.study_flag = created_studies >= sub_res.data?.study_count;
  return_obj.vc_flag = used_video_call_hrs >= sub_res.data?.video_call_hrs;
  return_obj.respondent_flag =
    total_respondants >= sub_res.data?.max_respondants;
  return_obj.exp_flag = new Date(exp_date) >= new Date();
  return_obj.kyc_flag = kyc_verified == false;
  return return_obj;
};

export const add_contact_msg = async (
  values,
  setloading,
  extra_func,
  dispatch
) => {
  setloading(true);
  add_contact_msg_api({ contact_data: values })
    .then((res) => {
      setloading(false);
      if (extra_func) {
        extra_func();
      }
    })
    .catch((err) => {
      console.warn(err);
      setloading(false);
    });
};

export const african_countries_and_states = [
  {
    country: "Algeria",
    states: [
      "Adrar",
      "Chlef",
      "Laghouat",
      "Oum El Bouaghi",
      "Batna",
      "Béjaïa",
      "Biskra",
      "Béchar",
      "Tamanrasset",
      "Tipaza",
      "Tissemsilt",
      "El Oued",
      "Annaba",
      "Guelma",
      "Constantine",
      "Médéa",
      "Mostaganem",
      "Mascara",
      "Ouargla",
      "Relizane",
      "El Tarf",
      "Tindouf",
      "Tissemsilt",
    ],
  },
  {
    country: "Angola",
    states: [
      "Bengo",
      "Benguela",
      "Bié",
      "Cabinda",
      "Huambo",
      "Huíla",
      "Kwanza Norte",
      "Kwanza Sul",
      "Luanda",
      "Malanje",
      "Namibe",
      "Cuando Cubango",
      "Uíge",
      "Zaire",
    ],
  },
  {
    country: "Benin",
    states: [
      "Alibori",
      "Atakora",
      "Atlantique",
      "Borgou",
      "Collines",
      "Couffo",
      "Donga",
      "Littoral",
      "Mono",
      "Ouémé",
      "Plateau",
      "Zou",
    ],
  },
  {
    country: "Botswana",
    states: [
      "Gaborone",
      "Francistown",
      "Maun",
      "Molepolole",
      "Serowe",
      "Selibe Phikwe",
      "Palapye",
      "Lobatse",
      "Kasane",
      "Jwaneng",
    ],
  },
  {
    country: "Burkina Faso",
    states: [
      "Boucle du Mouhoun",
      "Cascades",
      "Centre",
      "Centre-East",
      "Centre-North",
      "Centre-West",
      "East",
      "North",
      "Sahel",
      "South-West",
      "Hauts-Bassins",
      "Sud-Ouest",
    ],
  },
  {
    country: "Burundi",
    states: [
      "Bujumbura Mairie",
      "Bujumbura Rural",
      "Gitega",
      "Kayanza",
      "Kirundo",
      "Makamba",
      "Muramvya",
      "Muyinga",
      "Ngozi",
      "Rumonge",
      "Ruyigi",
    ],
  },
  {
    country: "Cabo Verde",
    states: ["Barlavento (Windward Islands)", "Sotavento (Leeward Islands)"],
  },
  {
    country: "Cameroon",
    states: [
      "Adamawa",
      "Centre",
      "East",
      "Far North",
      "Littoral",
      "North",
      "Northwest",
      "South",
      "Southwest",
      "West",
    ],
  },
  {
    country: "Central African Republic",
    states: [
      "Bangui",
      "Bamingui-Bangoran",
      "Ombella-Mpoko",
      "Nana-Mambéré",
      "Ouham",
      "Ouham-Pendé",
      "Haut-Mbomou",
      "Mbomou",
      "Sangha-Mbaéré",
      "Vakaga",
    ],
  },
  {
    country: "Chad",
    states: [
      "Batha",
      "Borkou",
      "Chari-Baguirmi",
      "Hadjer-Lamis",
      "Kanem",
      "Lac",
      "Logone Occidental",
      "Logone Oriental",
      "Mayo-Kebbi Est",
      "Mayo-Kebbi Ouest",
      "Ouaddaï",
      "Salamat",
      "Sila",
      "Tandjilé",
      "Tibesti",
    ],
  },
  {
    country: "Comoros",
    states: ["Grande Comore", "Anjouan", "Mohéli"],
  },
  {
    country: "Republic of the Congo",
    states: [
      "Bouenza",
      "Brazzaville",
      "Cuvette",
      "Cuvette-Ouest",
      "Likouala",
      "Niari",
      "Plateaux",
      "Pool",
      "Sangha",
    ],
  },
  {
    country: "Democratic Republic of the Congo",
    states: [
      "Bas-Uele",
      "Haut-Uele",
      "Ituri",
      "Tshopo",
      "Haut-Lomami",
      "Lualaba",
      "Kasaï",
      "Kasaï-Central",
      "Kasaï-Oriental",
      "Sankuru",
      "Maniema",
      "Sud-Kivu",
      "Tanganyika",
      "Haut-Katanga",
      "Kinshasa",
    ],
  },
  {
    country: "Djibouti",
    states: ["Ali Sabieh", "Dikhil", "Djibouti", "Obock", "Tadjourah"],
  },
  {
    country: "Egypt",
    states: [
      "Alexandria",
      "Aswan",
      "Asyut",
      "Beheira",
      "Beni Suef",
      "Cairo",
      "Dakahlia",
      "Damietta",
      "Faiyum",
      "Gharbia",
      "Giza",
      "Ismailia",
      "Kafr El Sheikh",
      "Luxor",
      "Matrouh",
      "Minya",
      "Port Said",
      "Qalyubia",
      "Qena",
      "Red Sea",
      "Sharqia",
      "Sohag",
      "South Sinai",
      "Suez",
    ],
  },
  {
    country: "Equatorial Guinea",
    states: [
      "Annobón",
      "Bioko Norte",
      "Bioko Sur",
      "Centro Sur",
      "Djibloho",
      "Wele-Nzas",
    ],
  },
  {
    country: "Eritrea",
    states: [
      "Anseba",
      "Debub",
      "Gash-Barka",
      "Maekel",
      "Semen",
      "Southern Red Sea",
    ],
  },
  {
    country: "Eswatini",
    states: ["Hhohho", "Lubombo", "Manzini", "Shiselweni"],
  },
  {
    country: "Ethiopia",
    states: [
      "Addis Ababa",
      "Afar",
      "Amhara",
      "Benishangul-Gumuz",
      "Dire Dawa",
      "Gambela",
      "Harari",
      "Oromia",
      "Sidama",
      "Somali",
      "SNNPR",
      "Tigray",
    ],
  },
  {
    country: "Gabon",
    states: [
      "Estuaire",
      "Haut-Ogooué",
      "Moyen-Ogooué",
      "Ngounié",
      "Nyanga",
      "Ogooué-Ivindo",
      "Ogooué-Lolo",
      "Woleu-Ntem",
    ],
  },
  {
    country: "Gambia",
    states: [
      "Banjul",
      "Central River",
      "Lower River",
      "North Bank",
      "Upper River",
      "Western",
    ],
  },
  {
    country: "Ghana",
    states: [
      "Greater Accra",
      "Ashanti",
      "Western",
      "Eastern",
      "Northern",
      "Central",
      "Western North",
      "Volta",
      "Bono",
      "Bono East",
      "Ahafo",
      "Oti",
    ],
  },
  {
    country: "Guinea",
    states: [
      "Boke",
      "Conakry",
      "Coyah",
      "Dalaba",
      "Dinguiraye",
      "Faranah",
      "Fria",
      "Kankan",
      "Kindia",
      "Mamou",
      "Nzerekore",
      "Télémélé",
    ],
  },
  {
    country: "Guinea-Bissau",
    states: ["Bissau", "Bolama", "Cacheu", "Gabu", "Oio", "Quinara", "Tombali"],
  },
  {
    country: "Côte d'Ivoire",
    states: [
      "Abidjan",
      "Bas-Sassandra",
      "Comoé",
      "Denguélé",
      "Gôh-Djiboua",
      "Lacs",
      "Lagunes",
      "Savanes",
      "Vallée du Bandama",
      "Woroba",
      "Yamoussoukro",
    ],
  },
  {
    country: "Kenya",
    states: [
      "Central",
      "Coast",
      "Eastern",
      "Nairobi",
      "North Eastern",
      "Nyanza",
      "Rift Valley",
      "Western",
      "Southern",
    ],
  },
  {
    country: "Lesotho",
    states: [
      "Berea",
      "Butha-Buthe",
      "Leribe",
      "Mafeteng",
      "Maseru",
      "Mohale's Hoek",
      "Qacha's Nek",
      "Quthing",
      "Thaba-Tseka",
    ],
  },
  {
    country: "Liberia",
    states: [
      "Bomi",
      "Bong",
      "Grand Bassa",
      "Grand Cape Mount",
      "Grand Gedeh",
      "Lofa",
      "Margibi",
      "Maryland",
      "Montserrado",
      "Nimba",
      "River Cess",
      "River Gee",
      "Sinoe",
    ],
  },
  {
    country: "Libya",
    states: [
      "Al Awbari",
      "Al Bayda",
      "Al Jabal al Akhdar",
      "Al Jufra",
      "Al Kufra",
      "Al Marj",
      "Al Wahat",
      "Benghazi",
      "Derna",
      "Ghat",
      "Misrata",
      "Nalut",
      "Sabha",
      "Sirte",
      "Tripoli",
      "Zawiya",
    ],
  },
  {
    country: "Madagascar",
    states: [
      "Antananarivo",
      "Antananarivo Atsimondrano",
      "Atsimo-Andrefana",
      "Atsimo-Atsinanana",
      "Diana",
      "Haute Matsiatra",
      "Ihorombe",
      "Itasy",
      "Melaky",
      "Menabe",
      "Sava",
      "Sofala",
      "Vakinankaratra",
      "Vatovavy-Fitovinany",
    ],
  },
  {
    country: "Malawi",
    states: [
      "Balaka",
      "Blantyre",
      "Chikwawa",
      "Chiradzulu",
      "Dedza",
      "Dowa",
      "Karonga",
      "Kasungu",
      "Likoma",
      "Lilongwe",
      "Machinga",
      "Mangochi",
      "Mulanje",
      "Mwanza",
      "Nkhata Bay",
      "Nkhotakota",
      "Ntchisi",
      "Phalombe",
      "Rumphi",
      "Salima",
      "Thyolo",
      "Zomba",
    ],
  },
  {
    country: "Mali",
    states: [
      "Bamako",
      "Gao",
      "Kayes",
      "Koulikoro",
      "Mopti",
      "Ségou",
      "Sikasso",
      "Tombouctou",
    ],
  },
  {
    country: "Mauritania",
    states: [
      "Adrar",
      "Assaba",
      "Brakna",
      "Daghdja",
      "Gorgol",
      "Guidimaka",
      "Hodh El Gharbi",
      "Hodh Ech Chargui",
      "Inchiri",
      "Nouakchott",
      "Trarza",
    ],
  },
  {
    country: "Mauritius",
    states: ["Agalega", "Mauritius", "Rodrigues"],
  },
  {
    country: "Morocco",
    states: [
      "Agadir-Ida Ou Tanane",
      "Al Haouz",
      "Al Jadida",
      "Assafi",
      "Beni Mellal",
      "Casablanca-Settat",
      "Chaouia-Ouardigha",
      "Drâa-Tafilalet",
      "Fes-Meknes",
      "Guelmim-Oued Noun",
      "Laâyoune-Sakia El Hamra",
      "Marrakech-Safi",
      "Oriental",
      "Souss-Massa",
      "Tangier-Tétouan-Al Hoceima",
    ],
  },
  {
    country: "Mozambique",
    states: [
      "Cabo Delgado",
      "Gaza",
      "Inhambane",
      "Manica",
      "Maputo",
      "Nampula",
      "Niassa",
      "Sofala",
      "Tete",
      "Zambezia",
    ],
  },
  {
    country: "Namibia",
    states: [
      "Caprivi",
      "Erongo",
      "Hardap",
      "Karas",
      "Kavango East",
      "Kavango West",
      "Khomas",
      "Kunene",
      "Ohangwena",
      "Omusati",
      "Oshana",
      "Oshikoto",
      "Zambezi",
    ],
  },
  {
    country: "Niger",
    states: [
      "Agadez",
      "Diffa",
      "Dosso",
      "Maradi",
      "Niamey",
      "Tahoua",
      "Tillabéri",
      "Zinder",
    ],
  },
  {
    country: "Nigeria",
    states: [
      "Abia",
      "Adamawa",
      "Abuja",
      "Akwa Ibom",
      "Anambra",
      "Bauchi",
      "Bayelsa",
      "Benue",
      "Borno",
      "Cross River",
      "Delta",
      "Ebonyi",
      "Edo",
      "Ekiti",
      "Enugu",
      "Gombe",
      "Imo",
      "Jigawa",
      "Kaduna",
      "Kano",
      "Katsina",
      "Kebbi",
      "Kogi",
      "Kwara",
      "Lagos",
      "Nasarawa",
      "Niger",
      "Ogun",
      "Ondo",
      "Osun",
      "Oyo",
      "Plateau",
      "Rivers",
      "Sokoto",
      "Taraba",
      "Yobe",
      "Zamfara",
    ],
  },
  {
    country: "Rwanda",
    states: ["Eastern", "Northern", "Western", "Southern", "Kigali"],
  },
  {
    country: "Sao Tome and Principe",
    states: ["Principe", "Sao Tome"],
  },
  {
    country: "Senegal",
    states: [
      "Dakar",
      "Diourbel",
      "Fatick",
      "Kaolack",
      "Kedougou",
      "Kolda",
      "Louga",
      "Matam",
      "Saint-Louis",
      "Tambacounda",
      "Thiès",
      "Ziguinchor",
    ],
  },
  {
    country: "Seychelles",
    states: [
      "Anse aux Pins",
      "Anse Boileau",
      "Baie Lazare",
      "Beau Vallon",
      "Bel Ombre",
      "Glacis",
      "Grand Anse",
      "La Digue",
      "Pointe La Rue",
      "Port Glaud",
      "Praslin",
    ],
  },
  {
    country: "Sierra Leone",
    states: [
      "Bombali",
      "Bo",
      "Bonthe",
      "Kailahun",
      "Kenema",
      "Koinadugu",
      "Kambia",
      "Port Loko",
      "Moyamba",
      "Western Area",
    ],
  },
  {
    country: "Somalia",
    states: [
      "Awdal",
      "Bakool",
      "Banadir",
      "Bari",
      "Bay",
      "Galguduud",
      "Gedo",
      "Hiiraan",
      "Middle Juba",
      "Lower Juba",
      "Lower Shabelle",
      "Togdheer",
      "Sanaag",
      "Sool",
    ],
  },
  {
    country: "South Africa",
    states: [
      "Eastern Cape",
      "Free State",
      "Gauteng",
      "KwaZulu-Natal",
      "Limpopo",
      "Mpumalanga",
      "North West",
      "Northern Cape",
      "Western Cape",
    ],
  },
  {
    country: "South Sudan",
    states: [
      "Central Equatoria",
      "Eastern Equatoria",
      "Jonglei",
      "Lakes",
      "Northern Bahr el Ghazal",
      "Unity",
      "Upper Nile",
      "Warrap",
      "Western Bahr el Ghazal",
      "Western Equatoria",
    ],
  },
  {
    country: "Sudan",
    states: [
      "Al Baḥr Al Aḥmar",
      "Al Jazirah",
      "Al Qadarif",
      "Al Khartoum",
      "Al Qadarif",
      "Blue Nile",
      "Darfur North",
      "Darfur South",
      "Darfur West",
      "Kassala",
      "Northern",
      "River Nile",
      "Sennar",
      "White Nile",
      "South Kordofan",
      "West Kordofan",
    ],
  },
  {
    country: "Tanzania",
    states: [
      "Arusha",
      "Dodoma",
      "Iringa",
      "Kagera",
      "Katavi",
      "Kigoma",
      "Kilimanjaro",
      "Lindi",
      "Mara",
      "Mbeya",
      "Morogoro",
      "Mtwara",
      "Mwanza",
      "Pwani",
      "Rukwa",
      "Ruvuma",
      "Shinyanga",
      "Singida",
      "Tabora",
      "Tanga",
      "Zanzibar",
    ],
  },
  {
    country: "Togo",
    states: ["Centrale", "Kara", "Maritime", "Plateaux", "Savannes"],
  },
  {
    country: "Tunisia",
    states: [
      "Ariana",
      "Béja",
      "Ben Arous",
      "Bizerte",
      "Gabès",
      "Gafsa",
      "Jendouba",
      "Kairouan",
      "Kebili",
      "Kef",
      "Mahdia",
      "Manouba",
      "Medenine",
      "Monastir",
      "Nabeul",
      "Sidi Bouzid",
      "Siliana",
      "Tataouine",
      "Tozeur",
      "Zaghouan",
    ],
  },
  {
    country: "Uganda",
    states: ["Central", "Eastern", "Northern", "Western"],
  },
  {
    country: "Zambia",
    states: [
      "Central",
      "Copperbelt",
      "Eastern",
      "Luapula",
      "Lusaka",
      "Northern",
      "Northwestern",
      "Southern",
      "Western",
    ],
  },
  {
    country: "Zimbabwe",
    states: [
      "Bulawayo",
      "Harare",
      "Manicaland",
      "Mashonaland East",
      "Mashonaland West",
      "Masvingo",
      "Midlands",
      "Matabeleland North",
      "Matabeleland South",
    ],
  },
]
  .map((item) => ({
    ...item,
    states: [...item.states].sort((a, b) => a.localeCompare(b)),
  }))
  .sort((a, b) => a.country.localeCompare(b.country));

export const positive_neagtive_words = {
  negatives: [
    "Negative ",
    "Abandoned",
    "Abysmal",
    "Accusation",
    "Aggravated",
    "Agony",
    "Alienated",
    "Angry",
    "Annoyed",
    "Anxious",
    "Apathetic",
    "Appalled",
    "Ashamed",
    "Assaulted",
    "Awful",
    "Bad",
    "Beaten",
    "Betrayed",
    "Bewildered",
    "Bitter",
    "Blame",
    "Bleak",
    "Bothered",
    "Broken",
    "Burned",
    "Burdened",
    "Chaos",
    "Cheated",
    "Clumsy",
    "Conflicted",
    "Confused",
    "Constricted",
    "Contempt",
    "Costly",
    "Crappy",
    "Crazy",
    "Crippling",
    "Crushed",
    "Damaged",
    "Dangerous",
    "Deceived",
    "Defeated",
    "Deficient",
    "Deflated",
    "Deflated",
    "Depressed",
    "Desolate",
    "Despair",
    "Destroyed",
    "Detached",
    "Deteriorated",
    "Detrimental",
    "Difficult",
    "Disappointed",
    "Disapproving",
    "Discontent",
    "Discouraged",
    "Disgust",
    "Dishonest",
    "Displeased",
    "Distressed",
    "Disturbing",
    "Dreadful",
    "Drained",
    "Enraged",
    "Exasperated",
    "Exhausted",
    "Failed",
    "Fearful",
    "Frustrated",
    "Fumbling",
    "Futile",
    "Grief",
    "Grievous",
    "Guilty",
    "Harsh",
    "Hopeless",
    "Hurt",
    "Ignored",
    "Impaired",
    "Inadequate",
    "Incompetent",
    "Indignant",
    "Ineffective",
    "Inefficient",
    "Insulted",
    "Intimidated",
    "Irate",
    "Irritated",
    "Jealous",
    "Judgmental",
    "Kicked",
    "Lament",
    "Lethargic",
    "Lonely",
    "Lost",
    "Low",
    "Malicious",
    "Mediocre",
    "Misery",
    "Misguided",
    "Neglected",
    "Negative",
    "Nervous",
    "Noisy",
    "Nonchalant",
    "Numb",
    "Obnoxious",
    "Offended",
    "Overwhelmed",
    "Painful",
    "Panic",
    "Paranoid",
    "Pathetic",
    "Pessimistic",
    "Powerless",
    "Precarious",
    "Quarrelsome",
    "Regret",
    "Rejected",
    "Reluctant",
    "Resentful",
    "Restless",
    "Ridiculous",
    "Ruined",
    "Sad",
    "Sceptical",
    "Scornful",
    "Seething",
    "Senseless",
    "Shocked",
    "Shoddy",
    "Skeptical",
    "Slow",
    "Sorrowful",
    "Stressed",
    "Stupid",
    "Suffering",
    "Tense",
    "Terrible",
    "Threatened",
    "Tired",
    "Tortured",
    "Trapped",
    "Troubled",
    "Uncertain",
    "Uncomfortable",
    "Unhappy",
    "Unimpressed",
    "Uninspired",
    "Unlucky",
    "Unreliable",
    "Unresolved",
    "Unstable",
    "Upset",
    "Useless",
    "Vague",
    "Violated",
    "Vulnerable",
    "Weak",
    "Weary",
    "Wretched",
    "Abhorrent",
    "Abnormal",
    "Agonizing",
    "Allergenic",
    "Antagonistic",
    "Awkward",
    "Blistering",
    "Bleeding",
    "Brutal",
    "Chilling",
    "Crushed",
    "Damning",
    "Debilitating",
    "Depressing",
    "Despicable",
    "Deterring",
    "Disastrous",
    "Disheartening",
    "Displeasure",
    "Disturbing",
    "Eerie",
    "Eroding",
    "Flawed",
    "Fractured",
    "Grating",
    "Harrowing",
    "Hazardous",
    "Heartbreaking",
    "Hostile",
    "Inconvenient",
    "Insufferable",
    "Intolerable",
    "Irrational",
    "Jaded",
    "Lousy",
    "Malignant",
    "Misguided",
    "Mismanaged",
    "Mistrustful",
    "Nasty",
    "Noxious",
    "Obsolete",
    "Outrageous",
    "Painstaking",
    "Pitiful",
    "Prejudiced",
    "Quagmire",
    "Regrettable",
    "Repressive",
    "Rigid",
    "Rough",
    "Scandalous",
    "Shocking",
    "Sinister",
    "Sloppy",
    "Sluggish",
    "Substandard",
    "Tainted",
    "Terrifying",
    "Tortuous",
    "Tragic",
    "Toxic",
    "Troublesome",
    "Unbearable",
    "Untrustworthy",
    "Unwholesome",
    "Unwanted",
    "Unyielding",
    "Vengeful",
    "Victimized",
    "Volatile",
    "Weakened",
    "Wobbly",
    "Abandon",
    "Anguish",
    "Badgered",
    "Blighted",
    "Burnt",
    "Chaotic",
    "Conflicted",
    "Constrained",
    "Contagious",
    "Despairing",
    "Devastated",
    "Disturb",
    "Dread",
    "Dubious",
    "Excluded",
    "Floundering",
    "Frustration",
    "Grim",
    "Haphazard",
    "Incurable",
    "Inequitable",
    "Infuriating",
    "Insidious",
    "Intolerant",
    "Judged",
    "Malcontent",
    "Mortified",
    "Neglectful",
    "Overbearing",
    "Overlooked",
    "Painful",
    "Perplexed",
    "Puzzled",
    "Quashed",
    "Ranting",
    "Regressive",
    "Rude",
    "Scarred",
    "Shadowy",
    "Shattered",
    "Shortchanged",
    "Smothered",
    "Stifled",
    "Stinging",
    "Suffocated",
    "Thwarted",
    "Torn",
    "Treacherous",
    "Troubling",
    "Uncertain",
    "Unforgiving",
    "Unruly",
    "Unwilling",
    "Vicious",
    "Villainous",
    "Wounded",
    "Yearning",
    "Abrasive",
    "Alluring",
    "Annoying",
    "Annulled",
    "Antsy",
    "Apprehensive",
    "Arbitrary",
    "Bitterly",
    "Careless",
    "Chaotic",
    "Complicated",
    "Compromised",
    "Convoluted",
    "Corrosive",
    "Damning",
    "Damper",
    "Degraded",
    "Disarray",
    "Displeased",
    "Distasteful",
    "Distraught",
    "Doubtful",
    "Dwindling",
    "Excruciating",
    "Flawed",
    "Fraying",
    "Frightful",
    "Grumpy",
    "Hardship",
    "Helpless",
    "Infiltrated",
    "Infuriated",
    "Insufficient",
    "Irritating",
    "Jeopardized",
    "Jittery",
    "Lackluster",
    "Lamentable",
    "Loathed",
    "Melancholic",
    "Mournful",
    "Negativity",
    "Neglect",
    "Nonsensical",
    "Obfuscate",
    "Overlooked",
    "Paradoxical",
    "Perilous",
    "Perturbed",
    "Precarious",
    "Rage",
    "Ruinous",
    "Scary",
    "Scattered",
    "Skepticism",
    "Slumped",
    "Splintered",
    "Stagnant",
    "Stymied",
    "Taut",
    "Thrashed",
    "Torn",
    "Troubling",
    "Tumultuous",
    "Turbulent",
    "Unaccommodating",
    "Unfortunate",
    "Unsettled",
    "Untrusting",
    "Vanished",
    "Vexed",
    "Wary",
    "Withdrawn",
    "Wretched",
    "Abusive",
    "Accursed",
    "Angry",
    "Annoyed",
    "Apathy",
    "Awkward",
    "Blamed",
    "Blameworthy",
    "Brooding",
    "Bugged",
    "Chagrined",
    "Chaotic",
    "Devoid",
    "Distrustful",
    "Dismal",
    "Disillusioned",
    "Disregarded",
    "Disjointed",
    "Displeased",
    "Dysfunctional",
    "Embittered",
    "Exiled",
    "Feeble",
    "Frustrated",
    "Grievous",
    "Hazy",
    "Helpless",
    "Irrelevant",
    "Jeering",
    "Jinxed",
    "Languishing",
    "Loathsome",
    "Malodorous",
    "Malignant",
    "Negated",
    "Nihilistic",
    "Obscured",
    "Ominous",
    "Opaque",
    "Pitiful",
    "Plagued",
    "Regrettable",
    "Repulsive",
    "Ruined",
    "Sapped",
    "Searing",
    "Senseless",
    "Shocking",
    "Skeptical",
    "Sorrowful",
    "Surly",
    "Testy",
    "Troubled",
    "Unenthusiastic",
    "Unwanted",
    "Unyielding",
    "Vacant",
    "Vulnerable",
    "Warped",
    "Wretched",
    "Abysmal",
    "Accidental",
    "Agitated",
    "Alarmed",
    "Annulled",
    "Annoyed",
    "Awful",
    "Badmouthed",
    "Crummy",
    "Defeated",
    "Dejected",
    "Deluded",
    "Disappointed",
    "Distorted",
    "Dreaded",
    "Dubious",
    "Exasperated",
    "Frustrating",
    "Grudging",
    "Grumpy",
    "Hindered",
    "Irritable",
    "Mistrusted",
    "Obliterated",
    "Obstructed",
    "Oppressive",
    "Outdated",
    "Overstressed",
    "Panicked",
    "Petty",
    "Quizzical",
    "Rejected",
    "Repelled",
    "Restless",
    "Ruffled",
    "Sappy",
    "Secluded",
    "Segregated",
    "Shunned",
    "Silenced",
    "Sinister",
    "Stubborn",
    "Uncaring",
    "Undermined",
    "Uninhibited",
    "Unrecognized",
    "Unsettling",
    "Unwelcome",
    "Unwholesome",
    "Unyielding",
    "Vulnerable",
    "Warped",
    "Wretched",
    "Alienation",
    "Despair",
    "Frustration",
    "Harm",
    "Hopelessness",
    "Isolation",
    "Jadedness",
    "Melancholy",
    "Oppression",
    "Regret",
    "Remorse",
    "Repression",
    "Resentment",
    "Suffering",
    "Torment",
    "Toxicity",
    "Trauma",
    "Unhappiness",
    "Unrest",
    "Worry",
    "Yearning",
    "Desolation",
    "Bashed",
    "Bashing",
    "yeye",
    "wowo",
    "Kolo",
    "Wahala",
    "Tight",
    "Sakawa",
    "Chop money",
    "vex ",
    "Kwasia",
    "Mshamba",
    "Nwa nkor",
    "Dudus",
    "Faka",
    "Tjo",
    "Kurukere",
    "Moo",
    "Broke",
    "Konto",
    "Gharam ",
    "Nairobi",
    "Shame",
    "Nyakuri",
    "Kiboko",
    "Njala",
    "Dawa",
    "Yawa",
    "Ngoma",
    "Kizungu",
    "Kasala",
    "Matsala",
    "Masala",
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  positives: [
    "Positive",
    "Happy",
    "Joyful",
    "Delighted",
    "Excited",
    "Content",
    "Pleased",
    "Satisfied",
    "Grateful",
    "Thankful",
    "Ecstatic",
    "Cheerful",
    "Thrilled",
    "Elated",
    "Hopeful",
    "Optimistic",
    "Enthusiastic",
    "Inspired",
    "Motivated",
    "Empowered",
    "Uplifted",
    "Radiant",
    "Loving",
    "Affectionate",
    "Appreciative",
    "Supportive",
    "Harmonious",
    "Balanced",
    "Joyous",
    "Serene",
    "Relaxed",
    "Energized",
    "Exhilarated",
    "Fulfilling",
    "Triumphant",
    "Victorious",
    "Creative",
    "Innovative",
    "Excellent",
    "Impressive",
    "Remarkable",
    "Outstanding",
    "Exceptional",
    "Wonderful",
    "Fantastic",
    "Marvelous",
    "Incredible",
    "Phenomenal",
    "Splendid",
    "Bountiful",
    "Abundant",
    "Prosperous",
    "Successful",
    "Thriving",
    "Flourishing",
    "Blissful",
    "Peaceful",
    "Restful",
    "Lovely",
    "Warm",
    "Charming",
    "Elegant",
    "Radiating",
    "Genuine",
    "Authentic",
    "Delightful",
    "Enchanting",
    "Pleasurable",
    "Satisfying",
    "Invigorating",
    "Revitalizing",
    "Exuberant",
    "Joy-bringing",
    "Charismatic",
    "Amiable",
    "Compassionate",
    "Friendly",
    "Welcoming",
    "Kind",
    "Generous",
    "Selfless",
    "Giving",
    "Nurturing",
    "Supportive",
    "Encouraging",
    "Reassuring",
    "Affirmative",
    "Refreshing",
    "Exquisite",
    "Radiant",
    "Vibrant",
    "Dazzling",
    "Dazzling",
    "Spectacular",
    "Exceptional",
    "Immaculate",
    "Resourceful",
    "Skillful",
    "Talented",
    "Accomplished",
    "Inspiring",
    "Astounding",
    "Remarkable",
    "Transcendent",
    "Magical",
    "Wholesome",
    "Gleeful",
    "Lucky",
    "Fortunate",
    "Proud",
    "Pleased",
    "Humble",
    "Bright",
    "Chipper",
    "Merry",
    "Sunny",
    "Sparkling",
    "Radiance",
    "Charming",
    "Delight",
    "Excellence",
    "Ease",
    "Bliss",
    "Dreamy",
    "Flawless",
    "Glee",
    "Fortuitous",
    "Tranquil",
    "Lively",
    "Zestful",
    "Vigorous",
    "Vivid",
    "Upbeat",
    "Peppy",
    "Daring",
    "Wondrous",
    "Whimsical",
    "Exciting",
    "Spritely",
    "Fulfilling",
    "Brilliant",
    "Striking",
    "Noble",
    "Wise",
    "Mighty",
    "Sublime",
    "Inviting",
    "Favorable",
    "Resounding",
    "Omo",
    "omooo",
    "Chale ",
    "Mzungu ",
    "Sawa",
    "Kaya ",
    "Pesa",
    "Fete",
    "sweet",
    "sexy",
    "Bafundi",
    "Kwenzi ",
    "Malaika ",
    "Akwaba ",
    "Nkosi ",
    "Adupe",
    "ese",
    "Fiyin",
    "Bene ",
    "Asante",
    "Zuri ",
    "Mzuri",
    "Sijambo",
    "Shukrani",
    "Mikono",
    "Banjuka ",
    "Kaya",
    "Bona",
    "Ayo ",
    "Jubilee",
    "Umoja",
    "Kushinda ",
    "Kipaji ",
    "Miliki",
    "Jaye",
    "jaiye",
    "Pamoja ",
    "Bwakire buse",
    "Sera",
    "Fursa",
    "Mavuno",
    "Expressive and Evocative Words",
    "Luminous",
    "Vibrancy",
    "Essence",
    "Majesty",
    "Exhilaration",
    "Rapture",
    "Astonishing",
    "Transformative",
    "Triumphant",
    "Serenity",
    "Exuberance",
    "Visionary",
    "Celestial",
    "Benevolent",
    "Upliftment",
    "Joyfulness",
    "Elation",
    "Tenderness",
    "Glorious",
    "Cherished",
    "Fulfillment",
    "Magnificence",
    "Compassion",
    "Veneration",
    "Fondness",
    "Elysium",
    "Resplendent",
    "Optimism",
    "Righteousness",
    "Flourish",
    "Paradise",
    "Rejuvenation",
    "Cherishing",
    "Magnanimity",
    "Companionship",
    "Rejoicing",
    "Celebration",
    "Symphony",
    "Tranquility",
    "Bubbling",
    "Spark",
    "Breezy",
    "Chipper",
    "Positivity",
    "Yonder",
    "Stellar",
    "Warming",
    "Resounding",
    "Patience",
    "Easefulness",
    "Wholesomeness",
    "Affability",
    "Benevolence",
    "Cherub",
    "Opulent",
    "Luminous",
    "Welcoming",
    "Authenticity",
    "Sympathetic",
    "Amicable",
    "Ingratiating",
    "Melodic",
    "Radiance",
    "Glorification",
    "Aglow",
    "Harmonious",
    "Serene",
    "Lively",
    "Adoring",
    "Dreamlike",
    "Rhapsody",
    "Elysian",
    "Spirit",
    "Delightfulness",
    "Dovetail",
    "Integrity",
    "Tenacity",
    "Savoring",
    "Aspiration",
    "Fulfillment",
    "Transcendence",
    "Invigorating",
    "Sublime",
    "Tantalizing",
    "Acclaiming",
    "Stalwart",
    "Benevolence",
    "Admiring",
    "Bolstering",
    "Unfettered",
    "Brave",
    "Dreamy",
    "Radiant",
    "Thrive",
    "Soar",
    "Leap",
    "Ascend",
    "Bloom",
    "Fancy",
    "Hooray",
    "Sparkle",
    "Huzzah",
    "Yay",
    "Woohoo",
    "Zest",
    "Zap",
    "Woo",
    "Whirlwind",
    "Sunshine",
    "Fancy",
    "Praise",
    "Brilliance",
    "Resonance",
    "Celebratory",
    "Ecstasy",
    "Joyousness",
    "Fond",
    "Euphoria",
    "Savor",
    "Uplift",
    "Glow",
    "Benevolent",
    "Jubilant",
    "Acclaim",
    "Serendipity",
    "Marvel",
    "Refreshing",
    "Bubbling",
    "Hearty",
    "Sweetness",
    "Comfort",
    "Inspiring",
    "Motivation",
    "Abundance",
    "Flourish",
    "Overjoyed",
    "Harmony",
    "Appreciation",
    "Benevolence",
    "Invigorated",
    "Winning",
    "Reviving",
    "Energetic",
    "Lighthearted",
    "Triumph",
    "Flourishing",
    "Resilience",
    "Tender",
    "Admired",
    "Goodwill",
    "Adventurous",
    "Nourishing",
    "Caring",
    "Delicious",
    "Favorable",
    "Nurturing",
    "Bounteous",
    "Dynamic",
    "Brighten",
    "Zealous",
    "Charming",
    "Rejuvenating",
    "Exhilarating",
    "Goodhearted",
    "Dazzling",
    "Prominent",
    "Exalted",
    "Victory",
    "Uplifted",
    "Radiate",
    "Energized",
    "Dreamer",
    "Open-hearted",
    "Soothing",
    "Blissful",
    "Respect",
    "Playful",
    "Cherished",
    "Fancy",
    "Accomplished",
    "Supportive",
    "Magnificent",
    "Courageous",
    "Harmonizing",
    "Heroic",
    "Vision",
    "Utopia",
    "Fulfilling",
    "Enthused",
    "Timeless",
    "Spirited",
    "Exquisite",
    "Flair",
    "Wholesome",
    "Benevolently",
    "Charm",
    "Invigorate",
    "Inviting",
    "Treasure",
    "Splendor",
    "Cherubim",
    "Zing",
    "Paradigm",
    "Fresh",
    "Prosperity",
    "Captivating",
    "Seraphic",
    "Lustrous",
    "Companionship",
    "Glow",
    "Enchanting",
    "Sacred",
    "Cultivated",
    "Optimism",
    "Noble",
    "Delightful",
    "Dreamlike",
    "Radiating",
    "Hopefulness",
    "Praise",
    "Bubbly",
    "Imaginative",
    "Resplendent",
    "Fairytale",
    null,
    "Glamour",
    "Affection",
    "Sublimity",
    "Vivacious",
    "Stellar",
    "Resounding",
    "Glistening",
    "Zephyr",
    "Glory",
    "Fancy",
    "Spritely",
    "Brimming",
    "Exciting",
    "Cognizance",
    "Angel",
    "Light",
    "Brightening",
    "Festival",
    "Peppy",
    "Affirmation",
    "Joyride",
    "Venerable",
    "Celestial",
    "Rhapsodic",
    "Celebration",
    "Kudos",
    "Vitality",
    "Felicity",
    "Euphoric",
    "Elan",
    "Vivacity",
    "Wondrous",
    "Cheer",
    "Surge",
    "Nurture",
    "Sincere",
    "Attentive",
    "High-spirited",
    "Resplendence",
    "Celebratory",
    "Goodwill",
    "Invigorated",
    "Alive",
    "Melody",
    "Heartwarming",
    "Fortuity",
    "Acclamation",
    "Unwavering",
    "Innovate",
    "Compassionate",
    "Lush",
    "Cherish",
    "Delightfulness",
    "Rewarding",
    "Glee",
    "Satisfaction",
    "Thrive",
    "Transcendent",
    "Bounty",
    "Emergence",
    "Resilience",
    "Melodious",
    "Blissfulness",
    "Liberty",
    "Treasure",
    "Alluring",
    "Majestic",
    "Cherish",
    "Serene",
    "Abounding",
    "Lively",
    "Delightful",
    "Felicity",
    "Enliven",
    "Hopeful",
    "Progressive",
    "Thriving",
    "Advantage",
    "Companionship",
    "Winning",
    "Optimistic",
    "Courage",
    "Empowerment",
    "Benevolence",
    "Goodness",
    "Enjoyment",
    "Breathe",
    "Symphony",
    "Ecstasy",
    "Perpetual",
    "Refreshing",
    "Sympathetic",
    "Heavenly",
    "Abundant",
    "Sunshine",
    "Joyfulness",
    "Elation",
    "Fancy",
    "Adoring",
    "Golden",
    "Radiant",
    "Benevolent",
    "Caring",
    "Playful",
    "Sublime",
    "Joyous",
    "Valiant",
    "Sustaining",
    "Inception",
    "Cognizance",
    "Serenity",
    "Joyousness",
    "Sanguine",
    "Verve",
    "Alacrity",
    "Plentiful",
    "Effulgent",
    "Unbounded",
    "Liberation",
    "Abloom",
    "Elevated",
    "Appreciative",
    "Aspirational",
    "Luminous",
    "Noble",
    "Nurtured",
    "Transitory",
    "Spirit",
    "Genius",
    "Vivify",
    "Resilience",
    "Tingling",
    "Tenderness",
    "Guidance",
    "Encouragement",
    "Elevation",
    "Vivid",
    "Sensation",
    "Harmony",
    "Veneration",
    "Happiness",
    "Eclectic",
    "Delicate",
    "Trust",
    "Appreciate",
    "Fervor",
    "Heroism",
    "Juvenile",
    "Gusto",
    "Affectionate",
    "Bountiful",
    "Gentleness",
    "Transform",
    "Cheery",
    "Optimism",
    "Affable",
    "Congratulatory",
    "Devotion",
    "Exuberance",
    "Intrepid",
    "Shimmering",
    "Cordial",
    "Adventurous",
    "Cherished",
    "Soothing",
    "Enchanting",
    "Affirmative",
    "Calming",
    "Sunny",
    "Gladdening",
    "Enriching",
    "Upbeat",
    "Celebration",
    "Ascend",
    "Kindred",
    "Visionary",
    "Delighted",
    "Excellence",
    "Invaluable",
    "Vibrancy",
    "Loyalty",
    "Generosity",
    "Energizing",
    "Sanguine",
    "Jubilant",
    "Satisfaction",
    "Magnanimity",
    "Allure",
    "Thrive",
  ],
};

export const wordCount = (paragraph) => {
  // Convert to lowercase and remove punctuation
  const sanitizedText = paragraph?.toLowerCase().replace(/[^\w\s]/g, "");

  // Split paragraph into words
  const words = sanitizedText?.split(/\s+/);

  // Initialize an object to store word counts
  const wordCounts = {};

  // Count each word
  words.forEach((word) => {
    if (word) {
      wordCounts[word] = (wordCounts[word] || 0) + 1;
    }
  });

  return wordCounts;
};

export const sliced_string = (string, count) => {
  return string?.length > count ? string?.slice(0, count) + ".." : string;
};

export const GenderPercentage = ({ malePercentage, femalePercentage }) => {
  const calculate_female_perc = (percentage) => {
    // Calculate the value based on the percentage
    const value = 1 + (percentage / 100) * (23 - 1);

    return Math.round(value); // Rounding to get an integer value
  };

  const calculate_male_perc = (percentage) => {
    const value = 5 + (percentage / 100) * (350 - 5);

    return Math.round(value); // Rounding to get an integer value
  };

  return null;

  //   return (
  //     <Flex_box
  //       style={{
  //         flexWrap: "wrap",
  //       }}
  //     >
  //       <Flex_box
  //         style={{
  //           gap: 10,
  //           width: "",
  //         }}
  //       >
  //         <div>
  //           <b
  //             style={{
  //               color: "violet",
  //               fontSize: "28px",
  //             }}
  //           >
  //             41.7%
  //           </b>
  //           <p
  //             style={{
  //               fontSize: "12px",
  //               textAlign: "end",
  //             }}
  //           >
  //             Male
  //           </p>
  //         </div>
  //         <div
  //           style={{
  //             paddingTop: "30px",
  //           }}
  //         >
  //           <span className="ikoncircle" id="male">
  //             <svg
  //               className="genelsvgs"
  //               id="katman_1"
  //               data-name="katman 1"
  //               xmlns="http://www.w3.org/2000/svg"
  //               viewBox="0 0 645.6 645.6"
  //               height={"150px"}
  //               width={"150px"}
  //             >
  //               <defs>
  //                 <clipPath id="cut-off-bottom">
  //                   <rect x="0" y="0" width="645" height="200" />
  //                 </clipPath>
  //               </defs>
  //               <path
  //                 className="bg"
  //                 width="150px"
  //                 style={{
  //                   width: "150px",
  //                 }}
  //                 d="M336.843,8.927c-5.959-5.976-14.148-9.174-22.594-8.787L211.774,4.758c-16.551,0.746-29.365,14.769-28.619,31.32
  // c0.746,16.552,14.789,29.342,31.32,28.619l24.955-1.125l-32.602,32.602c-21.324-13.469-46.053-20.681-71.893-20.681
  // c-36.061,0-69.963,14.042-95.459,39.541c-52.637,52.635-52.637,138.281,0,190.917c25.496,25.499,59.398,39.54,95.459,39.54
  // c36.061,0,69.961-14.041,95.459-39.54c45.348-45.348,51.623-115.193,18.834-167.326l32.701-32.701l-1.195,24.942
  // c-0.793,16.55,11.979,30.608,28.529,31.402c0.49,0.023,0.975,0.035,1.461,0.035c15.906,0,29.17-12.505,29.941-28.564l4.898-102.193
  // C345.97,23.115,342.804,14.904,336.843,8.927z M187.97,263.526c-14.166,14.165-33,21.966-53.033,21.966
  // c-20.035,0-38.869-7.801-53.033-21.966c-29.242-29.243-29.242-76.823,0-106.066c14.164-14.165,33-21.966,53.033-21.966
  // s38.869,7.801,53.033,21.966C217.212,186.701,217.212,234.282,187.97,263.526z"
  //               />
  //               <path
  //                 className="cls-1"
  //                 d="M336.843,8.927c-5.959-5.976-14.148-9.174-22.594-8.787L211.774,4.758c-16.551,0.746-29.365,14.769-28.619,31.32
  // c0.746,16.552,14.789,29.342,31.32,28.619l24.955-1.125l-32.602,32.602c-21.324-13.469-46.053-20.681-71.893-20.681
  // c-36.061,0-69.963,14.042-95.459,39.541c-52.637,52.635-52.637,138.281,0,190.917c25.496,25.499,59.398,39.54,95.459,39.54
  // c36.061,0,69.961-14.041,95.459-39.54c45.348-45.348,51.623-115.193,18.834-167.326l32.701-32.701l-1.195,24.942
  // c-0.793,16.55,11.979,30.608,28.529,31.402c0.49,0.023,0.975,0.035,1.461,0.035c15.906,0,29.17-12.505,29.941-28.564l4.898-102.193
  // C345.97,23.115,342.804,14.904,336.843,8.927z M187.97,263.526c-14.166,14.165-33,21.966-53.033,21.966
  // c-20.035,0-38.869-7.801-53.033-21.966c-29.242-29.243-29.242-76.823,0-106.066c14.164-14.165,33-21.966,53.033-21.966
  // s38.869,7.801,53.033,21.966C217.212,186.701,217.212,234.282,187.97,263.526z"
  //                 clip-path="url(#cut-off-bottom)"
  //               />
  //             </svg>
  //           </span>
  //         </div>
  //       </Flex_box>
  //       <Flex_box
  //         style={{
  //           gap: 10,
  //           width: "",
  //         }}
  //       >
  //         <span className="ikoncircle" id="female">
  //           <svg
  //             className="genelsvgs"
  //             id="katman_2"
  //             data-name="katman 2"
  //             xmlns="http://www.w3.org/2000/svg"
  //             viewBox="0 0 24 24"
  //             height={"90px"}
  //             width={"90px"}
  //           >
  //             <defs>
  //               <clipPath id="cut-off-bottom1">
  //                 <rect x="0" y="0" width="24" height="10" />
  //               </clipPath>
  //             </defs>
  //             <path
  //               className="bg"
  //               d="M20 9C20 13.0803 16.9453 16.4471 12.9981 16.9383C12.9994 16.9587 13 16.9793 13 17V19H14C14.5523 19 15 19.4477 15 20C15 20.5523 14.5523 21 14 21H13V22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22V21H10C9.44772 21 9 20.5523 9 20C9 19.4477 9.44772 19 10 19H11V17C11 16.9793 11.0006 16.9587 11.0019 16.9383C7.05466 16.4471 4 13.0803 4 9C4 4.58172 7.58172 1 12 1C16.4183 1 20 4.58172 20 9ZM6.00365 9C6.00365 12.3117 8.68831 14.9963 12 14.9963C15.3117 14.9963 17.9963 12.3117 17.9963 9C17.9963 5.68831 15.3117 3.00365 12 3.00365C8.68831 3.00365 6.00365 5.68831 6.00365 9Z"
  //             />
  //             <path
  //               className="cls-2"
  //               d="M20 9C20 13.0803 16.9453 16.4471 12.9981 16.9383C12.9994 16.9587 13 16.9793 13 17V19H14C14.5523 19 15 19.4477 15 20C15 20.5523 14.5523 21 14 21H13V22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22V21H10C9.44772 21 9 20.5523 9 20C9 19.4477 9.44772 19 10 19H11V17C11 16.9793 11.0006 16.9587 11.0019 16.9383C7.05466 16.4471 4 13.0803 4 9C4 4.58172 7.58172 1 12 1C16.4183 1 20 4.58172 20 9ZM6.00365 9C6.00365 12.3117 8.68831 14.9963 12 14.9963C15.3117 14.9963 17.9963 12.3117 17.9963 9C17.9963 5.68831 15.3117 3.00365 12 3.00365C8.68831 3.00365 6.00365 5.68831 6.00365 9Z"
  //               clip-path="url(#cut-off-bottom1)"
  //             />
  //           </svg>
  //         </span>
  //         <div>
  //           <b
  //             style={{
  //               color: "green",
  //               fontSize: "28px",
  //             }}
  //           >
  //             41.7%
  //           </b>
  //           <p
  //             style={{
  //               fontSize: "12px",
  //               textAlign: "start",
  //             }}
  //           >
  //             Female
  //           </p>
  //         </div>
  //       </Flex_box>
  //     </Flex_box>
  //   );
};

export const num_regex = /^\d+$/;

export const get_array = (length) => {
  var arr = [];
  for (let index = 0; index < length; index++) {
    arr.push(index + 1);
  }
  return arr;
};

export const reportMenu = [
  { id: 1, label: "Spam", value: "Spam", action: "reportSpam" },
  { id: 2, label: "Inappropriate", value: "Inappropriate", action: "reportInappropriate" },
  { id: 3, label: "Harassment or bullying", value: "Harassment or bullying", action: "reportHarassment" },
  { id: 4, label: "Hate speech", value: "Hate speech", action: "reportHateSpeech" },
  { id: 5, label: "Misinformation", value: "Misinformation", action: "reportMisinformation" },
  { id: 6, label: "Other", value: "Other", action: "reportOther" },
];


export const email_regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/