import React from "react";
import Layout from "../utilities/Layout";
import Page_heading from "../utilities/Page_heading";
import { theme_bg } from "../utilities/colors";
import FA_Icon_box from "../utilities/Icon_Box";
import { useNavigate } from "react-router-dom";
import Flex_box from "../utilities/flex_box";
import iso_pic_2 from "../assets/Cognicert-MS-Certificate-Pierrine-ISO20252-1447x2048.webp";

const Our_certifications = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        padding: "20px",
        boxSizing: "border-box",
      }}
    >
      <Page_heading
        label={""}
        suffix={
          <FA_Icon_box
            className={"fa-regular fa-circle-xmark"}
            style={{
              backgroundColor: "#000",
            }}
            onClick={() => {
                navigate(-1)
            }}
          />
        }
      />
      <div
        style={{
          backgroundColor: theme_bg,
          padding: "15px",
          borderRadius: "10px",
          fontSize: "14px",
          lineHeight: 1.7,
          marginTop: "15px",
          height: "85vh",
          overflowY: "auto",
        }}
      >
        <h1>
          <b>Our ISO Certifications</b>
        </h1>
        <p>
          At Yaarnbox, our international certifications are not just symbols of
          compliance—they embody{" "}
          <b>
            our vision of driving excellence in consumer insights across Africa.
          </b>{" "}
          These certifications affirm that our processes adhere to globally
          recognized standards, ensuring that we deliver quality, consistency,
          and trust in every project. For us, these certifications are a
          reflection of our ongoing commitment to innovation and service
          excellence, providing brands with reliable data and insights that fuel
          informed decisions.
        </p>

        <p>
          Our vision goes beyond mere data collection. We aim to be{" "}
          <b>a transformative force in the way brands engage with consumers</b>,
          ensuring that every insight is backed by rigorous quality management.
          These certifications guarantee that brands working with Yaarnbox are
          collaborating with a platform that values precision, ethical
          standards, and continuous improvement.
        </p>

        <p>
          By aligning with global benchmarks, we empower brands to make smarter,
          data-driven decisions, confident that they’re partnering with a
          platform built on integrity and excellence.
        </p>
        <Flex_box
          style={{
            flexWrap: "wrap",
            gap: 40,
            marginTop:"20px"
          }}
        >
          <div
            style={{
              width: "220px",
            }}
          >
            <h2>
              <b>ISO 20252 -</b>
            </h2>
            <p>
              This is the global certification for any market and social
              research agency where consistent and reliable delivery of quality
              services is essential. The standard provides guidance for those
              conducting, commissioning or buying market or social research and
              provides assurance that specified high standards of
              delivery/requirements are being met.
            </p>
          </div>
          <img
            style={{
              height: "400px",
            }}
            src={iso_pic_2}
          />
        </Flex_box>
        <Flex_box
          style={{
            flexWrap: "wrap",
            gap: 40,
            marginTop:"40px"
          }}
        >
          <img
            style={{
              height: "400px",
            }}
            src={iso_pic_2}
          />
          <div
            style={{
              width: "300px",
            }}
          >
            <h2>
              <b>ISO 9001 -</b>
            </h2>
            <p>
              Our ISO 9001 certification represents our commitment to
              maintaining the highest standards of quality management. As the
              globally recognized benchmark, ISO 9001 signifies that we have
              implemented a robust, efficient, and customer-focused management
              system.
            </p>
            <p>
              This certification not only highlights our dedication to
              continuous improvement but also ensures that we consistently
              deliver exceptional services that meet both regulatory
              requirements and customer expectations. It is a mark of excellence
              that reinforces our reliability and commitment to quality at every
              level of our operations.
            </p>
          </div>
        </Flex_box>
      </div>
    </div>
  );
};

export default Our_certifications;
