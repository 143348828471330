// import { useEffect, useState } from "react";
// import { theme_bg } from "./colors";
// import Flex_box from "./flex_box";

// export const Modal = ({
//   isOpen,
//   label,
//   onClose,
//   children,
//   style,
//   content_style,
//   label_style,
//   footer,
// }) => {
//   const [modal_visibility, setmodal_visibility] = useState(false);

//   useEffect(() => {
//     if (isOpen) {
//       setmodal_visibility(true);
//     } else {
//       setmodal_visibility(false);
//     }
//   }, [isOpen]);

//   const label_style_obj = label_style || {};

//   // window.addEventListener("click", (event) => {
//     // if (modal_visibility) {
//     //   setmodal_visibility(false);
//     //   if (document.querySelector(".modal-content")?.contains(event.target)) {
//     //     setmodal_visibility(false);
//     //     return;
//     //   } else {
//     //     if (onClose) {
//     //       onClose();
//     //       setmodal_visibility(false);
//     //     }
//     //   }
//     // }
//   // });

//   // if (!isOpen) return null;

//   return (
//     <>

//       <button
//         type="button"
//         class="btn btn-primary"
//         data-bs-toggle="modal"
//         data-bs-target="#exampleModal"
//         style={{
//           cursor:"pointer"
//         }}
//       >
//         Launch demo modal
//       </button>

//       <div
//         class="modal fade"
//         id="exampleModal"
//         tabindex="1"
//         aria-labelledby="exampleModalLabel"
//         aria-hidden="true"
//       >
//         <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
//           <div class="modal-content">
//             <div class="modal-header">
//               <h5 class="modal-title" id="exampleModalLabel">
//                 Modal title
//               </h5>
//               <button
//                 type="button"
//                 class="btn-close"
//                 data-bs-dismiss="modal"
//                 aria-label="Close"
//               ></button>
//             </div>
//             <div class="modal-body">...</div>
//             <div class="modal-footer">
//               <button
//                 type="button"
//                 class="btn btn-secondary"
//                 data-bs-dismiss="modal"
//               >
//                 Close
//               </button>
//               <button type="button" class="btn btn-primary">
//                 Save changes
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "@mui/material";
import Flex_box from "./flex_box";

export const Modal = ({
  isOpen,
  label,
  onClose,
  children,
  style,
  content_style,
  label_style,
  footer,
  static_modal,
  label_suffix
}) => {
  const max_w_500 = useMediaQuery("(max-width:500px)");

  const [modal_visibility, setmodal_visibility] = useState(false);
  const modalRef = useRef(null);
  const label_style_obj = label_style || {};

  // Detect click outside modal content to close modal
  useEffect(() => {
    if (!static_modal) {
      const handleOutsideClick = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          onClose();
          // setmodal_visibility(false);
        }
      };

      if (modal_visibility) {
        document.addEventListener("mousedown", handleOutsideClick);
      } else {
        document.removeEventListener("mousedown", handleOutsideClick);
      }

      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }
  }, [modal_visibility]);

  useEffect(() => {
    if (isOpen) {
      setmodal_visibility(true);
    } else {
      setmodal_visibility(false);
    }
  }, [isOpen]);

  const m_style = style || {};
  const content_style_obj = content_style || {};
  if (!isOpen) return null;

  return (
    <div>
      {modal_visibility && (
        <div
          className={`modal fade show d-block`}
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden={!modal_visibility}
          style={{
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "block",
            ...m_style,
          }}
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
            style={{
              maxWidth: "100%",
              width: max_w_500 ? "100%" : "43%",
              minWidth: "320px",
              ...content_style,
            }}
          >
            <div className="modal-content" ref={modalRef}>
              {label && (
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    id="exampleModalLabel"
                    style={label_style || {}}
                  >
                    {label}
                  </h5>
                  <Flex_box
                    style={{
                      width: "",
                      gap: 10,
                    }}
                  >
                    {label_suffix}
                    {onClose && (
                      <button
                        type="button"
                        className="btn-close"
                        onClick={onClose}
                        aria-label="Close"
                      ></button>
                    )}
                  </Flex_box>
                </div>
              )}
              <div className="modal-body">{children}</div>
              {footer && <div className="modal-footer">{footer}</div>}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
