import React, { useEffect, useState } from "react";
import Layout from "../../../utilities/Layout";
import { get_researcher_inc_history_api } from "../../../apis/apis";
import { useSelector } from "react-redux";
import DataTable from "../../../utilities/DataTable";
import Page_heading from "../../../utilities/Page_heading";

const Incentive_History = () => {
  const t_heads = [
    {
      label: "S No.",
      accessor: "index",
      element: (el, index) => {
        return (
          <div
            style={{
              width: "80px",
            }}
          >
            <span>{index + 1}</span>
          </div>
        );
      },
    },
    {
      label: "Respondent",
      accessor: "-",
      element: (el, index) => {
        return (
          <span>
            {el?.user_lookup[0]?.firstname + " " + el?.user_lookup[0]?.lastname}
          </span>
        );
      },
    },
    {
      label: "Amount",
      accessor: "amount",
      element: (el) => {
        return <span>{el?.amount + " coins"}</span>;
      },
    },
    {
      label: "Study",
      accessor: "-",
      element: (el, index) => {
        return <span>{el?.study_lookup[0]?.studyname}</span>;
      },
    },
    {
      label: "Date",
      accessor: "-",
      element: (el, index) => {
        return <span>{new Date(el?.createdAt).toDateString()}</span>;
      },
    },
  ];

  const { user_data } = useSelector((state) => state?.authReducer);
  const { app_balance } = useSelector((state) => state?.appReducer) || {};

  const [incentive_history, setincentive_history] = useState([]);

  const get_incentive_history = () => {
    get_researcher_inc_history_api({ userId: user_data?._id })
      .then((res) => {
        setincentive_history(res.data.res);
      })
      .catch((Err) => {
        console.error(Err);
      });
  };

  useEffect(get_incentive_history, []);

  return (
    <Layout
      style={{
        padding: "10px",
      }}
    >
      <Page_heading label={"Incentive Balance (" + app_balance + " left)"} />
      {incentive_history?.length > 0 && (
        <DataTable style={{
            marginTop:"10px"
        }} fontSize={"14px"} heads={t_heads} rows={incentive_history} />
      )}
    </Layout>
  );
};

export default Incentive_History;
