import React, { useEffect } from "react";
import Create_study_comp from "./Create_study_comp";
import Flex_box from "../../../../utilities/flex_box";
import Sidebar from "../../../Nav_Comp/Sidebar";
import Navbar from "../../../Nav_Comp/Navbar";
import Footer from "../../../Nav_Comp/Footer";
import { useDispatch, useSelector } from "react-redux";
import { check_kyc_status_api, get_kyc_docs_api } from "../../../../apis/apis";
import { alert_action } from "../../../../redux_prog/actions/base_action";
import { useNavigate } from "react-router-dom";
import { get_subscription_data_count_and_kyc } from "../../../../utilities/utilities";

const Create_study = () => {
  const { user_data } = useSelector((state) => state?.authReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const get_kyc_status = () => {
    get_subscription_data_count_and_kyc(dispatch, user_data)
      .then((sub_data) => {
        if (sub_data?.study_flag) {
          dispatch(
            alert_action({
              type: "error",
              title: "Study limit exceeded!",
              msg: "Please extend your study limit!",
            })
          );
        } else if (sub_data?.respondent_flag) {
          dispatch(
            alert_action({
              type: "error",
              title: "Respondent limit exceeded!",
              msg: "Please extend your respondent limit!",
            })
          );
        } else if (sub_data?.kyc_flag) {
          dispatch(
            alert_action({
              type: "error",
              title: "KYC Unverified!",
              msg: "Your KYC documents are not verified yet!",
            })
          );
        } else {
          return;
        }
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  useEffect(get_kyc_status, []);

  return (
    <Flex_box
      style={{
        justifyContent: "start",
        // paddingBottom: "70px",
      }}
    >
      <Sidebar />
      <div
        className="comp_container"
        style={{
          position: "relative",
        }}
      >
        <Navbar />
        <Create_study_comp />
        <Footer />
      </div>
    </Flex_box>
  );
};

export default Create_study;
