// import React from "react";
// import { theme_bg, theme_color } from "./colors";
// import Flex_box from "./flex_box";

// const DataTable = ({ style, heads, rows, fontSize, row_click_func }) => {
//   const style_obj = style || {};

//   const th_col_style = {
//     padding: "18px 0px 18px 15px",
//     textAlign: "start",
//     borderRadius: "10px",
//   };

//   const td_col_style = {
//     padding: "10px 0px 0px 15px",
//     textAlign: "start",
//   };

//   return (
//     <table
//       style={{
//         width: "100%",
//         padding: "0px",
//         ...style_obj,
//       }}
//     >
//       <thead
//         style={{
//           // backgroundColor: theme_bg,
//           color: "#000",
//           fontWeight: "600",
//           fontSize: fontSize || "11px",
//           // display: "flex",
//           // gap: "10",
//         }}
//       >
//         {heads?.length > 0 &&
//           heads.map((el, index) => {
//             return (
//               <th key={index}>
//                 <div
//                   style={{
//                     ...th_col_style,
//                     backgroundColor: theme_bg,
//                   }}
//                 >
//                   {el?.label}
//                 </div>
//               </th>
//             );
//           })}
//       </thead>
//       <tbody
//         style={{
//           backgroundColor: "#FFF",
//           color: "#000",
//           fontSize: fontSize || "11px",
//         }}
//       >
//         {rows?.length > 0 &&
//           rows.map((el, index) => {
//             return (
//               <tr
//                 key={index}
//                 style={{
//                   cursor: "pointer",
//                 }}
//                 onClick={() => {
//                   if (row_click_func) {
//                     row_click_func(el);
//                   }
//                 }}
//               >
//                 {heads?.length > 0 &&
//                   heads.map((li, ind) => {
//                     const style_obj = li.tr_style || {};
//                     return (
//                       <td
//                         key={ind}
//                         onClick={() => {
//                           if (li?.td_func) {
//                             li.td_func(el);
//                           }
//                         }}
//                         style={{ ...td_col_style, ...style_obj }}
//                       >
//                         {li.element
//                           ? li.element(el, index)
//                           : el[li.accessor] || "-"}
//                       </td>
//                     );
//                   })}
//               </tr>
//             );
//           })}
//       </tbody>
//     </table>
//   );
// };

// export default DataTable;

import * as React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import { theme_bg } from "./colors";
import { Box } from "@mui/material";

const paginationModel = { page: 0, pageSize: 10 };

export default function DataTable({
  style,
  heads,
  rows,
  fontSize,
  row_click_func,
  rowHeight,
}) {
  const columns = heads?.map((el) => {
    const val_getter_obj = el?.element
      ? {
          renderCell: (row) => {
            return el?.element(row.row, row.row.index, row);
          },
        }
      : {};

    const w_obj = el?.width ? { width: el?.width } : {  };
    return {
      field: el?.element ? Math.random() * 1000 : el?.accessor,
      headerName: el?.label,
      sortable: true,
      minWidth:150,
      flex:1,
      ...w_obj, // Set a default static width or use a provided width
      ...val_getter_obj,
    };
  });

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: theme_bg,
        padding: "20px",
        paddingBottom: "20px",
      }}
    >
      <Box
        sx={{ width: "100%", overflowX: "auto" }}
        className="data-grid-container"
      >
        <DataGrid
          rows={rows?.map((el, index) => {
            return {
              ...el,
              id: el?._id ? el?._id : Math.random() * 10000,
              index,
            };
          })}
          columns={columns}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[5, 10]}
          // checkboxSelection
          sx={{ border: 0 }}
          disableColumnResize
          disableColumnMenu
          style={{
            backgroundColor: "#fff",
          }}
          rowHeight={rowHeight || ""}
        />
      </Box>
    </div>
  );
}
