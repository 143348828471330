import Chats from "../screens/Chat_Screens/pages/Chats.jsx"
import Dashboard from "../screens/Researcher_screens/Dashboard/index.jsx"
import FAQ from "../screens/Researcher_screens/FAQ/index.jsx"
import KYC from "../screens/Researcher_screens/KYC/index.jsx"
import My_GDs from "../screens/Researcher_screens/My_GDs/index.jsx"
import Create_GD from "../screens/Researcher_screens/My_Studies/Create_GD/index.jsx"
import Create_Survey from "../screens/Researcher_screens/My_Studies/Create_Survey/index.jsx"
import Add_Questions from "../screens/Researcher_screens/My_Studies/Create_Survey/Add_Questions/index.jsx"
import Preview_Survey from "../screens/Researcher_screens/My_Studies/Create_Survey/Preview_Survey/index.jsx"
import Create_study from "../screens/Researcher_screens/My_Studies/Create_study/index.jsx"
import GD_Chat from "../screens/Researcher_screens/My_Studies/GD_Chat/index.jsx"
import Study_Intro_Page from "../screens/Researcher_screens/My_Studies/Intro_Page.jsx"
import Resp_list from "../screens/Researcher_screens/My_Studies/Resp_list/index.jsx"
import Select_Study_Type from "../screens/Researcher_screens/My_Studies/Select_Study_Type.jsx"
import Send_Invite from "../screens/Researcher_screens/My_Studies/Send_Invite/index.jsx"
import Study_Details from "../screens/Researcher_screens/My_Studies/Study_Details/index.jsx"
import Survey_Analysis from "../screens/Researcher_screens/My_Studies/Survey_Analysis/index.jsx"
import Edit_Profile from "../screens/Researcher_screens/Profile/Edit_Profile.jsx"
import My_Profile from "../screens/Researcher_screens/Profile/My_Profile.jsx"
import Active_Subscription from "../screens/Researcher_screens/Active_Subscriptions/Index.jsx"
import Subscriptions from "../screens/Researcher_screens/Subscriptions_Packages/Sub_Pages.jsx"
import Manage_Profile from "../screens/Researcher_screens/Profile/My_Profile.jsx"
import Incentive_History from "../screens/Researcher_screens/Incentive_History/index.jsx"
import Subscription_quota from "../screens/Researcher_screens/Subscription_quota/index.jsx"
// import Multi_user_VC from "../screens/Video_Chat/Multi_user_VC/index.js";
// import VideoCall from "../screens/Video_Chat/VideoCall";
// import V_Call from "../screens/Video_Chat/agora_ng_version/V_Call.tsx";

export const researcher_routes = [
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/create-study",
    element: <Create_study />,
  },
  {
    path: "/select-study-type",
    element: <Select_Study_Type />,
  },
  {
    path: "/create-survey",
    element: <Create_Survey />,
  },
  {
    path: "/add-survey-questions",
    element: <Add_Questions />,
  },
  {
    path: "/preview-survey",
    element: <Preview_Survey />,
  },
  {
    path: "/create-gd",
    element: <Create_GD />,
  },
  {
    path: "/send-invite",
    element: <Send_Invite />,
  },
  {
    path: "/gd-chat",
    element: <GD_Chat />,
    // element:<zzzzz
  },
  {
    path: "/my-profile",
    element: <My_Profile />,
  },
  {
    path: "/faq",
    element: <FAQ />,
  },
  {
    path: "/study-details",
    element: <Study_Details />,
  },
  {
    path: "/survey-analysis",
    element: <Survey_Analysis />,
  },
  {
    path: "/edit-profile",
    element: <Edit_Profile />,
  },
  {
    path: "/study-intro",
    element: <Study_Intro_Page />,
  },
  {
    path: "/chat-comp",
    element: <Chats />,
  },
  {
    path: "/my-gds",
    element: <My_GDs />,
  },
  {
    path: "/resp-list",
    element: <Resp_list />,
  },
  {
    path: "/kyc",
    element: <KYC />,
  },
  {
    path: "/subscription",
    element: <Subscriptions />,
  },
  {
    path: "/active-subscription",
    element: <Active_Subscription />,
  },
  {
    path: "/My_Profile",
    element: <Manage_Profile />,
  },
  {
    path: "/incentive-history",
    element: <Incentive_History />,
  },
  {
    path: "/my-surveys",
    element: <Dashboard />,
  },
  {
    path: "sub-quota",
    element: <Subscription_quota />,
  },
  // {
  //     path: '/video-chat-comp',
  //     element: <Multi_user_VC />
  // },
]
