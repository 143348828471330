export const countries = [
     null,
     {
      "African Countries and Their States\/Regions": "1. Algeria"
     },
     {
      "African Countries and Their States\/Regions": "Adrar"
     },
     {
      "African Countries and Their States\/Regions": "Chlef"
     },
     {
      "African Countries and Their States\/Regions": "Laghouat"
     },
     {
      "African Countries and Their States\/Regions": "Oum El Bouaghi"
     },
     {
      "African Countries and Their States\/Regions": "Batna"
     },
     {
      "African Countries and Their States\/Regions": "Béjaïa"
     },
     {
      "African Countries and Their States\/Regions": "Biskra"
     },
     {
      "African Countries and Their States\/Regions": "Béchar"
     },
     {
      "African Countries and Their States\/Regions": "Tamanrasset"
     },
     {
      "African Countries and Their States\/Regions": "Tipaza"
     },
     {
      "African Countries and Their States\/Regions": "Tissemsilt"
     },
     {
      "African Countries and Their States\/Regions": "El Oued"
     },
     {
      "African Countries and Their States\/Regions": "Annaba"
     },
     {
      "African Countries and Their States\/Regions": "Guelma"
     },
     {
      "African Countries and Their States\/Regions": "Constantine"
     },
     {
      "African Countries and Their States\/Regions": "Médéa"
     },
     {
      "African Countries and Their States\/Regions": "Mostaganem"
     },
     {
      "African Countries and Their States\/Regions": "Mascara"
     },
     {
      "African Countries and Their States\/Regions": "Ouargla"
     },
     {
      "African Countries and Their States\/Regions": "Relizane"
     },
     {
      "African Countries and Their States\/Regions": "El Tarf"
     },
     {
      "African Countries and Their States\/Regions": "Tindouf"
     },
     {
      "African Countries and Their States\/Regions": "Tissemsilt"
     },
     null,
     {
      "African Countries and Their States\/Regions": "2. Angola"
     },
     {
      "African Countries and Their States\/Regions": "Bengo"
     },
     {
      "African Countries and Their States\/Regions": "Benguela"
     },
     {
      "African Countries and Their States\/Regions": "Bié"
     },
     {
      "African Countries and Their States\/Regions": "Cabinda"
     },
     {
      "African Countries and Their States\/Regions": "Huambo"
     },
     {
      "African Countries and Their States\/Regions": "Huíla"
     },
     {
      "African Countries and Their States\/Regions": "Kwanza Norte"
     },
     {
      "African Countries and Their States\/Regions": "Kwanza Sul"
     },
     {
      "African Countries and Their States\/Regions": "Luanda"
     },
     {
      "African Countries and Their States\/Regions": "Malanje"
     },
     {
      "African Countries and Their States\/Regions": "Namibe"
     },
     {
      "African Countries and Their States\/Regions": "Cuando Cubango"
     },
     {
      "African Countries and Their States\/Regions": "Uíge"
     },
     {
      "African Countries and Their States\/Regions": "Zaire"
     },
     null,
     {
      "African Countries and Their States\/Regions": "3. Benin"
     },
     {
      "African Countries and Their States\/Regions": "Alibori"
     },
     {
      "African Countries and Their States\/Regions": "Atakora"
     },
     {
      "African Countries and Their States\/Regions": "Atlantique"
     },
     {
      "African Countries and Their States\/Regions": "Borgou"
     },
     {
      "African Countries and Their States\/Regions": "Collines"
     },
     {
      "African Countries and Their States\/Regions": "Couffo"
     },
     {
      "African Countries and Their States\/Regions": "Donga"
     },
     {
      "African Countries and Their States\/Regions": "Littoral"
     },
     {
      "African Countries and Their States\/Regions": "Mono"
     },
     {
      "African Countries and Their States\/Regions": "Ouémé"
     },
     {
      "African Countries and Their States\/Regions": "Plateau"
     },
     {
      "African Countries and Their States\/Regions": "Zou"
     },
     null,
     {
      "African Countries and Their States\/Regions": "4. Botswana"
     },
     {
      "African Countries and Their States\/Regions": "Gaborone"
     },
     {
      "African Countries and Their States\/Regions": "Francistown"
     },
     {
      "African Countries and Their States\/Regions": "Maun"
     },
     {
      "African Countries and Their States\/Regions": "Molepolole"
     },
     {
      "African Countries and Their States\/Regions": "Serowe"
     },
     {
      "African Countries and Their States\/Regions": "Selibe Phikwe"
     },
     {
      "African Countries and Their States\/Regions": "Palapye"
     },
     {
      "African Countries and Their States\/Regions": "Lobatse"
     },
     {
      "African Countries and Their States\/Regions": "Kasane"
     },
     {
      "African Countries and Their States\/Regions": "Jwaneng"
     },
     null,
     {
      "African Countries and Their States\/Regions": "5. Burkina Faso"
     },
     {
      "African Countries and Their States\/Regions": "Boucle du Mouhoun"
     },
     {
      "African Countries and Their States\/Regions": "Cascades"
     },
     {
      "African Countries and Their States\/Regions": "Centre"
     },
     {
      "African Countries and Their States\/Regions": "Centre-East"
     },
     {
      "African Countries and Their States\/Regions": "Centre-North"
     },
     {
      "African Countries and Their States\/Regions": "Centre-West"
     },
     {
      "African Countries and Their States\/Regions": "East"
     },
     {
      "African Countries and Their States\/Regions": "North"
     },
     {
      "African Countries and Their States\/Regions": "Sahel"
     },
     {
      "African Countries and Their States\/Regions": "South-West"
     },
     {
      "African Countries and Their States\/Regions": "Hauts-Bassins"
     },
     {
      "African Countries and Their States\/Regions": "Sud-Ouest"
     },
     null,
     {
      "African Countries and Their States\/Regions": "6. Burundi"
     },
     {
      "African Countries and Their States\/Regions": "Bujumbura Mairie"
     },
     {
      "African Countries and Their States\/Regions": "Bujumbura Rural"
     },
     {
      "African Countries and Their States\/Regions": "Gitega"
     },
     {
      "African Countries and Their States\/Regions": "Kayanza"
     },
     {
      "African Countries and Their States\/Regions": "Kirundo"
     },
     {
      "African Countries and Their States\/Regions": "Makamba"
     },
     {
      "African Countries and Their States\/Regions": "Muramvya"
     },
     {
      "African Countries and Their States\/Regions": "Muyinga"
     },
     {
      "African Countries and Their States\/Regions": "Ngozi"
     },
     {
      "African Countries and Their States\/Regions": "Rumonge"
     },
     {
      "African Countries and Their States\/Regions": "Ruyigi"
     },
     null,
     {
      "African Countries and Their States\/Regions": "7. Cabo Verde"
     },
     {
      "African Countries and Their States\/Regions": "Barlavento (Windward Islands)"
     },
     {
      "African Countries and Their States\/Regions": "Sotavento (Leeward Islands)"
     },
     null,
     {
      "African Countries and Their States\/Regions": "8. Cameroon"
     },
     {
      "African Countries and Their States\/Regions": "Adamawa"
     },
     {
      "African Countries and Their States\/Regions": "Centre"
     },
     {
      "African Countries and Their States\/Regions": "East"
     },
     {
      "African Countries and Their States\/Regions": "Far North"
     },
     {
      "African Countries and Their States\/Regions": "Littoral"
     },
     {
      "African Countries and Their States\/Regions": "North"
     },
     {
      "African Countries and Their States\/Regions": "Northwest"
     },
     {
      "African Countries and Their States\/Regions": "South"
     },
     {
      "African Countries and Their States\/Regions": "Southwest"
     },
     {
      "African Countries and Their States\/Regions": "West"
     },
     null,
     {
      "African Countries and Their States\/Regions": "9. Central African Republic"
     },
     {
      "African Countries and Their States\/Regions": "Bangui"
     },
     {
      "African Countries and Their States\/Regions": "Bamingui-Bangoran"
     },
     {
      "African Countries and Their States\/Regions": "Ombella-Mpoko"
     },
     {
      "African Countries and Their States\/Regions": "Nana-Mambéré"
     },
     {
      "African Countries and Their States\/Regions": "Ouham"
     },
     {
      "African Countries and Their States\/Regions": "Ouham-Pendé"
     },
     {
      "African Countries and Their States\/Regions": "Haut-Mbomou"
     },
     {
      "African Countries and Their States\/Regions": "Mbomou"
     },
     {
      "African Countries and Their States\/Regions": "Sangha-Mbaéré"
     },
     {
      "African Countries and Their States\/Regions": "Vakaga"
     },
     null,
     {
      "African Countries and Their States\/Regions": "10. Chad"
     },
     {
      "African Countries and Their States\/Regions": "Batha"
     },
     {
      "African Countries and Their States\/Regions": "Borkou"
     },
     {
      "African Countries and Their States\/Regions": "Chari-Baguirmi"
     },
     {
      "African Countries and Their States\/Regions": "Hadjer-Lamis"
     },
     {
      "African Countries and Their States\/Regions": "Kanem"
     },
     {
      "African Countries and Their States\/Regions": "Lac"
     },
     {
      "African Countries and Their States\/Regions": "Logone Occidental"
     },
     {
      "African Countries and Their States\/Regions": "Logone Oriental"
     },
     {
      "African Countries and Their States\/Regions": "Mayo-Kebbi Est"
     },
     {
      "African Countries and Their States\/Regions": "Mayo-Kebbi Ouest"
     },
     {
      "African Countries and Their States\/Regions": "Ouaddaï"
     },
     {
      "African Countries and Their States\/Regions": "Salamat"
     },
     {
      "African Countries and Their States\/Regions": "Sila"
     },
     {
      "African Countries and Their States\/Regions": "Tandjilé"
     },
     {
      "African Countries and Their States\/Regions": "Tibesti"
     },
     null,
     {
      "African Countries and Their States\/Regions": "11. Comoros"
     },
     {
      "African Countries and Their States\/Regions": "Grande Comore"
     },
     {
      "African Countries and Their States\/Regions": "Anjouan"
     },
     {
      "African Countries and Their States\/Regions": "Mohéli"
     },
     null,
     {
      "African Countries and Their States\/Regions": "12. Republic of the Congo"
     },
     {
      "African Countries and Their States\/Regions": "Bouenza"
     },
     {
      "African Countries and Their States\/Regions": "Brazzaville"
     },
     {
      "African Countries and Their States\/Regions": "Cuvette"
     },
     {
      "African Countries and Their States\/Regions": "Cuvette-Ouest"
     },
     {
      "African Countries and Their States\/Regions": "Likouala"
     },
     {
      "African Countries and Their States\/Regions": "Niari"
     },
     {
      "African Countries and Their States\/Regions": "Plateaux"
     },
     {
      "African Countries and Their States\/Regions": "Pool"
     },
     {
      "African Countries and Their States\/Regions": "Sangha"
     },
     null,
     {
      "African Countries and Their States\/Regions": "13. Democratic Republic of the Congo"
     },
     {
      "African Countries and Their States\/Regions": "Bas-Uele"
     },
     {
      "African Countries and Their States\/Regions": "Haut-Uele"
     },
     {
      "African Countries and Their States\/Regions": "Ituri"
     },
     {
      "African Countries and Their States\/Regions": "Tshopo"
     },
     {
      "African Countries and Their States\/Regions": "Haut-Lomami"
     },
     {
      "African Countries and Their States\/Regions": "Lualaba"
     },
     {
      "African Countries and Their States\/Regions": "Kasaï"
     },
     {
      "African Countries and Their States\/Regions": "Kasaï-Central"
     },
     {
      "African Countries and Their States\/Regions": "Kasaï-Oriental"
     },
     {
      "African Countries and Their States\/Regions": "Sankuru"
     },
     {
      "African Countries and Their States\/Regions": "Maniema"
     },
     {
      "African Countries and Their States\/Regions": "Sud-Kivu"
     },
     {
      "African Countries and Their States\/Regions": "Tanganyika"
     },
     {
      "African Countries and Their States\/Regions": "Haut-Katanga"
     },
     {
      "African Countries and Their States\/Regions": "Kinshasa"
     },
     null,
     {
      "African Countries and Their States\/Regions": "14. Djibouti"
     },
     {
      "African Countries and Their States\/Regions": "Ali Sabieh"
     },
     {
      "African Countries and Their States\/Regions": "Dikhil"
     },
     {
      "African Countries and Their States\/Regions": "Djibouti"
     },
     {
      "African Countries and Their States\/Regions": "Obock"
     },
     {
      "African Countries and Their States\/Regions": "Tadjourah"
     },
     null,
     {
      "African Countries and Their States\/Regions": "15. Egypt"
     },
     {
      "African Countries and Their States\/Regions": "Alexandria"
     },
     {
      "African Countries and Their States\/Regions": "Aswan"
     },
     {
      "African Countries and Their States\/Regions": "Asyut"
     },
     {
      "African Countries and Their States\/Regions": "Beheira"
     },
     {
      "African Countries and Their States\/Regions": "Beni Suef"
     },
     {
      "African Countries and Their States\/Regions": "Cairo"
     },
     {
      "African Countries and Their States\/Regions": "Dakahlia"
     },
     {
      "African Countries and Their States\/Regions": "Damietta"
     },
     {
      "African Countries and Their States\/Regions": "Faiyum"
     },
     {
      "African Countries and Their States\/Regions": "Gharbia"
     },
     {
      "African Countries and Their States\/Regions": "Giza"
     },
     {
      "African Countries and Their States\/Regions": "Ismailia"
     },
     {
      "African Countries and Their States\/Regions": "Kafr El Sheikh"
     },
     {
      "African Countries and Their States\/Regions": "Luxor"
     },
     {
      "African Countries and Their States\/Regions": "Matrouh"
     },
     {
      "African Countries and Their States\/Regions": "Minya"
     },
     {
      "African Countries and Their States\/Regions": "Port Said"
     },
     {
      "African Countries and Their States\/Regions": "Qalyubia"
     },
     {
      "African Countries and Their States\/Regions": "Qena"
     },
     {
      "African Countries and Their States\/Regions": "Red Sea"
     },
     {
      "African Countries and Their States\/Regions": "Sharqia"
     },
     {
      "African Countries and Their States\/Regions": "Sohag"
     },
     {
      "African Countries and Their States\/Regions": "South Sinai"
     },
     {
      "African Countries and Their States\/Regions": "Suez"
     },
     null,
     {
      "African Countries and Their States\/Regions": "16. Equatorial Guinea"
     },
     {
      "African Countries and Their States\/Regions": "Annobón"
     },
     {
      "African Countries and Their States\/Regions": "Bioko Norte"
     },
     {
      "African Countries and Their States\/Regions": "Bioko Sur"
     },
     {
      "African Countries and Their States\/Regions": "Centro Sur"
     },
     {
      "African Countries and Their States\/Regions": "Djibloho"
     },
     {
      "African Countries and Their States\/Regions": "Wele-Nzas"
     },
     null,
     {
      "African Countries and Their States\/Regions": "17. Eritrea"
     },
     null,
     null,
     {
      "African Countries and Their States\/Regions": "Anseba"
     },
     {
      "African Countries and Their States\/Regions": "Debub"
     },
     {
      "African Countries and Their States\/Regions": "Gash-Barka"
     },
     {
      "African Countries and Their States\/Regions": "Maekel"
     },
     {
      "African Countries and Their States\/Regions": "Semen"
     },
     {
      "African Countries and Their States\/Regions": "Southern Red Sea"
     },
     null,
     {
      "African Countries and Their States\/Regions": "18. Eswatini"
     },
     {
      "African Countries and Their States\/Regions": "Hhohho"
     },
     {
      "African Countries and Their States\/Regions": "Lubombo"
     },
     {
      "African Countries and Their States\/Regions": "Manzini"
     },
     {
      "African Countries and Their States\/Regions": "Shiselweni"
     },
     null,
     {
      "African Countries and Their States\/Regions": "19. Ethiopia"
     },
     {
      "African Countries and Their States\/Regions": "Addis Ababa"
     },
     {
      "African Countries and Their States\/Regions": "Afar"
     },
     {
      "African Countries and Their States\/Regions": "Amhara"
     },
     {
      "African Countries and Their States\/Regions": "Benishangul-Gumuz"
     },
     {
      "African Countries and Their States\/Regions": "Dire Dawa"
     },
     {
      "African Countries and Their States\/Regions": "Gambela"
     },
     {
      "African Countries and Their States\/Regions": "Harari"
     },
     {
      "African Countries and Their States\/Regions": "Oromia"
     },
     {
      "African Countries and Their States\/Regions": "Sidama"
     },
     {
      "African Countries and Their States\/Regions": "Somali"
     },
     {
      "African Countries and Their States\/Regions": "SNNPR"
     },
     {
      "African Countries and Their States\/Regions": "Tigray"
     },
     null,
     {
      "African Countries and Their States\/Regions": "20. Gabon"
     },
     {
      "African Countries and Their States\/Regions": "Estuaire"
     },
     {
      "African Countries and Their States\/Regions": "Haut-Ogooué"
     },
     {
      "African Countries and Their States\/Regions": "Moyen-Ogooué"
     },
     {
      "African Countries and Their States\/Regions": "Ngounié"
     },
     {
      "African Countries and Their States\/Regions": "Nyanga"
     },
     {
      "African Countries and Their States\/Regions": "Ogooué-Ivindo"
     },
     {
      "African Countries and Their States\/Regions": "Ogooué-Lolo"
     },
     {
      "African Countries and Their States\/Regions": "Woleu-Ntem"
     },
     null,
     {
      "African Countries and Their States\/Regions": "21. Gambia"
     },
     {
      "African Countries and Their States\/Regions": "Banjul"
     },
     {
      "African Countries and Their States\/Regions": "Central River"
     },
     {
      "African Countries and Their States\/Regions": "Lower River"
     },
     {
      "African Countries and Their States\/Regions": "North Bank"
     },
     {
      "African Countries and Their States\/Regions": "Upper River"
     },
     {
      "African Countries and Their States\/Regions": "Western"
     },
     null,
     {
      "African Countries and Their States\/Regions": "22. Ghana"
     },
     {
      "African Countries and Their States\/Regions": "Greater Accra"
     },
     {
      "African Countries and Their States\/Regions": "Ashanti"
     },
     {
      "African Countries and Their States\/Regions": "Western"
     },
     {
      "African Countries and Their States\/Regions": "Eastern"
     },
     {
      "African Countries and Their States\/Regions": "Northern"
     },
     {
      "African Countries and Their States\/Regions": "Central"
     },
     {
      "African Countries and Their States\/Regions": "Western North"
     },
     {
      "African Countries and Their States\/Regions": "Volta"
     },
     {
      "African Countries and Their States\/Regions": "Bono"
     },
     {
      "African Countries and Their States\/Regions": "Bono East"
     },
     {
      "African Countries and Their States\/Regions": "Ahafo"
     },
     {
      "African Countries and Their States\/Regions": "Oti"
     },
     null,
     {
      "African Countries and Their States\/Regions": "23. Guinea"
     },
     {
      "African Countries and Their States\/Regions": "Boke"
     },
     {
      "African Countries and Their States\/Regions": "Conakry"
     },
     {
      "African Countries and Their States\/Regions": "Coyah"
     },
     {
      "African Countries and Their States\/Regions": "Dalaba"
     },
     {
      "African Countries and Their States\/Regions": "Dinguiraye"
     },
     {
      "African Countries and Their States\/Regions": "Faranah"
     },
     {
      "African Countries and Their States\/Regions": "Fria"
     },
     {
      "African Countries and Their States\/Regions": "Kankan"
     },
     {
      "African Countries and Their States\/Regions": "Kindia"
     },
     {
      "African Countries and Their States\/Regions": "Mamou"
     },
     {
      "African Countries and Their States\/Regions": "Nzerekore"
     },
     {
      "African Countries and Their States\/Regions": "Télémélé"
     },
     null,
     {
      "African Countries and Their States\/Regions": "24. Guinea-Bissau"
     },
     {
      "African Countries and Their States\/Regions": "Bissau"
     },
     {
      "African Countries and Their States\/Regions": "Bolama"
     },
     {
      "African Countries and Their States\/Regions": "Cacheu"
     },
     {
      "African Countries and Their States\/Regions": "Gabu"
     },
     {
      "African Countries and Their States\/Regions": "Oio"
     },
     {
      "African Countries and Their States\/Regions": "Quinara"
     },
     {
      "African Countries and Their States\/Regions": "Tombali"
     },
     null,
     {
      "African Countries and Their States\/Regions": "25. Côte d'Ivoire"
     },
     {
      "African Countries and Their States\/Regions": "Abidjan"
     },
     {
      "African Countries and Their States\/Regions": "Bas-Sassandra"
     },
     {
      "African Countries and Their States\/Regions": "Comoé"
     },
     {
      "African Countries and Their States\/Regions": "Denguélé"
     },
     {
      "African Countries and Their States\/Regions": "Gôh-Djiboua"
     },
     {
      "African Countries and Their States\/Regions": "Lacs"
     },
     {
      "African Countries and Their States\/Regions": "Lagunes"
     },
     {
      "African Countries and Their States\/Regions": "Savanes"
     },
     {
      "African Countries and Their States\/Regions": "Vallée du Bandama"
     },
     {
      "African Countries and Their States\/Regions": "Woroba"
     },
     {
      "African Countries and Their States\/Regions": "Yamoussoukro"
     },
     null,
     {
      "African Countries and Their States\/Regions": "26. Kenya"
     },
     {
      "African Countries and Their States\/Regions": "Central"
     },
     {
      "African Countries and Their States\/Regions": "Coast"
     },
     {
      "African Countries and Their States\/Regions": "Eastern"
     },
     {
      "African Countries and Their States\/Regions": "Nairobi"
     },
     {
      "African Countries and Their States\/Regions": "North Eastern"
     },
     {
      "African Countries and Their States\/Regions": "Nyanza"
     },
     {
      "African Countries and Their States\/Regions": "Rift Valley"
     },
     {
      "African Countries and Their States\/Regions": "Western"
     },
     {
      "African Countries and Their States\/Regions": "Southern"
     },
     null,
     {
      "African Countries and Their States\/Regions": "27. Lesotho"
     },
     {
      "African Countries and Their States\/Regions": "Berea"
     },
     {
      "African Countries and Their States\/Regions": "Butha-Buthe"
     },
     {
      "African Countries and Their States\/Regions": "Leribe"
     },
     {
      "African Countries and Their States\/Regions": "Mafeteng"
     },
     {
      "African Countries and Their States\/Regions": "Maseru"
     },
     {
      "African Countries and Their States\/Regions": "Mohale's Hoek"
     },
     {
      "African Countries and Their States\/Regions": "Qacha's Nek"
     },
     {
      "African Countries and Their States\/Regions": "Quthing"
     },
     {
      "African Countries and Their States\/Regions": "Thaba-Tseka"
     },
     null,
     {
      "African Countries and Their States\/Regions": "28. Liberia"
     },
     {
      "African Countries and Their States\/Regions": "Bomi"
     },
     {
      "African Countries and Their States\/Regions": "Bong"
     },
     {
      "African Countries and Their States\/Regions": "Grand Bassa"
     },
     {
      "African Countries and Their States\/Regions": "Grand Cape Mount"
     },
     {
      "African Countries and Their States\/Regions": "Grand Gedeh"
     },
     {
      "African Countries and Their States\/Regions": "Lofa"
     },
     {
      "African Countries and Their States\/Regions": "Margibi"
     },
     {
      "African Countries and Their States\/Regions": "Maryland"
     },
     {
      "African Countries and Their States\/Regions": "Montserrado"
     },
     {
      "African Countries and Their States\/Regions": "Nimba"
     },
     {
      "African Countries and Their States\/Regions": "River Cess"
     },
     {
      "African Countries and Their States\/Regions": "River Gee"
     },
     {
      "African Countries and Their States\/Regions": "Sinoe"
     },
     null,
     {
      "African Countries and Their States\/Regions": "29. Libya"
     },
     {
      "African Countries and Their States\/Regions": "Al Awbari"
     },
     {
      "African Countries and Their States\/Regions": "Al Bayda"
     },
     {
      "African Countries and Their States\/Regions": "Al Jabal al Akhdar"
     },
     {
      "African Countries and Their States\/Regions": "Al Jufra"
     },
     {
      "African Countries and Their States\/Regions": "Al Kufra"
     },
     {
      "African Countries and Their States\/Regions": "Al Marj"
     },
     {
      "African Countries and Their States\/Regions": "Al Wahat"
     },
     {
      "African Countries and Their States\/Regions": "Benghazi"
     },
     {
      "African Countries and Their States\/Regions": "Derna"
     },
     {
      "African Countries and Their States\/Regions": "Ghat"
     },
     {
      "African Countries and Their States\/Regions": "Misrata"
     },
     {
      "African Countries and Their States\/Regions": "Nalut"
     },
     {
      "African Countries and Their States\/Regions": "Sabha"
     },
     {
      "African Countries and Their States\/Regions": "Sirte"
     },
     {
      "African Countries and Their States\/Regions": "Tripoli"
     },
     {
      "African Countries and Their States\/Regions": "Zawiya"
     },
     null,
     {
      "African Countries and Their States\/Regions": "30. Madagascar"
     },
     {
      "African Countries and Their States\/Regions": "Antananarivo"
     },
     {
      "African Countries and Their States\/Regions": "Antananarivo Atsimondrano"
     },
     {
      "African Countries and Their States\/Regions": "Atsimo-Andrefana"
     },
     {
      "African Countries and Their States\/Regions": "Atsimo-Atsinanana"
     },
     {
      "African Countries and Their States\/Regions": "Diana"
     },
     {
      "African Countries and Their States\/Regions": "Haute Matsiatra"
     },
     {
      "African Countries and Their States\/Regions": "Ihorombe"
     },
     {
      "African Countries and Their States\/Regions": "Itasy"
     },
     {
      "African Countries and Their States\/Regions": "Melaky"
     },
     {
      "African Countries and Their States\/Regions": "Menabe"
     },
     {
      "African Countries and Their States\/Regions": "Sava"
     },
     {
      "African Countries and Their States\/Regions": "Sofala"
     },
     {
      "African Countries and Their States\/Regions": "Vakinankaratra"
     },
     {
      "African Countries and Their States\/Regions": "Vatovavy-Fitovinany"
     },
     null,
     {
      "African Countries and Their States\/Regions": "31. Malawi"
     },
     {
      "African Countries and Their States\/Regions": "Balaka"
     },
     {
      "African Countries and Their States\/Regions": "Blantyre"
     },
     {
      "African Countries and Their States\/Regions": "Chikwawa"
     },
     {
      "African Countries and Their States\/Regions": "Chiradzulu"
     },
     {
      "African Countries and Their States\/Regions": "Dedza"
     },
     {
      "African Countries and Their States\/Regions": "Dowa"
     },
     {
      "African Countries and Their States\/Regions": "Karonga"
     },
     {
      "African Countries and Their States\/Regions": "Kasungu"
     },
     {
      "African Countries and Their States\/Regions": "Likoma"
     },
     {
      "African Countries and Their States\/Regions": "Lilongwe"
     },
     {
      "African Countries and Their States\/Regions": "Machinga"
     },
     {
      "African Countries and Their States\/Regions": "Mangochi"
     },
     {
      "African Countries and Their States\/Regions": "Mulanje"
     },
     {
      "African Countries and Their States\/Regions": "Mwanza"
     },
     {
      "African Countries and Their States\/Regions": "Nkhata Bay"
     },
     {
      "African Countries and Their States\/Regions": "Nkhotakota"
     },
     {
      "African Countries and Their States\/Regions": "Ntchisi"
     },
     {
      "African Countries and Their States\/Regions": "Phalombe"
     },
     {
      "African Countries and Their States\/Regions": "Rumphi"
     },
     {
      "African Countries and Their States\/Regions": "Salima"
     },
     {
      "African Countries and Their States\/Regions": "Thyolo"
     },
     {
      "African Countries and Their States\/Regions": "Zomba"
     },
     null,
     {
      "African Countries and Their States\/Regions": "32. Mali"
     },
     {
      "African Countries and Their States\/Regions": "Bamako"
     },
     {
      "African Countries and Their States\/Regions": "Gao"
     },
     {
      "African Countries and Their States\/Regions": "Kayes"
     },
     {
      "African Countries and Their States\/Regions": "Koulikoro"
     },
     {
      "African Countries and Their States\/Regions": "Mopti"
     },
     {
      "African Countries and Their States\/Regions": "Ségou"
     },
     {
      "African Countries and Their States\/Regions": "Sikasso"
     },
     {
      "African Countries and Their States\/Regions": "Tombouctou"
     },
     null,
     {
      "African Countries and Their States\/Regions": "33. Mauritania"
     },
     {
      "African Countries and Their States\/Regions": "Adrar"
     },
     {
      "African Countries and Their States\/Regions": "Assaba"
     },
     {
      "African Countries and Their States\/Regions": "Brakna"
     },
     {
      "African Countries and Their States\/Regions": "Daghdja"
     },
     {
      "African Countries and Their States\/Regions": "Gorgol"
     },
     {
      "African Countries and Their States\/Regions": "Guidimaka"
     },
     {
      "African Countries and Their States\/Regions": "Hodh El Gharbi"
     },
     {
      "African Countries and Their States\/Regions": "Hodh Ech Chargui"
     },
     {
      "African Countries and Their States\/Regions": "Inchiri"
     },
     {
      "African Countries and Their States\/Regions": "Nouakchott"
     },
     {
      "African Countries and Their States\/Regions": "Trarza"
     },
     null,
     {
      "African Countries and Their States\/Regions": "34. Mauritius"
     },
     {
      "African Countries and Their States\/Regions": "Agalega"
     },
     {
      "African Countries and Their States\/Regions": "Mauritius"
     },
     {
      "African Countries and Their States\/Regions": "Rodrigues"
     },
     null,
     {
      "African Countries and Their States\/Regions": "35. Morocco"
     },
     {
      "African Countries and Their States\/Regions": "Agadir-Ida Ou Tanane"
     },
     {
      "African Countries and Their States\/Regions": "Al Haouz"
     },
     {
      "African Countries and Their States\/Regions": "Al Jadida"
     },
     {
      "African Countries and Their States\/Regions": "Assafi"
     },
     {
      "African Countries and Their States\/Regions": "Beni Mellal"
     },
     {
      "African Countries and Their States\/Regions": "Casablanca-Settat"
     },
     {
      "African Countries and Their States\/Regions": "Chaouia-Ouardigha"
     },
     {
      "African Countries and Their States\/Regions": "Drâa-Tafilalet"
     },
     {
      "African Countries and Their States\/Regions": "Fes-Meknes"
     },
     {
      "African Countries and Their States\/Regions": "Guelmim-Oued Noun"
     },
     {
      "African Countries and Their States\/Regions": "Laâyoune-Sakia El Hamra"
     },
     {
      "African Countries and Their States\/Regions": "Marrakech-Safi"
     },
     {
      "African Countries and Their States\/Regions": "Oriental"
     },
     {
      "African Countries and Their States\/Regions": "Souss-Massa"
     },
     {
      "African Countries and Their States\/Regions": "Tangier-Tétouan-Al Hoceima"
     },
     null,
     {
      "African Countries and Their States\/Regions": "36. Mozambique"
     },
     {
      "African Countries and Their States\/Regions": "Cabo Delgado"
     },
     {
      "African Countries and Their States\/Regions": "Gaza"
     },
     {
      "African Countries and Their States\/Regions": "Inhambane"
     },
     {
      "African Countries and Their States\/Regions": "Manica"
     },
     {
      "African Countries and Their States\/Regions": "Maputo"
     },
     {
      "African Countries and Their States\/Regions": "Nampula"
     },
     {
      "African Countries and Their States\/Regions": "Niassa"
     },
     {
      "African Countries and Their States\/Regions": "Sofala"
     },
     {
      "African Countries and Their States\/Regions": "Tete"
     },
     {
      "African Countries and Their States\/Regions": "Zambezia"
     },
     null,
     {
      "African Countries and Their States\/Regions": "37. Namibia"
     },
     {
      "African Countries and Their States\/Regions": "Caprivi"
     },
     {
      "African Countries and Their States\/Regions": "Erongo"
     },
     {
      "African Countries and Their States\/Regions": "Hardap"
     },
     {
      "African Countries and Their States\/Regions": "Karas"
     },
     {
      "African Countries and Their States\/Regions": "Kavango East"
     },
     {
      "African Countries and Their States\/Regions": "Kavango West"
     },
     {
      "African Countries and Their States\/Regions": "Khomas"
     },
     {
      "African Countries and Their States\/Regions": "Kunene"
     },
     {
      "African Countries and Their States\/Regions": "Ohangwena"
     },
     {
      "African Countries and Their States\/Regions": "Omusati"
     },
     {
      "African Countries and Their States\/Regions": "Oshana"
     },
     {
      "African Countries and Their States\/Regions": "Oshikoto"
     },
     {
      "African Countries and Their States\/Regions": "Zambezi"
     },
     null,
     {
      "African Countries and Their States\/Regions": "38. Niger"
     },
     {
      "African Countries and Their States\/Regions": "Agadez"
     },
     {
      "African Countries and Their States\/Regions": "Diffa"
     },
     {
      "African Countries and Their States\/Regions": "Dosso"
     },
     {
      "African Countries and Their States\/Regions": "Maradi"
     },
     {
      "African Countries and Their States\/Regions": "Niamey"
     },
     {
      "African Countries and Their States\/Regions": "Tahoua"
     },
     {
      "African Countries and Their States\/Regions": "Tillabéri"
     },
     {
      "African Countries and Their States\/Regions": "Zinder"
     },
     null,
     {
      "African Countries and Their States\/Regions": "39. Nigeria"
     },
     {
      "African Countries and Their States\/Regions": "Abia"
     },
     {
      "African Countries and Their States\/Regions": "Adamawa"
     },
     {
      "African Countries and Their States\/Regions": "Akwa Ibom"
     },
     {
      "African Countries and Their States\/Regions": "Anambra"
     },
     {
      "African Countries and Their States\/Regions": "Bauchi"
     },
     {
      "African Countries and Their States\/Regions": "Bayelsa"
     },
     {
      "African Countries and Their States\/Regions": "Benue"
     },
     {
      "African Countries and Their States\/Regions": "Borno"
     },
     {
      "African Countries and Their States\/Regions": "Cross River"
     },
     {
      "African Countries and Their States\/Regions": "Delta"
     },
     {
      "African Countries and Their States\/Regions": "Ebonyi"
     },
     {
      "African Countries and Their States\/Regions": "Edo"
     },
     {
      "African Countries and Their States\/Regions": "Ekiti"
     },
     {
      "African Countries and Their States\/Regions": "Enugu"
     },
     {
      "African Countries and Their States\/Regions": "Gombe"
     },
     {
      "African Countries and Their States\/Regions": "Imo"
     },
     {
      "African Countries and Their States\/Regions": "Jigawa"
     },
     {
      "African Countries and Their States\/Regions": "Kaduna"
     },
     {
      "African Countries and Their States\/Regions": "Kano"
     },
     {
      "African Countries and Their States\/Regions": "Katsina"
     },
     {
      "African Countries and Their States\/Regions": "Kebbi"
     },
     {
      "African Countries and Their States\/Regions": "Kogi"
     },
     {
      "African Countries and Their States\/Regions": "Kwara"
     },
     {
      "African Countries and Their States\/Regions": "Lagos"
     },
     {
      "African Countries and Their States\/Regions": "Nasarawa"
     },
     {
      "African Countries and Their States\/Regions": "Niger"
     },
     {
      "African Countries and Their States\/Regions": "Ogun"
     },
     {
      "African Countries and Their States\/Regions": "Ondo"
     },
     {
      "African Countries and Their States\/Regions": "Osun"
     },
     {
      "African Countries and Their States\/Regions": "Oyo"
     },
     {
      "African Countries and Their States\/Regions": "Plateau"
     },
     {
      "African Countries and Their States\/Regions": "Rivers"
     },
     {
      "African Countries and Their States\/Regions": "Sokoto"
     },
     {
      "African Countries and Their States\/Regions": "Taraba"
     },
     {
      "African Countries and Their States\/Regions": "Yobe"
     },
     {
      "African Countries and Their States\/Regions": "Zamfara"
     },
     null,
     {
      "African Countries and Their States\/Regions": "40. Rwanda"
     },
     {
      "African Countries and Their States\/Regions": "Eastern"
     },
     {
      "African Countries and Their States\/Regions": "Northern"
     },
     {
      "African Countries and Their States\/Regions": "Western"
     },
     {
      "African Countries and Their States\/Regions": "Southern"
     },
     {
      "African Countries and Their States\/Regions": "Kigali"
     },
     null,
     {
      "African Countries and Their States\/Regions": "41. Sao Tome and Principe"
     },
     {
      "African Countries and Their States\/Regions": "Principe"
     },
     {
      "African Countries and Their States\/Regions": "Sao Tome"
     },
     null,
     {
      "African Countries and Their States\/Regions": "42. Senegal"
     },
     {
      "African Countries and Their States\/Regions": "Dakar"
     },
     {
      "African Countries and Their States\/Regions": "Diourbel"
     },
     {
      "African Countries and Their States\/Regions": "Fatick"
     },
     {
      "African Countries and Their States\/Regions": "Kaolack"
     },
     {
      "African Countries and Their States\/Regions": "Kedougou"
     },
     {
      "African Countries and Their States\/Regions": "Kolda"
     },
     {
      "African Countries and Their States\/Regions": "Louga"
     },
     {
      "African Countries and Their States\/Regions": "Matam"
     },
     {
      "African Countries and Their States\/Regions": "Saint-Louis"
     },
     {
      "African Countries and Their States\/Regions": "Tambacounda"
     },
     {
      "African Countries and Their States\/Regions": "Thiès"
     },
     {
      "African Countries and Their States\/Regions": "Ziguinchor"
     },
     null,
     {
      "African Countries and Their States\/Regions": "43. Seychelles"
     },
     {
      "African Countries and Their States\/Regions": "Anse aux Pins"
     },
     {
      "African Countries and Their States\/Regions": "Anse Boileau"
     },
     {
      "African Countries and Their States\/Regions": "Baie Lazare"
     },
     {
      "African Countries and Their States\/Regions": "Beau Vallon"
     },
     {
      "African Countries and Their States\/Regions": "Bel Ombre"
     },
     {
      "African Countries and Their States\/Regions": "Glacis"
     },
     {
      "African Countries and Their States\/Regions": "Grand Anse"
     },
     {
      "African Countries and Their States\/Regions": "La Digue"
     },
     {
      "African Countries and Their States\/Regions": "Pointe La Rue"
     },
     {
      "African Countries and Their States\/Regions": "Port Glaud"
     },
     {
      "African Countries and Their States\/Regions": "Praslin"
     },
     null,
     {
      "African Countries and Their States\/Regions": "44. Sierra Leone"
     },
     {
      "African Countries and Their States\/Regions": "Bombali"
     },
     {
      "African Countries and Their States\/Regions": "Bo"
     },
     {
      "African Countries and Their States\/Regions": "Bonthe"
     },
     {
      "African Countries and Their States\/Regions": "Kailahun"
     },
     {
      "African Countries and Their States\/Regions": "Kenema"
     },
     {
      "African Countries and Their States\/Regions": "Koinadugu"
     },
     {
      "African Countries and Their States\/Regions": "Kambia"
     },
     {
      "African Countries and Their States\/Regions": "Port Loko"
     },
     {
      "African Countries and Their States\/Regions": "Moyamba"
     },
     {
      "African Countries and Their States\/Regions": "Western Area"
     },
     null,
     {
      "African Countries and Their States\/Regions": "45. Somalia"
     },
     {
      "African Countries and Their States\/Regions": "Awdal"
     },
     {
      "African Countries and Their States\/Regions": "Bakool"
     },
     {
      "African Countries and Their States\/Regions": "Banadir"
     },
     {
      "African Countries and Their States\/Regions": "Bari"
     },
     {
      "African Countries and Their States\/Regions": "Bay"
     },
     {
      "African Countries and Their States\/Regions": "Galguduud"
     },
     {
      "African Countries and Their States\/Regions": "Gedo"
     },
     {
      "African Countries and Their States\/Regions": "Hiiraan"
     },
     {
      "African Countries and Their States\/Regions": "Middle Juba"
     },
     {
      "African Countries and Their States\/Regions": "Lower Juba"
     },
     {
      "African Countries and Their States\/Regions": "Lower Shabelle"
     },
     {
      "African Countries and Their States\/Regions": "Togdheer"
     },
     {
      "African Countries and Their States\/Regions": "Sanaag"
     },
     {
      "African Countries and Their States\/Regions": "Sool"
     },
     null,
     {
      "African Countries and Their States\/Regions": "46. South Africa"
     },
     {
      "African Countries and Their States\/Regions": "Eastern Cape"
     },
     {
      "African Countries and Their States\/Regions": "Free State"
     },
     {
      "African Countries and Their States\/Regions": "Gauteng"
     },
     {
      "African Countries and Their States\/Regions": "KwaZulu-Natal"
     },
     {
      "African Countries and Their States\/Regions": "Limpopo"
     },
     {
      "African Countries and Their States\/Regions": "Mpumalanga"
     },
     {
      "African Countries and Their States\/Regions": "North West"
     },
     {
      "African Countries and Their States\/Regions": "Northern Cape"
     },
     {
      "African Countries and Their States\/Regions": "Western Cape"
     },
     null,
     {
      "African Countries and Their States\/Regions": "47. South Sudan"
     },
     {
      "African Countries and Their States\/Regions": "Central Equatoria"
     },
     {
      "African Countries and Their States\/Regions": "Eastern Equatoria"
     },
     {
      "African Countries and Their States\/Regions": "Jonglei"
     },
     {
      "African Countries and Their States\/Regions": "Lakes"
     },
     {
      "African Countries and Their States\/Regions": "Northern Bahr el Ghazal"
     },
     {
      "African Countries and Their States\/Regions": "Unity"
     },
     {
      "African Countries and Their States\/Regions": "Upper Nile"
     },
     {
      "African Countries and Their States\/Regions": "Warrap"
     },
     {
      "African Countries and Their States\/Regions": "Western Bahr el Ghazal"
     },
     {
      "African Countries and Their States\/Regions": "Western Equatoria"
     },
     null,
     {
      "African Countries and Their States\/Regions": "48. Sudan"
     },
     {
      "African Countries and Their States\/Regions": "Al Baḥr Al Aḥmar"
     },
     {
      "African Countries and Their States\/Regions": "Al Jazirah"
     },
     {
      "African Countries and Their States\/Regions": "Al Qadarif"
     },
     {
      "African Countries and Their States\/Regions": "Al Khartoum"
     },
     {
      "African Countries and Their States\/Regions": "Al Qadarif"
     },
     {
      "African Countries and Their States\/Regions": "Blue Nile"
     },
     {
      "African Countries and Their States\/Regions": "Darfur North"
     },
     {
      "African Countries and Their States\/Regions": "Darfur South"
     },
     {
      "African Countries and Their States\/Regions": "Darfur West"
     },
     {
      "African Countries and Their States\/Regions": "Kassala"
     },
     {
      "African Countries and Their States\/Regions": "Northern"
     },
     {
      "African Countries and Their States\/Regions": "River Nile"
     },
     {
      "African Countries and Their States\/Regions": "Sennar"
     },
     {
      "African Countries and Their States\/Regions": "White Nile"
     },
     {
      "African Countries and Their States\/Regions": "South Kordofan"
     },
     {
      "African Countries and Their States\/Regions": "West Kordofan"
     },
     null,
     {
      "African Countries and Their States\/Regions": "49. Tanzania"
     },
     {
      "African Countries and Their States\/Regions": "Arusha"
     },
     {
      "African Countries and Their States\/Regions": "Dodoma"
     },
     {
      "African Countries and Their States\/Regions": "Iringa"
     },
     {
      "African Countries and Their States\/Regions": "Kagera"
     },
     {
      "African Countries and Their States\/Regions": "Katavi"
     },
     {
      "African Countries and Their States\/Regions": "Kigoma"
     },
     {
      "African Countries and Their States\/Regions": "Kilimanjaro"
     },
     {
      "African Countries and Their States\/Regions": "Lindi"
     },
     {
      "African Countries and Their States\/Regions": "Mara"
     },
     {
      "African Countries and Their States\/Regions": "Mbeya"
     },
     {
      "African Countries and Their States\/Regions": "Morogoro"
     },
     {
      "African Countries and Their States\/Regions": "Mtwara"
     },
     {
      "African Countries and Their States\/Regions": "Mwanza"
     },
     {
      "African Countries and Their States\/Regions": "Pwani"
     },
     {
      "African Countries and Their States\/Regions": "Rukwa"
     },
     {
      "African Countries and Their States\/Regions": "Ruvuma"
     },
     {
      "African Countries and Their States\/Regions": "Shinyanga"
     },
     {
      "African Countries and Their States\/Regions": "Singida"
     },
     {
      "African Countries and Their States\/Regions": "Tabora"
     },
     {
      "African Countries and Their States\/Regions": "Tanga"
     },
     {
      "African Countries and Their States\/Regions": "Zanzibar"
     },
     null,
     {
      "African Countries and Their States\/Regions": "50. Togo"
     },
     {
      "African Countries and Their States\/Regions": "Centrale"
     },
     {
      "African Countries and Their States\/Regions": "Kara"
     },
     {
      "African Countries and Their States\/Regions": "Maritime"
     },
     {
      "African Countries and Their States\/Regions": "Plateaux"
     },
     {
      "African Countries and Their States\/Regions": "Savannes"
     },
     null,
     {
      "African Countries and Their States\/Regions": "51. Tunisia"
     },
     {
      "African Countries and Their States\/Regions": "Ariana"
     },
     {
      "African Countries and Their States\/Regions": "Béja"
     },
     {
      "African Countries and Their States\/Regions": "Ben Arous"
     },
     {
      "African Countries and Their States\/Regions": "Bizerte"
     },
     {
      "African Countries and Their States\/Regions": "Gabès"
     },
     {
      "African Countries and Their States\/Regions": "Gafsa"
     },
     {
      "African Countries and Their States\/Regions": "Jendouba"
     },
     {
      "African Countries and Their States\/Regions": "Kairouan"
     },
     {
      "African Countries and Their States\/Regions": "Kebili"
     },
     {
      "African Countries and Their States\/Regions": "Kef"
     },
     {
      "African Countries and Their States\/Regions": "Mahdia"
     },
     {
      "African Countries and Their States\/Regions": "Manouba"
     },
     {
      "African Countries and Their States\/Regions": "Medenine"
     },
     {
      "African Countries and Their States\/Regions": "Monastir"
     },
     {
      "African Countries and Their States\/Regions": "Nabeul"
     },
     {
      "African Countries and Their States\/Regions": "Sidi Bouzid"
     },
     {
      "African Countries and Their States\/Regions": "Siliana"
     },
     {
      "African Countries and Their States\/Regions": "Tataouine"
     },
     {
      "African Countries and Their States\/Regions": "Tozeur"
     },
     {
      "African Countries and Their States\/Regions": "Zaghouan"
     },
     null,
     {
      "African Countries and Their States\/Regions": "52. Uganda"
     },
     {
      "African Countries and Their States\/Regions": "Central"
     },
     {
      "African Countries and Their States\/Regions": "Eastern"
     },
     {
      "African Countries and Their States\/Regions": "Northern"
     },
     {
      "African Countries and Their States\/Regions": "Western"
     },
     null,
     {
      "African Countries and Their States\/Regions": "53. Zambia"
     },
     {
      "African Countries and Their States\/Regions": "Central"
     },
     {
      "African Countries and Their States\/Regions": "Copperbelt"
     },
     {
      "African Countries and Their States\/Regions": "Eastern"
     },
     {
      "African Countries and Their States\/Regions": "Luapula"
     },
     {
      "African Countries and Their States\/Regions": "Lusaka"
     },
     {
      "African Countries and Their States\/Regions": "Northern"
     },
     {
      "African Countries and Their States\/Regions": "Northwestern"
     },
     {
      "African Countries and Their States\/Regions": "Southern"
     },
     {
      "African Countries and Their States\/Regions": "Western"
     },
     null,
     {
      "African Countries and Their States\/Regions": "54. Zimbabwe"
     },
     {
      "African Countries and Their States\/Regions": "Bulawayo"
     },
     {
      "African Countries and Their States\/Regions": "Harare"
     },
     {
      "African Countries and Their States\/Regions": "Manicaland"
     },
     {
      "African Countries and Their States\/Regions": "Mashonaland East"
     },
     {
      "African Countries and Their States\/Regions": "Mashonaland West"
     },
     {
      "African Countries and Their States\/Regions": "Masvingo"
     },
     {
      "African Countries and Their States\/Regions": "Midlands"
     },
     {
      "African Countries and Their States\/Regions": "Matabeleland North"
     },
     {
      "African Countries and Their States\/Regions": "Matabeleland South"
     }
    ]
    