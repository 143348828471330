import React, { useEffect, useState } from "react";
import { Modal } from "../../../utilities/Modal.jsx";
import Pre_login from "../../Auth_Screens/Pre_login/index.jsx";
import Nav_Comp from "../Nav_Comp/index.jsx";
import Footer from "../Nav_Comp/Footer.jsx";
import Flex_box from "../../../utilities/flex_box.jsx";
import Accordion from "../../../utilities/Accordian.js/index.jsx";
import Points_Comp from "../Components/Points_Comp.jsx";
import Button from "../../../utilities/Button.jsx";
import Contact_Form from "../Landing_Screen/Contact_Form.jsx";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { get_landing_page_data_api, image_url } from "../../../apis/apis.js";

const Support = () => {
  const navigate = useNavigate();
  const [isOpen, setisOpen] = useState(false);
  const max_w_1000 = useMediaQuery("(max-width:1000px)");

  const open_modal = () => {
    setisOpen(true);
  };

  const close_modal = () => {
    setisOpen(false);
  };

  const researcher_acc_arr = [
    {
      title: "How do I create a survey?",
      content:
        "Yaarnbox offers an intuitive interface for building engaging surveys. Choose from a variety of question formats, including multiple-choice, open-ended, ranking scales, and logic branching to create a dynamic survey experience that gathers the data you need. You also get to specify the demographic details of the respondents that you need. The system automatically filters out unqualified respondents from taking your surveys.",
      open: false,
    },
    {
      title: "How do I target the right audience for my studies?",
      content: `Go beyond demographics! Yaarnbox's advanced targeting options allow you to reach highly qualified respondents who perfectly match your research criteria. Utilize detailed demographic filters, location targeting, and Socio-Economic Classification targeting to ensure you connect with the ideal audience for your study.`,
      open: false,
    },
    {
      title: "What quota management options are available?",
      content: `Never settle for generic data. Set specific quotas for different demographics within your surveys. This ensures you receive the desired number of responses from each target group, guaranteeing a well-rounded and representative data set for your analysis.`,
      open: false,
    },
    {
      title: "How do I conduct interactive group discussions?",
      content: `Spark meaningful conversations with real-time text chat, audio conferencing, and video capabilities. Yaarnbox facilitates dynamic group discussions, allowing you to delve deeper into participant insights and gather richer qualitative data. Keep in mind that group discussions are limited to a maximum of 2 hours per session to maintain focus and engagement.`,
      open: false,
    },
    {
      title: "How do I analyze my data and gain actionable insights?",
      content: `Our user-friendly platform empowers you to transform raw data into actionable insights. Leverage intuitive dashboards that provide real-time visualizations of key metrics and trends. Generate comprehensive reports with customizable charts and graphs to effectively communicate your research findings. Utilize advanced filtering and segmentation tools to drill down into specific demographics or response options, uncovering hidden patterns and gaining a deeper understanding of your data.`,
      open: false,
    },
    {
      title: "What are Smart-Coins, and how do they incentivize participation?",
      content: `Smart-Coins are our platform's virtual currency used to reward respondents for their valuable time and contributions. Allocate Smart Coins as incentives for completing surveys or participating in group discussions. This incentivizes participation, leading to a wider pool of qualified respondents for your research.`,
      open: false,
    },
  ];

  const respondent_acc_arr = [
    {
      title: "How do I sign up and participate in studies?",
      content: `Creating a free account is quick and easy! Simply provide some basic information about yourself and get started. Once registered, you'll gain access to a variety of exciting research studies based on your interests and demographics.`,
      open: false,
    },
    {
      title: "How do I earn Smart-Coins and what are the benefits?",
      content: `Smart-Coins are your reward for contributing to valuable research! Earn them by completing surveys and participating in group discussions. The number of Smart-Coins awarded varies depending on the complexity of the study, allowing you to be fairly compensated for your time and effort.`,
      open: false,
    },
    {
      title: "How can I redeem Smart-Coins?",
      content: `We have retail partner outlets where you can convert your Smart-coins to shopping vouchers, which you can use to pay for products and services a your preferred time. Terms and Conditions of the respective outlet will apply.`,
      open: false,
    },
    {
      title: "Is my data secure on Yaarnbox?",
      content: `Data security is our top priority. We employ industry-standard encryption protocols to safeguard all information on the platform. This ensures your details and survey responses remain completely confidential.`,
      open: false,
    },
  ];

  const [scrollTop, setscrollTop] = useState(0);

  const [content_arr, setcontent_arr] = useState([]);
  const get_content = () => {
    get_landing_page_data_api({ page: 5 })
      .then((res) => {
        setcontent_arr(res.data.content_res || []);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  useEffect(get_content, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        overflowY: "auto",
        // paddingTop:"10px"
      }}
      onScroll={() => {
        setscrollTop(document.getElementById("landing_id")?.scrollTop);
      }}
      id="landing_id"
    >
      <Nav_Comp top_value={scrollTop > 10} open_modal={open_modal} />
      {/* <Nav_Comp/> */}
      <section class="innerbanner-sec">
        <div class="container">
          <div class="page-title">
            <h1 class="page-title-inner">Contact Us</h1>
            {/* <nav aria-label="breadcrumb" class="breadcrumb-row ">
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Contact Us
                </li>
              </ul>
            </nav> */}
          </div>
        </div>
        <img
          src="assets/images/shape1.png"
          class="shape shape-1"
          alt="banner"
        />
        <img
          src="assets/images/shape2.png"
          class="shape shape-2"
          alt="banner"
        />
      </section>

      <section class="research-sec2">
        <div class="container">
          <div class="banner">
            <div class="row align-items-center">
              <div class="col-md-8">
                {/* <h4 class="mb-3">Still have questions?</h4>
                <p>
                  Explore our comprehensive Documentation section for detailed
                  guides, tutorials, and FAQs covering a wide range of topics
                  related to using Yaarnbox as a researcher or respondent.
                </p> */}
<div
dangerouslySetInnerHTML={{
  __html:content_arr[0]?.content
}}
/>
                <div class="mt-5">
                  <Link to="/documentation" class="default-btn">
                    <span>Documentation</span>
                  </Link>
                </div>
              </div>
              <div class="col-md-4">
                {/* <img src="assets/images/about.png" class="amimy" width="100%" /> */}
                <img src={image_url+content_arr[0]?.image_file} class="amimy" width="100%" />
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/images/shape1.png"
          class="shape shape-3"
          alt="banner"
        />
        <img
          src="assets/images/shape-4.png"
          class="shape shape-4"
          alt="banner"
        />
        <img
          src="assets/images/shape3.svg"
          class="shape shape-5"
          alt="banner"
        />
      </section>

      {/* <section class="contact-sec">
        <div class="container">
          <div class="banner">
            <div class="row justify-content-center align-items-center mb-5">
              <div class="col-lg-8  mb-3">
                <form class="contactForm ">
                  <div class="section-title">
                    <h2>Get In Touch</h2>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 mb-3">
                      <div class="form-group">
                        <input
                          type="text"
                          name="txt_name"
                          id="txt_name"
                          class="form-control"
                          placeholder="Name*"
                          required=""
                          pattern="[a-zA-Z]{''}*"
                          maxlength="20"
                          minlength="3"
                        />
                      </div>
                    </div>

                    <div class="col-lg-6 mb-3">
                      <div class="form-group">
                        <input
                          type="email"
                          name="txt_email"
                          id="txt_email"
                          class="form-control"
                          placeholder="Email"
                          required=""
                        />
                      </div>
                    </div>

                    <div class="col-lg-6 mb-3">
                      <div class="form-group">
                        <input
                          type="text"
                          name="txt_contact_number"
                          id="txt_contact_number"
                          placeholder="Phone"
                          required=""
                          maxlength="10"
                          minlength="10"
                          pattern="[0-9]{10}"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div class="col-lg-6 mb-3">
                      <div class="form-group has-error">
                        <input
                          type="text"
                          name="txt_subject"
                          id="txt_subject"
                          class="form-control"
                          placeholder="Subject"
                          required=""
                          pattern="[a-zA-Z]*"
                        />
                      </div>
                    </div>

                    <div class="col-lg-12 mb-3">
                      <div class="form-group">
                        <textarea
                          name="txt_message"
                          class="form-control"
                          id="txt_message"
                          cols="30"
                          rows="8"
                          placeholder="Write message"
                          required=""
                          pattern="[a-zA-Z]*"
                        ></textarea>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <a type="submit" class="default-btn">
                        Send Message
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Contact_Form />
      <Footer />
      <Modal isOpen={isOpen} onClose={close_modal}>
        <div
          style={{
            position: "relative",
          }}
        >
          <span
            onClick={close_modal}
            style={{
              fontSize: "26px",
              position: "absolute",
              top: "0px",
              right: "10px",
              cursor: "pointer",
            }}
          >
            &times;
          </span>
          <Pre_login />
        </div>
      </Modal>
    </div>
  );
};

export default Support;
