import React from "react";
import Flex_box from "../../../utilities/flex_box";
import { theme_bg, theme_color } from "../../../utilities/colors";
import { useNavigate } from "react-router-dom";

const Subscription_Box = ({
  label,
  subtext,
  content_arr,
  path,
  btn_text,
  data,
  sub_data
}) => {
  const navigate = useNavigate();

  return (
    <Flex_box
      className="responsive_width"
      style={{
        width: "",
        flexDirection: "column",
        lineHeight: 0.9,
        boxShadow: "0px 10px 20px #0000000D",
        padding: "20px 10px",
        borderRadius: "10px",
      }}
    >
      <span style={{ fontSize: "24px", fontWeight: 600 }}>{label}</span>
      <br />
      <span style={{ fontSize: "18px" }}>{subtext}</span>
      <Flex_box
        style={{
          flexDirection: "column",
          backgroundColor: theme_color,
          borderRadius: "10px",
          color: "#fff",
          gap: 15,
          padding: "25px 10px",
          boxSizing: "border-box",
          marginTop: "20px",
        }}
      >
        {content_arr?.length > 0 &&
          content_arr.map((el, index) => {
            return <span key={index}>{el}</span>;
          })}
        <button
          id="btn_comp"
          style={{
            fontWeight: 600,
            backgroundColor: "#fff",
            color: "#000",
            marginTop: "20px",
          }}
          onClick={() => {
            navigate(path, {
              state: data || "",
            });
          }}
        >
          {btn_text}
        </button>
      </Flex_box>
    </Flex_box>
  );
};

export default Subscription_Box;
