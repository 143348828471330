import React, { useEffect, useState } from "react";
import Page_heading from "../../../../utilities/Page_heading";
import user_icon from "../../../../assets/user_dummy.png";
import Pagination from "../../../../utilities/Pagination";
import {
  get_survey_respondents_api,
  image_url,
  StudyDetails_api,
} from "../../../../apis/apis";
import { useLocation } from "react-router-dom";
import DataTable from "../../../../utilities/DataTable";

const Respondent_Table = ({ study_id, survey_data }) => {
  const { state } = useLocation();
  const { type } = state;
  const [resp_list, setresp_list] = useState([]);

  const pageSize = 10;
  const [currentPage, setcurrentPage] = useState(1);
  const [totalRows, settotalRows] = useState(0);
  console.warn(survey_data?.surveydata);

  const get_survey_respondents = () => {
    const survey_id = survey_data?.surveydata?._id;
    if (survey_id) {
      get_survey_respondents_api({
        survey_id,
        offset: (currentPage - 1) * pageSize,
        limit: pageSize,
      })
        .then((res) => {
          const res_data = res.data?.Res[0]?.paginatedResults;
          settotalRows(res.data?.Res[0]?.totalCount[0]?.count);

          // const new_data = res_data?.map((el) => {
          //   const lookup_res = el?.users_lookup[0] || {};
          //   return [
          //     <img
          //       src={image_url + lookup_res?.profilepictuer}
          //       style={{
          //         height: "35px",
          //       }}
          //     />,
          //     ,
          //     lookup_res?.email,
          //     el?.iscompleted ? "Completed" : "Incomplete",
          //   ];
          // });
          setresp_list(res_data);
        })
        .catch((err) => {
          console.warn(err);
        });
    }
  };

  useEffect(get_survey_respondents, [currentPage, survey_data]);

  const onNext = (page) => {
    setcurrentPage(page);
  };

  const onPrev = (page) => {
    setcurrentPage(page);
  };

  const t_heads = [
    {
      label: "Profile Picture",
      accessor: "-",
      element: (el) => {
        const lookup_res = el?.users_lookup[0] || {};
        return (
          <img
            src={image_url + lookup_res?.profilepictuer}
            style={{
              height: "35px",
            }}
          />
        );
      },
    },
    {
      label: "Name",
      accessor: "-",
      element: (el) => {
        const lookup_res = el?.users_lookup[0] || {};
        return (
          <span>
            {lookup_res?.firstname + " " + lookup_res?.lastname}
          </span>
        );
      },
    },
    {
      label: "Email",
      accessor: "-",
      element: (el) => {
        const lookup_res = el?.users_lookup[0] || {};
        return (
          <span>
            {lookup_res?.email}
          </span>
        );
      },
    },
    {
      label: "Survey Status",
      accessor: "-",
      element: (el) => {
        return (
          <span>
            {el?.iscompleted ? "Completed" : "Incomplete"}
          </span>
        );
      },
    },
  ];

  return (
    <div
      style={{
        padding: "20px 0px",
        paddingBottom: "40px",
      }}
    >
      <Page_heading label={type == "gd" ? "Members" : "Respondents"} />
      <div
        style={{
          width: "100%",
          marginTop: "20px",
          boxShadow: "0px 10px 15px #2424240D",
          borderRadius: "10px",
        }}
      >
        {resp_list?.length > 0 && (
          <>
            <DataTable
              style={{}}
              heads={t_heads}
              rows={resp_list}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Respondent_Table;
