import React, { useState } from "react";
import { Modal } from "../../../../../utilities/Modal";
import Select_box from "../../../../../utilities/Select_box";
import { add_top_up_api } from "../../../../../apis/apis";
import { useDispatch, useSelector } from "react-redux";
import { get_subscription_data_count_and_kyc } from "../../../../../utilities/utilities";
import { alert_action } from "../../../../../redux_prog/actions/base_action";
import Button from "../../../../../utilities/Button";
import Flex_box from "../../../../../utilities/flex_box";

const TopUp_Modal = ({ modalVisible, setmodalVisible }) => {
  const { user_data } = useSelector((state) => state?.authReducer);
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const topup_options = [
    {
      label: "50 Coins",
      value: 50,
    },
    {
      label: "200 Coins",
      value: 200,
    },
    {
      label: "500 Coins",
      value: 500,
    },
    {
      label: "1000 Coins",
      value: 1000,
    },
  ];

  const [selected_coin_value, setselected_coin_value] = useState("");

  const add_top_up = () => {
    setloading(true);
    add_top_up_api({
      topup_value: selected_coin_value,
      sub_id: user_data?.sub_id,
    })
      .then(async (res) => {
        await get_subscription_data_count_and_kyc(dispatch, user_data);
        dispatch(
          alert_action({
            type: "success",
            title: "Success",
            msg: "TopUp Successfull!",
          })
        );
        setloading(false);
        setmodalVisible(false);
      })
      .catch((err) => {
        console.warn(err);
        setloading(false);
        setmodalVisible(false);
      });
  };

  return (
    <>
      <Modal
        isOpen={modalVisible}
        onClose={() => {
          setmodalVisible(false);
        }}
        label={"TopUp Coins"}
        content_style={{
          padding: "0px",
        }}
        label_style={{
          width: "100%",
        }}
      >
        <Flex_box
          style={{
            padding: "10px",
            gap: 10,
            flexWrap: "wrap",
          }}
        >
          <Select_box
            label={"Select top up value"}
            options={topup_options}
            cont_style={{
              width: "100%",
            }}
            onChange={(e) => {
              setselected_coin_value(e.target.value);
            }}
          />
          <Button
            style={{
              width: "100%",
            }}
            label={"Add"}
            onClick={add_top_up}
            loading={loading}
          />
        </Flex_box>
      </Modal>
    </>
  );
};

export default TopUp_Modal;
