import React, { useState } from "react"
import { Modal } from "../../../utilities/Modal"
import Select_box from "../../../utilities/Select_box"
import { add_top_up_api } from "../../../apis/apis"
import { useDispatch, useSelector } from "react-redux"
import { get_subscription_data_count_and_kyc } from "../../../utilities/utilities"
import { alert_action } from "../../../redux_prog/actions/base_action"
import Button from "../../../utilities/Button"
import Flex_box from "../../../utilities/flex_box"

const TopUp_Modal = ({ modalVisible, setmodalVisible, selected_quota }) => {
  const { user_data } = useSelector((state) => state?.authReducer)
  const dispatch = useDispatch()
  const [loading, setloading] = useState(false)

  const coin_flag = selected_quota == "Coins"
  const respondent_flag = selected_quota == "Respondents"
  const study_flag = selected_quota == "Study"
  const v_call_hrs_flag = selected_quota == "Video Call Hrs"

  const topup_options = coin_flag
    ? [
        {
          label: "50 Coins",
          value: 50,
        },
        {
          label: "200 Coins",
          value: 200,
        },
        {
          label: "500 Coins",
          value: 500,
        },
        {
          label: "1000 Coins",
          value: 1000,
        },
      ]
    : study_flag
      ? [
          {
            label: "50 Studies",
            value: 50,
          },
          {
            label: "200 Studies",
            value: 200,
          },
          {
            label: "500 Studies",
            value: 500,
          },
          {
            label: "1000 Studies",
            value: 1000,
          },
        ]
      : respondent_flag
        ? [
            {
              label: "50 Respondents",
              value: 50,
            },
            {
              label: "200 Respondents",
              value: 200,
            },
            {
              label: "500 Respondents",
              value: 500,
            },
            {
              label: "1000 Respondents",
              value: 1000,
            },
          ]
        : [
            {
              label: "50 Video Hours",
              value: 50,
            },
            {
              label: "200 Video Hours",
              value: 200,
            },
            {
              label: "500 Video Hours",
              value: 500,
            },
            {
              label: "1000 Video Hours",
              value: 1000,
            },
          ]

  const [selected_coin_value, setselected_coin_value] = useState("")

  const add_top_up = () => {
    setloading(true)
    add_top_up_api({
      topup_value: selected_coin_value,
      sub_id: user_data?.sub_id,
      selected_quota,
    })
      .then(async (res) => {
        await get_subscription_data_count_and_kyc(dispatch, user_data)
        dispatch(
          alert_action({
            type: "success",
            title: "Success",
            msg: "TopUp Successfull!",
          }),
        )
        setloading(false)
        setmodalVisible(false)
      })
      .catch((err) => {
        console.warn(err)
        setloading(false)
        setmodalVisible(false)
      })
  }

  return (
    <>
      <Modal
        isOpen={modalVisible}
        onClose={() => {
          setmodalVisible(false)
        }}
        label={"TopUp " + selected_quota}
        content_style={{
          padding: "0px",
        }}
        label_style={{
          width: "100%",
        }}
      >
        <Flex_box
          style={{
            padding: "10px",
            gap: 10,
            flexWrap: "wrap",
          }}
        >
          <Select_box
            label={"Select top up value"}
            options={topup_options}
            cont_style={{
              width: "100%",
            }}
            onChange={(e) => {
              setselected_coin_value(e.target.value)
            }}
          />
          <Button
            style={{
              width: "100%",
            }}
            label={"Add"}
            onClick={add_top_up}
            loading={loading}
          />
        </Flex_box>
      </Modal>
    </>
  )
}

export default TopUp_Modal
