import React, { useEffect, useState } from 'react';
import './Accordian.css'; // Import CSS file for styling
import plus_icon from "../../assets/Group 38146.svg";
import arrow_icon from "../../assets/arrow-right-bold.svg";

function AccordionItem({ title, content, setacc_arr, acc_arr, index, open }) {
    // const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setacc_arr(acc_arr.map((el, id) => {
            if (id == index) {
                return {
                    ...el,
                    open: el?.open == true ? false : true
                }
            }
            else {
                return {
                    ...el,
                    open: false
                }
            }
        }));
    };

    return (
        <div className="accordion-item">
            <div className="accordion-header"
            style={{
                padding:"12px 10px",
                // alignItems:"center",
                // display:"flex",
                // justifyContent:"space-between"
            }}
             onClick={toggleAccordion}>
                <div>{title}</div>
                {
                    open &&
                    <img src={arrow_icon} style={{
                        height: "12px"
                    }}
                    />
                }
                {
                    !open &&
                    <img src={plus_icon} style={{
                        height: "20px"
                    }}
                    />
                }
            </div>
            {open && <div className="accordion-content">{content}</div>}
        </div>
    );
}

function Accordion({ items }) {

    const [acc_arr, setacc_arr] = useState(items);
// console.warn({items});

useEffect(() => {
  setacc_arr(items);
}, [items])


    return (
        <div className="accordion">
            {acc_arr?.map((item, index) => (
                <AccordionItem key={index} open={item.open} index={index} setacc_arr={setacc_arr} acc_arr={acc_arr} title={item.title} content={item.content} />
            ))}
        </div>
    );
}

export default Accordion;
