import React from "react";
import Flex_box from "../../../../../utilities/flex_box";
import Sidebar from "../../../../Nav_Comp/Sidebar";
import Navbar from "../../../../Nav_Comp/Navbar";
import Preview from "./Preview";
import Footer from "../../../../Nav_Comp/Footer";
import Layout from "../../../../../utilities/Layout";

const Preview_Survey = () => {
  return (
    <>
      {/* <Flex_box
            style={{
                justifyContent: "start"
            }}
        >
            <Sidebar />
            <div
                className='comp_container'
                style={{
                    position: 'relative'
                }}
            >
                <Navbar />
                <Preview />
                <Footer />
            </div>
        </Flex_box> */}
      <Layout>
        <Preview />
      </Layout>
    </>
  );
};

export default Preview_Survey;
