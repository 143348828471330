import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Flex_box from "../../../utilities/flex_box";
import { theme_color } from "../../../utilities/colors";
import Study_Box from "./Study_Box";
import {
  alert_action,
  loading_action,
} from "../../../redux_prog/actions/base_action";
import { useDispatch, useSelector } from "react-redux";
import { StudyList_api } from "../../../apis/apis";
import Study_Pagination from "../../../utilities/Study_Pagination";

const Dash_comp = () => {
  const dispatch = useDispatch();
  const [selected_tab, setselected_tab] = useState(1);

  const [study_list, setstudy_list] = useState([]);
  const { user_data } = useSelector((state) => state?.authReducer);
  const [pageIndex, setpageIndex] = useState(0);

  const get_study_list = () => {
    dispatch(loading_action(true));
    StudyList_api({
      UserId: user_data?._id,
      sec: user_data?.sec,
      pageIndex,
      limit: 10,
    })
      .then((res) => {
        dispatch(loading_action(false));
        if (res.data.status == 1) {
          setstudy_list(res.data.Res);
        }
        console.log(res);
      })
      .catch((err) => {
        dispatch(loading_action(false));
        dispatch(
          alert_action({
            type: "error",
            title: "Error",
            msg: "Some error occurred!",
          })
        );
      });
  };

  useEffect(get_study_list, [pageIndex]);

  return (
    <>
      <Study_Pagination
        arr={study_list}
        pageIndex={pageIndex}
        setpageIndex={setpageIndex}
      />
      <Flex_box
        style={{
          flexWrap: "wrap",
          justifyContent: "start",
          alignItems: "",
          gap: 20,
          boxSizing: "border-box",
          maxHeight: "74vh",
          overflowY: "auto",
          marginTop: "20px",
        }}
      >
        {study_list?.length > 0 &&
          study_list?.map((el, index) => {
            return <Study_Box key={index} item={el} progress={"30%"} />;
          })}
      </Flex_box>
    </>
  );
};

export default Dash_comp;
