import React, { useState } from "react";
import user_logo from "../../../assets/default_user.png";
import Flex_box from "../../../utilities/flex_box";
import { nav_bg_color, theme_bg, theme_color } from "../../../utilities/colors";
import bell_icon from "../../../assets/Component 37 – 3.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../utilities/Button";
import { BASE_URL, get_resp_noti_api, image_url, page_url } from "../../../apis/apis";
import Notifications from "./Notifications";
import {
  alert_action,
  loading_action,
  sidebar_action,
} from "../../../redux_prog/actions/base_action";
import { Modal } from "../../../utilities/Modal";
import ham_menu from "../../../assets/ham_menu.png";
import { useMediaQuery } from "@mui/material";
import {
  get_subscription_data_count_and_kyc,
  sliced_string,
} from "../../../utilities/utilities";
import { persistor } from "../../../redux_prog/store/store";
import { login_action, set_user_action } from "../../../redux_prog/actions/auth_action";

const Navbar = ({ no_nav_func_flag }) => {
  const navigate = useNavigate();
  const state = useLocation();
  const dispatch = useDispatch();
  const login_type = useSelector((state) => state?.authReducer?.login_type);
  const user_data = useSelector((state) => state?.authReducer?.user_data) || {};
  const is_researcher = login_type == 2;
  const { username, email, profilepictuer } = user_data;
  const [modal_open, setmodal_open] = useState(false);
  const [noti_arr, setnoti_arr] = useState([]);

  const max_w_700 = useMediaQuery("(max-width:700px)");

  const get_resp_noti = () => {
    // setmodal_open(true);
    dispatch(loading_action(true));
    get_resp_noti_api({
      UserId: user_data?._id,
    })
      .then((res) => {
        dispatch(loading_action(false));
        console.log({ res });
        if (res.data.status == 1) {
          if (res.data.Res.length > 0) {
            setmodal_open(true);
            setnoti_arr(res.data.Res);
          } else {
            dispatch(
              alert_action({
                type: "error",
                title: "Empty!",
                msg: "Sorry! You have no notifications!",
              })
            );
          }
        } else {
          dispatch(
            alert_action({
              type: "error",
              title: "Error",
              msg: "Some error occurred!",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(loading_action(false));
        console.log({ err });
      });
  };

  const uniqueKey = Date.now();

  return (
    <>
      <Flex_box
        style={{
          justifyContent: "space-between",
          padding: "7.5px 10px",
          backgroundColor: nav_bg_color,
        }}
      >
        <div
          onClick={() => {
            if (!no_nav_func_flag) {
              navigate("/my-profile");
            }
          }}
          style={{
            cursor: "pointer",
          }}
        >
          <Flex_box
            style={{
              width: "",
              padding: "15px 10px",
            }}
          >
            <img
              src={ham_menu}
              id="sidebar_toggle"
              onClick={() => {
                dispatch(sidebar_action(true));
              }}
              style={{
                height: "30px",
                marginRight: "20px",
                cursor: "pointer",
              }}
            />
            <img
              src={profilepictuer ? image_url + profilepictuer : user_logo}
              style={{
                height: "70px",
                width: "75px",
                borderRadius: "50%",
                marginRight: "20px",
              }}
            />
            <Flex_box
              style={{
                width: "",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                fontSize: "18px",
                gap: 8,
              }}
            >
              <b
                style={{
                  fontSize: "22px",
                }}
              >
                {username}
              </b>

              <span
                style={{
                  fontSize: "18px",
                }}
              >
                {sliced_string(email, 20)}
              </span>
            </Flex_box>
          </Flex_box>
        </div>
        <Flex_box
          style={{
            width: "",
            gap: 25,
            marginRight: "20px",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                gap: 25,
              }}
            >
              {user_data?.study_count && (
                <div className="main-heding">
                  <span className="heding-total">Studies : </span>{" "}
                  {user_data?.created_studies || 0} / {user_data?.study_count}
                </div>
              )}
              {user_data?.max_respondents && (
                <div className="main-heding">
                  <span className="heding-total">Respondents : </span>{" "}
                  {user_data?.total_respondants || 0} /{" "}
                  {user_data?.max_respondents}
                </div>
              )}
            </div>
            <div
              style={{
                fontSize: "16px",
                display: "flex",
                gap: 25,
              }}
            >
              {user_data?.coin_limit && (
                <div className="main-heding">
                  <span className="heding-total">Coins : </span>{" "}
                  {user_data?.used_coins || 0} / {user_data?.coin_limit}
                </div>
              )}
              {user_data?.video_call_hrs && (
                <div className="main-heding">
                  {" "}
                  <span className="heding-total">
                    Video Calls(Hrs.) :{" "}
                  </span>{" "}
                  {user_data?.used_video_call_hrs || 0} /{" "}
                  {user_data?.video_call_hrs}
                </div>
              )}
            </div>
          </div>
          {is_researcher && !max_w_700 && (
            <Button
              id="btn_comp"
              style={{
                width: "160px",
                height: "50px",
                fontSize: "16px",
                fontWeight: "520",
              }}
              onClick={async () => {
                // const sub_data = await get_subscription_data_count_and_kyc(
                //   dispatch,
                //   user_data
                // );
                // if (sub_data?.study_flag) {
                //   dispatch(
                //     alert_action({
                //       type: "error",
                //       title: "Unverified KYC",
                //       msg: "Please update your KYC!",
                //     })
                //   );
                // } else {
                if (!no_nav_func_flag) {
                  navigate("/create-study");
                }
                // }
              }}
              label={"Create Study"}
            ></Button>
          )}
          {!max_w_700 && (
            <>
              <Notifications
                get_resp_noti={get_resp_noti}
                setmodal_open={setmodal_open}
              />
              <Button
                id="btn_comp"
                style={{
                  width: "120px",
                  height: "40px",
                  padding: 0,
                  fontSize: "14px",
                  fontWeight: "520",
                  backgroundColor: "transparent",
                  color: "#000",
                  border: "2px solid " + theme_color,
                }}
                onClick={async () => {
                  dispatch(set_user_action({}));
                  dispatch(login_action(false));
                  persistor.purge();
                  localStorage.removeItem('persist:root')
                  await localStorage.clear();
                  // navigate("/");
                  window.location.href = page_url;
                }}
                label={"Logout"}
              ></Button>
            </>
          )}
        </Flex_box>
      </Flex_box>

      <Modal
        content_style={{
          // padding: 22,
          minWidth: "351px",
          width: "50%",
        }}
        isOpen={modal_open}
        onClose={() => {
          setmodal_open(false);
        }}
      >
        <div
          // className="removescrollvar"
          style={{
            // width: "450px",
            flexWrap: "wrap",
            gap: 10,
            position: "relative",
            marginTop: "-20px",
          }}
        >
          <span
            className="close"
            style={{
              top: 27,
              right: 10,
            }}
            onClick={() => {
              setmodal_open(false);
            }}
          >
            &times;
          </span>
          <div
            style={{
              paddingTop: "15px",
              paddingLeft: "10px",
            }}
          >
            <h4 style={{ fontStyle: "italic" }}>Notifications</h4>
          </div>
          <div
            style={{
              maxHeight: "300px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {noti_arr?.length > 0 &&
              noti_arr?.map((el, ind) => {
                return (
                  <div
                    key={ind}
                    id={"noti" + ind}
                    style={{
                      width: "100%",
                      textWrap: "wrap",
                      whiteSpace: "wrap",
                      borderBottom: "1px solid #C9C9C9",
                      paddingBottom: "5px",
                      fontSize: "14px",
                      cursor: "pointer",
                      backgroundColor: el?.isRead ? "#fff" : theme_bg,
                      padding: "10px",
                    }}
                    onMouseOver={() => {
                      document.getElementById("noti" + ind).style.opacity = 0.5;
                      document.getElementById(
                        "title" + ind
                      ).style.textDecoration = "underline";
                    }}
                    onMouseOut={() => {
                      document.getElementById("noti" + ind).style.opacity = 1;
                      document.getElementById(
                        "title" + ind
                      ).style.textDecoration = "none";
                    }}
                    onClick={() => {
                      if (state?.pathname?.includes("study-details")) {
                        navigate(state?.pathname + "?t=" + uniqueKey, {
                          replace: true,
                          state: {
                            type: el?.type == "Survey" ? "survey" : "gd",
                            id: el?.notificatonTypeId,
                            study_id: el?.notificatonTypeId,
                            key: uniqueKey,
                            study_invitation:
                              el?.title == "Study Invitation" ? true : false,
                          },
                        });
                      } else {
                        // console.warn(el);

                        navigate("/study-details?", {
                          state: {
                            // type: el?.type == "Survey" ? 1 : 2,
                            type: el?.type == "Survey" ? "survey" : "gd",
                            id: el?.notificatonTypeId,
                            study_id:el?.notificatonTypeId,
                            key: uniqueKey,
                            study_invitation:
                              el?.title == "Study Invitation" ? true : false,
                          },
                        });
                      }
                      setmodal_open(false);
                    }}
                  >
                    <b
                      id={"title" + ind}
                      style={{
                        fontSize: "16px",
                      }}
                    >
                      {el?.title}
                    </b>
                    <br />
                    <span>{el?.message}</span>
                  </div>
                );
              })}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Navbar;
