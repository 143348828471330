import React, { useState, useEffect } from "react";
import { Modal } from "../../../../../utilities/Modal";
import Select_box from "../../../../../utilities/Select_box";
import {
  add_top_up_api,
  get_assigned_researchers_api,
  get_res_email_invitation_api,
  get_researchers_api,
  researcher_assignment_api,
  send_res_email_invitation_api,
  upd_researcher_assignment_api,
} from "../../../../../apis/apis";
import { useDispatch, useSelector } from "react-redux";
import {
  email_regex,
  get_subscription_data_count_and_kyc,
} from "../../../../../utilities/utilities";
import { alert_action } from "../../../../../redux_prog/actions/base_action";
import Button from "../../../../../utilities/Button";
import Flex_box from "../../../../../utilities/flex_box";
import delete_icon from "../../../../../assets/delete.png";
import Text_Input from "../../../../../utilities/Text_Input";
import FA_Icon_box from "../../../../../utilities/FA_Icon_box";

const Researchers_Modal = ({
  modalVisible,
  setmodalVisible,
  study_id,
  study_name,
  type,
  type_id,
}) => {
  const { user_data } = useSelector((state) => state?.authReducer);
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [researcher_email, setresearcher_email] = useState("");
  const [researchers, setresearchers] = useState([]);
  const [assigned_researchers, setassigned_researchers] = useState([]);
  const [users_exist, setusers_exist] = useState(false);
  const assigned_res_ids =
    assigned_researchers?.map((el) => {
      return el?.res_id;
    }) || [];

  const researcher_options = researchers
    ?.filter((el) => {
      return assigned_res_ids?.includes(el?._id) == false;
    })
    ?.map((el) => {
      return {
        label: el?.firstname + " " + el?.lastname,
        value: el?._id,
      };
    });

  // const get_researchers = () => {
  //   get_researchers_api()
  //     .then((res) => {
  //       setresearchers(res.data.user_res || [])
  //     })
  //     .catch((err) => {
  //       console.error(err)
  //     })
  // }

  // const get_assigned_researchers = () => {
  //   get_assigned_researchers_api({ study_id })
  //     .then((res) => {
  //       setassigned_researchers(res.data.user_res[0]?.researchers || [])
  //       if (res.data.user_res[0]?.researchers) {
  //         setusers_exist(true)
  //       }
  //     })
  //     .catch((err) => {
  //       console.error(err)
  //     })
  // }

  // useEffect(() => {
  //   get_researchers()
  // }, [])

  // useEffect(() => {
  //   get_assigned_researchers()
  // }, [study_id])

  const assigned_users = () => {
    // if (users_exist) {
    //   upd_researcher_assignment_api({
    //     study_id,
    //     researchers: assigned_researchers,
    //     researcher_email,
    //     user_id: user_data?._id,
    //     study_name,
    //     type,
    //     type_id: type_id?.length > 0 ? type_id[0]?._id : "",
    //   })
    //     .then((res) => {
    //       if (res.data.status == 1) {
    //         dispatch(
    //           alert_action({
    //             type: "success",
    //             title: "Success",
    //             msg: "Reseachers assigned!",
    //           }),
    //         )
    //       } else {
    //         dispatch(
    //           alert_action({
    //             type: "error",
    //             title: "Error",
    //             msg: res.data.msg,
    //           }),
    //         )
    //       }
    //       setmodalVisible(false)
    //     })
    //     .catch((err) => {
    //       console.warn(err)
    //     })
    // } else {
    //   // if (assigned_researchers?.length > 0) {
    //     researcher_assignment_api({
    //       study_id,
    //       researchers: assigned_researchers,
    //       researcher_email,
    //       user_id: user_data?._id,
    //       study_name,
    //       type,
    //       type_id: type_id?.length > 0 ? type_id[0]?._id : "",
    //     })
    //       ?.then((res) => {
    //         if (res.data.status == 1) {
    //           dispatch(
    //             alert_action({
    //               type: "success",
    //               title: "Success",
    //               msg: "Reseachers assigned!",
    //             }),
    //           )
    //         } else {
    //           dispatch(
    //             alert_action({
    //               type: "error",
    //               title: "Error",
    //               msg: res.data.msg,
    //             }),
    //           )
    //         }
    //         setmodalVisible(false)
    //       })
    //       .catch((err) => {
    //         console.warn(err)
    //       })
    //   // }
    //   // else {
    //   //   dispatch(
    //   //     alert_action({
    //   //       type: "error",
    //   //       title: "Error",
    //   //       msg: "Please assigned a researcher first!",
    //   //     }),
    //   //   )
    //   // }
    // }

    if (email_regex.test(researcher_email)) {
      send_res_email_invitation();
    } else {
      dispatch(
        alert_action({
          type: "error",
          title: "Error",
          msg: "Email is not valid!",
        })
      );
    }
  };

  const get_assigned_researchers = () => {
    get_res_email_invitation_api({
      study_id,
    })
      .then((res) => {
        console.warn(res.data);
        setassigned_researchers(res.data.res_inv);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const send_res_email_invitation = () => {
    send_res_email_invitation_api({
      study_id,
      email: researcher_email,
      study_name: study_name,
      user_id: user_data?._id,
      notificatonTypeId: type_id[0]?._id,
      type,
    })
      .then((res) => {
        console.warn(res.data);
        dispatch(
          alert_action({
            type: "success",
            title: "Success",
            msg: "Invitation sent to " + researcher_email,
          })
        );
        get_res_email_invitation_api();
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  useEffect(get_assigned_researchers, [modalVisible]);

  return (
    <>
      <Modal
        isOpen={modalVisible}
        onClose={() => {
          setmodalVisible(false);
        }}
        label={"Add Researchers"}
        content_style={{
          padding: "0px",
        }}
        label_style={{
          width: "100%",
        }}
      >
        <Flex_box
          style={{
            padding: "0px 10px",
            gap: 10,
            flexWrap: "wrap",
          }}
        >
          <Flex_box
            style={{
              gap: 5,
              flexWrap: "wrap",
              //   alignItems:""
            }}
          >
            {/* <Select_box
              label={"Select a researcher"}
              options={researcher_options}
              cont_style={{
                width: "100%",
              }}
              onChange={(e) => {
                // setselected_researcher(e.target.value)
                if (assigned_researchers?.length < 3) {
                  setassigned_researchers([
                    ...assigned_researchers,
                    {
                      name: researcher_options?.filter((li) => {
                        return li.value == e.target.value
                      })[0]?.label,
                      res_id: e.target.value,
                    },
                  ])
                } else {
                  dispatch(
                    alert_action({
                      type: "error",
                      title: "Overflow",
                      msg: "You can't select more than 3 reserachers!",
                    }),
                  )
                }
              }}
            /> */}
            <Text_Input
              label={
                assigned_researchers?.length < 3
                  ? "Enter researcher email"
                  : "You have already assigned 3 researchers!"
              }
              cont_style={{
                width: "100%",
              }}
              disabled={assigned_researchers?.length == 3}
              value={researcher_email}
              onChange={(e) => {
                setresearcher_email(e.target.value);
              }}
            />
            {assigned_researchers?.length > 0 && (
              <div
                style={{
                  width: "100%",
                }}
              >
                {assigned_researchers?.map((el, index) => {
                  return (
                    <Flex_box
                      style={{
                        padding: "8px",
                        justifyContent: "space-between",
                        borderBottom: "1px solid grey",
                      }}
                    >
                      {el?.user_lookup[0].firstname +
                        " " +
                        el?.user_lookup[0].lastname}

                      <FA_Icon_box
                        className={
                          el?.status == 0
                            ? "fa-regular fa-clock"
                            : el?.status == 1
                            ? "fa-solid fa-check"
                            : "fa-solid fa-xmark"
                        }
                        style={{
                          backgroundColor:
                            el?.status == 0
                              ? "#FFC300"
                              : el?.status == 1
                              ? "green"
                              : "red",
                        }}
                      />
                      {/* <img
                        src={delete_icon}
                        style={{
                          height: "15px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setassigned_researchers(
                            assigned_researchers?.filter((li) => {
                              return li?.res_id != el?.res_id;
                            })
                          );
                        }}
                      /> */}
                    </Flex_box>
                  );
                })}
              </div>
            )}
          </Flex_box>
          <Button
            style={{
              width: "100%",
            }}
            label={"Submit"}
            onClick={assigned_users}
            loading={loading}
          />
        </Flex_box>
      </Modal>
    </>
  );
};

export default Researchers_Modal;
