import React, { useState } from "react";
import { Modal } from "../../../utilities/Modal";
import Select_box from "../../../utilities/Select_box";
import { add_custom_quota_api, add_top_up_api } from "../../../apis/apis";
import { useDispatch, useSelector } from "react-redux";
import { get_subscription_data_count_and_kyc } from "../../../utilities/utilities";
import { alert_action } from "../../../redux_prog/actions/base_action";
import Button from "../../../utilities/Button";
import Flex_box from "../../../utilities/flex_box";
import { useNavigate } from "react-router-dom";
import { addDays } from "../../../utilities/Date_Util";

const TopUp_Modal = ({
  modalVisible,
  setmodalVisible,
  buy_subscription,
  plan_id,
  func,
  modal_box,
  item,
}) => {
  const { user_data } = useSelector((state) => state?.authReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);

  const coin_options = [
    {
      label: "50 Coins",
      value: 50,
    },
    {
      label: "200 Coins",
      value: 200,
    },
    {
      label: "500 Coins",
      value: 500,
    },
    {
      label: "1000 Coins",
      value: 1000,
    },
  ];
  const respondent_options = [
    {
      label: "50 Respondents",
      value: 50,
    },
    {
      label: "200 Respondents",
      value: 200,
    },
    {
      label: "500 Respondents",
      value: 500,
    },
    {
      label: "1000 Respondents",
      value: 1000,
    },
  ];
  const study_options = [
    {
      label: "50 Studies",
      value: 50,
    },
    {
      label: "200 Studies",
      value: 200,
    },
    {
      label: "500 Studies",
      value: 500,
    },
    {
      label: "1000 Studies",
      value: 1000,
    },
  ];
  const v_call_options = [
    {
      label: "50 Video Hours",
      value: 50,
    },
    {
      label: "200 Video Hours",
      value: 200,
    },
    {
      label: "500 Video Hours",
      value: 500,
    },
    {
      label: "1000 Video Hours",
      value: 1000,
    },
  ];

  const topup_options = [];

  const [selected_coin_value, setselected_coin_value] = useState("");
  const [selected_resp_value, setselected_resp_value] = useState("");
  const [selected_v_call_value, setselected_v_call_value] = useState("");
  const [selected_study_value, setselected_study_value] = useState("");

  const buy = () => {
    if (
      selected_coin_value &&
      selected_resp_value &&
      selected_v_call_value &&
      selected_study_value
    ) {
      if (modal_box) {
        const user_sub_data = {
          coin_limit: selected_coin_value,
          study_count: selected_study_value,
          video_call_hrs: selected_v_call_value,
          max_respondents: selected_resp_value,
          user_id: user_data?._id,
          plan_id,
          exp_date: addDays(30),
          transaction_id: `7634ui78f357`,
        };
        func({
          user_sub_data,
          item,
          enterprise_flag: true,
        });
      } else {
        add_custom_quota_api({
          coin_limit: selected_coin_value,
          study_count: selected_study_value,
          video_call_hrs: selected_v_call_value,
          max_respondents: selected_resp_value,
          user_id: user_data?._id,
          plan_id,
          exp_date: addDays(30),
          transaction_id: `7634ui78f357`,
        })
          .then((res) => {
            if (res.data.status == 1) {
              if (func) {
                func();
              } else {
                dispatch(
                  alert_action({
                    type: "success",
                    title: "Success",
                    msg: "Your request is submitted, You can now access the features of SmartQ application!",
                  })
                );
                navigate("/my-surveys");
              }
            }
          })
          .catch((err) => {
            console.warn(err);
          });
      }
    } else {
      dispatch(
        alert_action({
          type: "error",
          title: "Empty Field",
          msg: "Please select all options!",
        })
      );
    }
  };

  return (
    <>
      <Modal
        isOpen={modalVisible}
        onClose={() => {
          setmodalVisible(false);
        }}
        label={"Custom Enterprise Quota"}
        content_style={{
          padding: "0px",
        }}
        label_style={{
          width: "100%",
        }}
      >
        <Flex_box
          style={{
            padding: "10px",
            gap: 10,
            flexWrap: "wrap",
          }}
        >
          <Select_box
            label={"Select coin value"}
            options={coin_options}
            cont_style={{
              width: "100%",
            }}
            onChange={(e) => {
              setselected_coin_value(e.target.value);
            }}
          />
          <Select_box
            label={"Select respondents value"}
            options={respondent_options}
            cont_style={{
              width: "100%",
            }}
            onChange={(e) => {
              setselected_resp_value(e.target.value);
            }}
          />
          <Select_box
            label={"Select study value"}
            options={study_options}
            cont_style={{
              width: "100%",
            }}
            onChange={(e) => {
              setselected_study_value(e.target.value);
            }}
          />
          <Select_box
            label={"Select video hrs value"}
            options={v_call_options}
            cont_style={{
              width: "100%",
            }}
            onChange={(e) => {
              setselected_v_call_value(e.target.value);
            }}
          />
          <Button
            style={{
              width: "100%",
            }}
            label={"Place Order"}
            onClick={buy}
            loading={loading}
          />
        </Flex_box>
      </Modal>
    </>
  );
};

export default TopUp_Modal;
