import React, { useEffect, useMemo, useRef, useState } from "react";
import Flex_box from "../../../../utilities/flex_box.jsx";
import { theme_bg } from "../../../../utilities/colors.js";
import phone_icon from "../../../../assets/phone.svg";
import video_icon from "../../../../assets/Group 32928.svg";
import user_icon from "../../../../assets/user_dummy.png";
import Chat_Footer from "./Chat_Footer.jsx";
import axios from "axios";
import { host } from "../../../Chat_Screens/utils/APIRoutes.js";
import { v4 as uuidv4 } from "uuid";
import V_Call from "../../../Video_Chat/agora_ng_version/V_Call.tsx";
import A_Call from "../../../Video_Chat/agora_ng_version/A_Call.tsx";
import Chat_Header from "./Chat_Header.jsx";

import io from "socket.io-client";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  BASE_URL,
  delete_message_api,
  get_polls_api,
  get_room_messages_api,
  image_url,
  report_msg_api,
  store_message_api,
} from "../../../../apis/apis.js";
import Poll_Form from "./Poll/Poll_Form.jsx";
import poll_icon from "../../../../assets/poll_icon.png";
import Poll_Details from "./Poll/Poll_Box/Poll_Details.jsx";
import Poll_Box from "./Poll/Poll_Box/index.jsx";
import dot_menu from "../../../../assets/dot_menu.png";
import { createClient } from "agora-rtc-sdk-ng/esm";
import { alert_action } from "../../../../redux_prog/actions/base_action.js";
import { Modal } from "../../../../utilities/Modal.jsx";
import Text_Input from "../../../../utilities/Text_Input.jsx";
import Button from "../../../../utilities/Button.jsx";
import Select_box from "../../../../utilities/Select_box.jsx";
import { reportMenu } from "../../../../utilities/utilities.js";

// const ENDPOINT = "http://localhost:5000";
let socket = io(host);

const Msg_comp = ({
  reverse,
  message,
  del_message,
  setMessages,
  messages,
  selected_msg,
  setselected_msg,
  gd_data,
}) => {
  const top_b_radius = !reverse
    ? { borderTopLeftRadius: "0px" }
    : { borderTopRightRadius: "0px" };
  const { user_data } = useSelector((state) => state?.authReducer);
  const user_pic = message?.user_image
    ? image_url + message?.user_image
    : user_icon;
  const [selected_report, setselected_report] = useState("");
  const [remark, setremark] = useState("");
  const [isModalVisible, setisModalVisible] = useState(false);

  const change_modal_visibility = () => {
    // setMessages(
    //   messages?.map((el) => {
    //     if (el?._id == message?._id) {
    //       return {
    //         ...el,
    //         modal: message?.modal ? false : true,
    //       };
    //     } else {
    //       return {
    //         ...el,
    //         modal: false,
    //       };
    //     }
    //   })
    // );
    if (selected_msg) {
      setselected_msg("");
    } else {
      setselected_msg(message?._id);
    }
  };

  const report_msg = () => {
    report_msg_api({
      report_msg: {
        message_id: message?._id,
        reporter_id: user_data?._id,
        room_id: gd_data?._id,
        selected_report,
        remark,
        user_id: message?.user_id,
      },
    })
      .then((res) => {
        setselected_msg("");
        setselected_report("");
        setremark("");
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  return (
    <>
      <div
        className="removescrollbar"
        style={{ width: "100%", position: "relative" }}
      >
        <Flex_box
          className="removescrollbar"
          style={
            reverse
              ? {
                  justifyContent: "end",
                  alignItems: "start",
                }
              : {
                  justifyContent: "start",
                  alignItems: "start",
                  // backgroundColor:"red"
                }
          }
        >
          {!reverse && (
            <img
              // src={`data:image/svg+xml;base64,${currentUser.avatarImage}`}
              src={user_pic}
              style={{
                height: "20px",
                marginTop: "9px",
                marginRight: "10px",
              }}
            />
          )}
          <div>
            <p style={{ fontSize: "10px" }}>
              {reverse ? "You" : message?.user}
            </p>
            <Flex_box
              style={{
                alignItems: "",
                marginTop: "-5px",
              }}
            >
              {!reverse && (
                <img
                  src={dot_menu}
                  style={{
                    height: "17px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    change_modal_visibility();
                  }}
                />
              )}
              <div
                style={{
                  backgroundColor: "rgb(2, 103, 193,0.1)",
                  fontSize: "12px",
                  padding: "10px",
                  borderRadius: "8px",
                  ...top_b_radius,
                }}
              >
                {message?.text?.split(".")[1] == "png" ? (
                  <img
                    src={image_url + message?.text}
                    style={{
                      height: "100px",
                      width: "100px",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  message?.text
                )}
              </div>

              {reverse && (
                <img
                  src={dot_menu}
                  style={{
                    height: "17px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    change_modal_visibility();
                  }}
                />
              )}
            </Flex_box>
          </div>

          {reverse && (
            <img
              // src={`data:image/svg+xml;base64,${currentChat.avatarImage}`}
              src={user_pic}
              style={{
                height: "20px",
                marginTop: "9px",
                marginLeft: "10px",
              }}
            />
          )}
        </Flex_box>
        {selected_msg == message?._id && (
          <Flex_box
            style={{
              width: "60px",
              position: "absolute",
              top: "25px",
              right: reverse ? "45px" : "",
              left: !reverse ? "45px" : "",
              flexDirection: "column",
              fontSize: "12px",
              backgroundColor: "#fff",
              border: "1px solid #c9c9c9",
              borderRadius: "8px",
            }}
          >
            {reverse && (
              <Flex_box
                style={{
                  width: "100%",
                  borderBottom: "1px solid #c9c9c9",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (window.confirm("Do you want to delete this message?")) {
                    del_message(
                      message?._id,
                      message?.text,
                      message?.createdAt
                    );
                    setselected_msg("");
                  }
                }}
              >
                <span>Delete</span>
              </Flex_box>
            )}
            {!reverse && (
              <Flex_box
                style={{
                  width: "100%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  // setselected_msg("");
                  // report_msg();
                  setisModalVisible(true);
                }}
              >
                <span>Report</span>
              </Flex_box>
            )}
          </Flex_box>
        )}
      </div>
      <Modal
        isOpen={isModalVisible}
        onClose={() => {
          setisModalVisible(false);
        }}
        label={"Report Message"}
      >
        <Select_box
          options={reportMenu}
          onChange={(e) => {
            setselected_report(e.target.value);
          }}
          value={selected_report}
          cont_style={{
            width: "100%",
          }}
          placeholder={"Select an option"}
        />
        {selected_report == "Other" && (
          <Text_Input
            label={"Remark"}
            value={remark}
            onChange={(e) => {
              setremark(e.target.value);
            }}
            cont_style={{
              marginTop: "20px",
            }}
          />
        )}
        <Button
          onClick={report_msg}
          label={"Submit"}
          style={{ width: "100%", marginTop: "20px" }}
        />
      </Modal>
    </>
  );
};

const Chat_Box = ({ width, is_gd }) => {
  const [isVideoOn, setisVideoOn] = useState(false);
  const [isAudioOn, setisAudioOn] = useState(false);
  const scrollRef = useRef();
  const [messages, setMessages] = useState([]);
  const [user_joined, setuser_joined] = useState(false);
  const dispatch = useDispatch();
  const state = useLocation();
  const { user_data } = useSelector((state) => state?.authReducer);
  const [currentUser, setcurrentUser] = useState(user_data || {});
  const [poll_form_modal, setpoll_form_modal] = useState(false);
  const [selected_msg, setselected_msg] = useState("");
  const room_id = state?.state?.study_detail?._id;
  const client = createClient({
    mode: "rtc",
    codec: "vp8",
  });
  const store_message = (message, createdAt) => {
    store_message_api(
      {
        message,
        room_id,
        user_id: user_data?._id,
        user: currentUser?.username,
        user_image: user_data?.profilepictuer,
        createdAt,
      },
      user_data?.role
    )
      .then((res) => {
        console.warn(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const get_messages = () => {
    get_room_messages_api(
      {
        room_id,
      },
      user_data?.role
    )
      .then((res) => {
        setMessages(
          res?.data?.msg_res?.map((el) => {
            return {
              text: el?.message,
              ...el,
            };
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(get_messages, []);

  const join_room = async () => {
    socket.emit(
      "join",
      {
        name: user_data?.username,
        room: room_id,
        message: state?.state?.gd_data?.groupdiscussionname,
        user_image: user_data?.profilepictuer,
        user_id: user_data?._id,
      },
      (error) => {
        if (error) {
          alert(error);
        }
        setuser_joined(true);
      }
    );
  };

  const [effect_func, seteffect_func] = useState(false);
  const [admin_mute, setadmin_mute] = useState(false);
  const [admin_remove, setadmin_remove] = useState(false);
  const [remote_users_arr, setremote_users_arr] = useState({});
  const [vc_flag, setvc_flag] = useState(false);

  useEffect(() => {
    const navigationTo = async () => {
      if (effect_func == true) {
        await join_room();
      } else {
        seteffect_func(true);
      }
    };
    navigationTo();
  }, [effect_func, socket]);

  useEffect(() => {
    if (effect_func == true) {
      socket.on("message", (message) => {
        const c_id = localStorage.getItem("client_uid");
        if (message?.text?.includes("~request")) {
          const id = message?.text?.split("~")[0];
          const name = message?.text?.split("~")[1];
          if (user_data?.role != 3) {
            // alert("Request");
            // console.warn(user_data);

            dispatch(
              alert_action({
                is_confirm: true,
                title: "Join Request",
                msg: name + " wants to join the group discussion",
                onSuccess: (e) => {
                  handleSubmit(e, id + "~approved", false, true);
                },
              })
            );
          }
        } else if (message?.text?.includes("~approved")) {
          const id = message?.text?.split("~")[0];
          if (user_data?._id == id) {
            setvc_flag(true);
          }
        } else if (message?.text?.includes("~muted")) {
          const id = message?.text?.split("~")[0];
          if (c_id == id) {
            setadmin_mute(true);
          }
        } else if (message?.text?.includes("~removed")) {
          const id = message?.text?.split("~")[0];
          if (c_id == id) {
            setadmin_remove(true);
            setvc_flag(false);
          }
          const { [c_id]: _, ...updatedData } = remote_users_arr;

          // Update the state
          setremote_users_arr(updatedData);
        } else if (message?.delete == true) {
          setMessages([
            ...new Set(
              messages?.filter((el) => {
                console.warn({ el, message });

                return el?.user_id == message?.user_id
                  ? el?.text == message?.text
                    ? el?.createdAt != message?.createdAt
                    : true
                  : true;
              })
            ),
          ]);
        } else if (
          messages
            ?.map((item) => {
              return item?.text;
            })
            ?.includes(message?.text) &&
          message?.text?.includes("poll~")
        ) {
          setMessages([...new Set(messages)]);
        } else {
          setMessages([...new Set([...messages, message])]);
        }
      });
    } else {
      seteffect_func(true);
    }
  }, [effect_func, socket, messages]);

  const handleSubmit = (e, message, nopreventDefault, dont_store) => {
    if (!nopreventDefault) {
      e.preventDefault();
    }
    if (message) {
      const date = new Date();
      socket.emit("sendMessage", { message, createdAt: date });
      if (!dont_store) {
        store_message(message, date);
      }
    } else alert("empty input");
  };

  const del_message = (_id, message, createdAt) => {
    delete_message_api(
      {
        _id,
      },
      user_data?.role
    )
      .then((res) => {
        socket.emit("delMessage", { message, createdAt });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const [v_modal_visible, setv_modal_visible] = useState(true);
  const [a_modal_visible, seta_modal_visible] = useState(true);
  const [isJoined, setIsJoined] = useState(false);

  const vid_call = useMemo(
    () =>
      isVideoOn && (
        <V_Call
          v_modal_visible={v_modal_visible}
          setv_modal_visible={setv_modal_visible}
          room_id={room_id}
          isVideoOn={isVideoOn}
          setIsVideoOn={setisVideoOn}
          handleSubmit={handleSubmit}
          admin_mute={admin_mute}
          admin_remove={admin_remove}
          client={client}
          remote_users_arr={remote_users_arr}
          setremote_users_arr={setremote_users_arr}
          vc_flag={vc_flag}
          isJoined={isJoined}
          setIsJoined={setIsJoined}
        />
      ),
    [
      isVideoOn,
      v_modal_visible,
      admin_mute,
      admin_remove,
      client,
      remote_users_arr,
      vc_flag,
      isJoined
    ]
  );

  const aud_call = useMemo(
    () =>
      isAudioOn && (
        <A_Call
          a_modal_visible={a_modal_visible}
          seta_modal_visible={seta_modal_visible}
          room_id={room_id}
          isAudioOn={isAudioOn}
          setIsAudioOn={setisAudioOn}
          admin_mute={admin_mute}
          admin_remove={admin_remove}
        />
      ),
    [isAudioOn, a_modal_visible, admin_mute, admin_remove]
  );

  // if(gd)

  return (
    <>
      {
        <Chat_Header
          gd_data={state?.state?.gd_data}
          study_detail={state?.state?.study_detail}
          user_detail={state?.state?.user_detail}
          setpoll_form_modal={setpoll_form_modal}
          setv_modal_visible={setv_modal_visible}
          seta_modal_visible={seta_modal_visible}
        />
      }
      <Flex_box
        className="removescrollbar"
        style={{
          width: width || "100%",
          backgroundColor: theme_bg,
          // marginTop: "10px",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px #0000000D",
          flexDirection: "column",
          paddingBottom: "10px",
        }}
      >
        {user_joined && (
          <>
            <Flex_box
              className="removescrollbar"
              style={{
                justifyContent: "end",
                gap: 15,
                padding: "14px 0px",
                width: "98%",
              }}
            >
              {user_data?.role == 2 && (
                <img
                  src={poll_icon}
                  onClick={() => {
                    setpoll_form_modal(true);
                  }}
                  style={{
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              )}
              <img
                src={phone_icon}
                onClick={() => {
                  setisAudioOn(true);
                  seta_modal_visible(true);
                }}
                style={{
                  height: "15px",
                  cursor: "pointer",
                }}
              />
              <img
                src={video_icon}
                onClick={() => {
                  setisVideoOn(true);
                  setv_modal_visible(true);
                }}
                style={{
                  height: "15px",
                  cursor: "pointer",
                }}
              />
            </Flex_box>
            <div
              style={{
                height: "35vh",
                overflowY: "auto",
                width: "97.5%",
                padding: "20px 10px",
              }}
              onFocus={(e) => {}}
              onLoad={() => {
                var target = document.getElementById("target");
                target.scrollIntoView({ behavior: "smooth" });
              }}
            >
              {messages?.length > 0 &&
                messages
                  ?.filter((el) => {
                    return el.text?.split("~")[1] != "joined";
                  })
                  .map((message) => {
                    return message?.text?.split("~")[0] == "poll" ? (
                      <>
                        <Poll_Box
                          ref={scrollRef}
                          key={uuidv4()}
                          message={message}
                          reverse={user_data?._id == message.user_id}
                          setMessages={setMessages}
                          messages={messages}
                          gd_data={state?.state?.gd_data}
                          seteffect_func={seteffect_func}
                          effect_func={effect_func}
                          handleSubmit={handleSubmit}
                          socket={socket}
                          del_message={del_message}
                          selected_msg={selected_msg}
                          setselected_msg={setselected_msg}
                          // gd_data={state?.state?.gd_data}
                        />
                      </>
                    ) : (
                      <>
                        <Msg_comp
                          ref={scrollRef}
                          key={uuidv4()}
                          message={message}
                          reverse={user_data?._id == message.user_id}
                          del_message={del_message}
                          setMessages={setMessages}
                          messages={messages}
                          selected_msg={selected_msg}
                          setselected_msg={setselected_msg}
                          gd_data={state?.state?.gd_data}
                        />
                      </>
                    );
                  })}
              <div
                id="target"
                style={{
                  width: "100%",
                  height: "1px",
                }}
              ></div>
            </div>
            <Chat_Footer onClick={handleSubmit} video_call={isJoined} g_id={state?.state?.gd_data?._id} />
          </>
        )}
        {!user_joined && (
          <Flex_box
            className="removescrollbar"
            style={{
              width: "97.5%",
              height: "60vh",
              // maxHeight: "65vh",
              backgroundColor: "#fff",
              padding: "20px 10px",
            }}
          >
            <b>No room created yet!!</b>
          </Flex_box>
        )}
      </Flex_box>
      {vid_call}
      {aud_call}
      <Poll_Form
        poll_form_modal={poll_form_modal}
        setpoll_form_modal={setpoll_form_modal}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default Chat_Box;
