import React from 'react'
import Add_question from './Add_question'
import Flex_box from '../../../../../utilities/flex_box'
import Sidebar from '../../../../Nav_Comp/Sidebar'
import Navbar from '../../../../Nav_Comp/Navbar'
import Footer from '../../../../Nav_Comp/Footer'
import Layout from '../../../../../utilities/Layout'

const Add_Questions = () => {
    return (
        <>
        {/* <Flex_box
            style={{
                justifyContent: "start"
            }}
        >
            <Sidebar />
            <div
                className='comp_container'
                style={{
                    position: 'relative'
                }}
            >
                <Navbar />
                <Footer />
            </div>
        </Flex_box> */}
        <Layout>
        <Add_question />
        </Layout>
        </>
    )
}

export default Add_Questions
