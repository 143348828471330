import React, { useEffect, useState } from "react";
import useSpeechToText from "react-hook-speech-to-text";
import audio_icon from "../../../../assets/microphone.png";
import stop_icon from "../../../../assets/stop_red.png";
import { create_transcripts_api } from "../../../../apis/apis";
import { useSelector } from "react-redux";

export default function STT({ setmsg, video_call,g_id }) {
  const {
    error,
    interimResult,
    isRecording,
    results,
    startSpeechToText,
    stopSpeechToText,
  } = useSpeechToText({
    continuous: true,
    useLegacyResults: false,
  });

const {user_data} = useSelector(state=>state?.authReducer);

const [stt_texts, setstt_texts] = useState('');

  useEffect(() => {
    if (stt_texts) {
      if (video_call) {
        create_transcripts_api({
          userid:user_data?._id,
          g_id,
          msg:stt_texts
        });
        setstt_texts("");
      } else {
        if(setmsg){
        setmsg(stt_texts);
        setstt_texts("");
      }
      }
    }
  }, [stt_texts, video_call]);

  useEffect(() => {
    if(results){
      setstt_texts(results
        ?.map((el) => {
          return el.transcript;
        })
        ?.toString()
        ?.replace(",", " "))
    }
    else{
      setstt_texts("");
    }
  }, [results])
  

  useEffect(() => {
    try {
      if (video_call && !isRecording) {
        startSpeechToText();
      }
    } catch (error) {
      console.warn(error);
    }
  }, [video_call]);

  if (error || video_call) return <>‍</>;

  return (
    <>
      <img
        src={isRecording ? stop_icon : audio_icon}
        style={{
          height: "15px",
          cursor: "pointer",
          borderRadius: isRecording ? "50%" : "",
        }}
        onClick={isRecording ? stopSpeechToText : startSpeechToText}
      />
    </>
  );
}
