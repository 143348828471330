import React from "react";
import Flex_box from "../../../utilities/flex_box";
import Layout from "../../../utilities/Layout";
import Subscription_Box from "./Subscriptions_Box";
import Price_Box from "./Pricing_Box";
import { useLocation } from "react-router-dom";
import { buy_subscription_api } from "../../../apis/apis";
import { useSelector } from "react-redux";

const Pricing_Page = ({}) => {
    const state = useLocation();
    const subscriptions = state?.state;
  
    return (
      <Layout no_sidebar={false}>
        <Flex_box
          style={{
            flexDirection: "column",
            justifyContent: "flex-start",
            height: "70vh",
            lineHeight: 1.8,
            paddingTop: "20px",
            overflowY: "auto",
          }}
        >
          <b style={{ fontSize: "24px" }}>Subscriptions</b>
          <span style={{ fontSize: "14px" }}>
            Unlock unlimited access to all the features
          </span>
    
          <Flex_box
            style={{
              gap: "30px",
              marginTop: "25px",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {subscriptions?.map((el, index) => {
              return (
                <Price_Box
                  key={index}
                  price={`$` + el?.Amount}
                  subtext={"Per " + el?.Cycle}
                  duration={el?.Duration}
                  content={el?.Description}
                  _id={el?._id}
                  item={el}
                  style={{
                    width: "300px",
                    marginBottom: "20px",
                  }}
                />
              );
            })}
          </Flex_box>
        </Flex_box>
      </Layout>
    );    
  };
  
  export default Pricing_Page;