import React, { useEffect, useState } from "react"
import Flex_box from "../../../../../utilities/flex_box"
import blue_circle from "../../../../../assets/Group 38514.svg"
import green_check from "../../../../../assets/check-circle-solid.svg"
import Text_Input from "../../../../../utilities/Text_Input"
import { theme_bg, theme_color } from "../../../../../utilities/colors"
import { Checkbox, Radio } from "@mui/material"
import Select_box from "../../../../../utilities/Select_box"
import Sub_Option_MCQ_Box from "./Sub_Option_MCQ_Box"
import { get_array } from "../../../../../utilities/utilities"

// const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);

const Option_Box = ({ item, type }) => {
  return (
    <Flex_box
      style={{
        justifyContent: "start",
        gap: 15,
        marginTop: "15px",
        width: "100%",
      }}
    >
      {type == 2 && (
        <Checkbox
          // checked={checked}
          // onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      )}
      {type == 3 && (
        <Radio
          // checked={true}
          // onChange={handleChange}
          value="a"
          name="radio-buttons"
          inputProps={{ "aria-label": "A" }}
        />
      )}
      <div
        id="text_input"
        className="removescrollbar"
        style={{
          textWrap: "wrap",
          whiteSpace: "wrap",
          overflowY: "auto",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          width: "87%",
          minWidth:"200px",
          minHeight: "40px",
          // paddingTop:"10px"
        }}
      >
        <span>{item?.optiontitle}</span>
      </div>
    </Flex_box>
  )
}

const MCQ_Comp = ({
  item,
  index,
  allQuestions,
  setquestion_arr,
  handelDependentQuestion,
  selectedQuestionIndex,
  setselectedQuestionIndex,
  set_value,
  sets
}) => {
  // console.log("question list", question_data);

  const [questionList, setQuestionList] = useState([{ label: "Remove", value: "" }]);
  const [setList, setsetList] = useState([{ label: "Remove", value: "" }]);
  const [selectedQuestion, setSelectedQuestion] = useState()

  const handelChedkedBox = (e, serial_no, index) => {
    
    var questions = []
    console.log("qstn lst", allQuestions)
    const existing_qs = [].concat(
      ...allQuestions?.filter((el)=>{
        return el.set == set_value
      }).map((el) => {
        return el?.Option?.map((li) => {
          console.warn({d_q:li.dependent_question});
          return li.dependent_question? parseInt(li.dependent_question):0
        })
      }),
    );

   
    for (let i = 0; i < allQuestions.length; i++) {
     
      console.warn({
        existing_qs,a_s:allQuestions[i].serial_no
      });
      
      // console.warn({
      //   i_s:allQuestions[i].serial_no,
      //   serial_no,
      //   set_value,
      //   i_set:allQuestions[i]?.set,
      //   exist_flag:!(existing_qs?.includes(allQuestions[i].serial_no)),
      //   if_flag:(
      //     allQuestions[i].serial_no > serial_no &&
      //     allQuestions[i]?.set == set_value &&
      //     !(existing_qs?.includes(allQuestions[i].serial_no))
      //   )
      // });
      
      if (
        (allQuestions[i].serial_no > serial_no) &&
        (allQuestions[i]?.set == set_value) &&
        !(existing_qs?.includes(allQuestions[i].serial_no))
      ) {
        questions.push({
          label: allQuestions[i].questiontitle,
          value: allQuestions[i].serial_no,
        })
      }
      //}
    }
    setQuestionList(questions);
    setsetList(get_array(sets?.length-set_value)?.map((li,li_index)=>{
      return {
        label:"Set "+(parseInt(set_value)+1+li_index),
        value:(parseInt(set_value)+1+li_index)
      }
    }))

    if (selectedQuestionIndex != index) {
      // setSelectedQuestionIndex(index);
      setselectedQuestionIndex(index)
    } else {
      setselectedQuestionIndex(null)
    }
  }

  const handelSelectedQuestion = (ind, val, serial_no) => {
    setquestion_arr(
      allQuestions.map((el) => {
        if (el.serial_no == item?.serial_no) {
          return {
            ...el,
            Option: el.Option?.map((li, index) => {
              if (index == ind) {
                return {
                  ...li,
                  switch_to: val,
                  dependent_question:val,
                  switch_to_set:0
                }
              } else {
                return {
                  ...li,
                }
              }
            }),
          }
        } else {
          return el
        }
      }),
    )
  };

  const handelSelectedSet = (ind, val, serial_no) => {
    setquestion_arr(
      allQuestions.map((el) => {
        if (el.serial_no == item?.serial_no) {
          return {
            ...el,
            Option: el.Option?.map((li, index) => {
              if (index == ind) {
                return {
                  ...li,
                  switch_to_set: val,
                  switch_to:0,
                  dependent_question:0
                }
              } else {
                return {
                  ...li,
                }
              }
            }),
          }
        } else {
          return el
        }
      }),
    )
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <Flex_box
          style={{
            marginTop: index == 0 ? "20px" : "30px",
            width: "98%",
            gap: 10,
          }}
        >
          <Text_Input
            label={"Question " + (index + 1)}
            cont_style={{
              width: "98%",
            }}
            value={item?.questiontitle}
            onChange={(e) => {
              e.preventDefault()
            }}
          />
          {item?.questiontype == 3 && (
            <div
              className="checkbox"
              style={{ margin: "0 10px", position: "relative", top: "8px" }}
            >
              <label htmlFor="" style={{ display: "flex" }}>
                <input
                  type="checkbox"
                  checked={selectedQuestionIndex == index}
                  onClick={(e) => handelChedkedBox(e, item.serial_no, index)}
                />
                <span style={{ marginLeft: "5px" }}>Routing</span>
              </label>
            </div>
          )}
        </Flex_box>
        <Flex_box
          style={{
            marginTop: index == 0 ? "20px" : "30px",
            width: "98%",
            gap: 10,
            flexWrap: "wrap",
            justifyContent:"start"
          }}
        >
          <Text_Input
            label={"Hint"}
            cont_style={{
              width: "98%",
            }}
            value={item?.sub_text}
            onChange={(e) => {
              e.preventDefault()
            }}
          />
          {get_array(item?.max_rating)?.length > 0 &&
            get_array(item?.max_rating)?.map((el, ind) => {
              return (
                <div
                  onClick={() => {}}
                  style={{
                    borderRadius: "50%",
                    border: "1px solid #696969",
                    color: "#000",
                    fontWeight: 600,
                    width: "40px",
                    cursor: "pointer",
                  }}
                >
                  <Flex_box
                    style={{
                      height: "35px",
                    }}
                  >
                    {el}
                  </Flex_box>
                </div>
              )
            })}
        </Flex_box>
      </div>
      {item?.Option?.length > 0 && (
        <>
          <span
            style={{
              fontSize: "10px",
              marginLeft: "10px",
              marginTop: "15px",
            }}
          >
            Select Correct Answer Below
          </span>

          {item?.Option?.map((li, ind) => {
            return (
              <>
                <div className="dropdown_wrap"
                style={{
                  width:"100%"
                }}
                >
                  <Option_Box item={li} type={item.questiontype} key={ind} />
                  {selectedQuestionIndex == index && (
                    <div className="dropdown" style={{ marginLeft: "10px",display:"flex",gap:10}}>
                      <Select_box
                        options={questionList}
                        placeholder={"Switch to"}
                        //  value={questionList?.filter((elem)=>{return elem?.value == li.dependent_question})[0]}
                        value={li.dependent_question || ''}
                        onChange={(e) => {
                          console.log(e.target.value)
                          handelSelectedQuestion(ind, e.target.value, item.serial_no)
                          handelDependentQuestion(e.target.value, index, ind)
                        }}
                        input_style={{
                          height: "40px",
                        }}
                        cont_style={{
                          marginTop: "9px",
                        }}
                      />
                      <Select_box
                        options={setList}
                        placeholder={"Switch to Set"}
                        //  value={questionList?.filter((elem)=>{return elem?.value == li.dependent_question})[0]}
                        value={li.switch_to_set || ''}
                        onChange={(e) => {
                          console.log(e.target.value)
                          handelSelectedSet(ind, e.target.value, item.serial_no)
                          // handelDependentQuestion(e.target.value, index, ind)
                        }}
                        input_style={{
                          height: "40px",
                        }}
                        cont_style={{
                          marginTop: "9px",
                        }}
                      />
                    </div>
                  )}
                </div>
              </>
            )
          })}
        </>
      )}
    </>
  )
}

const MCQ_Box = ({ question_arr, setquestion_arr, handelDependentQuestion,sets,map_qs }) => {
  // console.log("all question", question_arr);
  // const handelDependentQuestion = (value) => {
  // alert(value);
  // }
  const [selectedQuestionIndex, setselectedQuestionIndex] = useState(null)

  return (
    <Flex_box
      // className="responsive_width_35"
      style={{
        padding: "15px",
        paddingTop: "0px",
        borderRadius: "8px",
        flexDirection: "column",
        // width: "",
        justifyContent: "start",
        alignItems: "start",
        boxShadow: "0px 0px 10px #0000000D",
      }}
    >
      {map_qs.map((el, index) => {
        console.warn(el?.questiontype)
        return (
          <>
            {/* {el?.questiontype} */}
            {el?.questiontype != 4 ? (
              <MCQ_Comp
                setquestion_arr={setquestion_arr}
                item={el}
                key={index}
                index={index}
                allQuestions={question_arr}
                handelDependentQuestion={handelDependentQuestion}
                selectedQuestionIndex={selectedQuestionIndex}
                setselectedQuestionIndex={setselectedQuestionIndex}
                set_value={el?.set}
                sets={sets}
              />
            ) : (
              <Sub_Option_MCQ_Box
                setquestion_arr={setquestion_arr}
                item={el}
                key={index}
                index={index}
                allQuestions={question_arr}
                handelDependentQuestion={handelDependentQuestion}
                selectedQuestionIndex={selectedQuestionIndex}
                setselectedQuestionIndex={setselectedQuestionIndex}
              />
            )}
          </>
        )
      })}
      {/* <Flex_box
                style={{
                    paddingTop: "20px"
                }}
            >
                <button
                    id='btn_comp'
                    style={{
                        // marginTop: "40px",
                        backgroundColor: theme_bg,
                        border: "2px solid " + theme_color,
                        color: theme_color,
                        fontWeight: 600,
                        padding: "8px",
                        width: "150px"
                    }}
                    onClick={() => {
                        // navigate("/select-study-type");
                        window.scrollTo({
                            top:0,
                            behavior:"smooth"
                        })
                    }}
                >
                    Add Question
                </button>
            </Flex_box> */}
    </Flex_box>
  )
}

export default MCQ_Box
