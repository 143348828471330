import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../../utilities/Layout";
import Flex_box from "../../../utilities/flex_box";
import Chat_Box from "../../Researcher_screens/My_Studies/GD_Chat/Chat_Box_";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { alert_action } from "../../../redux_prog/actions/base_action";

export default function Chats() {
  const state = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  

  const is_gd = state?.state?.is_gd;

  useEffect(() => {
    if (state?.state?.gd_data?.started) {
    } else {
      dispatch(
        alert_action({
          type: "error",
          title: "Not started",
          msg: "This group hasn't started yet!",
        })
      );

      navigate(-1);
    }
  }, []);

  if (state?.state?.gd_data?.started) {
    return (
      <>
        <Layout
          style={{
            padding: "20px",
          }}
        >
          <Flex_box
            className="removescrollbar"
            style={{
              flexWrap: "wrap",
              justifyContent: "start",
              gap: 20,
            }}
          >
            <Chat_Box is_gd={is_gd} width={"97%"} />
          </Flex_box>
        </Layout>
        {/* <HomePage /> */}
      </>
    );
  } else {
    return <></>;
  }
}
