import React from "react";
import Sidebar from "../../Nav_Comp/Sidebar";
import Flex_box from "../../../utilities/flex_box";
import Navbar from "../../Nav_Comp/Navbar";
import Footer from "../../Nav_Comp/Footer";
import Support_Chat from "../../Support_Chat";
import GD_comp from "./GD_comp";
import { theme_color } from "../../../utilities/colors";
import Layout from "../../../utilities/Layout";

const My_GDs = () => {
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {/* <Flex_box
        style={{
          justifyContent: "start",
        }}
      >
        <Sidebar />
        <div
          className="comp_container"
          style={{
            position: "relative",
          }}
        >
          <Navbar />
          <GD_comp />
          <Footer />
        </div>
      </Flex_box> */}
      <Layout>
        <GD_comp/>
      </Layout>
      <Support_Chat />
    </div>
  );
};

export default My_GDs;
