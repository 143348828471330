import React from "react";
import Flex_box from "./flex_box";

const FA_Icon_box = ({ className, style,onClick }) => {
  const style_obj = style || {};
  return (
    <Flex_box
      style={{
        // padding: "8px 7px",
        height:"30px",
        width:"30px",
        borderRadius: "8px",
        // backgroundColor:"pink",
        fontSize: "12px",
        color: "#fff",
        cursor:"pointer",
        ...style_obj,
      }}
      onClick={(e)=>{
        if(onClick){
            onClick(e);
        }
      }}
    >
      <i className={className}></i>
    </Flex_box>
  );
};

export default FA_Icon_box;
