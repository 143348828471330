import React, { useEffect, useState } from "react";
import Text_Input from "../../../../utilities/Text_Input";
import Select_box from "../../../../utilities/Select_box";
import Flex_box from "../../../../utilities/flex_box";
import { useLocation, useNavigate } from "react-router-dom";
import validate_object from "../../../../utilities/Validate_object";
import { useDispatch, useSelector } from "react-redux";
import { alert_action } from "../../../../redux_prog/actions/base_action";
import { create_study_api, update_study_api } from "../../../../apis/apis";
import Button from "../../../../utilities/Button";
import {
  get_sec_value,
  industry_arr,
  secValues,
  state_arr,
} from "../../../../utilities/select_arr";
import {
  Remove_obj_keys,
  add_obj_values,
  convertToArrayOfObjects,
  convertToObjectOfArrays,
} from "../../../../utilities/Remove_obj_keys";
import Radio_Box from "../../../../utilities/Radio_Box";
import {
  african_countries_and_states,
  get_subscription_data_count_and_kyc,
} from "../../../../utilities/utilities";
import TopUp_Modal from "../Study_Details/TopUp_Modal";
import { toast } from "react-toastify";
import { study_validation_keys } from "../../../../utilities/data_validation_keys";
import { countries } from "./countries";

const Create_study_comp = () => {
  const r_state = useLocation();
  const study_detail = r_state?.state?.study_detail;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [state_options, setstate_options] = useState([]);

  const [loading, setloading] = useState(false);
  const [modalVisible, setmodalVisible] = useState(false);
  const [study_data, setstudy_data] = useState(
    study_detail
      ? {
          ...convertToObjectOfArrays(study_detail),
          StudyOnInductry: study_detail.studyoninductry,
          studyname: study_detail.studyname,
          Description: study_detail.description,
          IncentiveType: "Smart coins",
          IncevtiveAmount: study_detail.incevtiveamount,
          TotalRespondents: study_detail.totalrespondents,
          close_date: study_detail.close_date?.split("T")[0] || "",
        }
      : {
          StudyOnInductry: "",
          studyname: "",
          Description: "",
          IncentiveType: "Smart coins",
          IncevtiveAmount: "",
          TotalRespondents: "",
          gender: "",
          age: "",
          close_date: "",
          // Location: "tedipuliya",
          SocioEconomicClassification: "",
          location: "",
          avail_for_non_reg: false,
        }
  );

  const upd_study_data = (key, val) => {
    setstudy_data({
      ...study_data,
      [key]: val,
    });
    Object.keys(study_data)?.map((el) => {
      const elem = document.querySelector("." + el);
      if (elem) {
        elem.style.border = "";
      }
    });
  };

  const {
    StudyOnInductry,
    studyname,
    Description,
    IncentiveType,
    IncevtiveAmount,
    TotalRespondents,
    gender,
    location,
    age,
    Gender,
    Age,
    Location,
    SocioEconomicClassification,
    close_date,
    avail_for_non_reg,
  } = study_data;

  const gender_overflow = add_obj_values(gender || {}) > TotalRespondents;
  const age_overflow = add_obj_values(age || {}) > TotalRespondents;
  const state_overflow = add_obj_values(location || {}) > TotalRespondents;
  const state_count = add_obj_values(location || {});
  const SocioEconomicClassification_overflow =
    add_obj_values(SocioEconomicClassification || {}) > TotalRespondents;

  const { user_data } = useSelector((state) => state?.authReducer);

  const create_study = async () => {
    const sub_data = await get_subscription_data_count_and_kyc(
      dispatch,
      user_data
    );
    if (sub_data?.study_flag) {
      dispatch(
        alert_action({
          type: "error",
          title: "Study limit exceeded!",
          msg: "Please extend your study limit!",
        })
      );
    } else if (sub_data?.respondent_flag) {
      dispatch(
        alert_action({
          type: "error",
          title: "Respondent limit exceeded!",
          msg: "Please extend your respondent limit!",
        })
      );
    } else if (sub_data?.kyc_flag) {
      dispatch(
        alert_action({
          type: "error",
          title: "KYC Unverified!",
          msg: "Your KYC documents are not verified yet!",
        })
      );
    } else if (sub_data?.coin_flag) {
      setmodalVisible(true);
    } else {
      setloading(true);
      create_study_api(
        convertToArrayOfObjects({
          ...study_data,
          UserId: user_data?._id,
        })
      )
        .then(async (res) => {
          if (res.data.status == 1) {
            setloading(false);
            await get_subscription_data_count_and_kyc(dispatch, user_data);
            dispatch(
              alert_action({
                type: "success",
                title: "Success",
                msg: "Study created successfully!",
              })
            );
            navigate("/select-study-type", {
              state: {
                studyid: res.data.Res?._id,
                studyname,
                sec: Object.keys(study_data?.SocioEconomicClassification),
              },
            });
          }
        })
        .catch((err) => {
          setloading(false);
          console.log(err);
          dispatch(
            alert_action({
              type: "error",
              title: "Error",
              msg: "Some error occurred!",
            })
          );
        });
    }
  };

  const update_study = () => {
    setloading(true);
    update_study_api(
      convertToArrayOfObjects({
        ...study_data,
        UserId: user_data?._id,
      })
    )
      .then((res) => {
        if (res.data.status == 1) {
          setloading(false);
          dispatch(
            alert_action({
              type: "success",
              title: "Success",
              msg: "Successfully updated study!",
            })
          );
          navigate("/my-surveys");
        }
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
        dispatch(
          alert_action({
            type: "error",
            title: "Error",
            msg: "Some error occurred!",
          })
        );
      });
  };

  const handleSubmit = () => {
    const validated = validate_object(study_data);
    const sub_limits = user_data?.user_sub_lookup[0]?.sub_lookup[0];
    if (IncevtiveAmount > sub_limits?.coin_limit) {
      dispatch(
        alert_action({
          type: "error",
          title: "Coin overlow!",
          msg:
            "You cannot give incentive more than " +
            sub_limits?.coin_limit +
            "!",
        })
      );
    } else {
      if (
        validated?.status == true ||
        validated?.key == "avail_for_non_reg" ||
        validated?.key == "isClosed" ||
        validated?.key == "iscomplete"
      ) {
        if (
          gender_overflow ||
          age_overflow ||
          state_overflow ||
          SocioEconomicClassification_overflow
        ) {
          dispatch(
            alert_action({
              type: "error",
              title: "Respondent Count Overflow",
              msg: "Please make sure sum of respondents according to their demography is not more than total respondents.",
            })
          );
        } else {
          if (study_detail?._id) {
            update_study();
          } else {
            create_study();
          }
        }
      } else {
        dispatch(
          alert_action({
            type: "error",
            title: "",
            msg:
              "Please enter " +
              study_validation_keys[validated?.key] +
              " field!",
          })
        );
        document.querySelector("." + validated?.key).style.border =
          "2px solid red";
      }
    }
  };

  const [a_c_s_arr, seta_c_s_arr] = useState([]);

  const c_arr_func = () => {
    for (let index = 0; index < countries.length; index++) {
      const element = countries[index];
      if (element) {
        const elem = element["African Countries and Their States/Regions"];
        if (elem?.split(".")[0] > 0) {
          seta_c_s_arr([
            ...a_c_s_arr,
            {
              country: elem,
            },
          ]);
        } else {
          seta_c_s_arr(
            a_c_s_arr?.map((li) => {
              if (li?.country == elem) {
                return {
                  ...li,
                  state: elem,
                };
              } else {
                return li;
              }
            })
          );
        }
      }
    }
  };

  return (
    <div
      id="create_study_cont"
      style={{
        padding: "20px",
        maxHeight: "72.5vh",
        overflowY: "auto",
      }}
    >
      <span
        style={{
          fontSize: "26px",
          fontStyle: "italic",
          fontWeight: "700",
        }}
        onClick={() => {
          c_arr_func();
        }}
      >
        Create Study
      </span>
      <div className="responsive_width_35">
        <Select_box
          label={"Study on industry"}
          options={industry_arr}
          cont_style={{
            marginTop: "30px",
          }}
          value={StudyOnInductry}
          onChange={(e) => {
            upd_study_data("StudyOnInductry", e.target.value);
          }}
          s_className="StudyOnInductry"
        />
        <Select_box
          label={"Available for non-registered users?"}
          options={[
            {
              label: "Yes",
              value: true,
            },
            {
              label: "No",
              value: false,
            },
          ]}
          cont_style={{
            marginTop: "20px",
          }}
          value={avail_for_non_reg}
          onChange={(e) => {
            upd_study_data("avail_for_non_reg", e.target.value);
          }}
          s_className="avail_for_non_reg"
        />
        <Text_Input
          label={"Study name"}
          input_style={
            {
              // width: "34.7vw"
            }
          }
          cont_style={{
            marginTop: "20px",
            width: "100%",
          }}
          value={studyname}
          onChange={(e) => {
            upd_study_data("studyname", e.target.value);
          }}
          s_className={"studyname"}
        />

        <Text_Input
          textArea={true}
          label={"Description"}
          placeholder={"Briefly describe what your study is about"}
          input_style={{
            // width: "34.7vw",
            height: "100px",
          }}
          cont_style={{
            marginTop: "20px",
          }}
          value={Description}
          onChange={(e) => {
            upd_study_data("Description", e.target.value);
          }}
          s_className={"Description"}
        />
        <Select_box
          label={"Incentive Type"}
          input_style={
            {
              // width: "36vw"
            }
          }
          options={[
            {
              label: "Smart coins",
              value: "Smart coins",
            },
          ]}
          cont_style={{
            marginTop: "30px",
          }}
          value={IncentiveType}
          onChange={(e) => {
            upd_study_data("IncentiveType", e.target.value);
          }}
          s_className={"IncentiveType"}
        />
        <Text_Input
          label={"Incentive Amount"}
          type={"number"}
          negative_less={true}
          cont_style={{
            marginTop: "20px",
          }}
          value={IncevtiveAmount}
          onChange={(e) => {
            upd_study_data("IncevtiveAmount", e.target.value);
          }}
          onFocus={(e) => e.target.select()}
          s_className={"IncevtiveAmount"}
        />
        <Text_Input
          label={"Close Date"}
          type={"date"}
          cont_style={{
            marginTop: "20px",
          }}
          value={close_date}
          onChange={(e) => {
            if (new Date(e.target.value) >= new Date()) {
              upd_study_data("close_date", e.target.value);
            } else {
              dispatch(
                alert_action({
                  type: "error",
                  title: "Wrong Date",
                  msg: "Please enter date greater than today!",
                })
              );
            }
          }}
          s_className="close_date"
        />
      </div>
      <div
        style={{
          marginTop: "20px",
          lineHeight: 1.5,
        }}
      >
        <b>What kind of respondents do you need</b>
        <br />
        <span style={{ fontSize: "14px", fontWeight: 400 }}>
          Use the fields below to select the number of respondents you need for
          the study
        </span>
      </div>
      <Text_Input
        horizontalAlign={true}
        label={"Total Respondents"}
        type={"number"}
        negative_less={true}
        input_style={{
          width: "50px",
        }}
        no_placeholder={true}
        cont_style={{
          marginTop: "20px",
        }}
        value={TotalRespondents}
        onChange={(e) => {
          upd_study_data("TotalRespondents", e.target.value);
        }}
        onFocus={(e) => e.target.select()}
        s_className="TotalRespondents"
      />

      <p style={{ fontSize: "14px", fontWeight: "700", marginTop: "20px" }}>
        {"Locations"}
      </p>
      <Select_box
        label={"Country"}
        input_style={
          {
            // width: "36vw"
          }
        }
        options={african_countries_and_states?.map((el) => {
          return {
            ...el,
            label: el?.country,
            value: el?.country,
          };
        })}
        cont_style={{
          marginTop: "30px",
        }}
        // value={IncentiveType}
        onChange={(e) => {
          setstate_options(
            african_countries_and_states
              ?.filter((li) => {
                return li.country == e.target.value;
              })[0]
              ?.states?.map((el) => {
                return {
                  label: el,
                  value: el,
                };
              })
          );
        }}
        s_className={"countries"}
      />
      <Flex_box
        style={{
          gap: 10,
          justifyContent: "start",
          flexWrap: "wrap",
          marginTop: "10px",
          // marginTop: "-25px",
        }}
      >
        {state_options?.length > 0 &&
          state_options.map((el, index) => {
            return (
              <>
                <Text_Input
                  horizontalAlign={true}
                  type={"number"}
                  negative_less={true}
                  label={el?.label}
                  input_style={{
                    width: "50px",
                    border: state_overflow ? "2px solid red" : "",
                  }}
                  cont_style={{
                    width:"200px"
                  }}
                  no_placeholder={true}
                  label_right_align={true}
                  value={study_data?.location[el.label] || 0}
                  onChange={(e) => {
                    upd_study_data("location", {
                      ...study_data?.location,
                      [el.label]: e.target.value,
                    });
                  }}
                  onFocus={(e) => e.target.select()}
                  s_className="location"
                />
              </>
            );
          })}
      </Flex_box>

      <p style={{ fontSize: "14px", fontWeight: "700", marginTop: "20px" }}>
        {"Gender"}
      </p>
      <Flex_box
        style={{
          justifyContent: "start",
          marginTop: "20px",
          gap: 50,
        }}
      >
        <Text_Input
          horizontalAlign={true}
          type={"number"}
          negative_less={true}
          label={"Male"}
          input_style={{
            width: "50px",
            border: gender_overflow ? "2px solid red" : "",
          }}
          no_placeholder={true}
          label_right_align={true}
          value={study_data?.gender?.Male || 0}
          onChange={(e) => {
            setstudy_data({
              ...study_data,
              gender: {
                ...study_data?.gender,
                Male: e.target.value,
              },
            });
          }}
          onFocus={(e) => e.target.select()}
          s_className="gender"
        />
        <Text_Input
          horizontalAlign={true}
          label={"Female"}
          type={"number"}
          negative_less={true}
          input_style={{
            width: "50px",
            border: gender_overflow ? "2px solid red" : "",
          }}
          no_placeholder={true}
          label_right_align={true}
          value={study_data?.gender?.Female || 0}
          onChange={(e) => {
            setstudy_data({
              ...study_data,
              gender: {
                ...study_data?.gender,
                Female: e.target.value,
              },
            });
          }}
          onFocus={(e) => e.target.select()}
          s_className="gender"
        />
      </Flex_box>
      <p style={{ fontSize: "14px", fontWeight: "700", marginTop: "20px" }}>
        {"Age: (Multiple Answer)"}
      </p>
      {/* <Text_Input
        horizontalAlign={true}
        label={"Younger than 18 years old"}
        type={"number"}
        negative_less={true}
        input_style={{
          width: "50px",
          border: age_overflow ? "2px solid red" : "",
        }}
        no_placeholder={true}
        cont_style={{
          marginTop: "5px",
        }}
        label_right_align={true}
        value={study_data?.age?.YoungerThan18yearSold || 0}
        onChange={(e) => {
          upd_study_data("age", {
            ...study_data?.age,
            YoungerThan18yearSold: e.target.value,
          });
        }}
        onFocus={(e) => e.target.select()}
        s_className="age"
      /> */}
      <Text_Input
        horizontalAlign={true}
        label={"18 – 24 years old"}
        type={"number"}
        negative_less={true}
        input_style={{
          width: "50px",
          border: age_overflow ? "2px solid red" : "",
        }}
        no_placeholder={true}
        cont_style={{
          marginTop: "15px",
        }}
        label_right_align={true}
        value={study_data?.age?.Old18_24years || 0}
        onChange={(e) => {
          upd_study_data("age", {
            ...study_data?.age,
            Old18_24years: e.target.value,
          });
        }}
        onFocus={(e) => e.target.select()}
        s_className="age"
      />
      <Text_Input
        horizontalAlign={true}
        label={"25 – 34 years old"}
        type={"number"}
        negative_less={true}
        input_style={{
          width: "50px",
          border: age_overflow ? "2px solid red" : "",
        }}
        no_placeholder={true}
        cont_style={{
          marginTop: "15px",
        }}
        label_right_align={true}
        value={study_data?.age?.Old25_34years || 0}
        onChange={(e) => {
          upd_study_data("age", {
            ...study_data?.age,
            Old25_34years: e.target.value,
          });
        }}
        onFocus={(e) => e.target.select()}
        s_className="age"
      />
      <Text_Input
        horizontalAlign={true}
        label={"35 – 55 years old"}
        type={"number"}
        negative_less={true}
        input_style={{
          width: "50px",
          border: age_overflow ? "2px solid red" : "",
        }}
        no_placeholder={true}
        cont_style={{
          marginTop: "15px",
        }}
        label_right_align={true}
        value={study_data?.age?.Old35_55years || 0}
        onChange={(e) => {
          upd_study_data("age", {
            ...study_data?.age,
            Old35_55years: e.target.value,
          });
        }}
        onFocus={(e) => e.target.select()}
        s_className="age"
      />
      <Text_Input
        horizontalAlign={true}
        label={"56 Years old and above"}
        type={"number"}
        negative_less={true}
        input_style={{
          width: "50px",
          border: age_overflow ? "2px solid red" : "",
        }}
        no_placeholder={true}
        cont_style={{
          marginTop: "15px",
        }}
        label_right_align={true}
        value={study_data?.age?.Old56years || 0}
        onChange={(e) => {
          upd_study_data("age", {
            ...study_data?.age,
            Old56years: e.target.value,
          });
        }}
        onFocus={(e) => e.target.select()}
        s_className="age"
      />
      <p style={{ fontSize: "14px", fontWeight: "700", marginTop: "20px" }}>
        {"Socio-Economic Classification"}
      </p>
      <Flex_box
        style={{
          gap: 10,
          justifyContent: "start",
          flexWrap: "wrap",
          marginTop: "-25px",
        }}
      >
        {secValues?.map((el, index) => {
          return (
            <Text_Input
              horizontalAlign={true}
              label={el.sec}
              type={"number"}
              negative_less={true}
              input_style={{
                width: "50px",
                border: SocioEconomicClassification_overflow
                  ? "2px solid red"
                  : "",
              }}
              no_placeholder={true}
              cont_style={{
                marginTop: "15px",
                width: "200px",
              }}
              label_right_align={true}
              value={study_data?.SocioEconomicClassification[el.sec] || 0}
              onChange={(e) => {
                upd_study_data("SocioEconomicClassification", {
                  ...study_data?.SocioEconomicClassification,
                  users: 0,
                  [el.sec]: e.target.value,
                });
              }}
              onFocus={(e) => e.target.select()}
              s_className="SocioEconomicClassification"
            />
          );
        })}
      </Flex_box>
      <Button
        id="btn_comp"
        style={{
          marginTop: "40px",
          marginBottom: "20px",
        }}
        onClick={handleSubmit}
        loading={loading}
        label={"Create Study"}
      />
      <TopUp_Modal
        modalVisible={modalVisible}
        setmodalVisible={setmodalVisible}
      />
    </div>
  );
};

export default Create_study_comp;
