import React from "react";
import Flex_box from "../../../utilities/flex_box";
import Progress_bar from "../../../utilities/Progress_bar";
import "./study_box.css";
import { useNavigate } from "react-router-dom";
import { get_industry_name } from "../../../utilities/select_arr";
import { Age_keys } from "../../../utilities/Age_keys";

const Study_Box = ({ progress, item }) => {
  const navigate = useNavigate();
  const is_gd = item?.type == "gd";
  const { gender, description, age, incevtiveamount } = item;

  const study_name = is_gd ? item?.groupdiscussionname : item?.studyname;
  const study_about = is_gd ? item?.discussionabout : item?.studyoninductry;

  const { study } = item || {};

  const age_reqs = study?.age
    ?.map((el) => {
      return Age_keys[el.age];
    })
    ?.toString();

  const gender_reqs = study?.gender
    ?.map((el) => {
      return el.gender;
    })
    ?.toString();

  const close_date = study.close_date?.split("T")[0];

  return (
    <div
      className="study_box_cont"
      style={{
        gap: 8,
      }}
    >
      <span
        style={{
          fontWeight: 700,
          fontSize: "24px",
        }}
      >
        {study?.studyname}
      </span>

      <span
        style={{
          fontSize: "15px",
        }}
      >
        <span style={{ fontWeight: 600 }}>Study on </span>:{" "}
        {get_industry_name(study?.studyoninductry)}
      </span>
      {
        //   !is_gd &&
        <>
          <span
            style={{
              fontSize: "15px",
            }}
          >
            <span style={{ fontWeight: 500 }}>{gender_reqs}</span>
          </span>
          <span
            style={{
              fontSize: "15px",
            }}
          >
            <span style={{ fontWeight: 500 }}>{age_reqs} Years Old</span>
          </span>
          <span
            style={{
              fontSize: "15px",
            }}
          >
            <span style={{ fontWeight: 600 }}>Close Date </span>: {close_date}
          </span>
        </>
      }
      {item?.type && (
        <span
          style={{
            fontSize: "15px",
          }}
        >
          <span style={{ fontWeight: 600 }}>Study type</span>:
          {is_gd ? " Group Discussion" : " Survey"}
        </span>
      )}
      {
        //   !is_gd &&
        <>
          <span
            style={{
              fontSize: "15px",
            }}
          >
            <span style={{ fontWeight: 600 }}>Incentive amount </span>: 
            {study?.incevtiveamount + " coins"}
          </span>
          <p
            style={{
              fontSize: "11px",
              lineHeight: 1.5,
              height: "80px",
              overflowY: "auto",
            }}
          >
            {study?.description}
          </p>
        </>
      }

      <Flex_box
        style={{
          width: "",
          gap: 25,
          justifyContent: "start",
          marginTop: "10px",
        }}
      >
        <button
          id="btn_comp"
          style={{
            fontSize: "16px",
            fontWeight: "500",
            opacity: item.type ? 1 : 0.6,
            cursor: item.type ? "pointer" : "default",
          }}
          onClick={() => {
            if (item.type) {
              navigate("/study-details", {
                state: {
                  type: !is_gd ? 1 : 2,
                  id: study?._id,
                },
              });
            }
          }}
        >
          View Details
        </button>
      </Flex_box>
    </div>
  );
};

export default Study_Box;
