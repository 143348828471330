import React, { useEffect, useState } from "react";
import Layout from "../../../utilities/Layout";
import Page_heading from "../../../utilities/Page_heading";
import DataTable from "../../../utilities/DataTable";
import { get_voucher_history_api } from "../../../apis/apis";
import { useSelector } from "react-redux";

const Voucher_history = () => {
  const { user_data } = useSelector((State) => State?.authReducer);
  const [voucher_arr, setvoucher_arr] = useState([]);

  const t_heads = [
    {
      label: "S No.",
      accessor: "index",
      element: (el, index) => {
        return (
          <div
            style={{
              width: "80px",
            }}
          >
            <span>{index + 1}</span>
          </div>
        );
      },
    },
    {
      label: "Transaction Id",
      accessor: "transaction_id",
    },
    {
      label: "Product",
      accessor: "product_name",
    },
    {
      label: "Amount",
      accessor: "amount",
    },
    {
      label: "Date",
      accessor: "transaction_date",
    },
    {
      label: "Status",
      accessor: "status",
      tr_style: {
        minWidth: "120px",
      },
      element: (el) => {
        return (
          <span
            style={{
              color: el?.status == "delivered" ? "green" : "black",
            }}
          >
            {el?.status == "delivered" ? "Delivered" : el?.status}
          </span>
        );
      },
    },
  ];

  const get_voucher_history = () => {
    get_voucher_history_api({ user_id: user_data?._id })
      .then((res) => {
        setvoucher_arr(res?.data?.res);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  useEffect(get_voucher_history, []);

  return (
    <Layout
      style={{
        padding: "10px",
      }}
    >
      <Page_heading label={"Voucher Purchase History"} />
      {voucher_arr?.length > 0 && (
        <DataTable
          style={{
            marginTop: "10px",
          }}
          fontSize={"14px"}
          heads={t_heads}
          rows={voucher_arr}
        />
      )}
    </Layout>
  );
};

export default Voucher_history;
