import React, { useState } from "react";
import Flex_box from "../../../utilities/flex_box";
import contact_bg from "../../../assets/Path37581.png";
import bg2 from "../../../assets/Group 48291.svg";
import settings_icon from "../../../assets/Group 48324.svg";
import twofour_icon from "../../../assets/Group 48320.svg";
import man_icon from "../../../assets/Group 48343.svg";
import woman_icon from "../../../assets/Group 48312.svg";
import line_icon from "../../../assets/Group 2007.svg";
import arrow_icon from "../../../assets/Group 2194.svg";
import Text_Input from "../../../utilities/Text_Input";
import Select_box from "../../../utilities/Select_box";
import { margin, padding, width } from "@mui/system";
import Button from "../../../utilities/Button";
import { useMediaQuery } from "@mui/material";
import { add_contact_msg } from "../../../utilities/utilities";
import { useDispatch } from "react-redux";
import validate_object from "../../../utilities/Validate_object";

const Contact_Form = () => {
  // const max_w_850 = useMediaQuery("(max-width:850px)");
  const dispatch = useDispatch();
  const [contact_data, setcontact_data] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [loading, setloading] = useState(false);

  const { name, email, phone, subject, message } = contact_data;

  const upd_contact_data = (val_obj) => {
    setcontact_data({
      ...contact_data,
      ...val_obj,
    });
    Object.keys(contact_data)?.map((el) => {
      document.querySelector("#" + el).style.border = "";
    });
  };

  const handleSubmit = async () => {
    if (!loading) {
      const validated_obj = await validate_object(contact_data);
      if (validated_obj?.status) {
        await add_contact_msg(
          contact_data,
          setloading,
          () => {
            setcontact_data({
              name: "",
              email: "",
              phone: "",
              subject: "",
              message: "",
            });
          },
          dispatch
        );
      } else {
        document.querySelector("#" + validated_obj?.key).style.border =
          "2px solid red";
      }
    }
  };

  return (
    <>
      <section class="contact-sec">
        <div class="container">
          <div class="banner">
            <div class="row justify-content-center align-items-center mb-5">
              <div class="col-lg-8 order-2 mb-3">
                <form class="contactForm ">
                  <div class="section-title">
                    <h2>Get In Touch</h2>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 mb-3">
                      <div class="form-group">
                        <input
                          value={name}
                          onChange={(e) => {
                            upd_contact_data({
                              name: e.target.value,
                            });
                          }}
                          type="text"
                          name="txt_name"
                          id="name"
                          class="form-control"
                          placeholder="Name*"
                          required=""
                          pattern="[a-zA-Z]{''}*"
                          maxlength="20"
                          minlength="3"
                        />
                      </div>
                    </div>

                    <div class="col-lg-6 mb-3">
                      <div class="form-group">
                        <input
                          value={email}
                          onChange={(e) => {
                            upd_contact_data({
                              email: e.target.value,
                            });
                          }}
                          type="email"
                          name="txt_email"
                          id="email"
                          class="form-control"
                          placeholder="Email"
                          required=""
                        />
                      </div>
                    </div>

                    <div class="col-lg-6 mb-3">
                      <div class="form-group">
                        <input
                          value={phone}
                          onChange={(e) => {
                            upd_contact_data({
                              phone: e.target.value,
                            });
                          }}
                          type="number"
                          name="txt_contact_number"
                          id="phone"
                          placeholder="Phone"
                          required=""
                          maxlength="10"
                          minlength="10"
                          pattern="[0-9]{10}"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div class="col-lg-6 mb-3">
                      <div class="form-group has-error">
                        <input
                          value={subject}
                          onChange={(e) => {
                            upd_contact_data({
                              subject: e.target.value,
                            });
                          }}
                          type="text"
                          name="txt_subject"
                          id="subject"
                          class="form-control"
                          placeholder="Subject"
                          required=""
                          pattern="[a-zA-Z]*"
                        />
                      </div>
                    </div>

                    <div class="col-lg-12 mb-3">
                      <div class="form-group">
                        <textarea
                          value={message}
                          onChange={(e) => {
                            upd_contact_data({
                              message: e.target.value,
                            });
                          }}
                          name="txt_message"
                          class="form-control"
                          id="message"
                          cols="30"
                          rows="8"
                          placeholder="Write message"
                          required=""
                          pattern="[a-zA-Z]*"
                        ></textarea>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <a
                        type="submit"
                        class="default-btn"
                        onClick={handleSubmit}
                        style={{
                          opacity: loading ? 0.5 : 1,
                        }}
                      >
                        Send Message
                      </a>
                    </div>
                  </div>
                </form>
              </div>

              <div class="col-lg-4 order-1 mb-3">
                <img
                  src="assets/images/contact1.png"
                  width="280px"
                  class="mb-3 d-none d-lg-block"
                />
                <h4 class="mb-3 text-primary">Get In Touch with Our Experts</h4>
                <p class="mb-3">
                  Want find out how Yaarnbox can help your organization? We'd
                  love to hear about from you.
                </p>
                <h6 class="mb-3 text-primary">Integrate towards innovation</h6>
                <p>
                  Become a Yaarnbox partner to Launch, Run and Grow your
                  business globally.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Flex_box
      className="auth_box_cont"
      style={{
        flexDirection: "column",
        backgroundImage: `url(${contact_bg})`,
        padding: "40px 0px",
        position: "relative",
      }}
    >
      <img
        src={bg2}
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 50,
          left: 0,
          zIndex: 100,
        }}
        className="hover_img"
      />
      <img
        src={settings_icon}
        style={{
          height: "150px",
          position: "absolute",
          top: 100,
          left: 150,
        }}
        className="hover_img"
      />
      <img
        src={twofour_icon}
        style={{
          height: "80px",
          position: "absolute",
          top: 50,
          right: 150,
        }}
        className="hover_img"
      />
      {!max_w_850 && (
        <img
          src={man_icon}
          style={{
            height: "200px",
            position: "absolute",
            bottom: 50,
            left: "12%",
            zIndex: 102,
          }}
          className="hover_img"
        />
      )}
      <img
        src={woman_icon}
        style={{
          height: "170px",
          position: "absolute",
          bottom: 50,
          right: "5%",
        }}
        className="hover_img"
      />
      <img
        src={line_icon}
        style={{
          height: "200px",
          position: "absolute",
          top: "40%",
          right: "6.5%",
        }}
        className="hover_img"
      />
      <img
        src={arrow_icon}
        style={{
          height: "50px",
          position: "absolute",
          top: "38%",
          right: "3.5%",
        }}
        className="hover_img"
      />

      <span className="intro_heading_text" style={{ fontWeight: "400" }}>
        Got Questions?
      </span>
      <b className="intro_heading_text" style={{ fontStyle: "italic" }}>
        Let’s get in touch
      </b>

      <Flex_box
        style={{
          flexDirection: "column",
          width: "60%",
          padding: "20px 20px 35px 20px",
          borderRadius: "10px",
          backgroundColor: "white",
          zIndex: 101,
          marginTop: max_w_850 ? "10px" : "40px",
        }}
      >
        <Flex_box
          style={{
            justifyContent: "space-between",
          }}
        >
          <Text_Input
            label={"First Name"}
            cont_style={{
              width: "49%",
            }}
            
            onChange={(e) => {}}
          />
          <Text_Input
            label={"Last Name"}
            cont_style={{
              width: "49%",
            }}
            onChange={(e) => {}}
          />
        </Flex_box>
        <Text_Input
          label={"Your Email Address"}
          cont_style={{
            marginTop: "30px",
            width: "100%",
          }}
          onChange={(e) => {}}
        />
        <Select_box
          cont_style={{
            marginTop: "30px",
            width: "100%",
          }}
          input_style={{
            width: "100%",
          }}
          select_cont_style={{
            width: "100%",
          }}
          label={"Which related problem are you facing?"}
          // value={ConductIndustryStudiesIn}
          onChange={(e) => {}}
        />
        <Text_Input
          label={"Type your message"}
          textArea={true}
          input_style={{
            height: "60px",
          }}
          cont_style={{
            marginTop: "30px",
            width: "100%",
          }}
          onChange={(e) => {}}
        />
        <Button
          label={"Submit"}
          style={{
            marginTop: "40px",
          }}
        />
      </Flex_box>
    </Flex_box> */}
    </>
  );
};

export default Contact_Form;
