import React, { useEffect, useState } from "react";
import Layout from "../../../../utilities/Layout";
import Flex_box from "../../../../utilities/flex_box";
import Page_heading from "../../../../utilities/Page_heading";
import share_icon from "../../../../assets/Group 68128.svg";
import pdf_icon from "../../../../assets/Group 68130.svg";
import excel_icon from "../../../../assets/Group 68129.svg";
import Graph_Comp from "./Graph_Comp";
import Question_analysis from "./Question_analysis";
import {
  get_input_answers_api,
  get_study_demographics_data_api,
  get_survey_analysis_api,
  get_survey_user_resp_analysis_data_api,
} from "../../../../apis/apis";
import { useLocation } from "react-router-dom";
import { sum_of_array_elem } from "../../../../utilities/Add_array_elem";
import { useDispatch } from "react-redux";
import { loading_action } from "../../../../redux_prog/actions/base_action";
import Print_Report from "../Print_Report";
import Excel_Btn from "./Excel_Btn";
import { Age_keys } from "../../../../utilities/Age_keys";
import {
  positive_neagtive_words,
  wordCount,
} from "../../../../utilities/utilities";
import Sentiments_Graph from "./Sentiments_Graph";
import WordCloud from "react-wordcloud";

const Survey_Analysis = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { study_id, studyname } = state;

  const [study_analysis_data, setstudy_analysis_data] = useState({});
  const [excel_data, setexcel_data] = useState([]);
  const [demographic_data, setdemographic_data] = useState({});
  const [words, setwords] = useState([]);
  const [sentiment_words, setsentiment_words] = useState([]);
  const [words_obj, setwords_obj] = useState({});

  function transformData(data, key) {
    return Object.values(
      data.reduce((acc, item) => {
        const label = item[key];
        acc[label] = acc[label] || { label, y: 0 };
        acc[label].y += 1;
        return acc;
      }, {})
    );
  }

  const get_demographics_data = () => {
    get_study_demographics_data_api(study_id)
      .then((res) => {
        const data = res.data.demographic_data;

        const genderData = transformData(data, "gender");
        const ageData = transformData(data, "age")?.map((el) => {
          return {
            label: Age_keys[el?.label],
            y: el?.y,
          };
        });

        const locationData = transformData(data, "location");

        setdemographic_data({
          genderData,
          ageData,
          locationData,
        });
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const get_user_resp_analysis = () => {
    get_survey_user_resp_analysis_data_api(study_id)
      .then((res) => {
        const res_arr = res.data.user_resp?.map((el, index) => {
          const s_res_arr = el?.response;
          return {
            user_data: el?.users_data,
            res_arr: s_res_arr
              ?.map((li, ind) => {
                const q_type = li.question?.questiontype;
                if (q_type == 1) {
                  return li.inp_ans[0];
                } else if (q_type == 2 || q_type == 3) {
                  return li.options?.map((elem) => {
                    return elem.answer[0];
                  });
                } else {
                  return li.options
                    ?.map((elem) => {
                      return elem.subansw?.map((elemen) => {
                        return elemen.answer[0];
                      });
                    })
                    .flat();
                }
              })
              .flat()
              ?.filter((el) => {
                return el;
              }),
          };
        });

        const q_arr = res.data.question_res
          ?.map((el, index) => {
            const i = index + 1;

            if (el?.questiontype == 1) {
              return {
                _id: el?._id,
                // name: "q_" + i,
                name: "Q~" + i + " " + el?.questiontitle,
                answer: null,
                questiontype: 1,
              };
            } else if (el?.questiontype == 5) {
              return {
                _id: el?._id,
                // name: "q_" + i,
                name: "Q~" + i + " " + el?.questiontitle,
                answer: null,
                questiontype: 5,
              };
            } else if (el?.questiontype == 2) {
              return el?.multiple_options_lookup?.map((li, ind) => {
                const m_ind = ind + 1;
                return {
                  _id: li?._id,
                  // name: "q_" + i + "_" + m_ind,
                  name: "Q~" + i + "~" + m_ind + " " + li.optiontitle,
                  answer: null,
                  questiontype: 2,
                };
              });
            } else if (el?.questiontype == 3) {
              return el?.options_lookup?.map((li, ind) => {
                const o_ind = ind + 1;
                return {
                  _id: li?._id,
                  // name: "q_" + i + "_" + o_ind,
                  name: "Q~" + i + "~" + o_ind + " " + li.optiontitle,
                  answer: null,
                  questiontype: 3,
                };
              });
            } else {
              return el?.sub_question_lookup
                ?.map((li, ind) => {
                  const s_ind = ind + 1;
                  return li?.sub_options_lookup?.map((elem, inde) => {
                    const so_ind = inde + 1;
                    return {
                      _id: elem?._id,
                      // name: "q_" + i + "_" + s_ind + "_" + so_ind,
                      name:
                        "Q~" +
                        i +
                        "~" +
                        s_ind +
                        "~" +
                        so_ind +
                        " " +
                        elem.optiontitle,
                      answer: null,
                      questiontype: 4,
                    };
                  });
                })
                ?.flat();
            }
          })
          ?.flat();

        const exc_data = res_arr?.map((el) => {
          const res_obj = q_arr
            ?.map((li) => {
              const r_obj = el?.res_arr?.filter((elem) => {
                return li?.questiontype != 1
                  ? elem?.inputoptionid == li?._id
                  : elem?.questionid == li?._id;
              })[0];
              return {
                [li.name]: r_obj?.answer || null,
              };
            })
            .reduce((acc, item) => {
              return { ...acc, ...item };
            }, {});

          return {
            name: el.user_data?.firstname + " " + el.user_data?.lastname,
            ...res_obj,
          };
        });

        setexcel_data(exc_data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const get_surveyanalysis = () => {
    dispatch(loading_action(true));
    get_survey_analysis_api(study_id)
      .then((res) => {
        dispatch(loading_action(false));
        const analysis_data = res.data.Res.map((el) => {
          if (el?.question?.questiontype == 4) {
            return {
              questiontitle: el?.question?.questiontitle,
              sub_questions: el?.options?.map((li) => {
                return {
                  subquestiontitle: li?.subquestion?.questiontitle,
                  total_answers: sum_of_array_elem(li.subansw, "attempt"),
                  graph_data: li?.subansw?.map((elem) => {
                    return {
                      label: elem?.suboption?.optiontitle,
                      y: parseInt(elem?.attempt),
                    };
                  }),
                };
              }),
            };
          } else {
            return {
              questiontitle: el?.question?.questiontitle,
              inp_ans: el?.inp_ans,
              total_answers: sum_of_array_elem(el.options, "attempt"),
              graph_data: el?.options.map((li) => {
                return {
                  label: li?.option?.optiontitle,
                  y: parseInt(li?.attempt),
                };
              }),
            };
          }
        });

        console.warn({ analysis_data });

        setstudy_analysis_data(analysis_data);
      })
      .catch((err) => {
        dispatch(loading_action(false));
        console.log(err);
      });
  };

  const get_input_answers = () => {
    get_input_answers_api(study_id)
      .then((res) => {
        // console.warn(res.data);
        const inp_arr = res.data.res
          ?.map((el) => {
            return el?.answer?.toLowerCase();
          })
          .toString()
          ?.replace(",", "");
        const word_count_obj = wordCount(inp_arr);
        setwords_obj(word_count_obj);
        const word_count_arr = Object.keys(word_count_obj)
          .filter((el) => {
            return word_count_obj[el] > 0;
          })
          .map((el) => {
            return { text: el, value: word_count_obj[el] };
          })
          ?.sort((x, y) => {
            return y.value - x.value;
          });

        setwords(word_count_arr?.slice(0, 20));
        setsentiment_words(word_count_arr);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    get_surveyanalysis();
    get_user_resp_analysis();
    get_demographics_data();
    get_input_answers();
  }, []);

  const w_arr = sentiment_words?.map((li) => {
    return li.text;
  });

  const p_sentiments_arr =
    sentiment_words?.length > 0
      ? positive_neagtive_words.positives
          ?.filter((el) => {
            return w_arr?.includes(el?.toLowerCase());
          })
          ?.map((el, ind) => {
            return {
              // text:el,value:words_obj[el?.toLowerCase()],
              label: el,
              x: ind + 1,
              y: words_obj[el?.toLowerCase()],
            };
          })
      : [];

  const n_sentiments_arr =
    sentiment_words?.length > 0
      ? positive_neagtive_words.negatives
          ?.filter((el) => {
            return w_arr?.includes(el?.toLowerCase());
          })
          ?.map((el, ind) => {
            return {
              // text:el,value:words_obj[el?.toLowerCase()]
              label: el,
              x: ind + 1,
              y: words_obj[el?.toLowerCase()],
            };
          })
      : [];

  const neutral_sentiments_arr =
    sentiment_words?.length > 0
      ? words
          ?.filter((el) => {
            return (
              !positive_neagtive_words?.positives
                ?.map((li) => {
                  return JSON.stringify(li).toLowerCase();
                })
                .includes(JSON.stringify(el?.text)?.toLowerCase()) &&
              !positive_neagtive_words?.negatives
                ?.map((li) => {
                  return JSON.stringify(li).toLowerCase();
                })
                .includes(JSON.stringify(el?.text)?.toLowerCase())
            );
          })
          ?.map((el, ind) => {
            return {
              // text:el,value:words_obj[el?.toLowerCase()]
              label: el?.text,
              x: ind + 1,
              y: el?.value,
            };
          })
      : [];

  const options = {
    rotations: 2,
    rotationAngles: [-90, 0],
    fontSizes: [10, 60],
    fontFamily: "sans-serif",
    colors: ["#00aaff", "#ff0066", "#ffaa00", "#00ffaa", "#aa00ff"],
  };

  return (
    <Layout
      style={{
        padding: "20px",
      }}
    >
      <Flex_box
        style={{
          justifyContent: "space-between",
        }}
      >
        <Page_heading label={studyname + ` Survey's Analysis`} />
        <Flex_box
          style={{
            width: "",
            padding: "0px",
          }}
        >
          <img
            src={share_icon}
            style={{
              height: "50px",
            }}
          />
          {/* <img
            src={excel_icon}
            style={{
              height: "50px",
              cursor:"pointer"
            }}
            onClick={()=>{
              console.warn({
                study_analysis_data
              });
              
            }}
          /> */}
          <Excel_Btn
            study_analysis_data={study_analysis_data}
            excel_data={excel_data}
          />
          <Print_Report
            elem={
              <img
                src={pdf_icon}
                style={{
                  height: "50px",
                }}
              />
            }
            studyname={studyname}
            study_id={study_id}
          />
        </Flex_box>
      </Flex_box>
      <Flex_box
        style={{
          justifyContent: "start",
          gap: 10,
          marginBottom: 10,
          marginTop: 10,
        }}
      >
        <div
          // ref={containerRef}
          className="responsive_width_35 study_graph_box_cont"
          style={{ height: 400 }}
        >
          {words?.length > 0 && (
            <>
              <Flex_box>
                <b
                  style={{
                    fontSize: "23px",
                    color: "#000",
                  }}
                >
                  Word Cloud
                </b>
              </Flex_box>
              <div
                style={{
                  height: 340,
                  width: "100%",
                }}
              >
                <WordCloud words={words} options={{ ...options }} />
              </div>
            </>
          )}
        </div>
        <div
          className="responsive_width_35 study_graph_box_cont"
          style={{ height: 400 }}
        >
          <Sentiments_Graph
            p_sentiments_arr={p_sentiments_arr}
            n_sentiments_arr={n_sentiments_arr}
            neutral_sentiments_arr={neutral_sentiments_arr}
          />
        </div>
      </Flex_box>
      <Flex_box
        style={{
          justifyContent: "start",
          gap: 10,
        }}
      >
        <Graph_Comp
          graph_data={demographic_data?.genderData}
          type={"doughnut"}
          label={"Gender"}
        />
        <Graph_Comp
          graph_data={demographic_data?.locationData}
          type={"column"}
          label={"Location"}
        />
        <Graph_Comp
          graph_data={demographic_data?.ageData}
          type={"area"}
          label={"Age"}
        />
      </Flex_box>
      {study_analysis_data?.length > 0 &&
        study_analysis_data?.map((el, index) => {
          return <Question_analysis key={index} index={index} item={el} />;
        })}
    </Layout>
  );
};

export default Survey_Analysis;
