import React, { useEffect, useState } from "react";
import logo from "../../../assets/Group 40944@2x.png";
import { theme_bg, theme_color } from "../../../utilities/colors";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import logo_white from "../../../assets/Group 40944@2x.png";
import Flex_box from "../../../utilities/flex_box";
import down_arrow from "../../../assets/down_arrow_white.png";
import { Modal } from "../../../utilities/Modal";
import Pre_login from "../../Auth_Screens/Pre_login";
import Button from "../../../utilities/Button";
import landing_bg from "../../../assets/Path1.png";
import landing_mask from "../../../assets/Mask Group 1.svg";
import right_mask from "../../../assets/Group 47599.svg";
import welcome_icon from "../../../assets/Group 47601.png";
import zIndex from "@mui/material/styles/zIndex";
import Welcome_Comp from "./Welcome_Comp";
import Key_Features from "./Key_Features";
// import Contact_Form from "./Contact_Form";
import Contact_Form from "./Contact_Form";
import Why_SmartQ from "./Why_SmartQ";
import Subscriptions from "./Subscriptions";
import Footer from "../Nav_Comp/Footer";
import Nav_Comp from "../Nav_Comp";
import Other_Features from "./Other_Features";
import FadeInSection from "../../../utilities/FadeInSection";
import Carousal_Box from "../../../utilities/Carousal_Box";
import {
  get_landing_page_data_api,
  get_subscriptions_api,
  image_url,
} from "../../../apis/apis";
import FA_Icon_box from "../../../utilities/FA_Icon_box";
import Accordion from "../../../utilities/Accordian.js";

const Landing_Screen = () => {
  const [isOpen, setisOpen] = useState(false);

  const open_modal = () => {
    setisOpen(true);
  };

  const close_modal = () => {
    setisOpen(false);
  };

  const [scrollTop, setscrollTop] = useState(0);

  const [sub_arr, setsub_arr] = useState([]);
  const [content_arr, setcontent_arr] = useState([]);
  const get_content = () => {
    get_landing_page_data_api({ page: 1 })
      .then((res) => {
        setcontent_arr(res.data.content_res || []);
        console.warn(
          res.data.content_res[3].points_arr?.map((el) => {
            return {
              title: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: el?.split("<p>/n</p>")[0],
                  }}
                ></div>
              ),
              content: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: el?.split("<p>/n</p>")[1],
                  }}
                ></div>
              ),
              open: false,
            };
          })
        );
      })
      .catch((err) => {
        console.warn(err);
      });
  };
  const getSubscriptions = () => {
    get_subscriptions_api()
      .then((res) => {
        setsub_arr(res.data.Res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getSubscriptions();
    get_content();
  }, []);

  const Pic_1 = ({ style }) => {
    return (
      <img
        style={{
          height: "40px",
          width: "70px",
          position: "absolute",
          ...style,
        }}
        src="assets/images/b9d24648-tmplw-wpdev-saas-2694135-5122x.png"
      />
    );
  };

  const Pic_2 = ({ style }) => {
    return (
      <img
        style={{
          height: "40px",
          width: "70px",
          position: "absolute",
          ...style,
        }}
        src="assets/images/352d9fbe-tmplw-wpdev-saas-avtar2x.png"
      />
    );
  };

  const Pic_3 = ({ style }) => {
    return (
      <img
        style={{
          height: "40px",
          width: "70px",
          position: "absolute",
          ...style,
        }}
        src="assets/images/11e08c59-tmplw-wpdev-saas-2694134-5122x.png"
      />
    );
  };

  return (
    <>
      <div
        style={{
          height: "100vh",
          width: "100%",
          overflowY: "auto",
        }}
        id="landing_id"
        onScroll={() => {
          setscrollTop(document.getElementById("landing_id")?.scrollTop);
        }}
      >
        <Nav_Comp top_value={scrollTop > 10} open_modal={open_modal} />
        <section class="banner-sec">
          <div class="container">
            <div class="banner">
              <div class="row align-items-center">
                {/* <div class="col-lg-7">
                  <h2>
                  Fostering impactful connections between brands and consumers
                  </h2>
                  <p>
                  Beyond traditional research methods, we enable meaningful connections that unlock deeper insights. Explore a dynamic ecosystem designed to redefine the way researchers and respondents connect and interact.
                  </p>
                   <p>
                    Explore a dynamic ecosystem designed to redefine the way
                    researchers and respondents connect and interact.
                  </p> 
                   <div class="py-3">
                    <Link to="/documentation" class="default-btn me-3">
                      <span>Discover more</span>
                    </Link>
                    <a href="#" onClick={open_modal} class="default-btn">
                      <span>Start Now</span>
                    </a> 
                  </div>
                </div> */}
                {/* <div class="col-lg-5 d-none d-lg-block">
                  <img src="assets/images/banner.png" width="100%" />
                </div> */}
                <div class="col-lg-7">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: content_arr[0]?.content,
                    }}
                  ></div>
                  <div class="py-3">
                    {/* <Link to="/documentation" class="default-btn me-3">
                      <span>Discover more</span>
                    </Link> */}
                    <a href="#" onClick={open_modal} class="default-btn">
                      <span>Start Now</span>
                    </a>
                  </div>
                </div>
                <div class="col-lg-5 d-none d-lg-block">
                  <img
                    src={image_url + content_arr[0]?.image_file}
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>
          <img
            src="assets/images/shape1.png"
            class="shape shape-1"
            alt="banner"
          />
          <img
            src="assets/images/shape2.png"
            class="shape shape-2"
            alt="banner"
          />
        </section>

        <section class="feature-sec">
          <div class="container">
            <div className="row justify-content-center align-items-center mb-5">
              <p>
                Trusted by leading brands across
                <span style={{ color: "#1d71e3" }}> Africa. </span>
              </p>

              <div className="bransSection row">
                <div className="col-md-3">
                  <img
                    src="assets/images/brand1.png"
                    class="brandimg"
                    alt="brandimg"
                  />
                </div>

                <div className="col-md-3">
                  <img
                    src="assets/images/brand2.png"
                    class="brandimg"
                    alt="brandimg"
                  />
                </div>
                <div className="col-md-3">
                  <img
                    src="assets/images/brand3.png"
                    class="brandimg"
                    alt="brandimg"
                  />
                </div>

                <div className="col-md-3">
                  <img
                    src="assets/images/brand4.png"
                    class="brandimg"
                    alt="brandimg"
                  />
                </div>

                <div className="col-md-3">
                  <img
                    src="assets/images/brand5.png"
                    class="brandimg"
                    alt="brandimg"
                  />
                </div>

                <div className="col-md-3">
                  <img
                    src="assets/images/brand6.png"
                    class="brandimg"
                    alt="brandimg"
                  />
                </div>

                <div className="col-md-3">
                  <img
                    src="assets/images/brand7.png"
                    class="brandimg"
                    alt="brandimg"
                  />
                </div>

                <div className="col-md-3">
                  <img
                    src="assets/images/brand8.png"
                    class="brandimg"
                    alt="brandimg"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="working-sec">
          <div class="container">
            <div class="banner">
              <div class="row align-items-center">
                <div class="col-lg-7">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: content_arr[1]?.content,
                    }}
                  ></div>
                </div>
                <div class="col-lg-5 d-none d-lg-block">
                  <img
                    src={image_url + content_arr[1]?.image_file}
                    width="100%"
                  />
                </div>
                <Flex_box
                  style={{
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    alignItems: "",
                  }}
                >
                  {content_arr[1]?.points_arr?.map((el, index) => {
                    return (
                      <Flex_box
                        style={{
                          width: "45%",
                          alignItems: "",
                          // backgroundColor:"yellow",
                          minWidth: "300px",
                          gap: 15,
                        }}
                      >
                        <FA_Icon_box
                          className={"fa-solid fa-minus"}
                          style={{
                            backgroundColor: theme_bg,
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                            fontSize: "22px",
                            color: theme_color,
                          }}
                        />
                        <div
                          key={index}
                          dangerouslySetInnerHTML={{
                            __html: el,
                          }}
                          style={{
                            width: "85%",
                            minWidth: "230px",
                          }}
                        ></div>
                      </Flex_box>
                    );
                  })}
                </Flex_box>
              </div>
            </div>
          </div>
        </section>

        <section className="feature-sec">
          <div class="container">
            <div class="banner">
              <div class="row align-items-center">
                <div class="col-lg-7">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: content_arr[2]?.content,
                    }}
                  ></div>
                  <div class="py-3">
                    <a href="#" onClick={open_modal} class="default-btn">
                      <span>Explore More</span>
                    </a>
                  </div>
                </div>
                <div class="col-lg-5 d-none d-lg-block">
                  <img
                    src={image_url + content_arr[2]?.image_file}
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section class="feature-sec">
          <div class="container">
            <div className="row justify-content-center align-items-center mb-5">
              <img src="assets/images/howtowork.png" />
            </div>
          </div>
        </section>
        <section class="feature-sec">
          <div class="container">
            <div className="row justify-content-center align-items-center mb-5">
              <img src="assets/images/storysec.png" />
            </div>
          </div>
        </section> */}
        <section className="feature-sec faq-sec">
          <div class="container">
            <div class="banner">
              <div class="row align-items-center">
                <div class="col-lg-12">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: content_arr[3]?.content,
                    }}
                    style={{
                      textAlign: "justify",
                    }}
                  ></div>
                  <Flex_box>
                    <div
                      style={{
                        width: "70%",
                        marginTop: "30px",
                      }}
                    >
                      <Accordion
                        items={content_arr[3]?.points_arr?.map((el) => {
                          return {
                            title: (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: el?.split("<p>/n</p>")[0],
                                }}
                                style={{
                                  marginTop:"15px"
                                }}
                              ></div>
                            ),
                            content: (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: el?.split("<p>/n</p>")[1],
                                }}
                              ></div>
                            ),
                            open: false,
                          };
                        })}
                      />
                    </div>
                  </Flex_box>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section class="feature-sec">
          <div class="container">
            <div className="row justify-content-center align-items-center mb-5">
              <img src="assets/images/faqImg.png" />
            </div>
          </div>
        </section> */}
        <section class="feature-sec">
          <div class="container">
            <div
              style={{
                height: "400px",
                position: "relative",
              }}
              className="row justify-content-center align-items-center mb-5"
            >
              <Flex_box>
                <Flex_box
                  style={{
                    flexDirection: "column",
                    width: "60%",
                    minWidth: "300px",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: "10px",
                    padding: "10px 20px 0px 20px",
                  }}
                >
                  <img
                    src="assets/images/FCMB-qlg3uoo2hf95e71fdvrh5vt5f3w5rxg7tli80exrh8-removebg-preview.png"
                    style={{
                      height: "130px",
                      marginBottom: "20px",
                    }}
                  />
                  <p>
                    They are a long-standing partner, helping us keep tabs of
                    our brand equity and the effectiveness of our comms strategy
                    as a bank. The team is switched on and always ready to bring
                    growth-oriented thinking to the ‘table’. We are delighted to
                    have them as our go-to-market research partner as a
                    financial institution!
                  </p>

                  <p>VP, Corporate Communications / FCMB</p>
                  <p
                    style={{
                      color: theme_color,
                    }}
                  >
                    Diran Olojo
                  </p>
                </Flex_box>
              </Flex_box>

              <Pic_1
                style={{
                  top: "6%",
                  left: "8%",
                }}
              />
              <Pic_1
                style={{
                  top: "40%",
                  left: "2%",
                }}
              />
              <Pic_2
                style={{
                  bottom: "5%",
                  left: "7%",
                }}
              />

              <Pic_2
                style={{
                  top: "6%",
                  right: "8%",
                }}
              />
              <Pic_2
                style={{
                  top: "45%",
                  right: "10%",
                }}
              />
              <Pic_3
                style={{
                  bottom: "5%",
                  right: "7%",
                }}
              />
            </div>
          </div>
        </section>
        <section class="feature-sec">
          <div class="container">
            <div class="row justify-content-center align-items-center mb-5">
              <div class="col-md-8">
                <h2 class="mb-3">Key Features</h2>
                <h6 class="mb-0">
                  Designed for the ease of the researcher and respondent
                </h6>
                <p>
                  Yaarnbox makes conducting and participating in research a
                  breeze.
                </p>
              </div>
              <div class="col-md-4 text-end">
                <a href="#" onClick={open_modal} class="default-btn">
                  <span>Start Now</span>
                </a>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="features_box">
                  <span class="bg-shape"></span>
                  <div class="finner_box">
                    <img
                      src="assets/images/format.png"
                      class="mb-3"
                      width="80px"
                    />
                    <h5>Diverse Study Formats</h5>
                    <p>
                      Choose from engaging surveys with branching logic, rich
                      multimedia content, and interactive group discussions with
                      text chat, audio, and video conferencing.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="features_box">
                  <span class="bg-shape"></span>
                  <div class="finner_box">
                    <img
                      src="assets/images/secure.png"
                      class="mb-3"
                      width="80px"
                    />
                    <h5>Secure and Reliable</h5>
                    <p>
                      Yaarnbox employs industry-standard security measures to
                      ensure the confidentiality and integrity of your data.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="features_box">
                  <span class="bg-shape"></span>
                  <div class="finner_box">
                    <img
                      src="assets/images/analytics.png"
                      class="mb-3"
                      width="80px"
                    />
                    <h5>Advanced Data Analytics</h5>
                    <p>
                      Access powerful dashboards and reporting tools to uncover
                      hidden patterns and gain an in-depth understanding of your
                      data.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="feature-sec">
          <div class="container">
            <div class="row justify-content-center align-items-center mb-5">
              <div class="col-md-8 text-center">
                <h2 class="mb-3">
                  Everything you need to get the people's opinion on anything
                </h2>
                <p>Create your surveys and studies withmultiple options</p>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-md-4 ">
                <div class="card_box">
                  <div class="iconimg">
                    <img src="assets/images/format.png" width="45px" />
                  </div>
                  <h5>Voice Calling</h5>
                </div>
              </div>
              <div class="col-md-4 ">
                <div class="card_box">
                  <div class="iconimg">
                    <img src="assets/images/format.png" width="45px" />
                  </div>
                  <h5>Video Calling</h5>
                </div>
              </div>
              <div class="col-md-4 ">
                <div class="card_box">
                  <div class="iconimg">
                    <img src="assets/images/format.png" width="45px" />
                  </div>
                  <h5>Chatting</h5>
                </div>
              </div>
              <div class="col-md-4 ">
                <div class="card_box">
                  <div class="iconimg">
                    <img src="assets/images/format.png" width="45px" />
                  </div>
                  <h5>Polls</h5>
                </div>
              </div>
              <div class="col-md-4 ">
                <div class="card_box">
                  <div class="iconimg">
                    <img src="assets/images/format.png" width="45px" />
                  </div>
                  <h5>Group Discussion</h5>
                </div>
              </div>
              <div class="col-md-4 ">
                <div class="card_box">
                  <div class="iconimg">
                    <img src="assets/images/format.png" width="45px" />
                  </div>
                  <h5>Surveys</h5>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="research-sec ">
          <div class="container">
            <div class="banner">
              <div class="row align-items-center">
                <div class="col-lg-5 d-none d-lg-block">
                  {/* <img src="assets/images/research.png" width="100%" /> */}
                  <img
                    src={image_url + content_arr[1]?.image_file}
                    width="100%"
                  />
                </div>
                <div class="col-lg-7">
                  {/* <h4 class="mb-3">
                    Deliver your studies to qualified respondents based on
                    demographics and preferences, ensuring high-quality data
                    collection and fast delivery.
                  </h4>
                  <p>
                    Boost your Efficiency by Streamlining your research process,
                    saving valuable time and resources.
                  </p> */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: content_arr[4]?.content,
                    }}
                  ></div>
                  {content_arr[4]?.points_arr?.length > 0 &&
                    content_arr[4]?.points_arr?.map((el, index) => {
                      return (
                        <div class="dftcrd">
                          <div dangerouslySetInnerHTML={{ __html: el }}></div>
                        </div>
                      );
                    })}
                  {/* <div class="dftcrd">
                    <strong>Effortlessly Design Studies:</strong> Craft engaging
                    surveys and facilitate interactive group discussions with
                    our user-friendly tools.
                  </div>
                  <div class="dftcrd">
                    <strong>Gain Deeper Insights:</strong> Leverage powerful
                    data visualization and analytics to transform collected data
                    into actionable insights.
                  </div> */}
                  <div class="">
                    <Link to="/signup" class="default-btn">
                      <span>Sign Up as a researcher</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="assets/images/shape1.png"
            class="shape shape-3"
            alt="banner"
          />
          <img
            src="assets/images/shape-4.png"
            class="shape shape-4"
            alt="banner"
          />
          <img
            src="assets/images/shape3.svg"
            class="shape shape-5"
            alt="banner"
          />
        </section>
        <section class="research-sec2">
          <div class="container">
            <div class="banner">
              <div class="row align-items-center">
                <div class="col-md-7">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: content_arr[5]?.content,
                    }}
                  ></div>
                  {content_arr[5]?.points_arr?.length > 0 &&
                    content_arr[5]?.points_arr?.map((el, index) => {
                      return (
                        <div class="dftcrd">
                          <div dangerouslySetInnerHTML={{ __html: el }}></div>
                        </div>
                      );
                    })}
                  {/* <h4 class="mb-3">
                    Deliver your studies to qualified respondents based on
                    demographics and preferences, ensuring high-quality data
                    collection and fast delivery.
                  </h4>
                  <p>
                    Boost your Efficiency by Streamlining your research process,
                    saving valuable time and resources.
                  </p>
                  <div class="dftcrd">
                    <strong>Effortlessly Design Studies:</strong> Craft engaging
                    surveys and facilitate interactive group discussions with
                    our user-friendly tools.
                  </div>
                  <div class="dftcrd">
                    <strong>Gain Deeper Insights:</strong> Leverage powerful
                    data visualization and analytics to transform collected data
                    into actionable insights.
                  </div> */}
                  <div class=" mt-5">
                    <Link to="/signup" class="default-btn">
                      <span>
                        Sign Up to take part in surveys and start earning
                      </span>
                    </Link>
                  </div>
                </div>
                <div class="col-md-5">
                  <img
                    // src="assets/images/research1.png"
                    src={image_url + content_arr[2]?.image_file}
                    class="amimy"
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>
          <img
            src="assets/images/shape1.png"
            class="shape shape-3"
            alt="banner"
          />
          <img
            src="assets/images/shape-4.png"
            class="shape shape-4"
            alt="banner"
          />
          <img
            src="assets/images/shape3.svg"
            class="shape shape-5"
            alt="banner"
          />
        </section>
        <section class="subscriptions-sec">
          <div class="container">
            <div class="banner">
              <div class="row justify-content-center align-items-center mb-5">
                <div class="col-md-8 text-center">
                  <h2 class="mb-3">Subscriptions Plan</h2>
                  <p>
                    Try Yaarnbox for Free: Explore the Basic Plan for free or
                    opt for a 14-day trial of the Pro Plan.
                  </p>
                </div>
              </div>
              <div class="row align-items-center">
                <Carousal_Box>
                  {sub_arr?.length > 0 &&
                    sub_arr?.map((el, index) => {
                      return (
                        <div key={index} class="col-md-12">
                          <div class="subbox">
                            <div class="sub-innerbox">
                              <h2>{el?.Name}</h2>
                              <h6>
                                {el?.Amount > 0
                                  ? "$" + el?.Amount + "/" + el?.Cycle
                                  : el?.Name == "Enterprise"
                                  ? "Custom"
                                  : "Free"}
                              </h6>
                              <div
                                class="subtxt"
                                style={{
                                  width: "100%",
                                }}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: el?.Description,
                                  }}
                                ></div>
                              </div>
                            </div>
                            <img
                              src="assets/images/shape5.png"
                              class="shape shape-6"
                              alt="banner"
                            />
                          </div>
                        </div>
                      );
                    })}
                </Carousal_Box>
                {/* <div class="col-lg-4 col-md-6 mb-3">
                  <div class="subbox">
                    <div class="sub-innerbox">
                      <h2>Pro Plan</h2>
                      <h6>$29.99/month</h6>
                      <p class="subtxt">
                        Enhanced survey capabilities Advanced data analytics
                        Real time communication tools
                      </p>
                    </div>
                    <img
                      src="assets/images/shape5.png"
                      class="shape shape-6"
                      alt="banner"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-3">
                  <div class="subbox">
                    <div class="sub-innerbox">
                      <h2>Enterprise Plan</h2>
                      <h6>Custom Pricing</h6>
                      <p class="subtxt">
                        Tailored solutions for large-scale research projects
                        dedicated support and additional features
                      </p>
                    </div>
                    <img
                      src="assets/images/shape5.png"
                      class="shape shape-6"
                      alt="banner"
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <img
            src="assets/images/shape1.png"
            class="shape shape-3"
            alt="banner"
          />
          <img
            src="assets/images/shape-4.png"
            class="shape shape-4"
            alt="banner"
          />
          <img
            src="assets/images/shape3.svg"
            class="shape shape-5"
            alt="banner"
          />
        </section>
        <Contact_Form />
        <Footer />
      </div>
      <Modal isOpen={isOpen} onClose={close_modal}>
        <div
          style={{
            position: "relative",
          }}
        >
          <span
            onClick={close_modal}
            style={{
              fontSize: "26px",
              position: "absolute",
              top: "0px",
              right: "10px",
              cursor: "pointer",
            }}
          >
            &times;
          </span>
          <Pre_login />
        </div>
      </Modal>
    </>
  );
};

export default Landing_Screen;
