import React, { useEffect, useState } from "react";
import pdf_icon from "../../../../assets/bxs-file-pdf.svg";
import share_icon from "../../../../assets/share.svg";
import { theme_bg } from "../../../../utilities/colors";
import Flex_box from "../../../../utilities/flex_box";
import Progress_bar from "../../../../utilities/Progress_bar";
import { useLocation, useNavigate } from "react-router-dom";
import checkbox_icon from "../../../../assets/ios-checkbox-outline.svg";
import { get_industry_name } from "../../../../utilities/select_arr";
import { Age_keys } from "../../../../utilities/Age_keys";
import {
  check_study_sec_available_api,
  get_gd_member_count_api,
  get_survey_resp_count_api,
  page_url,
} from "../../../../apis/apis";
import { useDispatch, useSelector } from "react-redux";
import { alert_action } from "../../../../redux_prog/actions/base_action";

const Study_header = ({ study_detail, survey_details, state, gd_data }) => {
  const {
    age,
    description,
    studyname,
    studyoninductry,
    groupdiscussionname,
    _id,
  } = study_detail;

  const dispatch = useDispatch();

  const [total_resp_count, settotal_resp_count] = useState(0);

  const { user_data } = useSelector((state) => state?.authReducer);

  const type = state?.type;
  const completed = state?.completed;
  const navigate = useNavigate();

  const get_survey_resp_count = () => {
    console.warn(study_detail);

    get_survey_resp_count_api(
      { survey_id: survey_details?.surveydata?._id },
      user_data?.role
    )
      .then((res) => {
        // settotal_resp_count(res.data.Res);
       if(res.data.Res < study_detail?.totalrespondents){
        check_study_sec_available();
       }
       else{
        dispatch(
          alert_action({
            type: "error",
            title: "Max Limit!",
            msg: `This survey has reached it's maximum respondents!`,
          })
        );
       }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const check_study_sec_available = () => {
    check_study_sec_available_api({
      study_id: survey_details?.surveydata?.studyid,
      sec: user_data?.sec,
    })
      .then((res) => {
        if (res.data.status == 1) {
          navigate("/take-survey", { state: survey_details });
        } else {
          dispatch(
            alert_action({
              title: "Access Denied",
              type: "error",
              msg: "You are not eligible to join this study.",
              title_visible:true
            })
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // useEffect(()=>{
  //   if(type==1){
  //     get_survey_resp_count()
  //   }
  // }, []);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: theme_bg,
        padding: "20px",
        boxSizing: "border-box",
        borderRadius: "20px",
      }}
    >
      <Flex_box
        style={{
          justifyContent: "space-between",
        }}
      >
        <b
          style={{
            fontSize: "22px",
          }}
        >
          {groupdiscussionname || studyname}
        </b>
        {/* <Flex_box
          style={{
            width: "",
            gap: 20,
          }}
        >
          <button
            id="btn_comp"
            style={{
              width: "40px",
              height: "40px",
              backgroundColor: "#fff",
              boxShadow: "0px 2px 6px #0000000D",
              // padding:"0px"
            }}
            onClick={() => {
              navigator.clipboard
                .writeText(page_url + "study-details?" + _id)
                .then((res) => {
                  alert("Link copied successfully!");
                })
                .catch((err) => {
                  console.warn(err);
                });
            }}
          >
            <img
              src={share_icon}
              style={{
                height: "15px",
              }}
            />
          </button>
          <button
            id="btn_comp"
            style={{
              width: "40px",
              height: "40px",
              backgroundColor: "#fff",
              boxShadow: "0px 2px 6px #0000000D",
              // padding:"0px"
            }}
          >
            <img
              src={pdf_icon}
              style={{
                height: "15px",
              }}
            />
          </button>
        </Flex_box> */}
      </Flex_box>
      <span
        style={{
          fontSize: "15px",
        }}
      >
        <span style={{ fontWeight: 600 }}>Study on </span>:{" "}
        {get_industry_name(studyoninductry)}
      </span>
      <p
        style={{
          fontSize: "15px",
          marginTop: "8px",
        }}
      >
        <span style={{ fontWeight: 600 }}>
          {"Male & Female "},
          {age?.length > 0 &&
            age
              ?.map((el) => {
                return Age_keys[el?.age];
              })
              .toString()}
        </span>
      </p>
      <p
        style={{
          marginTop: "-6px",
          fontSize: "15px",
        }}
      >
        <span style={{ fontWeight: 600 }}>Close date </span>: 12 January 2024
      </p>
      <Flex_box
        style={{
          marginTop: "-6px",
          gap: 20,
          justifyContent: "start",
        }}
      >
        <div
          style={{
            width: "100%",
          }}
        >
          <span style={{ fontSize: "11px" }}>{description}</span>
          <br />
          {type == 1 && !completed && (
            <button
              id="btn_comp"
              style={{
                marginTop: "20px",
              }}
              onClick={() => {
                get_survey_resp_count();
              }}
            >
              Join Study
            </button>
          )}
          {completed && (
            <Flex_box
              style={{
                width: "",
                justifyContent: "start",
                gap: 10,
                marginTop: "15px",
              }}
            >
              <img
                style={{
                  height: "20px",
                }}
                src={checkbox_icon}
              />
              <b>Incentive received</b>
            </Flex_box>
          )}
        </div>
      </Flex_box>
    </div>
  );
};

export default Study_header;
