import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { theme_bg, theme_color } from "../../../../../utilities/colors";
import Text_Input from "../../../../../utilities/Text_Input";
import Select_box from "../../../../../utilities/Select_box";
import Flex_box from "../../../../../utilities/flex_box";
import blue_circle from "../../../../../assets/Group 38514.svg";
import green_check from "../../../../../assets/check-circle-solid.svg";
import MCQ_Box from "./MCQ_Box";
import { useDispatch } from "react-redux";
import { alert_action } from "../../../../../redux_prog/actions/base_action";
import Button from "../../../../../utilities/Button";
import * as XLSX from "xlsx";
import { Tooltip } from "@mui/material";

const Add_question = () => {
  const { state } = useLocation();

  // console.warn(state)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const question_type_options = [
    { label: "Open ended", value: 1 },
    { label: "Multi Select", value: 2 },
    { label: "Single Select", value: 3 },
    { label: "Scale", value: 4 },
    { label: "Rating", value: 5 },
  ];

  const q_l_data = localStorage.getItem("question_data")
    ? JSON.parse(localStorage.getItem("question_data"))
    : {};
  const [question_data, setquestion_data] = useState({
    questiontitle: "",
    questiontype: 1,
    serial_no: 1,
    Option: [],
    sub_text: "",
    max_rating: 0,
    set: '1',
  });

  const { questiontitle, questiontype, Option, sub_text, max_rating, set } =
    question_data;
  const [question_arr, setquestion_arr] = useState(q_l_data?.Questions || []);
  const surveyData = state ? state?.state : q_l_data?.SurveyData || {};

  const upd_question_data = (key, val, sub_key, sub_id) => {
    console.warn({ key, val, sub_key, sub_id });
    const new_obj =
      key == "Option"
        ? {
            ...question_data,
            Option: question_data?.Option.map((li, ind) => {
              if (sub_id == ind) {
                return {
                  ...li,
                  [sub_key]: val,
                };
              } else {
                return li;
              }
            }),
          }
        : {
            ...question_data,
            [key]: val,
          };
    setquestion_data(new_obj);
  };

  const add_option = (type) => {
    if (type == 4) {
      const option_has_value =
        question_data?.Option[question_data?.Option?.length - 1]?.optiontitle;
      if (option_has_value) {
        const new_obj = {
          ...question_data,
          Option: [
            ...question_data?.Option,
            {
              optiontitle: "",
              sub_options: [
                {
                  optiontitle: "",
                  answer: false,
                },
              ],
            },
          ],
        };
        setquestion_data(new_obj);
      } else {
        dispatch(
          alert_action({
            type: "error",
            title: "Empty option",
            msg: "Please fill the option first!",
          })
        );
      }
    } else {
      const option_has_value =
        question_data?.Option[question_data?.Option?.length - 1]?.optiontitle;
      if (option_has_value) {
        const new_obj = {
          ...question_data,
          Option: [
            ...question_data?.Option,
            {
              optiontitle: "",
              answer: false,
              switch_to: 0,
              end_flag:false
            },
          ],
        };
        setquestion_data(new_obj);
      } else {
        dispatch(
          alert_action({
            type: "error",
            title: "Empty option",
            msg: "Please fill the option first!",
          })
        );
      }
    }
  };

  const add_sub_option = (o_index, option_data) => {
    const option_has_value =
      option_data?.sub_options[option_data?.sub_options?.length - 1]
        ?.optiontitle;
    if (option_has_value) {
      const new_obj = {
        ...option_data,
        sub_options: [
          ...option_data?.sub_options,
          {
            optiontitle: "",
            sub_options: [
              {
                optiontitle: "",
                answer: false,
              },
            ],
          },
        ],
      };
      setquestion_data({
        ...question_data,
        Option: Option.map((el, op_index) => {
          if (op_index == o_index) {
            return new_obj;
          } else {
            return el;
          }
        }),
      });
    } else {
      dispatch(
        alert_action({
          type: "error",
          title: "Empty option",
          msg: "Please fill the option first!",
        })
      );
    }
  };

  const select_question_question = (type) => {
    var noOfQuestion = question_arr?.filter((el)=>{
      return el.set == set
    }).length + 1;
    if (type == 1 || type == 5) {
      setquestion_data({
        questiontitle: questiontitle,
        questiontype: type,
        serial_no: noOfQuestion,
        sub_text,
        set,
      });
    } else if (type == 4) {
      setquestion_data({
        questiontitle: questiontitle,
        questiontype: 4,
        serial_no: noOfQuestion,
        sub_text,
        set,
        // next_question: 0,
        Option: [
          {
            optiontitle: "",
            sub_options: [
              {
                optiontitle: "",
                answer: false,
              },
            ],
          },
        ],
      });
    } else {
      setquestion_data({
        questiontitle: questiontitle,
        questiontype: type,
        serial_no: noOfQuestion,
        sub_text,
        // next_question: 0,,
        set,
        Option: [
          {
            optiontitle: "",
            answer: false,
            switch_to: 0,
          },
        ],
      });
    }
  };

  const add_question = () => {
    const option_has_value =
      questiontype == 1 || questiontype == 5
        ? true
        : question_data?.Option[question_data?.Option?.length - 1]?.optiontitle;
    // debugger;
    if (set) {
      console.warn({set, set_options});

      if (questiontitle && option_has_value) {
        if (questiontype == 5 ? question_data.max_rating > 0 : true) {
          setquestion_arr([...question_arr, question_data]);
          var noOfQuestion = question_arr?.filter((el)=>{
            return el.set == set
          }).length + 1;
          if (question_data.questiontitle) {
            noOfQuestion += 1;
          }


          setquestion_data({
            questiontitle: "",
            questiontype: "",
            serial_no: noOfQuestion,
            set,
          });
        } else {
          dispatch(
            alert_action({
              type: "error",
              title: "Missing fields!",
              msg: "Please enter the rating field also!",
            })
          );
        }
      } else {
        dispatch(
          alert_action({
            type: "error",
            title: "Missing fields!",
            msg: "Please fill the option and question title!",
          })
        );
      }
    } else {
      dispatch(
        alert_action({
          type: "error",
          title: "Missing fields!",
          msg: "Please select a set first!",
        })
      );
    }
  };

  const handelRemoveOption = (index) => {
    const updatedQuestionData = { ...question_data };
    updatedQuestionData.Option.splice(index, 1);
    setquestion_data(updatedQuestionData);
  };

  const handleEndFlag = (index,flag)=>{
    const updatedQuestionData = { ...question_data };
    updatedQuestionData.Option = 
    setquestion_data({
      ...updatedQuestionData,
    Option:updatedQuestionData.Option.map((el,ind)=>{
      if(ind == index){
        return {
          ...el,
          end_flag:flag
        }
      }
      else {
        return el
      }
    })});
  }

  const handelRemoveSubOption = (p_index, index) => {
    const updatedQuestionData = { ...question_data };
    updatedQuestionData.Option[p_index]?.sub_options.splice(index, 1);
    setquestion_data(updatedQuestionData);
  };

  const handelAddSubOption = (p_index, index) => {
    const updatedQuestionData = { ...question_data };
    const option_has_value =
      updatedQuestionData?.Option[p_index]?.sub_options[
        updatedQuestionData?.Option[p_index]?.sub_options?.length - 1
      ]?.optiontitle;
    if (option_has_value) {
      updatedQuestionData.Option[p_index]?.sub_options.push({
        optiontitle: "",
        answer: false,
      });
      setquestion_data(updatedQuestionData);
    } else {
      dispatch(
        alert_action({
          type: "error",
          title: "Missing fields!",
          msg: "Please fill the option and question title!",
        })
      );
    }
  };

  const handelDependentQuestion = (value, questionIndex, optionIndex) => {

    
    // alert(questionIndex);
    // debugger;
    // if (!question_arr[questionIndex].Option[optionIndex]?.dependent_question) {
    //   question_arr[questionIndex].Option[optionIndex]["dependent_question"] =
    //     value;
    //   question_arr[questionIndex].Option[optionIndex]["switch_to_set"] = 0;
    //   // switch_to_set: val,
    //   //           switch_to:0,
    //   //           dependent_question:0
    // } else {
    //   question_arr[questionIndex].Option[optionIndex].dependent_question =
    //     value;
    //   question_arr[questionIndex].Option[optionIndex]["switch_to_set"] = 0;
    // }
    // setquestion_arr([...question_arr]);
    // console.log("dependent questions", question_arr);
    //alert(value);
  };

  const handleFileUpload = (file) => {
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const binaryStr = e.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });

        // Assuming the first sheet
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];

        // Convert the worksheet to JSON
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        // setData(jsonData)
        // console.log({ jsonData })
        setquestion_arr([...question_arr, ...transformData(jsonData)].sort((a,b)=>a.set - b.set));
        setquestion_data({
          questiontitle: "",
          questiontype: "",
          serial_no: [...question_arr, ...transformData(jsonData)].filter((el)=>{
            return el.set == set
          }).length+1,
          set,
        });
      };

      reader.readAsBinaryString(file);
    }
  };

  const transformData = (data) => {

    let n_transformData = [];

    for (let index = 0; index < data.length; index++) {
      const item = data[index];

      const n_serial_no = n_transformData?.filter((li)=>{
        return li.serial_no >0 && ((item?.set == li.set))
      })?.length+1

        const transformedItem = {
        questiontitle: item.question,
        questiontype: item.type,
        serial_no: n_serial_no,
        // serial_no:item.serial_no,
        sub_text: item.hint,
        set: item?.set || '1',
        max_rating: item?.max_rating || 0,
      };

      if (item.type == 4) {
        transformedItem.Option = [];
        for (let i = 1; item[`o_${i}`]; i++) {
          // console.warn("=========>"+(/(?=.*\d)|(?=.*\S)/.test(item[`o_${i}`])));

          if (/(?=.*\d)|(?=.*\S)/.test(item[`o_${i}`])) {
            const option = {
              optiontitle: item[`o_${i}`],
              sub_options: [],
            };

            for (let j = 1; item[`o_${i}_${j}`]; j++) {
              option.sub_options.push({
                optiontitle: item[`o_${i}_${j}`],
                answer: false,
              });
            }
            transformedItem.Option.push(option);
          }
        }
      } else if (item.type == 3 || item.type == 2) {
        transformedItem.Option = [];
        for (let i = 1; item[`o_${i}`]; i++) {
          if (/(?=.*\d)|(?=.*\S)/.test(item[`o_${i}`])) {
            transformedItem.Option.push({
              optiontitle: item[`o_${i}`],
              answer: false,
              switch_to: 0,
              dependent_question:0,
              switch_to_set:0
            });
          }
        }
      }

      n_transformData.push(transformedItem)
      
    }
    return n_transformData;
    // return data.map((item, index) => {
    //   const transformedItem = {
    //     questiontitle: item.question,
    //     questiontype: item.type,
    //     serial_no: index + 1,
    //     // serial_no:item.serial_no,
    //     sub_text: item.hint,
    //     set: item?.set || '1',
    //     max_rating: item?.max_rating || 0,
    //   };

    //   if (item.type == 4) {
    //     transformedItem.Option = [];
    //     for (let i = 1; item[`o_${i}`]; i++) {
    //       // console.warn("=========>"+(/(?=.*\d)|(?=.*\S)/.test(item[`o_${i}`])));

    //       if (/(?=.*\d)|(?=.*\S)/.test(item[`o_${i}`])) {
    //         const option = {
    //           optiontitle: item[`o_${i}`],
    //           sub_options: [],
    //         };

    //         for (let j = 1; item[`o_${i}_${j}`]; j++) {
    //           option.sub_options.push({
    //             optiontitle: item[`o_${i}_${j}`],
    //             answer: false,
    //           });
    //         }
    //         transformedItem.Option.push(option);
    //       }
    //     }
    //   } else if (item.type == 3 || item.type == 2) {
    //     transformedItem.Option = [];
    //     for (let i = 1; item[`o_${i}`]; i++) {
    //       if (/(?=.*\d)|(?=.*\S)/.test(item[`o_${i}`])) {
    //         transformedItem.Option.push({
    //           optiontitle: item[`o_${i}`],
    //           answer: false,
    //           switch_to: 0,
    //           dependent_question:0,
    //           switch_to_set:0
    //         });
    //       }
    //     }
    //   }
    //   return transformedItem;
    // });
  };

  // console.warn(question_arr);

  const transformed_q_Data = question_arr?.reduce((acc, question) => {
    // Find the existing set in the accumulator
    let setGroup = acc.find((group) => group.set == question.set);

    if (!setGroup) {
      // If the set group doesn't exist, create it
      setGroup = { set: question.set, question_arr: [] };
      acc.push(setGroup);
    }

    // Add the current question to the appropriate set group
    setGroup.question_arr.push(question);

    return acc;
  }, []);

  const set_options =
    transformed_q_Data?.length > 0
      ? [
          ...transformed_q_Data?.map((el, index) => {
            return {
              label: "Set " + (index + 1),
              value: (index + 1).toString(),
            };
          }),
          {
            label: "New Set",
            value: (transformed_q_Data?.length + 1).toString(),
          },
        ]
      : [
          {
            label: "Set 1",
            value: "1",
          },
        ];

  return (
    <div
      style={{
        padding: "20px",
        height: "73vh",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        gap: 20,
      }}
    >
      <Flex_box
        style={{
          justifyContent: "space-between",
        }}
      >
        <span
          style={{
            fontSize: "26px",
            fontStyle: "italic",
            fontWeight: "700",
          }}
        >
          Add Question
        </span>

        <Button
          label={"Import From Excel"}
          onClick={() => {
            document.querySelector("#excel_input").click();
          }}
        />
        <input
          type="file"
          style={{
            display: "none",
          }}
          accept=".xlsx, .xls"
          id="excel_input"
          onChange={(e) => {
            handleFileUpload(e.target.files[0]);
          }}
        />
      </Flex_box>

      <div
        className="question_wrapper"
        style={{ display: "flex", alignItems: "center", gap: 10 }}
      >
        <Text_Input
          label={"Question"}
          input_style={{
            width: "35vw",
          }}
          value={questiontitle}
          onChange={(e) => {
            upd_question_data("questiontitle", e.target.value);
          }}
        />
        <Select_box
          label={"Set"}
          placeholder={"Set"}
          options={set_options}
          onChange={(e) => {
            upd_question_data("set", e.target.value);
          }}
          value={set}
          cont_style={{
            width: "100px",
          }}
        />
      </div>
      <div
        className="question_wrapper"
        style={{ display: "flex", alignItems: "center" }}
      >
        <Text_Input
          label={"Hint"}
          input_style={{
            width: "35vw",
          }}
          value={sub_text}
          onChange={(e) => {
            upd_question_data("sub_text", e.target.value);
          }}
        />
      </div>
      <Select_box
        label={"Type of question"}
        input_style={{
          width: "36.5vw",
        }}
        options={question_type_options}
        value={questiontype}
        onChange={(e) => {
          select_question_question(e.target.value);
        }}
      />
      {questiontype == 5 && (
        <div
          className="question_wrapper"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Text_Input
            label={"Max Rating"}
            input_style={{
              width: "35vw",
            }}
            value={max_rating}
            onChange={(e) => {
              upd_question_data("max_rating", e.target.value);
            }}
          />
        </div>
      )}
      {questiontype != 4 &&
        Option?.length > 0 &&
        Option.map((el, index) => {
          return (
            <>
              <div
                className="responsive_width_35"
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Text_Input
                  label={index == 0 ? "Create options below" : ""}
                  placeholder={"Option " + (index + 1)}
                  value={el?.optiontitle}
                  onChange={(e) => {
                    upd_question_data(
                      "Option",
                      e.target.value,
                      "optiontitle",
                      index
                    );
                  }}
                  input_style={{
                    width: "35vw",
                    marginTop: index == 0 ? "10px" : "",
                  }}
                />
                {Option?.length > 1 && (
                  <Flex_box
                  style={{
                    width:"",
                    gap:10
                  }}
                  >
                  <div
                    onClick={() => handelRemoveOption(index)}
                    style={{
                      position: "absolute",
                      top: index == 0 ? "50%" : "30%",
                      right: "-58px",
                      color: "red",
                      fontSize: "20px",
                      cursor: "pointer",
                      border: "1px solid red",
                      padding: "0px 5px 0px 6px",
                      borderRadius: "50%",
                    }}
                  >
                    <i class="fa-solid fa-xmark"></i>
                  </div>
                  {
                    questiontype == 3 &&
                    <div
                    style={{
                      position: "absolute",
                      top: index == 0 ? "50%" : "30%",
                      right: "-98px",
                    }}
                    >

                      <Tooltip title={el.end_flag?"Survey will end here":"Click to make the survey end here"} >
                    <div
                      onClick={() => handleEndFlag(index,el.end_flag?false:true)}
                      style={{
                        color: "red",
                        fontSize: "20px",
                        cursor: "pointer",
                        border: "1px solid red",
                        padding: "0px 5px 0px 6px",
                        borderRadius: "50%",
                      }}
                    >
                      <i class="fa-solid fa-ban"></i>
                    </div>
                    </Tooltip>
                    </div>
                  }
                  </Flex_box>
                )}
              </div>
            </>
          );
        })}
      {questiontype == 4 &&
        Option?.length > 0 &&
        Option.map((el, index) => {
          return (
            <>
              <div
                className="responsive_width_35"
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Text_Input
                  label={index == 0 ? "Create options below" : ""}
                  placeholder={"Option " + (index + 1)}
                  value={el?.optiontitle}
                  onChange={(e) => {
                    upd_question_data(
                      "Option",
                      e.target.value,
                      "optiontitle",
                      index
                    );
                  }}
                  input_style={{
                    width: "35vw",
                    marginTop: index == 0 ? "10px" : "",
                  }}
                />
                {Option?.length > 1 && (
                  <div
                    onClick={() => handelRemoveOption(index)}
                    style={{
                      position: "absolute",
                      top: index == 0 ? "50%" : "30%",
                      right: "-58px",
                      color: "red",
                      fontSize: "20px",
                      cursor: "pointer",
                      border: "1px solid red",
                      padding: "0px 5px 0px 6px",
                      borderRadius: "50%",
                    }}
                  >
                    <i class="fa-solid fa-xmark"></i>
                  </div>
                )}
              </div>
              {el.sub_options?.length > 0 &&
                el?.sub_options?.map((elem, ind_el) => {
                  return (
                    <div
                      className="responsive_width_35"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <Text_Input
                        label={ind_el == 0 ? "Create sub options below" : ""}
                        placeholder={"Sub-Option " + (ind_el + 1)}
                        value={elem?.optiontitle}
                        onChange={(e) => {
                          upd_question_data(
                            "Option",
                            el?.sub_options?.map((elemen, ele_ind) => {
                              if (ele_ind == ind_el) {
                                return {
                                  ...elemen,
                                  optiontitle: e.target.value,
                                };
                              } else {
                                return elemen;
                              }
                            }),
                            "sub_options",
                            index
                          );
                        }}
                        input_style={{
                          width: "30vw",
                          marginTop: ind_el == 0 ? "10px" : "",
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: ind_el == 0 ? "50%" : "30%",
                          right: "-58px",
                          display: "flex",
                          alignItems: "center",
                          gap: 8,
                        }}
                      >
                        {
                          // Option?.length > 1 &&
                          <div
                            onClick={() => handelAddSubOption(index, ind_el)}
                            style={{
                              color: "#000",
                              fontSize: "20px",
                              cursor: "pointer",
                              border: "1px solid #000",
                              padding: "0px 5px 0px 6px",
                              borderRadius: "50%",
                            }}
                          >
                            <i class="fa-solid fa-plus"></i>
                          </div>
                        }
                        {el.sub_options?.length > 1 && (
                          <div
                            onClick={() => handelRemoveSubOption(index, ind_el)}
                            style={{
                              color: "red",
                              fontSize: "20px",
                              cursor: "pointer",
                              border: "1px solid red",
                              padding: "0px 5px 0px 6px",
                              borderRadius: "50%",
                            }}
                          >
                            <i class="fa-solid fa-xmark"></i>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </>
          );
        })}
      <Flex_box
        style={{
          justifyContent: "start",
          gap: 20,
        }}
      >
        {questiontype != 1 && questiontype != 5 && (
          <button
            id="btn_comp"
            style={{
              backgroundColor: theme_bg,
              border: "2px solid " + theme_color,
              color: theme_color,
              fontWeight: 600,
              padding: "8px",
              width: "150px",
            }}
            onClick={() => {
              add_option(questiontype);
            }}
          >
            Add Option
          </button>
        )}

        <button
          id="btn_comp"
          style={{
            fontWeight: 600,
            padding: "10px",
            width: "150px",
          }}
          onClick={() => {
            // navigate("/select-study-type");
            add_question();
          }}
        >
          Add Question
        </button>
      </Flex_box>

      {transformed_q_Data?.length > 0 && (
        <>
          {transformed_q_Data?.map((el) => {
            return (
              <>
                <Flex_box>
                  <h3
                    style={{
                      textDecoration: "underline",
                    }}
                  >
                    Set {el?.set}
                  </h3>
                </Flex_box>
                {el?.question_arr?.length > 0 && (
                  <>
                    <b>Questions</b>
                    <MCQ_Box
                      setquestion_arr={setquestion_arr}
                      question_arr={question_arr}
                      map_qs={el?.question_arr}
                      handelDependentQuestion={handelDependentQuestion}
                      sets={transformed_q_Data}
                    />
                  </>
                )}
              </>
            );
          })}
          <Flex_box
            className="responsive_width_35"
            style={{
              width: "",
              justifyContent: "space-between",
              marginTop: "40px",
              // gap: 20,
            }}
          >
            <button
              id="btn_comp"
              style={{
                backgroundColor: theme_bg,
                border: "2px solid " + theme_color,
                color: theme_color,
                fontWeight: 600,
              }}
              onClick={() => {
                localStorage.removeItem("question_data");
                navigate("/create-survey", { state: state?.state });
              }}
            >
              Cancel
            </button>
            <button
              id="btn_comp"
              style={{
                fontWeight: 600,
              }}
              onClick={() => {
                localStorage.setItem(
                  "question_data",
                  JSON.stringify({
                    SurveyData: surveyData,
                    Questions: question_arr,
                    image: state?.image || q_l_data?.image,
                  })
                );
                navigate("/preview-survey", {
                  state: {
                    state: state?.state,
                    studyname: state?.studyname,
                    sec: state?.sec,
                  },
                });
              }}
            >
              Save & Preview
            </button>
          </Flex_box>
        </>
      )}

      {/* <Flex_box>
        <Button label={"Add Set +"} />
      </Flex_box> */}
    </div>
  );
};

export default Add_question;
