import React, { useEffect, useState } from "react";
import Layout from "../../../utilities/Layout";
import Page_heading from "../../../utilities/Page_heading";
import user_logo from "../../../assets/default_user.png";
import { theme_color } from "../../../utilities/colors";
import Text_Input from "../../../utilities/Text_Input";
import Flex_box from "../../../utilities/flex_box";
import { useNavigate } from "react-router-dom";
import {
  BASE_URL,
  BusinessDetailDetailById_api,
  image_url,
} from "../../../apis/apis";
import { useDispatch, useSelector } from "react-redux";
import edit_icon from "../../../assets/edit.svg";
import {
  loading_action,
  alert_action,
} from "../../../redux_prog/actions/base_action";
import File_input from "../../../utilities/File_input";
import { update_researcher_detail_api } from "../../../apis/apis";
import { set_user_action } from "../../../redux_prog/actions/auth_action";
import Select_box from "../../../utilities/Select_box";
import { african_countries_and_states } from "../../../utilities/utilities";

const My_Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { app_balance } = useSelector((state) => state?.appReducer) || {};
  const { user_data } = useSelector((state) => state?.authReducer) || {};

  const country_options = african_countries_and_states?.map((el) => {
    return {
      label: el.country,
      value: el.country,
    };
  });

  const get_my_profile = () => {
    dispatch(loading_action(true));
    BusinessDetailDetailById_api({ UserId: user_data?._id })
      .then((res) => {
        // console.log(res);
        dispatch(loading_action(false));
        setuserData(res.data.Res[0] || {});
      })
      .catch((err) => {
        dispatch(loading_action(false));
        console.log(err);
      });
  };

  const [disabled, setdisabled] = useState(true);

  const handelCancled = () => {
    setuser_details({
      firstname: user_data?.firstname,
      lastname: user_data?.lastname,
      username: user_data?.username,
      email: user_data?.email,
      profilepictuer: user_data?.profilepictuer
        ? image_url + user_data?.profilepictuer
        : "",
      country: user_data?.country,
    });
    setdisabled(true);
  };

  useEffect(() => {
    get_my_profile();
  }, []);

  const [userData, setuserData] = useState({});

  const {
    businessname,
    businessdescription,
    businessaddress,
    industry,
    conductindustrystudiesin,
    surname,
  } = userData;

  const [user_details, setuser_details] = useState({
    firstname: user_data?.firstname,
    lastname: user_data?.lastname,
    username: user_data?.username,
    email: user_data?.email,
    profilepictuer: user_data?.profilepictuer
      ? image_url + user_data?.profilepictuer
      : "",
    country: user_data?.country,
  });
  const [image, setimage] = useState("");
  const { firstname, lastname, username, email, profilepictuer, country } =
    user_details;

  const upd_user_details = (key, val) => {
    if (!disabled) {
      setuser_details({
        ...user_details,
        [key]: val,
      });
    }
  };

  const upd_buisnessdetails = (key, val) => {
    if (!disabled) {
      setuserData({
        ...userData,
        [key]: val,
      });
    }
  };

  // Function to handle file input change event
  const handelImage = (value) => {
    // debugger;
    const file = value;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        user_details.profilepictuer = reader.result;
        console.log("img base64", reader.result);
        //setBase64(reader.result);
        // setImagePreview(URL.createObjectURL(file));
      };
      reader.readAsDataURL(file);
    }
  };

  const updateUserDetails = () => {
    dispatch(loading_action(true));
    // debugger;
    // debugger;
    let body = {
      UserDetail: {
        Id: user_data._id,
        FirstName: user_details.firstname,
        LastName: user_details.lastname,
        Email: user_details.email,
        UserName: user_details.username,
        profilepictuer: user_details.profilepictuer?.includes(image_url)
          ? null
          : user_details.profilepictuer,
      },
      BusinessDetail: userData,
    };
    // debugger;
    update_researcher_detail_api(body)
      .then((res) => {
        // debugger;
        dispatch(loading_action(false));
        console.log(res);
        if (res.data.status == 1) {
          dispatch(
            alert_action({
              type: "success",
              title: "Success",
              msg: "Update successfully!",
            })
          );
          dispatch(
            set_user_action({
              ...user_data,
              ...res.data.Res,
            })
          );
        } else {
          dispatch(
            alert_action({
              type: "error",
              title: "Error",
              msg: "Some error occurred!",
            })
          );
        }
        setdisabled(true);
      })
      .catch((err) => {
        dispatch(loading_action(false));
        dispatch(
          alert_action({
            type: "error",
            title: "Error",
            msg: "Some error occurred!",
          })
        );
      });
  };

  return (
    <Layout>
      <div
        className="input-form"
        style={{
          padding: "20px",
          paddingBottom: "50px",
        }}
      >
        <Page_heading label={"Profile"} />

        <Flex_box
          style={{
            flexWrap: "wrap",
            gap: 40,
            justifyContent: "start",
            marginTop: "20px",
          }}
        >
          <Flex_box
            className="responsive_width"
            style={{
              padding: "20px",
              gap: 20,
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
              width: "",
              boxShadow: "0px 10px 20px #0000000D",
              borderRadius: "15px",
              // backgroundColor:"red"
            }}
          >
            <div
              style={{
                position: "relative",
                alignItems: "center",
                display: "flex",
                // backgroundColor:"red"
              }}
            >
              {
                <File_input
                  onChange={(e) => {
                    console.log(e[0]);
                    //upd_user_details("profilepictuer", e[0]);
                    handelImage(e[0]);
                    setimage(URL.createObjectURL(e[0]));
                  }}
                  disabled={disabled}
                  renderItem={
                    <img
                      src={image || profilepictuer || user_logo}
                      style={{
                        height: "90px",
                        width: "90px",
                        borderRadius: "50%",
                        border: disabled ? "" : "2px solid " + theme_color,
                        cursor: disabled ? "" : "pointer",
                      }}
                    />
                  }
                />
              }

              {!disabled && (
                <Flex_box
                  style={{
                    padding: "5px",
                    backgroundColor: theme_color,
                    borderRadius: "50%",
                    position: "absolute",
                    top: "0px",
                    right: "5px",
                    width: "12px",
                    height: "12px",
                  }}
                >
                  <img
                    src={edit_icon}
                    style={{
                      height: "11px",
                    }}
                  />
                </Flex_box>
              )}

              <div class="main-heding">
                {" "}
                <strong class="heding-total">
                  &nbsp;&nbsp;Balance :{" "}
                </strong>{" "}
                {app_balance}
              </div>
            </div>
            <Text_Input
              label={"First Name"}
              cont_style={{
                width: "100%",
              }}
              input_style={{
                width: "90%",
              }}
              value={firstname}
              disabled={disabled}
              onChange={(e) => {
                upd_user_details("firstname", e.target.value);
              }}
            />
            <Text_Input
              label={"Last Name"}
              cont_style={{
                width: "100%",
              }}
              input_style={{
                width: "90%",
              }}
              value={lastname}
              disabled={disabled}
              onChange={(e) => {
                upd_user_details("lastname", e.target.value);
              }}
            />
            <Text_Input
              label={"Username"}
              cont_style={{
                width: "100%",
              }}
              input_style={{
                width: "90%",
              }}
              value={username}
              disabled={disabled}
              onChange={(e) => {
                upd_user_details("username", e.target.value);
              }}
            />
            <Text_Input
              label={"Email"}
              cont_style={{
                width: "100%",
              }}
              input_style={{
                width: "90%",
              }}
              value={email}
              disabled={true}
              onChange={(e) => {
                upd_user_details("email", e.target.value);
              }}
            />
            {country}
            <Select_box
              label={"Select Country"}
              options={country_options}
              cont_style={{
                width: "90%",
              }}
              value={country}
              onChange={(e) => {
                upd_user_details("country", e.target.value);
              }}
              s_className="country"
            />
          </Flex_box>

          <Flex_box
            className="responsive_width"
            style={{
              padding: "20px",
              gap: 20,
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
              width: "",
              boxShadow: "0px 10px 20px #0000000D",
              borderRadius: "15px",
            }}
          >
            <b>Business Detail's</b>

            <Text_Input
              label={"Business Name"}
              cont_style={{
                width: "100%",
              }}
              input_style={{
                width: "90%",
              }}
              value={businessname}
              disabled={disabled}
              onChange={(e) => {
                upd_buisnessdetails("businessname", e.target.value);
              }}
            />
            <Text_Input
              label={"Business Description"}
              textArea={true}
              cont_style={{
                width: "90%",
              }}
              input_style={{
                width: "90%",
                minHeight: "60px",
              }}
              value={businessdescription}
              disabled={disabled}
              onChange={(e) => {
                upd_buisnessdetails("businessdescription", e.target.value);
              }}
            />
            <Text_Input
              label={"Business Address"}
              cont_style={{
                width: "100%",
              }}
              input_style={{
                width: "90%",
              }}
              value={businessaddress}
              disabled={disabled}
              onChange={(e) => {
                upd_buisnessdetails("businessaddress", e.target.value);
              }}
            />
            <Text_Input
              label={"Industry"}
              cont_style={{
                width: "100%",
              }}
              input_style={{
                width: "90%",
              }}
              value={industry}
              disabled={disabled}
              onChange={(e) => {
                upd_buisnessdetails("industry", e.target.value);
              }}
            />
            <Text_Input
              label={"Conduct Industry Studies in"}
              cont_style={{
                width: "100%",
              }}
              input_style={{
                width: "90%",
              }}
              value={conductindustrystudiesin}
              disabled={disabled}
              onChange={(e) => {
                upd_buisnessdetails("conductindustrystudiesin", e.target.value);
              }}
            />
          </Flex_box>
        </Flex_box>
        <Flex_box
          style={{
            gap: 20,
            justifyContent: "start",
          }}
        >
          <button
            id="btn_comp"
            style={{
              marginTop: "20px",
              width: "150px",
            }}
            onClick={() => {
              // navigate('/edit-profile')
              if (disabled) {
                setdisabled(false);
              } else {
                updateUserDetails();
                // setdisabled(true);
              }
            }}
          >
            {disabled ? "Edit Profile" : "Save"}
          </button>
          {!disabled && (
            <button
              id="btn_comp"
              style={{
                marginTop: "20px",
                backgroundColor: "red",
                width: "150px",
              }}
              onClick={() => {
                handelCancled(true);
              }}
            >
              Cancel
            </button>
          )}
        </Flex_box>
      </div>
    </Layout>
  );
};

export default My_Profile;
