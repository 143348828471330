import React, { useEffect, useState } from "react";
import Sidebar from "../../Nav_Comp/Sidebar";
import Table from "../../../utilities/Table";
import Flex_box from "../../../utilities/flex_box";
import Page_heading from "../../../utilities/Page_heading";
import {
  alert_action,
  loading_action,
} from "../../../redux_prog/actions/base_action";
import { get_doc_type } from "../../../utilities/select_arr";
import Navbar from "../../Nav_Comp/Navbar";
import Footer from "../../Nav_Comp/Footer";
import Support_Chat from "../../Support_Chat";
import { theme_bg, theme_color } from "../../../utilities/colors";
import { useNavigate } from "react-router-dom";
import Layout from "../../../utilities/Layout";
import { GetUserSubscriptionsById_api } from "../../../apis/apis";
import Subscription_Box from "../Subscriptions/Subscription_Box";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../../utilities/DataTable";
import { Modal } from "../../../utilities/Modal";

const Index = () => {
  const dispatch = useDispatch();
  const [KYC_arr, setKYC_arr] = useState([]); // KYC documents array
  const [tableHeaders, setTableHeaders] = useState([]); // Dynamic table headers
  const user_data = useSelector((state) => state?.authReducer?.user_data) || {};
  const [selected_item, setselected_item] = useState({});
  const [modalVisible, setmodalVisible] = useState(false);
  const { username, email, profilepictuer } = user_data;

  const t_heads = [
    {
      label: "S No.",
      accessor: "index",
      element: (el, index) => {
        return (
          <div
            style={{
              width: "80px",
            }}
          >
            <span>{index + 1}</span>
          </div>
        );
      },
    },
    {
      label: "Plan Name",
      accessor: "doc_type",
      tr_style: {
        minWidth: "120px",
      },
      element: (el) => {
        return <span>{el?.plan?.Name}</span>;
      },
    },
    {
      label: "Amount",
      accessor: "doc_type",
      tr_style: {
        minWidth: "120px",
      },
      element: (el) => {
        return <span>{el?.plan?.Amount}</span>;
      },
    },
    {
      label: "Start Date",
      accessor: "doc_type",
      tr_style: {
        minWidth: "120px",
      },
      element: (el) => {
        return (
          <span>
            {new Date(el?.subscription?.createdAt).toLocaleDateString()}
          </span>
        );
      },
    },
    {
      label: "Expire Date",
      accessor: "doc_type",
      tr_style: {
        minWidth: "120px",
      },
      element: (el) => {
        return (
          <span>
            {new Date(el?.subscription?.exp_date).toLocaleDateString()}
          </span>
        );
      },
    },
    {
      label: "Status",
      accessor: "subscription.status",
      tr_style: {
        minWidth: "120px",
      },
      element: (el,index) => {
        return (
          <span
            style={{
              color: el?.subscription?.status ? "green" : "red",
            }}
          >
            {el?.subscription?.status ? "Active" : "Inactive"}
          </span>
        ); // Dynamically displaying status
      },
    },
  ];

  // Fetch active subscriptions from API
  const get_Active_subscriptions = () => {
    dispatch(loading_action(true));

    GetUserSubscriptionsById_api({
      UserId: user_data?._id,
    })
      .then((res) => {
        // Log the entire response to check the structure
        console.log("Full API Response:", res);

        // Check if the response contains the expected data
        if (res.data && res.data.status === 1) {
          // Access the subscriptions array
          const subscriptionsData = res.data.data.subscriptions;
          const userData = res.data.data.user; // Correctly access the user data

          // Log the subscriptions and user data
          console.log("Subscriptions Data:", subscriptionsData);
          console.log("User Data:", userData); // Correct logging for user data

          if (subscriptionsData?.length > 0) {
            // Set the subscription data into the state
            setKYC_arr(subscriptionsData);

            // Dynamically set table headers based on the API response keys
            // if (subscriptionsData.length > 0) {
            //   const dynamicHeaders = Object.keys(subscriptionsData[0].subscription).map((key) => ({
            //     label: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter of the header
            //     accessor: key,
            //     element: (el) => {
            //       return <span>{el.subscription[key]}</span>; // Dynamically display the value for each row
            //     },
            //   }));

            //   // Adding user information as well
            //   dynamicHeaders.push({
            //     label: "User Name",
            //     accessor: "username",
            //     element: () => <span>{userData.username}</span>,  // Use userData correctly
            //   });

            //   dynamicHeaders.push({
            //     label: "User Email",
            //     accessor: "email",
            //     element: () => <span>{userData.email}</span>,  // Display email
            //   });

            //   setTableHeaders(dynamicHeaders);
            // }
          } else {
            console.warn("No subscription data found or it's not an array");
          }
        } else {
          console.warn("API response status is not 1 or res.data is undefined");
        }

        dispatch(loading_action(false));
      })
      .catch((err) => {
        dispatch(loading_action(false));
        console.error(err);
      });
  };

  useEffect(get_Active_subscriptions, []);

  const Sub_Item = ({ label, value }) => {
    return (
      <Flex_box
        style={{
          justifyContent: "start",
          gap: 5,
          marginBottom: "6px",
        }}
      >
        <b
          style={{
            fontSize: "16px",
          }}
        >
          {label} :
        </b>
        <span
          style={{
            fontSize: "14px",
          }}
        >
          {value}
        </span>
      </Flex_box>
    );
  };

  return (
    <>
      <Layout
        style={{
          padding: "10px",
        }}
      >
        <Flex_box
          style={{
            justifyContent: "space-between",
            paddingTop: "10px",
            paddingLeft: "15px",
            width: "98%",
          }}
        >
          <Page_heading label={"User Subscription History"} />
        </Flex_box>

        {/* Display DataTable only if there is data */}
        {KYC_arr?.length > 0 && (
          <div
            style={{
              height: "60vh",
              overflow: "auto",
              marginTop: "10px",
            }}
          >
            <DataTable
              row_click_func={(el) => {
                setmodalVisible(true);
                setselected_item(el);

                console.warn(el);
              }}
              fontSize={"14px"}
              heads={t_heads}
              rows={KYC_arr}
            />{" "}
          </div>
        )}
        
      </Layout>
      <Modal
        isOpen={modalVisible}
        onClose={() => {
          setmodalVisible(false);
        }}
        label={"Reciept Modal"}
        label_style={{
          width: "100%",
        }}
        content_style={{
          padding: "0px",
          width: "300px",
        }}
      >
        {selected_item?.plan?.Name && (
          <div
            style={{
              padding: "10px 10px 0px 10px",
              width: "100%",
            }}
          >
            <Sub_Item label={"Package"} value={selected_item?.plan?.Name} />
            <Sub_Item label={"Amount"} value={selected_item?.plan?.Amount} />
            <Sub_Item
              label={"Transaction Id"}
              value={selected_item?.subscription?.transaction_id}
            />
            <Sub_Item
              label={"Start Date"}
              value={new Date(
                selected_item?.subscription?.createdAt
              ).toLocaleDateString()}
            />
            <Sub_Item
              label={"Exp Date"}
              value={new Date(
                selected_item?.subscription?.exp_date
              ).toLocaleDateString()}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default Index;
