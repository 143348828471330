import React from "react";
import Layout from "../utilities/Layout";
import Page_heading from "../utilities/Page_heading";
import { theme_bg } from "../utilities/colors";
import FA_Icon_box from "../utilities/Icon_Box";
import { useNavigate } from "react-router-dom";

const Privacy_Policy = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        padding: "20px",
        boxSizing: "border-box",
      }}
    >
      <Page_heading
        label={""}
        suffix={
          <FA_Icon_box
            className={"fa-regular fa-circle-xmark"}
            style={{
              backgroundColor: "#000",
            }}
            onClick={() => {
                navigate(-1)
            }}
          />
        }
      />
      <div
        style={{
          backgroundColor: theme_bg,
          padding: "15px",
          borderRadius: "10px",
          fontSize: "12px",
          lineHeight: 1.7,
          marginTop: "15px",
          height: "85vh",
          overflowY: "auto",
        }}
      >
        <h2><b>Privacy Policy</b></h2>
        <b>Effective Date:</b> December 22, 2023<br /><br />{" "}
      
        Yaarnbox respects your privacy and is committed to protecting the personal information you share with us. This Privacy Policy explains how we collect, use, and protect your data when you access our platform. By using Yaarnbox, you consent to this Privacy Policy. We are based in Lagos, Nigeria, and our platform is functional across all African countries. Your privacy is of utmost importance to us, and we are committed to complying with data protection laws applicable in Nigeria and across Africa.
<br/><br/>
<h5>1. Information We Collect</h5>
We collect and process personal information when you use Yaarnbox, including:

<br/><b>Account Information:</b> When you register on Yaarnbox, we collect your name, email address, phone number, and demographic data such as age, gender, and location.
<br/><b>Survey Responses:</b> If you participate in research studies, we collect the data you provide in response to survey questions. This may include personal opinions, preferences, and other sensitive information.
<br/><b>Usage Data: </b>We gather information about your interactions with our platform, such as the types of surveys completed, reward redemptions, and in-app navigation.
<br/><b>Device Information:</b> We automatically collect technical data from your device, such as your IP address, browser type, device ID, and operating system, to improve performance and functionality.
<br/><b>Location Data: </b>When applicable, we may collect location information to personalize your experience and offer region-specific content or services.
<br /><br/> 
<h5>2. How We Use Your Information</h5>
Yaarnbox uses the information we collect to provide and improve our services. Specifically, we use your data to:

<br/><b>Facilitate research studies:</b> We use your information to match you with relevant research studies, ensuring a personalized experience.
<br/><b>Manage rewards and incentives:</b> Your account data helps us track your participation and reward you for completing your studies.
<br/><b>Improve user experience: </b>Analyzing usage data allows us to make the platform more intuitive and responsive to user needs.
<br/><b>Enhance platform functionality:</b> Device and location information enables us to optimize the app’s performance across different devices and regions.
<br/><b>Communicate with you: </b>We may send you notifications regarding surveys, promotions, or updates related to Yaarnbox.
<br/><b>Legal obligations:</b> We may use or disclose your information if required by law to comply with legal obligations or requests from government authorities.
<br /><br/> 
<h5>3. Sharing Your Information</h5>
We value your privacy and do not sell your personal information. However, we may share your data under the following conditions:

<br/><b>With research clients:</b> Your data may be shared with researchers and brands, but only in anonymized and aggregated forms that do not personally identify you.
<br/><b>Third-party service providers:</b> We work with trusted partners who help us operate Yaarnbox (e.g., for hosting, data analysis, or rewards fulfilment), and they are bound by strict confidentiality agreements.
<br/><b>Legal purposes: </b>If required by law, we may disclose your information to comply with legal requests or court orders.
<br /><br/> 
<h5>4. Data Security</h5>
Yaarnbox implements advanced security measures to safeguard your personal information against unauthorized access, loss, and misuse. These include data encryption, firewalls, and secure cloud storage. However, while we strive to protect your data, no system is completely secure, and we cannot guarantee the security of your information transmitted to or through the platform.
<br /><br/> 
<h5>5. Your Data Rights</h5>
Following Nigeria’s Data Protection Regulations and other applicable laws in Africa, you have certain rights regarding your data:
<br/>
<b>Access:</b> You can request to access the personal data we hold about you.
<br/><b>Correction:</b> You have the right to correct any inaccuracies in your personal information.
<br/><b>Deletion:</b> You may request the deletion of your personal data under certain circumstances, although some data may need to be retained for legal or research purposes.
<br/><b>Objection:</b> You may object to how we use your personal data for marketing or other purposes.
<br/><b>Data portability:</b> You can request a copy of your personal information in a structured format.
To exercise these rights, please contact us at support@yaarnbox.com. We will respond to your request within 30 days.
<br /><br/> 
<h5>6. Data Retention</h5>
Yaarnbox retains your data for as long as necessary to fulfill the purposes for which it was collected, including legal and business requirements. If your account remains inactive for a prolonged period, we may anonymize or delete your data.
<br /><br/> 
<h5>7. Children’s Privacy</h5>
Yaarnbox is not intended for use by individuals under the age of 18. We do not knowingly collect personal data from children. If we learn that we have collected personal information from a child without parental consent, we will take steps to delete the data promptly.
<br /><br/> 
<h5>8. Changes to This Privacy Policy</h5>
We may update this Privacy Policy from time to time to reflect changes in our services or legal requirements. You will be notified of any significant changes through email or an in-app notification. Please review this policy periodically to stay informed of our data practices.
<br /><br/> 
<h5>9. Contact Us</h5>
If you have any questions or concerns about this Privacy Policy or how we handle your data, please contact us at:
<br/>
General Enquiries: contact@yaarnbox.com
<br/>
Technical Support: support@yaarnbox.com
        {/* Limited, the developers and operators of Yaarnbox ("Application", "we",
        "us", or "our"), are committed to protecting your privacy. This Privacy
        Policy ("Policy") describes the information we collect from you, how we
        use it, disclose it, and protect it. Please read this Policy carefully
        before using the Application. 
        Information We Collect We collect
        different types of information from you depending on your interaction
        with the Application. This information may include: Personal
        Information: Account Information: Name, email address, password,
        username, profile picture, and other information you provide during
        registration or through your account settings.
         Payment Information:
        Payment method details (e.g., credit card number, billing address) if
        you upgrade to a paid plan. Usage Data: Information about your use of
        the Application, such as features accessed, files uploaded, data
        processed, and actions taken.
         Log Data: Technical information
        automatically collected when you use the Application, such as IP
        address, browser type, operating system, date and time of access,
        referring/exit pages, and cookie data.
         Non-Personal Information:
        Anonymous Usage Data: Aggregated information about how users interact
        with the Application, which does not identify any individual user.
        Device Information: Information about your device, such as hardware
        model, operating system version, and unique identifiers.
         How We Use Your Information
         We use your information for the following purposes: To
        provide and operate the Application: We use your Account Information to
        identify you as a user and provide you with access to the Application's
        features. We use your Usage Data to improve the Application's
        performance and functionality. We use your Log Data to troubleshoot
        technical issues and maintain the security of the Application. To send
        you important information: We use your email address to send you account
        updates, security alerts, and other important information related to the
        Application. For marketing and promotional purposes: We may use your
        information to send you promotional emails about new features, products,
        or services offered by us. You can opt out of these emails at any time
        by following the unsubscribe instructions in the emails. To comply with
        legal requirements: We may use your information to comply with
        applicable laws and regulations.
        
         How We Share Your Information We may
        share your information with third-party service providers who help us
        operate the Application and provide services to you. These service
        providers will only have access to your information as needed to perform
        their services and are obligated to protect your information. We will
        not share your information with any third-party for marketing or
        promotional purposes without your consent. Data Retention We will retain
        your information for as long as it is necessary to provide you with the
        Application and fulfill the purposes described in this Policy. We may
        also retain your information for legal or compliance purposes. Your
        Choices You have the following choices regarding your information:
        Account Information: You can access and update your Account Information
        at any time through your account settings. Marketing Emails: You can opt
        out of receiving marketing emails from us by following the unsubscribe
        instructions in the emails. Cookies: You can control your cookie
        preferences through your browser settings. Access to Information: You
        can request access to the information we have collected about you by
        contacting us using the information provided below. Data Security We
        take appropriate technical and organizational measures to protect your
        information from unauthorized access, disclosure, alteration, or
        destruction. However, no website or internet transmission is completely
        secure, and we cannot guarantee the security of your information.
        International Transfers Your information may be transferred to and
        processed in countries other than your own. These countries may have
        different data protection laws than your own country. We will take
        reasonable steps to ensure that your information is treated securely and
        in accordance with this Policy. Children's Privacy The Application is
        not intended for children under the age of 13. We do not knowingly
        collect personal information from children under the age of 13. If you
        are a parent or guardian and you are aware that your child has provided
        us with personal information, please contact us. Changes to this Policy
        We may update this Policy from time to time. We will notify you of any
        changes by posting the new Policy on the Application. We encourage you
        to review this Policy regularly for any updates. Contact Us If you have
        any questions about this Policy, please contact us at: [Your email
        address] [Your physical address] We hope this Policy helps you
        understand how we collect, use, and protect your information. If you
        have any questions or concerns, please do not hesitate to contact us. */}
      </div>
    </div>
  );
};

export default Privacy_Policy;
