import React, { useEffect, useState } from "react"
import Page_heading from "../../../../utilities/Page_heading"
import Flex_box from "../../../../utilities/flex_box"
import survey_pic from "../../../../assets/Image.png"
import { theme_bg, theme_color } from "../../../../utilities/colors"
import { useLocation, useNavigate } from "react-router-dom"
import green_check from "../../../../assets/Mask Group 4.png"
import { useDispatch, useSelector } from "react-redux"
import { alert_action } from "../../../../redux_prog/actions/base_action"
import {
  check_study_sec_available_api,
  get_gd_member_count_api,
  get_survey_resp_count_api,
  getGDbyStudyId_api,
  GetSurveyByStudyId_api,
  image_url,
  leave_gd_api,
  send_gd_join_request_api,
} from "../../../../apis/apis"
import { Modal } from "../../../../utilities/Modal"
import Eligibility_Question from "./EligibilityQuestions"
import Button from "../../../../utilities/Button"

const Survey_Comp = ({ completed, study_id, survey_details, setsurvey_details, study_detail,ongoing }) => {
  const navigate = useNavigate()

  const { user_data } = useSelector((state) => state?.authReducer)
  const dispatch = useDispatch()

  const get_survey_details = () => {
    GetSurveyByStudyId_api({ studyid: study_id })
      .then((res) => {
        if (res.data.status == 1) {
          setsurvey_details(res.data.Res)
        }
      })
      .catch((Err) => {
        console.log("====================================")
        console.log(Err)
        console.log("====================================")
      })
  }

  useEffect(get_survey_details, [])

  const { surveydata, questionsdata } = survey_details

  const [numberOfQuestion, setNumberOfQuestion] = useState(0)

  useEffect(() => {
    var qstnList = []
    debugger
    console.log("not dependent question", questionsdata)
    for (let i = 0; i < questionsdata?.length; i++) {
      var question = questionsdata?.find((x) =>
        x?.Options.find((y) => y.dependent_question == questionsdata[i].QuestionData.serial_no),
      )
      if (!question) {
        qstnList.push(questionsdata[i])
        questionsdata[i]["depend_question"] = false
      } else {
        questionsdata[i]["depend_question"] = true
        qstnList.push(questionsdata[i])
      }
    }
    console.log("dependent questions", qstnList)

    var noOfNotDependentQuestion = qstnList.filter((x) => !x.depend_question).length
    var noOfDependentQuestions = qstnList.filter((x) =>
      x?.Options.some((y) => y?.dependent_question),
    ).length
    console.log("no of not dependent question", noOfNotDependentQuestion)
    console.log("no of dependent question", noOfDependentQuestions)
    setNumberOfQuestion(noOfNotDependentQuestion + noOfDependentQuestions)
  })

  const get_survey_resp_count = () => {
    get_survey_resp_count_api({ survey_id: survey_details?.surveydata?._id }, user_data?.role)
      .then((res) => {
        // settotal_resp_count(res.data.Res);
        if (res.data.Res < study_detail?.totalrespondents) {
          check_study_sec_available()
        } else {
          dispatch(
            alert_action({
              type: "error",
              title: "Max Limit!",
              msg: `This survey has reached it's maximum respondents!`,
            }),
          )
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const check_study_sec_available = () => {
    check_study_sec_available_api({
      study_id: surveydata?.studyid,
      sec: user_data?.sec,
    })
      .then((res) => {
        if (res.data.status == 1 || ongoing) {
          navigate("/take-survey", { state: survey_details })
        } else {
          dispatch(
            alert_action({
              title: "Respondents Completed",
              type: "error",
              msg: "You are not eligible to join this study because all the required respondents has been completed.",
              btn_text: "OK",
              isopen: true,
            }),
          )
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <Flex_box
      className="responsive_width_35"
      style={{
        width: "",
        flexDirection: "column",
        alignItems: "start",
        padding: "12px",
        boxSizing: "border-box",
        boxShadow: "0px 0px 30px #A6A6A633",
        borderRadius: "10px",
        marginTop: "20px",
      }}
    >
      <img
        onClick={() => {
          // navigate("/gd-chat");
        }}
        style={{
          width: "100%",
          height: "230px",
          objectFit: "cover",
          borderRadius: "10px",
          cursor: "pointer",
        }}
        src={surveydata?.image ? image_url + surveydata?.image : survey_pic}
      />
      <b style={{ fontSize: "18px", marginTop: "10px" }}>{surveydata?.surveytitle}</b>
      <span style={{ fontSize: "14px", fontWeight: "600", marginTop: "10px" }}>
        Questions Added : {numberOfQuestion}
      </span>
      <span style={{ fontSize: "12px", marginTop: "9px" }}>{surveydata?.surveydescription}</span>
      {completed && (
        <Flex_box
          style={{
            // width: "",
            gap: 25,
            justifyContent: "space-between",
            padding: "20px 0px 10px 0px",
            // marginTop: "10px"
          }}
        >
          <Flex_box
            style={{
              // width: '',
              justifyContent: "start",
              gap: 10,
            }}
          >
            <b>Completed</b>
            <img
              style={{
                height: "20px",
              }}
              src={green_check}
            />
          </Flex_box>
          <button
            id="btn_comp"
            // style={{ fontSize: "16px", fontWeight: "500", backgroundColor: "#FFCC99", color: "#000" }}
            onClick={() => {
              navigate("/survey-response", {
                state: {
                  study_id: surveydata?.studyid,
                },
              })
            }}
          >
            Review Response
          </button>
        </Flex_box>
      )}
      {!completed && (
        <Flex_box
          style={{
            // width: "",
            gap: 25,
            // justifyContent: "start",
            padding: "20px 0px 10px 0px",
          }}
        >
          <button
            id="btn_comp"
            style={{
              fontWeight: "600",
              color: theme_color,
              backgroundColor: theme_bg,
              border: "2px solid " + theme_color,
            }}
            onClick={() => {
              // check_study_sec_available();
              get_survey_resp_count()
            }}
          >
            Take survey
          </button>
        </Flex_box>
      )}
    </Flex_box>
  )
}

const GD_Comp = ({
  completed,
  study_id,
  survey_details,
  setsurvey_details,
  study_detail,
  gd_data,
  setgd_data,
  setmodalVisible,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user_data } = useSelector((state) => state?.authReducer)
  const [total_resp_count, settotal_resp_count] = useState(0)

  const get_gd_details = () => {
    getGDbyStudyId_api({
      StudyId: study_id,
    })
      .then((res) => {
        setgd_data(res.data.Res[0])
        get_gd_member_count_api({ g_id: res.data.Res[0]?._id }, user_data?.role)
          .then((g_res) => {
            settotal_resp_count(g_res.data.Res)
          })
          .catch((err) => {
            console.error(err)
          })
      })
      .catch((err) => {
        console.log("====================================")
        console.log(err)
        console.log("====================================")
      })
  }

  const gd_request_data =
    gd_data?.g_requests_lookup?.filter((el) => {
      return el?.u_id == user_data?._id
    }) || []
  const gd_request_status = gd_request_data[0]?.status

  const { _id, groupdiscussionname, discussionabout, date, time, audio, video } = gd_data || {}

  useEffect(get_gd_details, [])

  const req_action_data = (status) => {
    return {}
  }

  return (
    <Flex_box
      className="responsive_width_35"
      style={{
        width: "",
        flexDirection: "column",
        alignItems: "start",
        padding: "12px",
        boxSizing: "border-box",
        boxShadow: "0px 0px 30px #A6A6A633",
        borderRadius: "10px",
        marginTop: "20px",
      }}
    >
      <b style={{ fontSize: "18px", marginTop: "10px" }}>{groupdiscussionname}</b>
      <p
        style={{
          marginTop: "8px",
          fontSize: "15px",
        }}
      >
        <span style={{ fontWeight: 600 }}>Start Date & Time </span>: {date + "-" + time}
      </p>
      <span style={{ fontSize: "12px", marginTop: "-6px" }}>{discussionabout}</span>
      <Flex_box
        style={{
          gap: 25,
          padding: "20px 0px 10px 0px",
        }}
      >
        {gd_request_status != 4 && (
          <button
            id="btn_comp"
            style={
              gd_request_status == 1
                ? {}
                : {
                    fontWeight: "600",
                    color: theme_color,
                    backgroundColor: theme_bg,
                    border: "2px solid " + theme_color,
                  }
            }
            onClick={() => {
              if (gd_request_status == 1) {
                navigate("/chat-comp", {
                  state: {
                    // is_gd: true,
                    study_detail: study_detail,
                    gd_data: gd_data,
                  },
                })
              } else if (gd_request_data?.length != 1) {
                if (total_resp_count < study_detail?.totalrespondents) {
                  dispatch(
                    alert_action({
                      type: "success",
                      title: "Join?",
                      msg: "Do you want to join this discussion?",
                      is_confirm: true,
                      onSuccess: () => {
                        setmodalVisible(true)
                      },
                    }),
                  )
                } else {
                  dispatch(
                    alert_action({
                      type: "error",
                      title: "Max Limit!",
                      msg: `This study has reached it's maximum members!`,
                    }),
                  )
                }
              }
            }}
          >
            {gd_request_status == 1
              ? "Go to chat"
              : gd_request_data?.length > 0 && gd_request_status == 0
                ? "Approval Pending"
                : gd_request_status == 2
                  ? "Rejected"
                  : "Join Group Discussion"}
          </button>
        )}
        {gd_request_status == 1 && (
          <Button
            label={"Leave"}
            style={{
              backgroundColor: "red",
            }}
            onClick={() => {
              leave_gd_api({
                u_id: user_data?._id,
                g_id: gd_data?._id,
              })
                .then((res) => {
                  console.warn(res.data)
                  get_gd_details();
                })
                .catch((err) => {
                  console.warn(err)
                })
            }}
          />
        )}
        {gd_request_status == 4 && (
          <Button
            label={"Left Group"}
            style={{
              backgroundColor: "red",
              border:"2px solid red"
            }}
          />
        )}
      </Flex_box>
    </Flex_box>
  )
}

const Study_Block = ({
  study_id,
  study_detail,
  survey_details,
  setsurvey_details,
  state,
  gd_data,
  setgd_data,
}) => {
  const dispatch = useDispatch()

  const { user_data } = useSelector((state) => state?.authReducer)
  const type = state?.type
  const completed = state?.completed
const ongoing = state?.ongoing;
  // alert(completed)

  const [modalVisible, setmodalVisible] = useState(false)

  return (
    <>
      <div
        style={{
          padding: "20px 0px",
        }}
      >
        <Page_heading label={type == 1 ? "Survey" : "Group Discussion"} />
        {type == 1 && (
          <Survey_Comp
            survey_details={survey_details}
            setsurvey_details={setsurvey_details}
            completed={completed}
            study_id={study_id}
            study_detail={study_detail}
            ongoing={ongoing}
          />
        )}
        {type == 2 && (
          <GD_Comp
            gd_data={gd_data}
            setgd_data={setgd_data}
            study_detail={study_detail}
            study_id={study_id}
            setmodalVisible={setmodalVisible}
          />
        )}
      </div>
      <Modal
        isOpen={modalVisible}
        onClose={() => {
          setmodalVisible(false)
        }}
        label={"Eligibility Question"}
      >
        <Eligibility_Question
          studyid={study_id}
          func={() => {
            send_gd_join_request_api({
              req_data: {
                u_id: user_data?._id,
                g_id: gd_data?._id,
              },
              noti_data: {
                UserId: user_data?._id,
                toId: gd_data?.userid,
                msg: "You have a group discussion join request for " + gd_data?.groupdiscussionname,
                title: "Join Request",
                type: "GD",
                notificatonTypeId: study_id,
                sender_type: 3,
              },
            })
              .then((res) => {
                if (res.data?.status == 1) {
                  setgd_data({
                    ...gd_data,
                    g_requests_lookup: [
                      {
                        u_id: user_data?._id,
                        g_id: gd_data?._id,
                        status: 0,
                      },
                    ],
                  })
                  dispatch(
                    alert_action({
                      type: "success",
                      title: "Success",
                      msg: "Request sent successfully!",
                    }),
                  )
                }
                setmodalVisible(false)
              })
              .catch((err) => {
                dispatch(
                  alert_action({
                    type: "error",
                    title: "Oops!",
                    msg: "Some error occured!",
                  }),
                )
                setmodalVisible(false)
              })
          }}
        />
      </Modal>
    </>
  )
}

export default Study_Block
