import React, { useEffect, useState } from "react";
import { Modal } from "../../../../utilities/Modal";
import Select_box from "../../../../utilities/Select_box";
import Flex_box from "../../../../utilities/flex_box";
import {
  add_voucher_history_api,
  get_countries_api,
  get_product_types_api,
  get_variation_codes_api,
  purchase_product_api,
} from "../../../../apis/apis";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../utilities/Button";
import { alert_action } from "../../../../redux_prog/actions/base_action";

const Voucher_Modal = ({ user_balance, get_resp_balance }) => {
  const [modal_visible, setmodal_visible] = useState(false);
  const [country_code, setcountry_code] = useState("");
  const [product_type, setproduct_type] = useState("");
  const [product, setproduct] = useState("");
  const [product_name, setproduct_name] = useState("");
  const [country_code_options, setcountry_code_options] = useState([]);
  const [product_type_options, setproduct_type_options] = useState([]);
  const [product_options, setproduct_options] = useState([]);
  const [modalVisible, setmodalVisible] = useState(false);
  const { user_data } = useSelector((state) => state?.authReducer);
  const dispatch = useDispatch();

  const get_countries = () => {
    get_countries_api()
      .then((res) => {
        setcountry_code_options(
          res?.data?.countries?.map((el) => {
            return {
              label: el?.name + "(" + el?.code + ")",
              value: el?.code,
            };
          })
        );
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const get_product_types = (code) => {
    get_product_types_api(code)
      .then((res) => {
        setproduct_type_options(
          res?.data?.product_types
            // ?.filter((el) => {
            //   return el?.product_type_id != 4;
            // })
            ?.map((el) => {
              return {
                label: el?.name,
                value: el?.product_type_id,
              };
            }) || []
        );
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const get_variation_codes = (product_type_id) => {
    get_variation_codes_api(product_type_id)
      .then((res) => {
        setproduct_options(
          res?.data?.variations?.map((el) => {
            return {
              label:
                el?.name +
                " ~ " +
                el?.charged_amount +
                " " +
                el?.charged_currency,
              value: el?.variation_code,
              ...el,
            };
          })
        );
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const redeem_voucher = () => {
    const payload = {
      serviceID: "foreign-airtime",
      billersCode: 7644432652,
      variation_code: product,
      amount: 1234,
      phone: "08011111111",
      operator_id: "5",
      country_code: country_code,
      product_type_id: product_type,
      email: user_data?.email,
      user_id: user_data?._id,
      variation_name: product_name,
    };

    purchase_product_api(payload)
      .then((res) => {
        const voucher_data = {
          status: res.data.transaction.status,
          product_name: res.data.transaction.product_name,
          amount: res.data.transaction.amount,
          quantity: res.data.transaction.quantity,
          transaction_id: res.data.transaction.transactionId,
          transaction_date: res.data.transaction_date.date,
          country_code,
          user_id: user_data?._id,
        };
        add_voucher_history(voucher_data);
      })
      .catch((err) => {
        setmodalVisible(false);
        console.warn(err);
      });
  };

  const add_voucher_history = (v_data) => {
    add_voucher_history_api({
      voucher_body: v_data,
      balance: user_balance - (v_data?.amount * 2),
    })
      .then((res) => {
        get_resp_balance();
        setmodalVisible(false);
        dispatch(
          alert_action({
            type: "success",
            title: "Transaction Successfull!",
            msg: "Your voucher is successfully redeemed!",
          })
        );
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  useEffect(() => {
    get_countries();
  }, []);

  return (
    <>
      <Button
        onClick={() => {
          setmodalVisible(true);
        }}
        label={"Redeem Now!"}
      />
      <Modal
        isOpen={modalVisible}
        label={"Redeem your vouchers!"}
        content_style={{
          padding: "0px",
        }}
        style={{
          padding: "0px",
        }}
        label_style={{
          width: "100%",
        }}
        onClose={() => {
          setmodalVisible(false);
        }}
      >
        <Flex_box
          style={{
            justifyContent: "space-between",
            gap: 10,
            flexWrap: "wrap",
            padding: "10px",
          }}
        >
          <Select_box
            cont_style={{
              width: "45%",
            }}
            label={"Select Country Code"}
            options={country_code_options}
            onChange={(e) => {
              setcountry_code(e.target.value);
              setproduct("");
              setproduct_options([]);
              setproduct_type("");
              get_product_types(e.target.value);
            }}
          />
          <Select_box
            cont_style={{
              width: "45%",
            }}
            label={"Select Product Type"}
            options={product_type_options}
            onChange={(e) => {
              setproduct_type(e.target.value);
              get_variation_codes(e.target.value);
              setproduct("");
            }}
            value={product_type}
          />
          <Select_box
            cont_style={{
              width: "100%",
            }}
            label={"Select Vouchers"}
            options={product_options}
            onChange={(e) => {
              const p_obj = product_options?.filter((li) => {
                return li?.value == e.target.value;
              })[0];
              // console.warn(p_obj);
              
              if (p_obj?.charged_amount <= (user_balance / 2)) {
                setproduct(e.target.value);
                setproduct_name(p_obj?.name);
              } else {
                dispatch(
                  alert_action({
                    type: "error",
                    title: "Insufficient Coins",
                    msg: "You do not have enough coins to redeem this voucher!",
                    // msg:p_obj?.amount+"-"+(user_balance / 2)
                  })
                );
              }
            }}
            value={
              product
                ? product_options.filter((el) => {
                    return el?.value == product;
                  })[0]
                : ""
            }
          />
          <Button
            label={"Redeem Now"}
            onClick={redeem_voucher}
            style={{
              width: "100%",
            }}
            disabled={!product}
          />
        </Flex_box>
      </Modal>
    </>
  );
};

export default Voucher_Modal;
