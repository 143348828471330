import "./App.css";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux_prog/store/store';
import Navigation_comp from './Navigation_Comp';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Navigation_comp />
        <ToastContainer toastClassName="toast-container" />
      </PersistGate>
    </Provider>
  );
}

export default App;