import React from 'react'
import Flex_box from '../../../../utilities/flex_box'
import Sidebar from '../../../Nav_Comp/Sidebar'
import Navbar from '../../../Nav_Comp/Navbar'
import Survey_Form from './Survey_Form'
import Footer from '../../../Nav_Comp/Footer'
import Layout from '../../../../utilities/Layout'

const Create_Survey = () => {
    return (
        <>
        {/* <Flex_box
            style={{
                justifyContent: "start"
            }}
        >
            <Sidebar />
            <div
                className='comp_container'
                style={{
                    position: 'relative',
                    // paddingBottom:"30px"
                }}
            >
                <Navbar />
                <Survey_Form />
                <Footer />
            </div>
        </Flex_box> */}
        <Layout>
        <Survey_Form />
        </Layout>
        </>
    )
}

export default Create_Survey
