import React, { useEffect, useMemo, useRef, useState } from "react";
import Flex_box from "../../../../utilities/flex_box";
import msg_icon from "../../../../assets/001-ui.svg";
import { theme_color } from "../../../../utilities/colors";
import pin_icon from "../../../../assets/attach.svg";
import File_input from "../../../../utilities/File_input";
import { convert_into_base64 } from "../../../../utilities/Image_conv";
import { upload_image_api } from "../../../../apis/apis";
import { useDispatch } from "react-redux";
import { alert_action } from "../../../../redux_prog/actions/base_action";
import { useForkRef } from "@mui/material";
// import { getElementScrollY, getCaptionScrollPX } from "../../../../Transcript_Comp/common"
import { useSelector } from "react-redux";
// import HomePage from "../../../../Transcript_Comp/pages/home"
import STT from "./STT";
import { toast } from "react-toastify";

const Chat_Footer = ({ onClick, g_id, video_call }) => {
  const [msg, setmsg] = useState("");
  const dispatch = useDispatch();

  const confirmAction = () => {
    const handleConfirm = () => {
      toast.dismiss(); // Close the confirmation toast
      toast.success("Action confirmed!");
    };

    const handleCancel = () => {
      toast.dismiss(); // Close the confirmation toast
      toast.info("Action canceled.");
    };

    toast(
      ({ closeToast }) => (
        <div
          style={{
            cursor: "pointer",
          }}
        >
          <p>Are you sure you want to proceed?</p>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              onClick={handleConfirm}
              style={{
                padding: "5px 10px",
                backgroundColor: "green",
                color: "white",
                border: "none",
                cursor: "pointer",
              }}
            >
              Confirm
            </button>
            <button
              onClick={handleCancel}
              style={{
                padding: "5px 10px",
                backgroundColor: "red",
                color: "white",
                border: "none",
                cursor: "pointer",
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      ),
      { autoClose: false } // Keep the toast open until the user interacts
    );
  };

  return (
    <>
      <Flex_box
        style={{
          justifyContent: "space-between",
          padding: "15px",
          boxShadow: "0px -5px 15px #53B1FF0D",
          backgroundColor: "#fff",
          width: "97%",
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
        }}
      >
        <input
          placeholder="Message..."
          className="transaparent_input"
          style={{
            border: "0px",
            width: "70%",
            color: "#000",
            fontSize: "16px",
          }}
          id="msg_input"
          value={msg}
          onChange={(e) => {
            setmsg(e.target.value);
          }}
        />
        <Flex_box
          style={{
            width: "",
            gap: 20,
          }}
        >
          <Flex_box
            style={{
              width: "32px",
              height: "32px",
              borderRadius: "50%",
              backgroundColor: "rgb(2, 103, 193,0.08)",
            }}
          >
            <File_input
              renderItem={
                <img
                  src={pin_icon}
                  style={{
                    height: "15px",
                    cursor: "pointer",
                  }}
                />
              }
              onChange={(files, e) => {
                convert_into_base64(files[0], (base64) => {
                  upload_image_api({ image_file: base64?.split(",")[1] }).then(
                    (res) => {
                      if (res.data.status == 1) {
                        onClick(e, res.data.file_name);
                      } else {
                        dispatch(
                          alert_action({
                            type: "error",
                            title: "Error",
                            msg: "Some error occurred!",
                          })
                        );
                      }
                    }
                  );
                });
              }}
            />
          </Flex_box>
          <Flex_box
            style={{
              width: "32px",
              height: "32px",
              borderRadius: "50%",
              backgroundColor: "rgb(2, 103, 193,0.08)",
            }}
          >
            <STT setmsg={setmsg} g_id={g_id} video_call={video_call} />
          </Flex_box>
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "32px",
              height: "32px",
              borderRadius: "50%",
              backgroundColor: theme_color,
            }}
            onClick={(e) => {
              // confirmAction();
              if (msg && onClick) {
                onClick(e, msg);
                setmsg("");
              }
            }}
          >
            <img
              src={msg_icon}
              style={{
                height: "15px",
                cursor: "pointer",
              }}
            />
          </div>
        </Flex_box>
      </Flex_box>
      {/* <div ref={captionRef}></div>
      <HomePage /> */}
    </>
  );
};

export default Chat_Footer;
