import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Flex_box from "../../../utilities/flex_box";
import { theme_color } from "../../../utilities/colors";
import Study_Box from "./Study_Box";
import { StudyList_api, get_gd_api, get_survey_api } from "../../../apis/apis";
import { useDispatch, useSelector } from "react-redux";
import {
  alert_action,
  loading_action,
} from "../../../redux_prog/actions/base_action";
import Study_Pagination from "../../../utilities/Study_Pagination";

const GD_comp = () => {
  const dispatch = useDispatch();
  const [selected_tab, setselected_tab] = useState(1);
  const [study_list, setstudy_list] = useState([]);
  const { user_data } = useSelector((state) => state?.authReducer);
  const [pageIndex, setpageIndex] = useState(0);

  const get_study_list = () => {
    dispatch(loading_action(true));
    get_gd_api({
      UserId: user_data?._id,
      pageIndex,
      limit: 10,
    })
      .then((res) => {
        dispatch(loading_action(false));
        if (res.data.status == 1) {
          setstudy_list(res.data.Res);
        }
        console.log(res);
      })
      .catch((err) => {
        dispatch(loading_action(false));
        dispatch(
          alert_action({
            type: "error",
            title: "Error",
            msg: "Some error occurred!",
          })
        );
      });
  };

  useEffect(get_study_list, [pageIndex]);

  return (
    <Tabs
      style={{
        padding: "0px 0px 15px 23px",
      }}
    >
      <div
        style={{
          padding: "20px 0px",
          width: "100%",
        }}
      >
        <Flex_box>
          <Tab
            onClick={() => {
              setselected_tab(1);
            }}
            style={{
              width: "50%",
              borderBottom: selected_tab == 1 ? "2px solid " + theme_color : "",
              textAlign: "center",
              padding: "0px 0px 20px 0px",
              opacity: selected_tab == 1 ? 1 : 0.5,
              color: theme_color,
              fontWeight: selected_tab == 1 ? 800 : 400,
              fontSize: "18px",
            }}
          >
            ONGOING GDs
          </Tab>
          <Tab
            onClick={() => {
              setselected_tab(2);
            }}
            style={{
              width: "50%",
              borderBottom: selected_tab == 2 ? "2px solid " + theme_color : "",
              textAlign: "center",
              padding: "0px 0px 20px 0px",
              opacity: selected_tab == 2 ? 1 : 0.5,
              color: theme_color,
              fontWeight: selected_tab == 2 ? 800 : 400,
              fontSize: "18px",
            }}
          >
            COMPLETED GDs
          </Tab>
        </Flex_box>
        <Study_Pagination
          arr={study_list?.filter((el) => {
            if (selected_tab == 2) {
              return el?.study?.isClosed == true;
            } else {
              return el?.study?.isClosed == false;
            }
          })}
          pageIndex={pageIndex}
          setpageIndex={setpageIndex}
        />
        <Flex_box
          style={{
            flexWrap: "wrap",
            justifyContent: "start",
            gap: 20,
            marginTop: "10px",
            maxHeight: "58vh",
            overflowY: "auto",
            alignItems: "",
          }}
        >
          {study_list?.length > 0 &&
            study_list
              ?.filter((el) => {
                if (selected_tab == 2) {
                  return el?.study?.isClosed == true;
                } else {
                  return el?.study?.isClosed == false;
                }
              })
              ?.map((el, index) => {
                return <Study_Box progress={"30%"} item={el} />;
              })}
        </Flex_box>
      </div>
    </Tabs>
  );
};

export default GD_comp;
