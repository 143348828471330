export const researcher_route_labels = [
  // {
  //     path: '/dashboard',
  //     label: 'Dashboard'
  // },
  {
    path: "/my-gds",
    label: "My Group Discussions",
  },
  {
    path: "/my-surveys",
    label: "My Surveys",
  },
  {
    path: "/faq",
    label: "Support",
  },
  {
    path: "/kyc",
    label: "KYC",
  },
  {
    path: "/sub-quota",
    label: "Subscription Quotas",
  },
  {
    path: "/subscription",
    label: "Subscriptions",
  },
  {
    path: "/active-subscription",
    label: "Subscription History",
  },
  {
    path: "/incentive-history",
    label: "My Incentive Balance",
  },
  {
    path: "/My_Profile",
    label: "Manage Profile",
  },
  // {
  //     path: '/chat-comp',
  //     label: 'Chat'
  // },
  // {
  //     path: '/video-chat-comp',
  //     label: 'Video Chat Example'
  // },
]

export const respondent_route_labels = [
  {
    path: "/dashboard",
    label: "Dashboard",
  },
  {
    path: "/my-studies",
    label: "My Studies",
  },
  {
    path: "/my-balance",
    label: "My Balance",
  },
  {
    path: "/faq",
    label: "Support",
  },
  {
    path: "/voucher-history",
    label: "Voucher History",
  },
  // {
  //     path: '/video-chat-comp',
  //     label: 'Video Chat Example'
  // },
]
