import React from "react";
import Flex_box from "../../../../utilities/flex_box";
import { theme_bg, theme_color } from "../../../../utilities/colors";
import { useLocation, useNavigate } from "react-router-dom";
import { page_url } from "../../../../apis/apis";

const Invite_Comp = () => {
  const state = useLocation();
  const navigate = useNavigate();
  const study_id = state?.state?.study_id;

  return (
    <div
      style={{
        padding: "20px",
        maxHeight: "79vh",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        gap: 20,
      }}
    >
      <span
        style={{
          fontSize: "26px",
          fontStyle: "italic",
          fontWeight: "700",
        }}
      >
        Send Invitations
      </span>
      <Flex_box
        className="responsive_width_35"
        style={{
          flexDirection: "column",
          padding: "25px 20px",
          width: "",
          borderRadius: "10px",
          backgroundColor: theme_bg,
        }}
      >
        <span style={{ fontSize: "20px", fontWeight: 600 }}>
          Send Invitations
        </span>
        <span style={{ fontSize: "14px", marginTop: "8px" }}>
          Copy Link, share it with anyone
        </span>
        <Flex_box
          style={{
            width: "90%",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            marginTop: "40px",
          }}
        >
          <span style={{ fontSize: "11px" }}>Your share link</span>
          <Flex_box
            style={{
              borderRadius: "30px",
              border: "2px dashed " + theme_color,
              // padding: "5px 0px",
              paddingLeft: "10px",
              justifyContent: "space-between",
              marginTop: "5px",
            }}
          >
            <span style={{ fontSize: "13px" }}>
              {page_url + "study-details?" + study_id}
            </span>
            <button
              id="btn_comp"
              style={{
                padding: "8px 0px",
                width: "80px",
                borderRadius: "30px",
              }}
              onClick={() => {
                navigator.clipboard.writeText(
                  page_url + "study-details?" + study_id
                );
                alert("Link copied successfully!");
              }}
            >
              Copy
            </button>
          </Flex_box>
        </Flex_box>
      </Flex_box>
      <button
        id="btn_comp"
        style={{
          marginTop: "10px",
        }}
        onClick={()=>{
          navigate("/study-details", { state: { study_id,type:'gd' } });
        }}
      >
        Continue
      </button>
    </div>
  );
};

export default Invite_Comp;
