import React, { useEffect, useState } from "react";
import Flex_box from "../../../utilities/flex_box";
import Layout from "../../../utilities/Layout";
import Price_Box from "./Pricing_Box";
import Subscription_Box from "./Subscriptions_Box";
import {
  get_subscriptions_api,
  GetActiveSubscriptionById_api,
} from "../../../apis/apis";
import { useDispatch, useSelector } from "react-redux";

const Sub_Pages = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [activeSubscription, setActiveSubscription] = useState(null);
  const user_data = useSelector((state) => state?.authReducer?.user_data) || {};
  const { username, email, profilepictuer } = user_data;

  const getSubscriptions = () => {
    get_subscriptions_api()
      .then((res) => {
        setSubscriptions(res.data.Res);
        if (user_data?._id) {
          get_Active_subscriptions(res.data.Res);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const get_Active_subscriptions = (allSubscriptions) => {
    GetActiveSubscriptionById_api({
      UserId: user_data._id,
    })
      .then((res) => {
        const activeSub = allSubscriptions.find(
          (sub) => sub._id === res.data.Res[0].plan_id
        );
        setActiveSubscription(activeSub);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getSubscriptions();
  }, []);

  const firstSub = subscriptions?.length > 0 ? subscriptions[0] : "";

  return (
    <Layout
      style={{
        paddingBottom: "20px",
      }}
      no_sidebar={false}
    >
      <Flex_box
        style={{
          flexDirection: "column",
          justifyContent: "flex-start",
          height: "",
          lineHeight: 1.8,
          paddingTop: "20px",
          overflowY: "auto",
        }}
      >
        <b style={{ fontSize: "24px" }}>Subscriptions</b>
        <span style={{ fontSize: "14px" }}>
          Unlock unlimited access to all the features
        </span>

        <Flex_box
          style={{
            gap: "30px",
            marginTop: "25px",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {subscriptions?.map((el, index) => {
            const isActive = activeSubscription?._id === el._id;

            return (
              <Price_Box
                key={index}
                label={el.Name}
                price={`$${el?.Amount}`}
                subtext={`Per ${el?.Cycle}`}
                duration={el?.Duration}
                content={el?.Description}
                coin={el?.coin_limit}
                max_respondents={el?.max_respondents}
                study={el?.study_limit}
                call={el?.video_call_hrs}
                _id={el?._id}
                item={el}
                isActive={isActive}
                style={{
                  width: "300px",
                  marginBottom: "20px",
                  border: isActive ? "2px solid green" : "1px solid grey",
                }}
                active_sub={activeSubscription?._id}
              />
            );
          })}
          {/* Continue Free Option */}
          <Subscription_Box
            label={"Continue Free"}
            subtext={<span>&nbsp;</span>}
            content_arr={[
              <span>100 Minutes voice call</span>,
              <span>100 Minutes of video call</span>,
              <span>&nbsp;</span>,
              <span>&nbsp;</span>,
            ]}
            btn_text={"Continue"}
            path={"/my-surveys"}
          />
        </Flex_box>
      </Flex_box>
    </Layout>
  );
};

export default Sub_Pages;
