import React, { useEffect, useState } from "react";
import Layout from "../../../../utilities/Layout";
import Study_header from "./Study_header";
import Study_Block from "./Study_Block";
import Respondent_Table from "./Respondent_Table";
import { StudyDetails_api } from "../../../../apis/apis";
import { useLocation } from "react-router-dom";
import { loading_action } from "../../../../redux_prog/actions/base_action";
import { useDispatch, useSelector } from "react-redux";
import GD_Block from "./GD_Block";

const Study_Details = () => {
  // const { state } = useLocation();
  // const { study_id, type } = state;

  const path_state = useLocation();
  const s_id = path_state?.search?.split("?")[1];
  const t = path_state?.search?.includes("t=");
  const { token } = useSelector((state) => state?.authReducer);
  const [state, setstate] = useState(
    !t && s_id
      ? {
          study_id: s_id,
          type: 2,
        }
      : {
          study_id: path_state?.state?.study_id || path_state?.state?.id,
          type: path_state?.state?.type,
          completed: path_state?.state?.completed,
          study_invitation:path_state?.state?.study_invitation
        }
  );

  const { study_id, type } = state || {};

  const dispatch = useDispatch();
  const [study_detail, setstudy_detail] = useState({});

  const get_study_details = () => {
    dispatch(loading_action(true));
    StudyDetails_api(
      {
        Id: study_id,
      },
      token
    )
      .then((res) => {
        dispatch(loading_action(false));
        console.log(res);
        if (res.data.status == 1) {
          setstudy_detail(res.data.Res[0] || {});
          setstate({
            ...state,
            type:
              res.data.Res[0]?.gds_lookup?.length > 0
                ? "gd"
                : res.data.Res[0]?.surveys_lookup?.length > 0
                ? "survey"
                : "",
            completed: res.data.Res[0]?.completed,
          });
        }
      })
      .catch((err) => {
        dispatch(loading_action(false));
        console.log(err);
      });
  };

  useEffect(() => {
    if (!t ? study_detail?._id : false) {
    } else {
      get_study_details();
    }
  }, [state?.study_id]);

  useEffect(() => {
    setstate(
      !t && s_id
        ? {
            study_id: s_id,
            type: 2,
          }
        : {
            study_id: path_state?.state?.study_id || path_state?.state?.id,
            type: path_state?.state?.type,
            completed: path_state?.state?.completed,
            study_invitation:path_state?.state?.study_invitation
          }
    );
  }, [path_state?.key]);

  return (
    <Layout>
      <div
        style={{
          width: "100%",
          height: "85.5vh",
          overflowY: "auto",
          padding: "20px",
          boxSizing: "border-box",
          // zIndex:100
        }}
      >
        <Study_header
          state={state}
          study_detail={study_detail}
          type={state?.type}
        />
        {type == "gd" && (
          <GD_Block
            study_id={study_id}
            state={state}
            study_detail={study_detail}
          />
        )}
        {type == "survey" && (
          <Study_Block
            study_id={study_id}
            state={state}
            study_detail={study_detail}
          />
        )}
        {/* <Respondent_Table type={type} study_detail={study_detail} study_id={study_id} /> */}
      </div>
    </Layout>
  );
};

export default Study_Details;
