import React from "react"
import Layout from "../../../../utilities/Layout"
import Chat_Header from "./Chat_Header"
import Chat_Box from "./Chat_Box"
import Chat_Footer from "./Chat_Footer"
// import HomePage from "../../../../Transcript_Comp/pages/home"

const GD_Chat = () => {
  return <></>
  // return (
  //     <Layout>
  //         <div
  //     className="remove_scrollbar"
  //     style={{
  //                 padding: "20px"
  //             }}
  //         >
  //             <Chat_Header />
  //             <Chat_Box />
  //         </div>
  //     </Layout>
  // )
}

export default GD_Chat
