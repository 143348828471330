import React, { useEffect, useMemo, useRef, useState } from "react";
import Flex_box from "../../../../utilities/flex_box.jsx";
import { theme_bg } from "../../../../utilities/colors.js";
import phone_icon from "../../../../assets/phone.svg";
import video_icon from "../../../../assets/Group 32928.svg";
import user_icon from "../../../../assets/user_dummy.png";
import Chat_Footer from "./Chat_Footer.jsx";
import axios from "axios";
import { host } from "../../../Chat_Screens/utils/APIRoutes.js";
import { v4 as uuidv4 } from "uuid";
import V_Call from "../../../Video_Chat/agora_ng_version/V_Call.tsx";
import A_Call from "../../../Video_Chat/agora_ng_version/A_Call.tsx";
import Chat_Header from "./Chat_Header.jsx";

import io from "socket.io-client";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  BASE_URL,
  delete_message_api,
  get_polls_api,
  get_room_messages_api,
  image_url,
  report_msg_api,
  store_message_api,
} from "../../../../apis/apis.js";
import Poll_Form from "./Poll/Poll_Form.jsx";
import poll_icon from "../../../../assets/poll_icon.png";
import Poll_Details from "./Poll/Poll_Box/Poll_Details.jsx";
import Poll_Box from "./Poll/Poll_Box/index.jsx";
import dot_menu from "../../../../assets/dot_menu.png";
import { createClient } from "agora-rtc-sdk-ng/esm";
import { alert_action } from "../../../../redux_prog/actions/base_action.js";
import { Modal } from "../../../../utilities/Modal.jsx";
import Text_Input from "../../../../utilities/Text_Input.jsx";
import Button from "../../../../utilities/Button.jsx";
import Select_box from "../../../../utilities/Select_box.jsx";
import { reportMenu } from "../../../../utilities/utilities.js";
let socket = io(host);

const Msg_comp = ({
  reverse,
  message,
  del_message,
  setMessages,
  messages,
  selected_msg,
  setselected_msg,
  gd_data,
}) => {
  const top_b_radius = !reverse
    ? { borderTopLeftRadius: "0px" }
    : { borderTopRightRadius: "0px" };
  const { user_data } = useSelector((state) => state?.authReducer);
  const user_pic = message?.user_image
    ? image_url + message?.user_image
    : user_icon;
  const [selected_report, setselected_report] = useState("");
  const [remark, setremark] = useState("");
  const [isModalVisible, setisModalVisible] = useState(false);

  const change_modal_visibility = () => {
    if (selected_msg) {
      setselected_msg("");
    } else {
      setselected_msg(message?._id);
    }
  };

  return (
    <>
      <div
        className="removescrollbar"
        style={{ width: "100%", position: "relative" }}
      >
        <Flex_box
          className="removescrollbar"
          style={
            reverse
              ? {
                  justifyContent: "end",
                  alignItems: "start",
                }
              : {
                  justifyContent: "start",
                  alignItems: "start",
                  // backgroundColor:"red"
                }
          }
        >
          {!reverse && (
            <img
              // src={`data:image/svg+xml;base64,${currentUser.avatarImage}`}
              src={user_pic}
              style={{
                height: "20px",
                marginTop: "9px",
                marginRight: "10px",
              }}
            />
          )}
          <div>
            <p style={{ fontSize: "10px" }}>
              {reverse ? "You" : message?.user}
            </p>
            <Flex_box
              style={{
                alignItems: "",
                marginTop: "-5px",
              }}
            >
              {!reverse && (
                <img
                  src={dot_menu}
                  style={{
                    height: "17px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    change_modal_visibility();
                  }}
                />
              )}
              <div
                style={{
                  backgroundColor: "rgb(2, 103, 193,0.1)",
                  fontSize: "12px",
                  padding: "10px",
                  borderRadius: "8px",
                  ...top_b_radius,
                }}
              >
                {message?.text?.split(".")[1] == "png" ? (
                  <img
                    src={image_url + message?.text}
                    style={{
                      height: "100px",
                      width: "100px",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  message?.text
                )}
              </div>

              {reverse && (
                <img
                  src={dot_menu}
                  style={{
                    height: "17px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    change_modal_visibility();
                  }}
                />
              )}
            </Flex_box>
          </div>

          {reverse && (
            <img
              // src={`data:image/svg+xml;base64,${currentChat.avatarImage}`}
              src={user_pic}
              style={{
                height: "20px",
                marginTop: "9px",
                marginLeft: "10px",
              }}
            />
          )}
        </Flex_box>
        {selected_msg == message?._id && (
          <Flex_box
            style={{
              width: "60px",
              position: "absolute",
              top: "25px",
              right: reverse ? "45px" : "",
              left: !reverse ? "45px" : "",
              flexDirection: "column",
              fontSize: "12px",
              backgroundColor: "#fff",
              border: "1px solid #c9c9c9",
              borderRadius: "8px",
            }}
          >
            {reverse && (
              <Flex_box
                style={{
                  width: "100%",
                  borderBottom: "1px solid #c9c9c9",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (window.confirm("Do you want to delete this message?")) {
                    del_message(
                      message?._id,
                      message?.text,
                      message?.createdAt
                    );
                    setselected_msg("");
                  }
                }}
              >
                <span>Delete</span>
              </Flex_box>
            )}
          </Flex_box>
        )}
      </div>
    </>
  );
};

const Chat_Box = ({ width, top_less }) => {
  const [isVideoOn, setisVideoOn] = useState(false);
  const [isAudioOn, setisAudioOn] = useState(false);
  const scrollRef = useRef();
  const [messages, setMessages] = useState([]);
  const [user_joined, setuser_joined] = useState(false);
  const dispatch = useDispatch();
  const state = useLocation();
  const { user_data } = useSelector((state) => state?.authReducer);
  const [currentUser, setcurrentUser] = useState(user_data || {});
  const [poll_form_modal, setpoll_form_modal] = useState(false);
  const [selected_msg, setselected_msg] = useState("");
  const room_id = user_data?._id;

  const store_message = (message, createdAt) => {
    store_message_api(
      {
        message,
        room_id,
        user_id: user_data?._id,
        user: currentUser?.username,
        user_image: user_data?.profilepictuer,
        createdAt,
      },
      user_data?.role
    )
      .then((res) => {
        console.warn(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const get_messages = () => {
    get_room_messages_api(
      {
        room_id,
      },
      user_data?.role
    )
      .then((res) => {
        setMessages(
          res?.data?.msg_res?.map((el) => {
            return {
              text: el?.message,
              ...el,
            };
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(get_messages, []);

  const join_room = async () => {
    socket.emit(
      "join",
      {
        name: user_data?.username,
        room: room_id,
        message: "Hii",
        user_image: user_data?.profilepictuer,
        user_id: user_data?._id,
      },
      (error) => {
        if (error) {
          alert(error);
        }
        setuser_joined(true);
      }
    );
  };

  const [effect_func, seteffect_func] = useState(false);

  useEffect(() => {
    const navigationTo = async () => {
      if (effect_func == true) {
        await join_room();
      } else {
        seteffect_func(true);
      }
    };
    navigationTo();
  }, [effect_func, socket]);

  useEffect(() => {
    if (effect_func == true) {
      socket.on("message", (message) => {
        if (message?.delete == true) {
          setMessages([
            ...new Set(
              messages?.filter((el) => {
                return el?.user_id == message?.user_id
                  ? el?.text == message?.text
                    ? el?.createdAt != message?.createdAt
                    : true
                  : true;
              })
            ),
          ]);
        } else {
          setMessages([...new Set([...messages, message])]);
        }
      });
    } else {
      seteffect_func(true);
    }
  }, [effect_func, socket, messages]);

  const handleSubmit = (e, message, nopreventDefault, dont_store) => {
    if (!nopreventDefault) {
      e.preventDefault();
    }
    if (message) {
      const date = new Date();
      socket.emit("sendMessage", { message, createdAt: date });
      if (!dont_store) {
        store_message(message, date);
      }
    } else alert("empty input");
  };

  const del_message = (_id, message, createdAt) => {
    delete_message_api(
      {
        _id,
      },
      user_data?.role
    )
      .then((res) => {
        socket.emit("delMessage", { message, createdAt });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const predefined_questions = [
    {
      question:"What is yaarnbox?"
    },
    {
      question:"Why should i choose yaarnbox?"
    },
    {
      question:"What are the subscription plans?"
    }
  ];

  const [other_btn_clicked, setother_btn_clicked] = useState(false)

  return (
    <Flex_box
      className="remove_scrollbar"
      style={{
        width: width || "100%",
        backgroundColor: theme_bg,
        marginTop: "20px",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px #0000000D",
        flexDirection: "column",
        paddingBottom: "10px",
      }}
    >
      {!top_less && (
        <Flex_box
          className="remove_scrollbar"
          style={{
            justifyContent: "end",
            gap: 15,
            padding: "15px 0px",
            width: "98%",
          }}
        >
          <img
            src={phone_icon}
            style={{
              height: "15px",
            }}
          />
          <img
            src={video_icon}
            style={{
              height: "15px",
            }}
          />
        </Flex_box>
      )}
      <Flex_box
        className="remove_scrollbar"
        style={{
          width: "97.5%",
          height: "54vh",
          maxHeight: "60vh",
          backgroundColor: "#fff",
          flexDirection: "column",
          justifyContent: "end",
          flexFlow: "column nowrap",
          padding: "20px 10px",
          overflowY: "auto",
        }}
      >
        {messages?.length > 0 &&
          messages?.map((message, index) => {
            return (
              <Msg_comp
                ref={scrollRef}
                key={uuidv4()}
                message={message}
                reverse={user_data?._id == message.user_id}
                del_message={del_message}
                setMessages={setMessages}
                messages={messages}
                selected_msg={selected_msg}
                setselected_msg={setselected_msg}
              />
            );
          })}
         { !other_btn_clicked && messages?.length == 0 && (
          <Flex_box
            style={{
              flexDirection: "column",
              gap: 10,
            }}
          >
            {
              predefined_questions.map((el,index)=>{
                return (
                  <Button
                    label={el.question}
                    onClick={(e) => {
                      handleSubmit(e, el.question);
                    }}
                  />
                )
              })
            }
            <Button label={"I have another question"}
            onClick={()=>{
              setother_btn_clicked(true);
              document.getElementById("msg_input").focus();
            }}
            />
          </Flex_box>
        )}
      </Flex_box>
      <Chat_Footer onClick={handleSubmit} />
    </Flex_box>
  );
};

export default Chat_Box;
