import React from "react";
import { Modal } from "./Modal";
import Flex_box from "./flex_box";
import { useDispatch, useSelector } from "react-redux";
import {
  alert_action,
  close_alert_action,
} from "../redux_prog/actions/base_action";
import error_icon from "../assets/Image 107.png";
import success_icon from "../assets/Image 110.png";
import { toast } from "react-toastify";

const Alert = () => {
  const alert_obj = useSelector((state) => state?.baseReducer?.alert_obj);
  //   console.log(alert_obj);
  const {
    type,
    msg,
    title,
    btn_text,
    isopen,
    is_confirm,
    onSuccess,
    success_btn_text,
  } = alert_obj;
  const dispatch = useDispatch();

  if (is_confirm) {
    const handleConfirm = (e) => {
      toast.dismiss(); // Close the confirmation toast
      onSuccess(e);
      // toast.success("Action confirmed!");
    };

    const handleCancel = () => {
      toast.dismiss(); // Close the confirmation toast
      // toast.info("Action canceled.");
    };

    toast(
      ({ closeToast }) => (
        <div
          style={{
            cursor: "pointer",
          }}
        >
          <b>{title}</b>
          <p>{msg}</p>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              onClick={handleConfirm}
              style={{
                padding: "5px 10px",
                backgroundColor: "green",
                color: "white",
                border: "none",
                cursor: "pointer",
              }}
            >
              Confirm
            </button>
            <button
              onClick={handleCancel}
              style={{
                padding: "5px 10px",
                backgroundColor: "red",
                color: "white",
                border: "none",
                cursor: "pointer",
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      ),
      { autoClose: false } // Keep the toast open until the user interacts
    );
    return <></>;
    // return (
    //   <Modal isOpen={isopen}>
    //     <Flex_box
    //       // className="responsive_width_35"
    //       style={{
    //         flexDirection: "column",
    //         gap: 15,
    //         width: "",
    //         padding: "5px 0px",
    //         // backgroundColor:"red"
    //       }}
    //     >
    //       <img
    //         src={type == "error" ? error_icon : success_icon}
    //         style={{
    //           height: "80px",
    //         }}
    //       />
    //       <b
    //         style={{
    //           fontSize: "24px",
    //         }}
    //       >
    //         {title}
    //       </b>
    //       <span style={{ textAlign: "center", fontSize: "15px", lineHeight: 1.4 }}>{msg}</span>
    //       <Flex_box
    //         style={{
    //           gap: 20,
    //         }}
    //       >
    //         <button
    //           id="btn_comp"
    //           style={{
    //             border: is_confirm ? "2px solid red" : "",
    //             ...(is_confirm
    //               ? {
    //                   marginTop: "10px",
    //                   backgroundColor: "red",
    //                 }
    //               : {
    //                   marginTop: "10px",
    //                 }),
    //           }}
    //           onClick={() => {
    //             dispatch(close_alert_action())
    //           }}
    //         >
    //           {btn_text || "Cancel"}
    //         </button>
    //         {is_confirm && (
    //           <button
    //             id="btn_comp"
    //             style={{
    //               marginTop: "10px",
    //               backgroundColor: "green",
    //               border: "2px solid green",
    //             }}
    //             onClick={(e) => {
    //               if (onSuccess) {
    //                 onSuccess(e)
    //               }
    //               dispatch(close_alert_action())
    //             }}
    //           >
    //             {success_btn_text || "Ok"}
    //           </button>
    //         )}
    //       </Flex_box>
    //     </Flex_box>
    //   </Modal>
    // )
  }
  if (msg && !is_confirm) {
    const alert_msg = (
      <>
        {title && (
          <>
            <b>{title}</b>
            <br />
          </>
        )}
        <span>{msg}</span>
      </>
    );

    if (type == "error") {
      toast.error(alert_msg, {
        autoClose: 10000,
        closeOnClick: () => {
          toast?.dismiss();
        },
      });
    } else {
      toast.success(alert_msg, {
        autoClose: 10000,
        closeOnClick: () => {
          toast?.dismiss();
        },
      });
    }
    // return null;
    return <div></div>;
  }
  return <div></div>;
};

export default Alert;
