import React, { useState } from "react";
import Flex_box from "./flex_box";
import Button from "./Button";

const Study_Pagination = ({ limit, pageIndex, setpageIndex, arr }) => {
  //   const [pageIndex, setpageIndex] = useState(0);
  const limit_num = limit || 10;

  return (
    <Flex_box
      style={{
        justifyContent: "space-between",
        marginTop: "10px",
      }}
    >
      <Button
        style={{
          width: "120px",
        }}
        label={"Previous"}
        onClick={() => {
          if (pageIndex >= 1) {
            setpageIndex(pageIndex - 1);
          }
        }}
        disabled={pageIndex == 0}
      />
      <Button
        style={{
          width: "120px",
        }}
        label={"Next"}
        disabled={arr?.length < (limit_num * (pageIndex + 1))}
        onClick={() => {
          if (arr?.length >= (limit_num * (pageIndex + 1))) {
            setpageIndex(pageIndex + 1);
          }
        }}
      />
    </Flex_box>
  );
};

export default Study_Pagination;
