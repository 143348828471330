import React, { useEffect, useState } from "react";
import Layout from "../../../../utilities/Layout";
import Study_header from "./Study_header";
import Study_Block from "./Study_Block";
import { useDispatch, useSelector } from "react-redux";
import { loading_action } from "../../../../redux_prog/actions/base_action";
import { resp_StudyDetails_api, StudyDetails_api } from "../../../../apis/apis";
import { useLocation, useNavigate } from "react-router-dom";

const Study_Details = () => {
  const path_state = useLocation();
  const study_id = path_state?.search?.split("?")[1];
  const [state, setstate] = useState(
    path_state?.search
      ? {
          id: study_id,
          type: 2,
        }
      : {
          id: path_state?.state?.study_id || path_state?.state?.id,
          type: path_state?.state?.type,
          completed: path_state?.state?.completed,
          ongoing:path_state?.state?.ongoing
        }
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [study_detail, setstudy_detail] = useState({});
  const [gd_data, setgd_data] = useState();
  const [survey_details, setsurvey_details] = useState({});
  const { user_data,token } = useSelector((state) => state?.authReducer);

  const get_study_details = () => {
    if (state?.id) {
      dispatch(loading_action(true));
      resp_StudyDetails_api({
        Id: state?.id,
        user_id: user_data?._id,
      },token)
        .then((res) => {
          dispatch(loading_action(false));
          if (res.data.status == 1) {
            setstudy_detail(res.data.Res[0] || {});
            setstate({
              ...state,
              type:
                res.data.Res[0]?.gds_lookup?.length > 0
                  ? 2
                  : res.data.Res[0]?.surveys_lookup?.length > 0
                  ? 1
                  : "",
                  // completed:
                  //   res.data.Res[0]?.surveys_lookup[0]?.usersres_lookup?.length > 0,
                    // completed:
                    //   res.data.Res[0]?.surveys_lookup[0]?.usersres_lookup?.length > 0,
            });
          }
        })
        .catch((err) => {
          dispatch(loading_action(false));
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (user_data?._id) {
      if (study_detail?._id) {
      } else {
        get_study_details();
      }
    } else {
      navigate("/login", {
        state: { study_id },
      });
    }
  }, [state, study_detail, study_id, user_data]);

  return (
    <Layout>
      <div
        style={{
          width: "100%",
          height: "85.5vh",
          overflowY: "auto",
          padding: "20px",
          boxSizing: "border-box",
        }}
      >
        {study_detail && (
          <>
            <Study_header
              survey_details={survey_details}
              setsurvey_details={setsurvey_details}
              study_detail={study_detail}
              state={state}
              gd_data={gd_data}
            />
            <Study_Block
              survey_details={survey_details}
              setsurvey_details={setsurvey_details}
              study_detail={study_detail}
              study_id={state?.id}
              state={state}
              gd_data={gd_data}
              setgd_data={setgd_data}
            
            />
          </>
        )}
      </div>
    </Layout>
  );
};

export default Study_Details;
