import React, { useEffect, useState } from "react";
import { Modal } from "../../../utilities/Modal";
import Pre_login from "../../Auth_Screens/Pre_login";
import Nav_Comp from "../Nav_Comp";
import Footer from "../Nav_Comp/Footer";
import Welcome from "./Welcome";
import Plans from "./Plans";
import Comparison_table from "./Comparison_table";
import Unsure from "./Unsure";
import {
  get_landing_page_data_api,
  get_subscriptions_api,
  image_url,
} from "../../../apis/apis";
import Carousal_Box from "../../../utilities/Carousal_Box";
import Flex_box from "../../../utilities/flex_box";
import Button from "../../../utilities/Button";
import { useNavigate } from "react-router-dom";

const Pricing = () => {
  const [isOpen, setisOpen] = useState(false);
  const navigate = useNavigate();
  const open_modal = () => {
    setisOpen(true);
  };

  const close_modal = () => {
    setisOpen(false);
  };
  const [scrollTop, setscrollTop] = useState(0);

  const [sub_arr, setsub_arr] = useState([]);

  const [content_arr, setcontent_arr] = useState([]);
  const get_content = () => {
    get_landing_page_data_api({ page: 4 })
      .then((res) => {
        setcontent_arr(res.data.content_res || []);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const getSubscriptions = () => {
    get_subscriptions_api()
      .then((res) => {
        setsub_arr(res.data.Res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getSubscriptions();
    get_content();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        overflowY: "auto",
      }}
      id="landing_id"
      onScroll={() => {
        setscrollTop(document.getElementById("landing_id")?.scrollTop);
      }}
    >
      <Nav_Comp top_value={scrollTop > 10} open_modal={open_modal} />
      {/* <Nav_Comp /> */}
      <section class="innerbanner-sec">
        <div class="container">
          <div class="page-title">
            <h1 class="page-title-inner">Pricing</h1>
            {/* <nav aria-label="breadcrumb" class="breadcrumb-row ">
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Pricing
                </li>
              </ul>
            </nav> */}
          </div>
        </div>
        <img
          src="assets/images/shape1.png"
          class="shape shape-1"
          alt="banner"
        />
        <img
          src="assets/images/shape2.png"
          class="shape shape-2"
          alt="banner"
        />
      </section>

      <section class="research-sec2">
        <div class="container">
          <div class="banner">
            <div class="row align-items-center">
              <div class="col-md-7">
                {/* <h4 class="mb-3">Tailored to Your Research Needs</h4>
                <p>
                  Yaarnbox offers a variety of flexible subscription plans
                  designed to cater to the research needs of individuals and
                  teams of all sizes. Choose the plan that best suits your
                  budget and research volume.
                </p> */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: content_arr[0]?.content,
                  }}
                ></div>

                <div class=" mt-5">
                  <a href="#" class="default-btn">
                    <span>Contact Us</span>
                  </a>
                </div>
              </div>
              <div class="col-md-5">
                <img src={image_url+content_arr[0]?.image_file} class="amimy" width="100%" />
                {/* <img src="assets/images/about.png" class="amimy" width="100%" /> */}
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/images/shape1.png"
          class="shape shape-3"
          alt="banner"
        />
        <img
          src="assets/images/shape-4.png"
          class="shape shape-4"
          alt="banner"
        />
        <img
          src="assets/images/shape3.svg"
          class="shape shape-5"
          alt="banner"
        />
      </section>

      <section class="research-sec2">
        <div class="container">
          <div class="banner">
            <div class="row align-items-center">
              <Carousal_Box>
                {sub_arr?.map((el, index) => {
                  return (
                    <div class="col-md-12">
                      <div class="price_box">
                        <h2 class="mb-3">
                          {el?.Name} <br /> (
                          {el?.Amount > 0
                            ? "$" + el?.Amount + "/" + el?.Cycle
                            : "Free"}
                          )
                        </h2>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: el?.Description,
                          }}
                          style={{
                            flex: 1,
                          }}
                        ></div>
                        {/* <p>
                          This free plan is perfect for researchers getting
                          started with Yaarnbox or conducting small-scale
                          research projects.
                        </p>

                        <h4 class="mb-3">Features</h4>
                        <ul class="price_features">
                          <li>
                            Create and manage basic surveys (up to 10 questions)
                          </li>
                          <li>
                            Limited quota management (up to 50 responses per
                            survey)
                          </li>
                          <li>
                            Access to basic reporting and data visualization
                            tools
                          </li>
                          <li>
                            Conduct 5 group discussions per month (Maximum of 3
                            Participants each)
                          </li>
                          <li>Incentive Credit: 10,000 Smart-Coins</li>
                        </ul>

                        <h4 class="mb-3">Ideal for:</h4>
                        <ul>
                          <li>Individual researchers</li>
                          <li>Academic research projects</li>
                          <li>Pilot surveys and feasibility studies</li>
                        </ul> */}
                        <Flex_box
                          style={{
                            marginTop: "20px",
                          }}
                        >
                          <Button
                            onClick={() => {
                              navigate("/signup");
                            }}
                            label={"Sign Up"}
                          />
                        </Flex_box>
                      </div>
                    </div>
                  );
                })}

                {/* <div class="col-md-12">
                  <div class="price_box">
                    <h2 class="mb-3">
                      Standard Plan <br />
                      ($29/month){" "}
                    </h2>
                    <p>
                      This plan is ideal for researchers conducting regular
                      studies and requiring more advanced features.
                    </p>

                    <h4 class="mb-3">Features</h4>
                    <ul class="price_features">
                      <li>
                        Create and manage unlimited surveys of any complexity
                      </li>
                      <li>
                        Advanced quota management (up to 250 responses per
                        survey)
                      </li>
                      <li>
                        Comprehensive reporting and data visualization tools
                      </li>
                      <li>
                        Conduct up to 10 group discussions per month (Maximum of
                        10 Participants each)
                      </li>
                      <li>Dedicated customer support</li>
                      <li>Incentive Credit: 100,000 Smart-Coins</li>
                    </ul>

                    <h4 class="mb-3">Ideal for:</h4>
                    <ul>
                      <li>Small research teams</li>
                      <li>Marketing and product research professionals</li>
                      <li>Freelancers and independent researchers</li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="price_box">
                    <h2 class="mb-3">
                      Premium Plan <br />
                      ($99/month)
                    </h2>
                    <p>
                      This plan is designed for high-volume research projects
                      and teams requiring maximum functionality.
                    </p>

                    <h4 class="mb-3">Features</h4>
                    <ul class="price_features">
                      <li>
                        Create and manage unlimited surveys of any complexity
                      </li>
                      <li>
                        Advanced quota management (up to 1000 responses per
                        survey)
                      </li>
                      <li>
                        In-depth data analysis and reporting with advanced
                        customization options
                      </li>
                      <li>
                        Host up to 15 group discussions per month (Up to 15
                        Participants per session)
                      </li>
                      <li>Priority customer support</li>
                      <li>Team collaboration tools</li>
                    </ul>

                    <h4 class="mb-3">Ideal for:</h4>
                    <ul>
                      <li>Large research agencies</li>
                      <li>Enterprise-level market research teams</li>
                      <li>
                        Academic institutions conducting large-scale studies
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="price_box">
                    <h2 class="mb-3">
                      Starter <br /> (Free)
                    </h2>
                    <p>
                      This free plan is perfect for researchers getting started
                      with Yaarnbox or conducting small-scale research projects.
                    </p>

                    <h4 class="mb-3">Features</h4>
                    <ul class="price_features">
                      <li>
                        Create and manage basic surveys (up to 10 questions)
                      </li>
                      <li>
                        Limited quota management (up to 50 responses per survey)
                      </li>
                      <li>
                        Access to basic reporting and data visualization tools
                      </li>
                      <li>
                        Conduct 5 group discussions per month (Maximum of 3
                        Participants each)
                      </li>
                      <li>Incentive Credit: 10,000 Smart-Coins</li>
                    </ul>

                    <h4 class="mb-3">Ideal for:</h4>
                    <ul>
                      <li>Individual researchers</li>
                      <li>Academic research projects</li>
                      <li>Pilot surveys and feasibility studies</li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="price_box">
                    <h2 class="mb-3">
                      Standard Plan <br />
                      ($29/month){" "}
                    </h2>
                    <p>
                      This plan is ideal for researchers conducting regular
                      studies and requiring more advanced features.
                    </p>

                    <h4 class="mb-3">Features</h4>
                    <ul class="price_features">
                      <li>
                        Create and manage unlimited surveys of any complexity
                      </li>
                      <li>
                        Advanced quota management (up to 250 responses per
                        survey)
                      </li>
                      <li>
                        Comprehensive reporting and data visualization tools
                      </li>
                      <li>
                        Conduct up to 10 group discussions per month (Maximum of
                        10 Participants each)
                      </li>
                      <li>Dedicated customer support</li>
                      <li>Incentive Credit: 100,000 Smart-Coins</li>
                    </ul>

                    <h4 class="mb-3">Ideal for:</h4>
                    <ul>
                      <li>Small research teams</li>
                      <li>Marketing and product research professionals</li>
                      <li>Freelancers and independent researchers</li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="price_box">
                    <h2 class="mb-3">
                      Premium Plan <br />
                      ($99/month)
                    </h2>
                    <p>
                      This plan is designed for high-volume research projects
                      and teams requiring maximum functionality.
                    </p>

                    <h4 class="mb-3">Features</h4>
                    <ul class="price_features">
                      <li>
                        Create and manage unlimited surveys of any complexity
                      </li>
                      <li>
                        Advanced quota management (up to 1000 responses per
                        survey)
                      </li>
                      <li>
                        In-depth data analysis and reporting with advanced
                        customization options
                      </li>
                      <li>
                        Host up to 15 group discussions per month (Up to 15
                        Participants per session)
                      </li>
                      <li>Priority customer support</li>
                      <li>Team collaboration tools</li>
                    </ul>

                    <h4 class="mb-3">Ideal for:</h4>
                    <ul>
                      <li>Large research agencies</li>
                      <li>Enterprise-level market research teams</li>
                      <li>
                        Academic institutions conducting large-scale studies
                      </li>
                    </ul>
                  </div>
                </div> */}
              </Carousal_Box>
            </div>
          </div>
        </div>
        <img
          src="assets/images/shape1.png"
          class="shape shape-3"
          alt="banner"
        />
        <img
          src="assets/images/shape-4.png"
          class="shape shape-4"
          alt="banner"
        />
        <img
          src="assets/images/shape3.svg"
          class="shape shape-5"
          alt="banner"
        />
      </section>

      <section class="research-sec2">
        <div class="container">
          <table class="table">
            <thead class="table-header">
              <tr>
                <th class="table-cell">Feature</th>
                <th class="table-cell">Starter (Free)</th>
                <th class="table-cell">Standard ($29/month)</th>
                <th class="table-cell">
                  Premium ($99/month or $951/year, Save 20%)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="table-cell">Surveys</td>
                <td class="table-cell">Up to 5 questions</td>
                <td class="table-cell">Up to 20 questions</td>
                <td class="table-cell">Unlimited</td>
              </tr>
              <tr>
                <td class="table-cell">Survey Complexity</td>
                <td class="table-cell">Basic</td>
                <td class="table-cell">Any Complexity</td>
                <td class="table-cell">Any Complexity</td>
              </tr>
              <tr>
                <td class="table-cell">Quota Management</td>
                <td class="table-cell">Up to 50 responses</td>
                <td class="table-cell">Up to 250 responses</td>
                <td class="table-cell">Up to 1000 responses</td>
              </tr>
              <tr>
                <td class="table-cell">Reporting & Visualization</td>
                <td class="table-cell">Basic Tools</td>
                <td class="table-cell">Comprehensive Tools</td>
                <td class="table-cell">In-Depth Analysis & Customization</td>
              </tr>
              <tr>
                <td class="table-cell">Group Discussions</td>
                <td class="table-cell">
                  Up to 5 per month (3 participants max)
                </td>
                <td class="table-cell">
                  Up to 10 per month (10 participants max)
                </td>
                <td class="table-cell">
                  Up to 15 per month (15 participants max)
                </td>
              </tr>
              <tr>
                <td class="table-cell">Customer Support</td>
                <td class="table-cell">Basic</td>
                <td class="table-cell">Dedicated</td>
                <td class="table-cell">Priority</td>
              </tr>
              <tr>
                <td class="table-cell">Team Collaboration Tools</td>
                <td class="table-cell">N/A</td>
                <td class="table-cell">N/A</td>
                <td class="table-cell">Included</td>
              </tr>
              <tr>
                <td class="table-cell">Incentive Credit (Smart-Coins)</td>
                <td class="table-cell">10,000</td>
                <td class="table-cell">100,000</td>
                <td class="table-cell">500,000</td>
              </tr>
            </tbody>
          </table>
        </div>
        <img
          src="assets/images/shape1.png"
          class="shape shape-3"
          alt="banner"
        />
        <img
          src="assets/images/shape-4.png"
          class="shape shape-4"
          alt="banner"
        />
        <img
          src="assets/images/shape3.svg"
          class="shape shape-5"
          alt="banner"
        />
      </section>

      <Footer />
      <Modal isOpen={isOpen} onClose={close_modal}>
        <div
          style={{
            position: "relative",
          }}
        >
          <span
            onClick={close_modal}
            style={{
              fontSize: "26px",
              position: "absolute",
              top: "0px",
              right: "10px",
              cursor: "pointer",
            }}
          >
            &times;
          </span>
          <Pre_login />
        </div>
      </Modal>
    </div>
  );
};

export default Pricing;
