import React, { useState } from "react";
import eye_pic from "../assets/Icon_ionic_ios_eye.svg";
import eye_off_pic from "../assets/Icon_ionic_ios_eye_off.svg";

const Text_Input = ({
  input_cont_style,
  input_class_name,
  label,
  placeholder,
  onChange,
  value,
  cont_style,
  type,
  textArea,
  input_style,
  suffix_img,
  horizontalAlign,
  no_placeholder,
  label_right_align,
  prefix_img,
  disabled,
  negative_less,
  extra_func,
  onFocus,
  id,
  onKeyPress,
  s_className
}) => {
  const cont_style_obj = cont_style || {};
  const input_cont_style_obj = input_cont_style || {};
  const input_style_obj = input_style || {};
  const [eye_opened, seteye_opened] = useState(false);
  const horiz_m_top = horizontalAlign ? {} : { marginTop: "2px" };

  const change_func = (e) => {
    if (disabled == true) {
      e.preventDefault();
    }
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div
      className="removescrollbar"
      style={{
        flexDirection: "column",
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        gap: 5,
        ...cont_style_obj,
        // width:"100%"
      }}
    >
      {!horizontalAlign && (
        <span style={{ fontSize: "12px", marginLeft: "10px" }}>{label}</span>
      )}
      <div
        className="removescrollbar"
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          ...cont_style_obj,
          ...horiz_m_top,
          ...input_cont_style_obj,
          width: "100%",
        }}
      >
        {horizontalAlign && !label_right_align && (
          <span
            style={{ fontSize: "14px", fontWeight: "700", marginRight: "10px" }}
          >
            {label}
          </span>
        )}
        {!textArea && (
          <input
            onKeyDown={(e) => {
              if (onKeyPress) {
                onKeyPress(e);
              }
            }}
            value={value || ""}
            // value={value || (type == "number" ? 0 : "")}
            onFocus={(e) => {
              if (onFocus) {
                onFocus(e);
              }
            }}
            onChange={(e) => {
              if (type == "number" ? e.target.value >= 0 : true) {
                change_func(e);
              }
            }}
            type={eye_opened ? "text" : type || "text"}
            id="text_input"
            className={"removescrollbar " + (input_class_name || "")+" "+(s_className || '')}
            style={{
              width: "100%",
              backgroundColor: disabled ? "#c9c9c9" : "",
              height:"45px",
              ...{ ...(input_style_obj || { height: "" }) },
            }}
            disabled={disabled || false}
            placeholder={no_placeholder ? "" : placeholder || label}
            onClick={(e)=>{
              if(type == "date"){
                e.target.showPicker();
              }
            }}
            autoComplete="off"
          />
        )}
        {textArea && (
          <textarea
            onFocus={() => {
              if (onFocus) {
                onFocus();
              }
            }}
            disabled={disabled || false}
            value={value}
            onChange={change_func}
            type={eye_opened ? "text" : type || "text"}
            id="text_input"
            className={"removescrollbar " + (input_class_name || "")+" "+(s_className||'')}
            style={{
              backgroundColor: disabled ? "#c9c9c9" : "",
              ...{ ...(input_style_obj || { height: "" }) },
              width: "100%",
              minHeight:"42px"
            }}
            placeholder={placeholder || label}
          />
        )}
        {horizontalAlign && label_right_align && (
          <span
            style={{ fontSize: "14px", fontWeight: "700", marginLeft: "10px" }}
          >
            {label}
          </span>
        )}
        {type == "Password" && (
          <img
            src={!eye_opened ? eye_off_pic : eye_pic}
            onClick={() => seteye_opened(!eye_opened)}
            style={{
              cursor: "pointer",
              height: eye_opened ? 8 : 10,
              position: "absolute",
              right: 10,
              top: 18,
            }}
          />
        )}
        {suffix_img && (
          <img
            src={suffix_img}
            style={{
              height: 15,
              position: "absolute",
              right: 10,
              top: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              if (extra_func) {
                extra_func();
              }
            }}
          />
        )}
        {prefix_img && (
          <img
            src={prefix_img}
            style={{
              height: 15,
              position: "absolute",
              left: 10,
              top: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              if (extra_func) {
                extra_func();
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Text_Input;
