import React, { useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import { Modal } from "../../../../utilities/Modal";
import Flex_box from "../../../../utilities/flex_box";
import { num_regex } from "../../../../utilities/utilities";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Sentiments_Graph = ({
  p_sentiments_arr,
  n_sentiments_arr,
  neutral_sentiments_arr,
}) => {
  const [modalVisible, setmodalVisible] = useState(false);
  const [s_tab, sets_tab] = useState("positive");
  const graph_data_1 = p_sentiments_arr?.filter((el) => {
    return !(num_regex.test(el.label)) && el?.y > 0;
  });

  const graph_data_2 = n_sentiments_arr?.filter((el) => {
    return !(num_regex.test(el.label)) && el?.y > 0;
  });

  const graph_data_3 = neutral_sentiments_arr?.filter((el) => {
    return !(num_regex.test(el.label)) && el?.y > 0;
  });

  const options = (graph_type, tab) => {
    const p_tab = tab == "positive";
    const neu_tab = tab == "neutral";
    const n_tab = tab == "negative";

    return {
      animationEnabled: true,
      // theme: "light2", // Available themes: "light1", "light2", "dark1", "dark2"
      backgroundColor: "transparent",
      title: {
        text: tab ? "" : "Sentiments",
        fontColor: "#000",
      },
      axisX: {
        title: "X Axis",
      },
      axisY: {
        title: "Y Axis",
        includeZero: true,
      },
      data: tab
        ? [
            {
              type: graph_type, // Change type to "bar", "area", "pie", etc.
              name: p_tab
                ? "Positive Words"
                : neu_tab
                ? "Neutral Words"
                : "Negative Words",
              showInLegend: true,
              indexLabel: "{y}", // Shows y value on all data points
              // indexLabelFontColor: "#5A5757",
              indexLabelFontColor: p_tab
                ? "green"
                : neu_tab
                ? "#0096FF"
                : "red",
              color: p_tab ? "green" : neu_tab ? "#0096FF" : "red",
              indexLabelPlacement: "outside",
              dataPoints: p_tab
                ? graph_data_1
                : neu_tab
                ? graph_data_3
                : graph_data_2,
            },
          ]
        : [
            {
              type: graph_type, // Change type to "bar", "area", "pie", etc.
              name: "Positive Words",
              showInLegend: true,
              indexLabel: "{y}", // Shows y value on all data points
              // indexLabelFontColor: "#5A5757",
              color: "green",
              indexLabelFontColor: "green",
              indexLabelPlacement: "outside",
              dataPoints: graph_data_1,
            },
            {
              type: graph_type,
              name: "Negative Words",
              showInLegend: true,
              indexLabel: "{y}",
              color: "red",
              indexLabelFontColor: "red",
              indexLabelPlacement: "outside",
              dataPoints: graph_data_2,
            },
            {
              type: graph_type,
              name: "Neutral Words",
              showInLegend: true,
              indexLabel: "{y}",
              color: "#0096FF",
              indexLabelFontColor: "#0096FF",
              indexLabelPlacement: "outside",
              dataPoints: graph_data_3,
            },
          ],
    };
  };

  return (
    <>
      <div
        onClick={() => {
          setmodalVisible(true);
        }}
      >
        <CanvasJSChart
          options={{
            // ...options("doughnut","neutral"),
            ...options("line"),
          }}
          containerProps={{
            height: "360px",
            width: "100%",
            backgroundColor: "transparent",
          }}
        />
      </div>
      <Modal
        isOpen={modalVisible}
        onClose={() => setmodalVisible(false)}
        label={"Sentiments"}
      >
        <ul className="nav nav-pills justify-content-center">
          <li className="nav-item">
            <a
              className="nav-link pe-auto"
              aria-current="page"
              style={
                s_tab == "positive"
                  ? {
                      color: "#fff",
                      backgroundColor: "green",
                      cursor: "pointer",
                    }
                  : {
                      color: "green",
                      cursor: "pointer",
                    }
              }
              onClick={() => {
                sets_tab("positive");
              }}
            >
              Positive
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              style={
                s_tab == "neutral"
                  ? {
                      color: "#fff",
                      backgroundColor: "#0096FF",
                      cursor: "pointer",
                    }
                  : {
                      color: "#0096FF",
                      cursor: "pointer",
                    }
              }
              onClick={() => {
                sets_tab("neutral");
              }}
            >
              Neutral
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              style={
                s_tab == "negative"
                  ? {
                      color: "#fff",
                      backgroundColor: "red",
                      cursor: "pointer",
                    }
                  : {
                      color: "red",
                      cursor: "pointer",
                    }
              }
              onClick={() => {
                sets_tab("negative");
              }}
            >
              Negative
            </a>
          </li>
        </ul>
        <CanvasJSChart
          options={{
            ...options("bar", s_tab),
          }}
          containerProps={{
            height: "100%",
            maxHeight: "600px",
            minHeight: "400px",
            width: "100%",
            minWidth: "350px",
            maxWidth: "500px",
            marginTop: "10px",
          }}
        />
      </Modal>
    </>
  );
};

export default Sentiments_Graph;
