import React, { useEffect, useState } from "react";
import Page_heading from "../../../../utilities/Page_heading";
import user_icon from "../../../../assets/user_dummy.png";
import Pagination from "../../../../utilities/Pagination";
import {
  BASE_URL,
  gd_request_action_api,
  get_gd_requests_api,
  image_url,
  StudyDetails_api,
} from "../../../../apis/apis";
import { useLocation } from "react-router-dom";
import Button from "../../../../utilities/Button";
import { theme_bg, theme_color } from "../../../../utilities/colors";
import Flex_box from "../../../../utilities/flex_box";
import { useDispatch, useSelector } from "react-redux";
import { alert_action } from "../../../../redux_prog/actions/base_action";
import { Modal } from "../../../../utilities/Modal";
import DataTable from "../../../../utilities/DataTable";

const GD_Request_table = ({ gd_data, study_id, study_detail }) => {
  const dispatch = useDispatch();
  const [resp_list, setresp_list] = useState([]);
  const { user_data } = useSelector((state) => state?.authReducer);
  const [selected_user, setselected_user] = useState({});
  const pageSize = 10;
  const [currentPage, setcurrentPage] = useState(1);
  const [totalRows, settotalRows] = useState(0);
  const [modalVisible, setmodalVisible] = useState(false);

  const req_action = (el, status) => {
    const lookup_res = el?.users_lookup[0] || {};
    const status_msg = status == 1 ? "approved!" : "rejected!";
    gd_request_action_api({
      _id: el?._id,
      status,
      noti_body: {
        UserId: user_data?._id,
        toId: lookup_res?._id,
        msg:
          status == 3
            ? "You are removed from " + gd_data?.groupdiscussionname
            : "Your request for joining " +
              gd_data?.groupdiscussionname +
              " has been " +
              status_msg,
        title: status == 3 ? "Group Suspension" : "Request " + status_msg,
        type: "GD",
        notificatonTypeId: study_id,
        isread: false,
        sender_type: 2,
      },
    })
      .then((res) => {
        get_gd_requests();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const get_gd_requests = () => {
    const g_id = gd_data?._id;
    if (g_id) {
      get_gd_requests_api({
        g_id,
        offset: (currentPage - 1) * pageSize,
        limit: pageSize,
      })
        .then((res) => {
          const res_data = res.data?.Res[0]?.paginatedResults;
          settotalRows(res.data?.Res[0]?.totalCount[0]?.count);

          const approved_members_count = res_data?.filter((el) => {
            return el?.status == 1;
          })?.length;

          const new_data = res_data?.map((el) => {
            const lookup_res = el?.users_lookup[0] || {};
            return [
              <img
                src={image_url + lookup_res?.profilepictuer}
                style={{
                  height: "35px",
                }}
              />,
              lookup_res?.firstname + " " + lookup_res?.lastname,
              lookup_res?.email,
              <Flex_box
                style={{
                  width: el?.status == 0 || el?.status == 1 ? "200px" : "100px",
                  gap: 5,
                  justifyContent: "start",
                }}
              >
                <Button
                  style={
                    el?.status != 0
                      ? {
                          fontWeight: "600",
                          color:
                            el?.status == 2 || el?.status == 3
                              ? "red"
                              : theme_color,
                          backgroundColor: theme_bg,
                          border:
                            "2px solid " +
                            (el?.status == 2 || el?.status == 3
                              ? "red"
                              : theme_color),
                          padding: "8px 0px",
                          width: "100px",
                        }
                      : {
                          padding: "8px 0px",
                          width: "100px",
                          backgroundColor: "green",
                        }
                  }
                  label={
                    el?.status == 0
                      ? "Take Action"
                      : el?.status == 1
                      ? "Approved"
                      : el?.status == 3
                      ? "Removed"
                      : el?.status == 4
                      ? "Left"
                      : "Rejected"
                  }
                  onClick={() => {
                    if (el?.status == 0) {
                      if (
                        approved_members_count < study_detail?.totalrespondents
                      ) {
                        setmodalVisible(true);
                        // req_action(el, 1);
                        setselected_user(el);
                      } else {
                        dispatch(
                          alert_action({
                            type: "error",
                            title: "Max Limit!",
                            msg: `This study has reached it's maximum members!`,
                          })
                        );
                      }
                    }
                  }}
                />
                {el?.status == 1 && lookup_res?._id != user_data?._id && (
                  <Button
                    style={{
                      padding: "8px 0px",
                      width: "100px",
                      backgroundColor: "red",
                      border: "2px solid red",
                      color: "#fff",
                    }}
                    label={"Remove"}
                    onClick={() => {
                      dispatch(
                        alert_action({
                          type: "error",
                          title: "Remove?",
                          msg: "Do you want to remove this respondent?",
                          is_confirm: true,
                          onSuccess: () => req_action(el, 3),
                        })
                      );
                    }}
                  />
                )}
              </Flex_box>,
            ];
          });
          setresp_list(res_data);
        })
        .catch((err) => {
          console.warn(err);
        });
    }
  };

  const approved_members_count = resp_list?.filter((el) => {
    return el?.status == 1;
  })?.length;

  useEffect(get_gd_requests, [currentPage, gd_data]);

  const onNext = (page) => {
    setcurrentPage(page);
  };

  const onPrev = (page) => {
    setcurrentPage(page);
  };

  const Resp_Profile = ({}) => {
    // const {}
    const lookup_res = selected_user?.users_lookup[0] || {};

    return (
      <>
        <Flex_box>
          <img
            src={image_url + lookup_res?.profilepictuer}
            style={{
              height: "100px",
              width: "100px",
              objectFit: "contain",
              borderRadius: "50%",
            }}
          />
        </Flex_box>
        <Flex_box
          style={{
            // justifyContent: "start",
            marginTop: "10px",
          }}
        >
          <b
            style={{
              width: "80px",
            }}
          >
            Name
          </b>
          <b
            style={{
              width: "15px",
            }}
          >
            :
          </b>
          <div
            style={{
              width: "150px",
            }}
          >
            {lookup_res?.firstname + " " + lookup_res?.lastname}
          </div>
        </Flex_box>
        <Flex_box
          style={{
            // justifyContent: "start",
            marginTop: "10px",
          }}
        >
          <b
            style={{
              width: "80px",
            }}
          >
            Country
          </b>
          <b
            style={{
              width: "15px",
            }}
          >
            :
          </b>
          <div
            style={{
              width: "150px",
            }}
          >
            {lookup_res?.country}
          </div>
        </Flex_box>
        <Flex_box
          style={{
            marginTop: "15px",
            gap: 15,
          }}
        >
          <Button
            style={{
              color: "#fff",
              backgroundColor: "red",
              border: "2px solid red",
              padding: "7px 0px",
            }}
            label={"Reject"}
            onClick={() => {
              req_action(selected_user, 2);
              setmodalVisible(false);
            }}
          />
          <Button
            style={{
              padding: "7px 0px",
            }}
            onClick={() => {
              req_action(selected_user, 1);
              setmodalVisible(false);
            }}
            label="Approve"
          />
        </Flex_box>
      </>
    );
  };

  const t_heads = [
    {
      label: "Profile Picture",
      accessor: "-",
      element: (el) => {
        const lookup_res = el?.users_lookup[0] || {};
        return (
          <img
            src={image_url + lookup_res?.profilepictuer}
            style={{
              height: "35px",
            }}
          />
        );
      },
    },
    {
      label: "Name",
      accessor: "-",
      element: (el) => {
        const lookup_res = el?.users_lookup[0] || {};
        return (
          <span>{lookup_res?.firstname + " " + lookup_res?.lastname}</span>
        );
      },
    },
    {
      label: "Email",
      accessor: "-",
      element: (el) => {
        const lookup_res = el?.users_lookup[0] || {};
        return <span>{lookup_res?.email}</span>;
      },
    },
    {
      label: "Action",
      accessor: "-",
      element: (el) => {
        const lookup_res = el?.users_lookup[0] || {};
        return (
          <Flex_box
            style={{
              width: el?.status == 0 || el?.status == 1 ? "200px" : "100px",
              gap: 5,
              justifyContent: "start",
              height: "100%",
            }}
          >
            <Button
              label_style={{
                marginTop: "-7px",
              }}
              style={
                el?.status != 0
                  ? {
                      fontWeight: "600",
                      color:
                        el?.status == 2 || el?.status == 3
                          ? "red"
                          : theme_color,
                      backgroundColor: theme_bg,
                      border:
                        "2px solid " +
                        (el?.status == 2 || el?.status == 3
                          ? "red"
                          : theme_color),
                      // padding: "2px 0px",
                      height: "40px",
                      width: "100px",
                      // paddingBottom:"30px"
                      padding: "0px 0px",
                    }
                  : {
                      height: "40px",
                      padding: "0px 0px",
                      width: "100px",
                      backgroundColor: "green",
                    }
              }
              label={
                el?.status == 0
                  ? "Take Action"
                  : el?.status == 1
                  ? "Approved"
                  : el?.status == 3
                  ? "Removed"
                  : el?.status == 4
                  ? "Left"
                  : "Rejected"
              }
              onClick={() => {
                if (el?.status == 0) {
                  if (approved_members_count < study_detail?.totalrespondents) {
                    setmodalVisible(true);
                    // req_action(el, 1);
                    setselected_user(el);
                  } else {
                    dispatch(
                      alert_action({
                        type: "error",
                        title: "Max Limit!",
                        msg: `This study has reached it's maximum members!`,
                      })
                    );
                  }
                }
              }}
            />
            {el?.status == 1 && lookup_res?._id != user_data?._id && (
              <Button
                label_style={{
                  marginTop: "-7px",
                }}
                style={{
                  padding: "0px 0px",
                  width: "100px",
                  backgroundColor: "red",
                  border: "2px solid red",
                  color: "#fff",
                  height: "40px",
                }}
                label={"Remove"}
                onClick={() => {
                  dispatch(
                    alert_action({
                      type: "error",
                      title: "Remove?",
                      msg: "Do you want to remove this respondent?",
                      is_confirm: true,
                      onSuccess: () => req_action(el, 3),
                    })
                  );
                }}
              />
            )}
          </Flex_box>
        );
      },
    },
  ];

  return (
    <>
      <div
        style={{
          padding: "20px 0px",
          paddingBottom: "40px",
        }}
      >
        <Page_heading label={"Members"} />
        <div
          style={{
            width: "100%",
            marginTop: "20px",
            boxShadow: "0px 10px 15px #2424240D",
            borderRadius: "10px",
          }}
        >
          {resp_list?.length > 0 && (
            <>
              <DataTable style={{}} heads={t_heads} rows={resp_list} />
              {/* <Pagination
                currentPage={currentPage}
                onNext={onNext}
                onPrev={onPrev}
                totalRows={totalRows}
                pageSize={pageSize}
              /> */}
            </>
          )}
        </div>
      </div>
      <Modal
        isOpen={modalVisible}
        label={"Respondent Profile"}
        onClose={() => {
          setmodalVisible(false);
        }}
      >
        <Resp_Profile />
      </Modal>
    </>
  );
};

export default GD_Request_table;
