import Layout from "../../../utilities/Layout";
import DataTable from "../../../utilities/DataTable";
import React, { useEffect, useState } from "react";
import Flex_box from "../../../utilities/flex_box";
import Button from "../../../utilities/Button";
import TopUp_Modal from "./Top_Up_Modal";
import { get_subscription_data_count_and_kyc } from "../../../utilities/utilities";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import MUI_Table from "../../../utilities/MUI_Table";
// import { max } from "lodash-es"

const Subscription_quota = () => {
  const [modalVisible, setmodalVisible] = useState(false);
  const dispatch = useDispatch();

  const [selected_quota, setselected_quota] = useState("");

  const { user_data } = useSelector((state) => state?.authReducer);

  const t_heads = [
    {
      label: "Quota",
      accessor: "Quota",
    },
    {
      label: "Maxmimum",
      accessor: "max",
    },
    {
      label: "Left",
      accessor: "left",
    },
    {
      label: "Action",
      accessor: "-",
      element: (el,index,row) => {
        return (
          <Button
            label={"Add Top-up"}
            style={{
              paddingBottom: "25px",
              height: "30px",
              width: "100px",
            }}
            label_style={{
              marginTop: "-17px",
            }}
            onClick={() => {
              console.warn(el,index,row);
              
              // setselected_quota(el.Quota);
              // setmodalVisible(true);
            }}
          />
        );
      },
    },
  ];

  const {
    study_count,
    created_studies,
    max_respondents,
    total_respondants,
    coin_limit,
    used_coins,
    video_call_hrs,
    used_video_call_hrs,
  } = user_data || {};

  const t_rows =
    study_count > 0
      ? [
          {
            id: 1,
            Quota: "Study",
            max: study_count,
            left: study_count - created_studies,
          },
          {
            id: 2,
            Quota: "Respondents",
            max: max_respondents,
            left: max_respondents - total_respondants,
          },
          {
            id: 3,
            Quota: "Coins",
            max: coin_limit,
            left: coin_limit - used_coins,
          },
          {
            id: 4,
            Quota: "Video Call Hrs",
            max: video_call_hrs,
            left: video_call_hrs - used_video_call_hrs,
          },
        ]
      : [];

  useEffect(() => {
    get_subscription_data_count_and_kyc(dispatch, user_data);
  }, []);

  return (
    <>
      <Layout>
        <DataTable heads={t_heads} rows={t_rows} />
        {/* <MUI_Table heads={t_heads} rows={t_rows} /> */}
      </Layout>
      <TopUp_Modal
        selected_quota={selected_quota}
        modalVisible={modalVisible}
        setmodalVisible={setmodalVisible}
      />
    </>
  );
};

export default Subscription_quota;
