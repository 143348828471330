import React, { useEffect, useState } from "react";
import { Modal } from "../../../utilities/Modal";
import { get_subscriptions_api } from "../../../apis/apis";
import Pricing_Box from "../../Researcher_screens/Subscriptions_Packages/Pricing_Box";
import Flex_box from "../../../utilities/flex_box";

const Subs_Modal = ({ isModalVisible, setisModalVisible,func }) => {
  const [sub_arr, setsub_arr] = useState([]);;

  const getSubscriptions = () => {
    get_subscriptions_api()
      .then((res) => {
        setsub_arr(res.data.Res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(getSubscriptions, []);
  

  return (
    <Modal
      label={"Select Subscription"}
      isOpen={isModalVisible}
      onClose={() => setisModalVisible(false)}
    >
        <Flex_box
          style={{
            gap: "30px",
            // marginTop: "25px",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {sub_arr?.map((el, index) => {

            return (
              <Pricing_Box
                key={index}
                label={el.Name}
                price={`$${el?.Amount}`}
                subtext={`Per ${el?.Cycle}`}
                duration={el?.Duration}
                content={el?.Description}
                coin={el?.coin_limit}
                max_respondents={el?.max_respondents}
                study={el?.study_limit}
                call={el?.video_call_hrs}
                _id={el?._id}
                item={el}
                // isActive={isActive}
                style={{
                  width: "300px",
                  marginBottom: "20px",
                  border:  "1px solid grey",
                }}
                func={func}
                modal_box={true}
                setisModalVisible={setisModalVisible}
                // active_sub={activeSubscription?._id}
              />
            );
          })}
          {/* Continue Free Option */}
          
        </Flex_box>
    </Modal>
  );
};

export default Subs_Modal;
