import React, { useRef } from "react";
import Survey_Analysis from "./Survey_Analysis";
import pdf_icon from "../../../../assets/bxs-file-pdf.svg";
import Footer from "../../../../utilities/PDF/Footer";
import Content from "../../../../utilities/PDF/Content";
import Title from "../../../../utilities/PDF/Title";
import { useReactToPrint } from "react-to-print";

const Print_Report = ({ study_id, studyname, elem }) => {
  const ref_p = useRef(null);

  const generatepdf = useReactToPrint({
    documentTitle: "",
    // pageStyle: `.divider { background-color:red }`,
    // onAfterPrint: () => {
    //     setprint_flag(false);
    // },
    content: () => ref_p.current,
  });

  const create_pdf = () => {
    setTimeout(() => {
      generatepdf();
    }, 200);
  };

  return (
    <>
      {elem && (
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={create_pdf}
        >
          {elem}
        </div>
      )}
      {!elem && (
        <button
          id="btn_comp"
          style={{
            width: "40px",
            height: "40px",
            backgroundColor: "#fff",
            boxShadow: "0px 2px 6px #0000000D",
            paddingTop: "7px",
          }}
          onClick={create_pdf}
        >
          <img
            src={pdf_icon}
            style={{
              height: "15px",
            }}
          />
        </button>
      )}
      <div
        style={{
          width: "100%",
          position: "fixed",
          height: "100%",
          overflowY: "auto",
          zIndex: -100,
          left: -100000,
          top: -100000,
        }}
      >
        <div
          ref={ref_p}
          style={{
            width: "100%",
            backgroundColor: "#fff",
            minHeight: "100vh",
            position: "relative",
          }}
        >
          {/* <Header img_ml={'5px'} div_pl={'90px'} /> */}
          <table className="print-component" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>
                  <div>
                    <Title
                      title={studyname + " survey analysis"}
                      // border_b_null={true}
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Content>
                    <div
                      style={{
                        width: "100%",
                        marginTop: "-20px",
                      }}
                    >
                      <Survey_Analysis studyname={studyname} s_id={study_id} />
                    </div>
                  </Content>
                </td>
              </tr>
            </tbody>
            <tfoot className="table-footer" style={{ border: "none" }}>
              <tr style={{ border: "none" }}>
                <td style={{ border: "none" }}>
                  <Footer />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
};

export default Print_Report;
