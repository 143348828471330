import { theme_color } from "../../../utilities/colors";
import { useDispatch, useSelector } from "react-redux";
import { get_eligibility_questions_api } from "../../../apis/apis";
import React, { useEffect, useState } from "react";
import { loading_action } from "../../../redux_prog/actions/base_action";
import Flex_box from "../../../utilities/flex_box";
import { Radio } from "@mui/material";
import Select_box from "../../../utilities/Select_box";
import { state_arr } from "../../../utilities/select_arr";
import { check_eligibility_question_response_api } from "../../../apis/apis";
import { useLocation, useNavigate } from "react-router-dom";
import { alert_action } from "../../../redux_prog/actions/base_action";
import { set_user_action } from "../../../redux_prog/actions/auth_action";
import { set_temp_user_action } from "../../../redux_prog/actions/app_action";
import { african_countries_and_states } from "../../../utilities/utilities";

const Eligibility_Question = ({ setintro_completed, survey_details }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [eligibilityQuestions, setEligibilityQuestions] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [eligibility_data, seteligibility_data] = useState({});
  const { user_data, temp_user } = useSelector(
    (r_state) => r_state?.authReducer
  );

  const { state } = useLocation();
  const { surveydata, questionsdata } = state || survey_details;

  // const [stateList, setStateList] = useState([]);

  const stateList = african_countries_and_states
    ?.map((el) => {
      return el.states;
    })
    .flat()
    ?.map((el) => {
      return {
        label: el,
        value: el,
      };
    }).sort((a, b) => a.label.localeCompare(b.label));

  const get_eligibility_questions = () => {
    // debugger;
    dispatch(loading_action(true));
    get_eligibility_questions_api()
      .then((res) => {
        // debugger;
        console.log("eligibility question", res.data.Res);
        setEligibilityQuestions(res.data.Res || []);
        dispatch(loading_action(false));
        // console.log(res);
        // if (res.data.status == 1) {
        //     setstudy_detail(res.data.Res[0] || {});
        // }
      })
      .catch((err) => {
        dispatch(loading_action(false));
        console.log(err);
      });
  };

  useEffect(get_eligibility_questions, []);

  const handelState = () => {
    const newArray = [...eligibilityQuestions];
    for (let i = 0; i < state_arr.length; i++) {
      newArray.push({ label: state_arr[i].state, value: state_arr[i].state });
    }
    console.log("state array", newArray);
    // setStateList(newArray);
  };

  // useEffect(handelState, []);



  const handelNextQuestion = () => {
    if (selectedAnswer) {
      dispatch(loading_action(true));
      var body = {
        studyId: surveydata.studyid,
        answer: selectedAnswer,
        questionType: eligibilityQuestions[currentIndex].questionType,
        user_id:user_data?._id || temp_user?._id,
        temp_user:temp_user?._id?true:false,
        _id:eligibility_data?._id
      };
      // debugger;
      check_eligibility_question_response_api(body)
        .then((res) => {
          dispatch(loading_action(false));
          if (res.data.Res) {
            if (temp_user?._id) {
              dispatch(
                set_temp_user_action({
                  ...temp_user,
                  [eligibilityQuestions[currentIndex].questionType]:
                    selectedAnswer,
                })
              );
            } else {
              dispatch(
                set_user_action({
                  ...user_data,
                  [eligibilityQuestions[currentIndex].questionType]:
                    selectedAnswer,
                })
              );
            }

            if(currentIndex == 0){
              seteligibility_data(res.data.eligibility_res);
            }

            setSelectedAnswer("");

            if (currentIndex < eligibilityQuestions.length - 1) {
              setCurrentIndex(currentIndex + 1);
            } else {
              setintro_completed(true);
            }

          } else {
            navigate("/dashboard");
            dispatch(
              alert_action({
                type: "error",
                title: "Eligibility",
                msg: "You are not eligible!",
              })
            );
          }
        })
        .catch((err) => {
          dispatch(loading_action(false));
        });
    } else {
      alert("Answer is required");
    }
  };

  const handelPreviousQuestion = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1) % eligibilityQuestions.length
    );
  };

  return (
    <>
      <Flex_box className="responsive_width_35">
        <span
          style={{
            color: theme_color,
            fontWeight: 800,
            fontSize: "22px",
            // position: 'absolute',
            // left: '0'
          }}
        >
          Eligibility Questions
        </span>
      </Flex_box>
      <Flex_box
        className="responsive_width_35"
        style={{
          flexDirection: "column",
          padding: "20px",
          width: "",
          borderRadius: "10px",
          boxShadow: "0px 5px 10px #0000000D",
          gap: 10,
          textAlign: "center",
          position: "relative",
          //   marginTop: "10px",
          // backgroundColor:"red"
        }}
      >
        {/* <b style={{ fontSize: "22px" }}>{surveydata?.surveytitle}</b> */}
        <div style={{ width: "100%", textAlign: "start" }}>
          <b style={{ fontSize: "14px" }}>
            Q{currentIndex + 1} {eligibilityQuestions[currentIndex]?.question}
            {/* Q{current_ques + 1} {cur_question?.QuestionData?.questiontitle} */}
          </b>
          <div
            style={{
              width: "100%",
              marginTop: "15px",
            }}
          >
            {eligibilityQuestions[currentIndex]?.questionType == "gender" ? (
              <>
                {eligibilityQuestions[currentIndex]?.options?.map((option) => (
                  <Flex_box
                    style={{
                      justifyContent: "start",
                      // gap: 5,
                      marginTop: "5px",
                      width: "100%",
                    }}
                  >
                    <Radio
                      checked={selectedAnswer == option}
                      onChange={(e) => {
                        setSelectedAnswer(e.target.value);
                      }}
                      value={option}
                      name="same"
                      inputProps={{ "aria-label": "A" }}
                    />
                    <span
                      style={{
                        fontSize: "11px",
                      }}
                    >
                      {option}
                    </span>
                  </Flex_box>
                ))}
              </>
            ) : eligibilityQuestions[currentIndex]?.questionType == "age" ? (
              <>
                {eligibilityQuestions[currentIndex]?.options?.map((option) => (
                  <Flex_box
                    style={{
                      justifyContent: "start",
                      // gap: 5,
                      marginTop: "5px",
                      width: "100%",
                    }}
                  >
                    <Radio
                      checked={selectedAnswer == option.value}
                      onChange={(e) => {
                        setSelectedAnswer(e.target.value);
                      }}
                      value={option.value}
                      name="same"
                      inputProps={{ "aria-label": "A" }}
                    />
                    <span
                      style={{
                        fontSize: "11px",
                      }}
                    >
                      {option.label}
                    </span>
                  </Flex_box>
                ))}
              </>
            ) : (
              eligibilityQuestions[currentIndex]?.questionType ==
                "location" && (
                <>
                  {!eligibilityQuestions[currentIndex]?.options?.length && (
                    <Select_box
                      cont_style={{
                        marginTop: "20px",
                        width: "84%",
                      }}
                      input_style={{
                        width: "100%",
                      }}
                      select_cont_style={{
                        width: "100%",
                      }}
                      options={stateList}
                      value={selectedAnswer}
                      onChange={(e) => {
                        setSelectedAnswer(e.target.value);
                      }}
                    />
                  )}
                </>
              )
            )}
          </div>
          <Flex_box
            style={{
              gap: 25,
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            {currentIndex > 0 && (
              <button
                id="btn_comp"
                style={{ fontSize: "16px", fontWeight: "500" }}
                onClick={() => {
                  handelPreviousQuestion();
                }}
              >
                Previous
              </button>
            )}
            <button
              id="btn_comp"
              style={{
                fontSize: "16px",
                fontWeight: "500",
                backgroundColor: "#FFCC99",
                border: "2px solid #FFCC99",
                color: "#000",
                opacity: 1,
              }}
              // disabled={loading}
              onClick={() => {
                handelNextQuestion();
              }}
            >
              <Flex_box
                style={{
                  gap: 10,
                }}
              >
                <b>Next</b>
              </Flex_box>
            </button>
          </Flex_box>
        </div>
      </Flex_box>
    </>
  );
};

export default Eligibility_Question;
