import React from "react";
import footer_bg from "../../../assets/Group2255.svg";
import bg2 from "../../../assets/Path11389.png";
import logo from "../../../assets/Group 40944.png";
import Flex_box from "../../../utilities/flex_box";
import Nav_Link from "./Nav_Link";
import facebook_icon from "../../../assets/Group 48347.svg";
import linkedin_icon from "../../../assets/Group 48348.svg";
import insta_icon from "../../../assets/Group 48349.svg";
import { theme_color } from "../../../utilities/colors";
import zIndex from "@mui/material/styles/zIndex";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import logo from "../../../assets/Group 40944.png";

const Footer = () => {
  const max_w_1000 = useMediaQuery("(max-width:1000px)");
  const navigate = useNavigate();
  return (
    <>
      {/* <Flex_box
        style={{
          justifyContent: "center",
          padding: "10px",
          boxSizing: "border-box",
          alignItems: "",
          gap: 40,
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            width: max_w_1000 ? "100%" : "",
          }}
        >
          <img
            src={logo}
            style={{
              height: "40px",
            }}
          />
          <p
            style={{
              fontSize: "13px",
              width: "280px",
            }}
          >
            Yaarnbox enables businesses to verify, authenticate, and engage
            customers globally.
          </p>
          <Flex_box
            style={{
              boxSizing: "border-box",
              gap: 20,
              zIndex: 101,
              justifyContent: "start",
            }}
          >
            <img
              src={facebook_icon}
              style={{
                height: "30px",
              }}
            />
            <img
              src={linkedin_icon}
              style={{
                height: "30px",
              }}
            />
            <img
              src={insta_icon}
              style={{
                height: "30px",
              }}
            />
          </Flex_box>
        </div>
        <div
          style={{
            fontSize: "13px",
            width: max_w_1000 ? "100%" : "",
          }}
        >
          <b
            style={{
              fontSize: "15px",
            }}
          >
            Company
          </b>
          <p>About Yaarnbox</p>
          <p>Careers</p>
          <p>Terms of service</p>
          <p>Privacy Policies</p>
          <p>Compliance</p>
          <p>Annual Report (2023)</p>
        </div>
        <Flex_box
          style={{
            width: "",
            flexWrap: "wrap",
            gap: 10,
            justifyContent: "start",
            alignItems: "",
          }}
        >
          <div
            style={{
              maxWidth: "250px",
            }}
          >
            <img
              src="https://www.termii.com/_nuxt/us.122a7364.svg"
              style={{
                height: "40px",
              }}
            />
            <br />
            <b>SAN JOSE, CA</b>
            <div
              style={{
                fontSize: "13px",
              }}
            >
              <p>2880 Zanker Road Suite 203 San Jose CA 95134</p>
              <p>1-669-241-1742</p>
              <p>xyz@mail.com</p>
            </div>
          </div>
          <div
            style={{
              maxWidth: "250px",
            }}
          >
            <img
              src="https://www.termii.com/_nuxt/nig.b73fdda9.svg"
              style={{
                height: "40px",
              }}
            />
            <br />
            <b>LAGOS, NG</b>
            <div
              style={{
                fontSize: "13px",
              }}
            >
              <p>2880 Zanker Road Suite 203 San Jose CA 95134</p>
              <p>1-669-241-1742</p>
              <p>xyz@mail.com</p>
            </div>
          </div>
          <div
            style={{
              maxWidth: "250px",
            }}
          >
            <img
              src="https://www.termii.com/_nuxt/Cote.d6c74cee.svg"
              style={{
                height: "40px",
              }}
            />
            <br />
            <b>ABIDJAN, CI</b>
            <div
              style={{
                fontSize: "13px",
              }}
            >
              <p>2880 Zanker Road Suite 203 San Jose CA 95134</p>
              <p>1-669-241-1742</p>
              <p>xyz@mail.com</p>
            </div>
          </div>
        </Flex_box>
      </Flex_box> */}
<footer>
   <div class="container">
      <div class="row justify-content-center">
         <div class="col-md-5 footerprh">
            <a href="#" class="footerlogo"> <img src="assets/images/yarn-dark.png" width="200px" /> </a> 
            <p class="text-white"> Yaarnbox was built to foster impactful connections between brands and their consumers. Beyond traditional surveys, we enable meaningful connections that unlock deeper insights. </p>
            <p class="text-white"> Researchers and brands can now deliver their studies to qualified respondents based on demographics and preferences, ensuring high-quality data collection and fast delivery, Boosting efficiency by streamlining process, and saving valuable time. </p>
            <ul class="social-link">
               {/* <li> <a href="https://www.facebook.com/" target="_blank"> <i class="fa-brands fa-facebook"></i>{" "} </a> </li> */}
               <li> <a href="https://twitter.com/" target="_blank"> <i class="fa-brands fa-instagram"></i>{" "} </a> </li>
               <li> <a href="https://www.instagram.com/" target="_blank"> <i class="fa-brands fa-linkedin"></i>{" "} </a> </li>
            </ul>
         </div>
         <div class="col-md-7">
            <div class="">
               <div class="row justify-content-center ">
                  <div class="col-md-4">
                     <h4 class="footer-title">Our Links</h4>
                     <ul class="f-list">
                     
                  
                  <li> <div style={{ cursor: "pointer", color: "#fff", }} > Our Story{" "} 
               </div>
               {" "} </li> 

                  <li> <div style={{ cursor: "pointer", color: "#fff", }} > How It Works{" "} 
               </div>
               {" "} </li> 
               
               
               <li> <div style={{ cursor: "pointer", color: "#fff", }} onClick={() => { navigate("/privacy-policy"); }} > Privacy Policies{" "} </div>{" "} </li>
            
             <li> <div style={{ cursor: "pointer", color: "#fff", }} onClick={() => { navigate("/terms-conditions");  }} > Terms of Use{" "} 
         </div>
         {" "} </li> 
         
         
         <li> <div style={{ cursor: "pointer", color: "#fff", }} onClick={() => { navigate("/our-certifications");  }}> Our ISO Certifications{" "} 
      </div>
      {" "} </li> 
      
      </ul> 
   </div>
   <div class="col-md-4">
      <h4 class="footer-title">Company</h4>
      <ul class="f-list">
         <li> <a href="#">Pierrine Consulting </a>{" "} </li>
         <li> <a href="#">Afrikan Insights </a>{" "} </li>
      </ul>
   </div>
   <div class="col-md-4">
      <h4 class="footer-title">Contact</h4>
      <ul class="f-list">
         <li> <a href=""> <b>Email:</b> contact@yaarnbox.com </a> </li>
         <li> <a href=""> <b>Phone:</b> +234 703 670 6134 </a> </li>
      </ul>
   </div>
   </div> </div> </div> </div> </div> 
</footer>
     
    </>
  );
};

export default Footer;
