export const study_validation_keys = {
  StudyOnInductry: "Industry",
  studyname: "Name",
  Description: "Description",
  IncentiveType: "Incentive Type",
  IncevtiveAmount: "Incentive Amount",
  TotalRespondents: "Total Respondents",
  gender: "Gender",
  age: "Age",
  close_date: "Close date",
  SocioEconomicClassification: "SocioEconomic Classification",
  location: "Location",
};

export const signup_keys = {
  user_name: "Username",
  first_name: "First Name",
  last_name: "Last Name",
  email: "Email",
  password: "Password",
};

export const researcher_auth_keys = {
  Businessname: "Name",
  BusinessDescription: "Description",
  BusinessAddress: "Address",
  Industry: "Industry",
  ConductIndustryStudiesIn: "Study conducting places",
  SurName: "Sunname",
  FirstName: "First Name",
  OtherName: "Other Name",
  Mobileno: "Mobile Number",
  Address: "Address",
  Email: "Email",
  ProfessionalAcadeMicQualification: "Qualification",
  StateOfOrigin: "State of origin",
  LGAoForigin: "LGA of origin",
  Signature: "Signature",
  Country: "Country",
  State: "State",
  Dop: "Date of birth",
};

export const gd_validation_keys = {
  GroupDiscussionName: "Name",
  DiscussionAbout: "About",
  date: "Date",
  Time: "Time",
};
