import React from "react";
import Layout from "../utilities/Layout";
import Page_heading from "../utilities/Page_heading";
import { theme_bg } from "../utilities/colors";
import FA_Icon_box from "../utilities/Icon_Box";
import { useNavigate } from "react-router-dom";

const Terms_conditions = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        padding: "20px",
        boxSizing: "border-box",
      }}
    >
      <Page_heading
        label={""}
        suffix={
          <FA_Icon_box
            className={"fa-regular fa-circle-xmark"}
            style={{
              backgroundColor: "#000",
            }}
            onClick={() => {
              navigate(-1);
            }}
          />
        }
      />
      <div
        style={{
          backgroundColor: theme_bg,
          padding: "15px",
          borderRadius: "10px",
          fontSize: "12px",
          lineHeight: 1.7,
          marginTop: "15px",
          height: "85vh",
          overflowY: "auto",
        }}
      >
        <h2><b>Yaarnbox Terms and Conditions</b></h2>
        <b>Last Updated:</b> December 22, 2023 <br /><br />{" "}
        <h5>1. Welcome to Yaarnbox</h5>
        Thank you for choosing Yaarnbox, a market research platform operated by
        Pierrine Consulting Limited ("Company", "we", "us", or "our"). These
        Terms and Conditions ("Terms") govern your access and use of the
        Yaarnbox platform, website, and associated services ("Platform"). By
        accessing or using the Platform, you agree to be bound by these Terms.
        If you do not agree to these Terms, you may not access or use the
        Platform.
        <br /><br/> <h5>2. Eligibility</h5>
        You must be at least 18 years old to use the Platform. By using the
        Platform, you represent and warrant that you are at least 18 years old.{" "}
        <br /><br/>
        <h5>3. User Roles</h5> The Platform caters to two distinct user groups:
        Researchers: Individuals or organizations conducting market research
        using the Platform's functionalities. Respondents: Individuals
        participating in market research studies hosted on the Platform. Each
        user group has specific rights and responsibilities outlined in these
        Terms.
        <br /><br/> <h5>4. Account Registration</h5> Both Researchers and Respondents may be
        required to create an account to access certain features of the
        Platform. You are responsible for maintaining the confidentiality of
        your account information, including username and password. You are also
        responsible for all activity that occurs under your account. You agree
        to notify us immediately of any unauthorized use of your account.
        <br /><br/> <h5>5. Researcher Responsibilities</h5> Compliance with Laws and
        Regulations: Researchers must ensure their research conducted through
        Yaarnbox adheres to all applicable laws and regulations, including data
        privacy laws, ethical research principles, and respondent compensation
        agreements. Survey Design and Content: Researchers are responsible for
        the content and design of their surveys, ensuring they are fair,
        unbiased, and do not contain any harmful or illegal material. Respondent
        Data Privacy and Security: Researchers are responsible for ensuring the
        privacy and security of respondent data collected through the Platform.
        This includes implementing appropriate data security measures and
        complying with all data privacy regulations. Respondent Compensation:
        Researchers are responsible for fulfilling any agreed-upon compensation
        or incentives for respondents participating in their studies.
        <br /><br/> <h5>6. Respondent Responsibilities</h5> Truthful Participation:
        Respondents agree to participate in research studies honestly and
        provide accurate information to the best of their knowledge. Respectful
        Conduct: Respondents agree to treat researchers and other participants
        with respect and refrain from any discriminatory, offensive, or harmful
        behavior. Data Privacy: Respondents understand that their participation
        in research studies may involve the collection and analysis of their
        personal data. They have the right to access, review, and rectify their
        data through the Platform's privacy settings. <br /><br/>
        <h5>7. Intellectual Property </h5>The Platform and all of its content, including
        text, graphics, logos, images, software, and code, are protected by
        copyright, trademark, and other intellectual property laws. You may not
        copy, modify, adapt, reproduce, distribute, publish, display, perform,
        transmit, or create derivative works from any content on the Platform
        without our prior written permission. <br /><br/>
        <h5>8. User Content</h5> Researchers and Respondents may submit content to the
        Platform, such as survey questions, responses, and chat transcripts. You
        retain ownership of any intellectual property rights in the content you
        submit. However, by submitting content to the Platform, you grant us a
        non-exclusive, worldwide, royalty-free license to use, reproduce,
        distribute, modify, adapt, publicly perform, and publicly display your
        content for the purpose of operating and improving the Platform. <br /><br/>
        <h5>9. Termination</h5> We may terminate your access to the Platform at any time,
        for any reason, without notice. If we terminate your access, you will no
        longer be able to use the Platform.
        <br /><br/> <h5>10. Disclaimer of Warranties</h5> The Platform is provided on an "as
        is" and "as available" basis. We make no warranties of any kind, express
        or implied, about the Platform, including, but not limited to,
        warranties of merchantability, fitness for a particular purpose,
        non-infringement, and title.
        <br /><br/> <h5>11. Limitation of Liability</h5> We will not be liable for any damages
        arising out of your use of the Platform, including, but not limited to,
        direct, indirect, incidental, consequential, special, exemplary, or
        punitive damages.
        <br /><br/> <h5>12. Governing Law</h5> These Terms are governed by the laws of [Your
        Company's Jurisdiction]. You agree to submit to the exclusive
        jurisdiction of the courts of [Your Company's Jurisdiction] to resolve
        any disputes arising out of these Terms. <br /><br/>
        <h5>13. Entire Agreement</h5> These Terms constitute the entire agreement between
        you and us regarding your use of the Platform.
        <br /><br/> <h5>14. Changes to Terms</h5> We may update these Terms from time to time.
        We will notify you of any changes by posting the new Terms on the
        Platform. You are responsible
      </div>
    </div>
  );
};

export default Terms_conditions;
