import React, { useEffect, useState } from "react";
import Flex_box from "../../../../../../utilities/flex_box";
import user_icon from "../../../../../../assets/user_dummy.png";
import {
  get_polls_api,
  image_url,
  poll_answer_api,
} from "../../../../../../apis/apis";
import Button from "../../../../../../utilities/Button";
import pie_chart_icon from "../../../../../../assets/pie-chart.png";
import Poll_Details from "./Poll_Details";
import { get_iso_date } from "../../../../../../utilities/Date_Util";
import { useSelector } from "react-redux";
import { theme_color } from "../../../../../../utilities/colors";
import dot_menu from "../../../../../../assets/dot_menu.png";

const Poll_Box = ({
  reverse,
  message,
  messages,
  setMessages,
  gd_data,
  seteffect_func,
  effect_func,
  handleSubmit,
  socket,
  del_message,
}) => {
  const [poll_detail_modal, setpoll_detail_modal] = useState(false);
  const top_b_radius = !reverse
    ? { borderTopLeftRadius: "0px" }
    : { borderTopRightRadius: "0px" };
  const { user_data } = useSelector((state) => state?.authReducer);

  const user_pic = message?.user_image
    ? image_url + message?.user_image
    : user_icon;

  const [poll_detail, setpoll_detail] = useState({});

  const get_polls = (e) => {
    get_polls_api({
      _id: message?.text?.split("~")[1],
    })
      .then((res) => {
        // console.warn(res.data);
        const groupedData = res.data.Res[0]?.options?.reduce((acc, item) => {
          console.warn({ item });

          const id = item._id;
          if (!acc[id]) {
            acc[id] = {
              _id: item._id,
              option: item.option,
              responses: item.responses ? [item.responses] : [],
              count: 1,
              allow_multiple_opts: res.data.Res[0].allow_multiple_opts,
            };
          } else {
            acc[id].count += 1;
            if (item.responses) {
              acc[id].responses.push(item.responses);
            }
          }
          return acc;
        }, {});

        const option_result = Object.values(groupedData);

        // console.warn(option_result);

        setpoll_detail({
          ...res.data.Res[0],
          options: option_result,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const poll_answer = ({ option_id, status, deloption_id }) => {
    console.warn({ option_id, status, deloption_id });

    poll_answer_api({
      poll_res: { user_id: user_data?._id, option_id, status },
    })
      .then(async (res) => {
        console.warn(res.data);
        if (status == true) {
          // setpoll_detail({
          //   ...poll_detail,
          //   options: poll_detail?.options?.map((elem) => {
          //     if (elem._id == option_id) {
          //       return {
          //         ...elem,
          //         responses: [
          //           ...elem.responses,
          //           {
          //             user_id: user_data?._id,
          //             option_id,
          //           },
          //         ],
          //       };
          //     } else {
          //       return elem;
          //     }
          //   }),
          // });

          if (deloption_id) {
            await poll_answer_api({
              poll_res: {
                user_id: user_data?._id,
                option_id: deloption_id,
                status: false,
              },
            });
            get_polls();
            socket.emit("sendMessage", { message: message?.text });
            seteffect_func(!effect_func);
          } else {
            get_polls();
            socket.emit("sendMessage", { message: message?.text });
            seteffect_func(!effect_func);
          }
        } else {
          setpoll_detail({
            ...poll_detail,
            options: poll_detail?.options?.map((elem) => {
              if (elem._id == option_id) {
                return {
                  ...elem,
                  responses: elem?.responses?.filter((li) => {
                    return li.user_id != user_data?._id;
                  }),
                };
              } else {
                return elem;
              }
            }),
          });
          socket.emit("sendMessage", { message: message?.text });
          seteffect_func(!effect_func);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(get_polls, [effect_func]);

  const total_ans_given = poll_detail?.options
    ?.map((elem) => {
      return elem?.responses;
    })
    ?.flat()
    ?.filter((elem) => {
      return elem?.user_id == user_data?._id;
    });

  return (
    <>
      <div className="removescrollbar" style={{ width: "100%" }}>
        <Flex_box
          className="removescrollbar"
          style={
            reverse
              ? {
                  justifyContent: "end",
                  alignItems: "start",
                }
              : {
                  justifyContent: "start",
                  alignItems: "start",
                  // backgroundColor:"red"
                }
          }
        >
          {!reverse && (
            <img
              // src={`data:image/svg+xml;base64,${currentUser.avatarImage}`}
              src={user_pic}
              style={{
                height: "20px",
                marginTop: "9px",
                marginRight: "10px",
              }}
            />
          )}
          <div>
            <p style={{ fontSize: "10px" }}>
              {reverse ? "You" : message?.user}
            </p>

            <Flex_box
              style={{
                alignItems: "",
                marginTop: "-5px",
              }}
            >
              <div
                style={{
                  backgroundColor: "rgb(2, 103, 193,0.1)",
                  fontSize: "12px",
                  padding: "10px",
                  borderRadius: "8px",
                  marginTop: "-5px",
                  ...top_b_radius,
                }}
              >
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  {poll_detail?.question}
                </span>
                <br />
                {poll_detail?.options?.map((li, ind) => {
                  const ans_given = li?.responses?.filter((elem) => {
                    return elem?.user_id == user_data?._id;
                  });

                  // console.warn({ li });

                  return (
                    <>
                      <Button
                        style={{
                          padding: "10px 0px",
                          borderRadius: "5px",
                          marginTop: "5px",
                          backgroundColor:
                            ans_given?.length > 0 ? theme_color : "#fff",
                          border: "2px solid " + theme_color,
                        }}
                        label={
                          <Flex_box
                            style={{
                              justifyContent: "space-between",
                              paddingLeft: "10px",
                              color: ans_given?.length > 0 ? "#fff" : "#000",
                            }}
                          >
                            <span>{li?.option}</span>
                            <span
                              style={{
                                marginRight: "20px",
                              }}
                            >
                              {li?.responses?.length}
                            </span>
                          </Flex_box>
                        }
                        onClick={() => {
                          poll_answer({
                            option_id: li?._id,
                            status: ans_given?.length > 0 ? false : true,
                            deloption_id: li?.allow_multiple_opts
                              ? ""
                              : total_ans_given[0]?.option_id,
                          });
                        }}
                      />
                      <br />
                    </>
                  );
                })}
                <div>
                  <span
                    style={{
                      fontSize: "10px",
                      color: "grey",
                    }}
                  >
                    Expires on {get_iso_date(poll_detail?.exp_date)} at{" "}
                    {poll_detail?.exp_time}
                  </span>
                  <br />
                  <Flex_box
                    style={{
                      justifyContent: "start",
                      gap: "5px",
                      padding: "5px 0px",
                    }}
                  >
                    <img
                      src={pie_chart_icon}
                      style={{
                        height: "10px",
                      }}
                    />
                    <span
                      style={{
                        fontSize: "10px",
                        color: "grey",
                      }}
                    >
                      Poll
                    </span>
                  </Flex_box>
                  {user_data?.role == 2 && (
                    <>
                      <div
                        style={{
                          height: "2px",
                          width: "100%",
                          backgroundColor: "#fff",
                          marginBottom: "5px",
                        }}
                      ></div>
                      <span
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setpoll_detail_modal(true);
                        }}
                      >
                        <Flex_box>
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            View Details
                          </span>
                        </Flex_box>
                      </span>
                    </>
                  )}
                </div>
              </div>

              {reverse && (
                <img
                  src={dot_menu}
                  style={{
                    height: "17px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (window.confirm("Do you want to delete this message?")) {
                      del_message(
                        message?._id,
                        message?.text,
                        message?.createdAt
                      );
                    }
                  }}
                />
              )}
            </Flex_box>
          </div>

          {reverse && (
            <img
              src={user_pic}
              style={{
                height: "20px",
                marginTop: "9px",
                marginLeft: "10px",
              }}
            />
          )}
        </Flex_box>
      </div>
      <Poll_Details
        poll_detail_modal={poll_detail_modal}
        setpoll_detail_modal={setpoll_detail_modal}
        poll_detail={poll_detail}
        gd_data={gd_data}
      />
    </>
  );
};

export default Poll_Box;
