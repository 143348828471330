import React from "react";
import Flex_box from "./flex_box";

const Page_heading = ({ label, suffix }) => {
  return (
    <Flex_box
      style={{
        justifyContent: "space-between",
        boxSizing:"border-box",
        paddingRight:"10px"
      }}
    >
      <span
        style={{
          fontSize: "24px",
          fontStyle: "italic",
          fontWeight: "700",
          marginTop: "5px",
        }}
      >
        {label}
      </span>
      {suffix || null}
    </Flex_box>
  );
};

export default Page_heading;
