import React from "react";
import * as XLSX from "xlsx";
import excel_icon from "../../../../assets/Group 68129.svg";

const Excel_Btn = ({ study_analysis_data, excel_data }) => {
  const exportToExcel = () => {
    // // Create a worksheet from the data

    const new_arr = excel_data;
    //  study_analysis_data?.filter((el)=>{
    //     return !(el?.sub_questions?.length>0)
    // })?.map((el) => {
    //   return {
    //     Question: el?.questiontitle,
    //     "Input Answers": el?.inp_ans,
    //     "Option Answers": el?.graph_data
    //       ?.map((li) => {
    //         return JSON.stringify({
    //           [li.label]: li.y,
    //         });
    //       })
    //       .toString(),
    //       "Total Answers": el?.inp_ans || el?.total_answers
    //   };
    // });

    const worksheet = XLSX.utils.json_to_sheet(new_arr);

    // Create a workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate an Excel file and trigger the download
    XLSX.writeFile(workbook, "Data.xlsx");
  };

  return (
    <img
      src={excel_icon}
      style={{
        height: "50px",
        cursor: "pointer",
      }}
      onClick={exportToExcel}
    />
  );
};

export default Excel_Btn;
