import React, { useEffect, useState } from "react";
import Layout from "../../../utilities/Layout";
import Page_heading from "../../../utilities/Page_heading";
import Flex_box from "../../../utilities/flex_box";
import { theme_bg, theme_color } from "../../../utilities/colors";
import money_pic from "../../../assets/Group 40686.svg";
import user_icon from "../../../assets/user_dummy.png";
import { get_resp_balance_api } from "../../../apis/apis";
import { useDispatch, useSelector } from "react-redux";
import { get_rewards_api } from "../../../apis/apis";
import {
  loading_action,
  alert_action,
} from "../../../redux_prog/actions/base_action";
import Button from "../../../utilities/Button";
import Voucher_Modal from "./Voucher_Modal";

const Balance = () => {
  const [user_balance, setuser_balance] = useState("");
  const [time_updated, settime_updated] = useState("");
  const [total_balance, settotal_balance] = useState([]);
  const { user_data } = useSelector((state) => state?.authReducer);

  const get_resp_balance = () => {
    get_resp_balance_api({ UserId: user_data?._id })
      .then((res) => {
        setuser_balance(res.data?.Res[0]?.balance || 0);
        settime_updated(
          res.data?.Res[0]?.updatedAt
            ? new Date(res.data?.Res[0]?.updatedAt)?.toDateString()
            : ""
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(get_resp_balance, []);

  const dispatch = useDispatch();
  const [rewardList, setRewardList] = useState([]);

  const get_rewards = () => {
    dispatch(loading_action(true));
    get_rewards_api({
      UserId: user_data?._id,
    })
      .then((res) => {
        dispatch(loading_action(false));
        setRewardList(res.data.Res);
      })
      .catch((err) => {
        dispatch(loading_action(false));
        dispatch(
          alert_action({
            type: "error",
            title: "Error",
            msg: "Some error occurred!",
          })
        );
      });
  };

  useEffect(get_rewards, []);

  return (
    <>
      <Layout
        style={{
          padding: "20px",
        }}
      >
        <Flex_box
          style={{
            justifyContent: "space-between",
          }}
        >
          <Page_heading label={"My Balance"} />
          <Voucher_Modal user_balance={user_balance} get_resp_balance={get_resp_balance} />
        </Flex_box>
        <Flex_box
          style={{
            justifyContent: "space-between",
            padding: "10px",
            borderRadius: "10px",
            backgroundColor: theme_bg,
            margin: "15px 0px",
          }}
        >
          <div style={{ lineHeight: 1.3 }}>
            <span style={{ fontSize: "11px", fontWeight: "500" }}>
              Total Earning
            </span>
            <br />
            <b
              style={{
                fontSize: "22px",
                color: theme_color,
                fontStyle: "italic",
              }}
            >
              {parseFloat(user_balance)?.toFixed(2)}
            </b>
            <br />
            <span style={{ fontSize: "11px" }}>
              {time_updated ? "Updated today at " + time_updated : ""}
            </span>
          </div>
          <img
            src={money_pic}
            style={{
              height: "70px",
            }}
          />
        </Flex_box>
        <span
          style={{
            fontSize: "14px",
            fontStyle: "italic",
            fontWeight: "500",
          }}
        >
          Latest Updates
        </span>
        <Flex_box
          style={{
            justifyContent: "space-between",
            flexWrap: "Wrap",
            margin: "5px 0px",
          }}
        >
          {rewardList?.length > 0 &&
            rewardList?.map((val) => {
              return (
                <Flex_box
                  className="responsive_width_35"
                  style={{
                    justifyContent: "space-between",
                    padding: "10px",
                    borderRadius: "10px",
                    backgroundColor: theme_bg,
                    width: "",
                    marginTop: "10px",
                    boxSizing: "border-box",
                  }}
                >
                  <Flex_box
                    style={{
                      width: "",
                      gap: 10,
                    }}
                  >
                    <img
                      src={user_icon}
                      style={{
                        height: "60px",
                        border: "1px solid " + theme_color,
                        borderRadius: "50%",
                      }}
                    />
                    <div style={{ lineHeight: 1.3 }}>
                      <span style={{ fontSize: "14px", fontWeight: "500" }}>
                        {val?.study_lookup[0]?.studyname}
                      </span>
                      <br />
                      <span style={{ fontSize: "12px" }}>
                        {val?.study_lookup[0]?.user_lookup[0]?.username}
                      </span>
                    </div>
                  </Flex_box>
                  <b
                    style={{
                      fontSize: "16px",
                      color: theme_color,
                      fontStyle: "italic",
                    }}
                  >
                    {parseFloat(val?.amount)?.toFixed(2)}
                  </b>
                </Flex_box>
              );
            })}
        </Flex_box>
      </Layout>
    </>
  );
};

export default Balance;
