import React, { useState } from "react";
import back_icon from "../../../assets/chevron_back_circle.svg";
import { theme_bg, theme_color } from "../../../utilities/colors";
import Flex_box from "../../../utilities/flex_box";
import File_input from "../../../utilities/File_input";
import upload_img from "../../../assets/Group 33339.png";
import Text_Input from "../../../utilities/Text_Input";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useLocation, useNavigate } from "react-router-dom";
import map_icon from "../../../assets/location-filled.svg";
import phone_icon from "../../../assets/phone.svg";
import down_arrow_icon from "../../../assets/arrow-right-bold.svg";
import calender_icon from "../../../assets/calendar-week-fill.svg";
import Select_box from "../../../utilities/Select_box";
import Radio_Box from "../../../utilities/Radio_Box";
import Auth_header from "../Auth_header";
import Button from "../../../utilities/Button";
import {
  login_action,
  set_user_action,
} from "../../../redux_prog/actions/auth_action";
import { useDispatch, useSelector } from "react-redux";
import { alert_action } from "../../../redux_prog/actions/base_action";
import { signup_api } from "../../../apis/apis";
import { sum_of_array_elem } from "../../../utilities/Add_array_elem";
import { get_sec_value } from "../../../utilities/select_arr";
import { set_temp_user_action } from "../../../redux_prog/actions/app_action";

const Question_box = ({
  question,
  options,
  questionIndex,
  onChange,
  isMultipleSelection,
  answer,
}) => {
  return (
    <div
      style={{
        width: "95%",
        marginTop: "30px",
      }}
    >
      <b>{question}</b>
      <Flex_box
        style={{
          justifyContent: "space-between",
          width: "100%",
          marginTop: "18px",
          flexWrap: "wrap",
        }}
      >
        {options.length > 0 &&
          options.map((el, index) => {
            return (
              <Radio_Box
                checked={answer?.includes(el?.label)}
                key={index}
                value={el.label}
                label={el.label}
                onClick={() => {
                  onChange(el.label, questionIndex, isMultipleSelection);
                }}
                style={{
                  width: "49%",
                  justifyContent: "start",
                  whiteSpace: "wrap",
                }}
              />
            );
          })}
      </Flex_box>
    </div>
  );
};

const Respondent_authentication = () => {
  const path_state = useLocation();
  const study_id = path_state?.state?.study_id;
  const gd_path_data = {
    id: study_id,
    type: 2,
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);

  const { login_type, auth_otp, user_data } =
    useSelector((state) => state?.authReducer) || {};

  const [respondent, setRespondent] = useState([
    {
      question: "HOUSEHOLD DURABLES (Multiple mention)",
      options: [
        { label: "Household help", value: 2 },
        { label: "Fridge/freezer", value: 2 },
        { label: "Video", value: 2 },
        { label: "Car", value: 2 },
        { label: "Colour TV", value: 4 },
        { label: "Music System", value: 1 },
        { label: "Air Conditioning", value: 3 },
        { label: "Satellite Dish", value: 4 },
        { label: "Washing Machine", value: 4 },
        { label: "Black & White TV", value: 1 },
        { label: "DVD (Digital Video Disk) Player", value: 5 },
        { label: "Cable Satellite", value: 3 },
        { label: "Telephone (land)", value: 3 },
        { label: "Telephone (mobile)", value: 4 },
        { label: "Personal Driver", value: 2 },
        { label: "Multiple Cars", value: 3 },
      ],
      answer: [],
      isMultipleSelection: true,
    },
    {
      question: "RESPONDENT’S OCCUPATION (Single mention)",
      options: [
        { label: "Senior Management/Admin", value: 4 },
        { label: "Manager", value: 3 },
        { label: "Professional e.g Doctor, Lawyer, Engineers", value: 5 },
        { label: "Skilled Workers", value: 2 },
        { label: "Unskilled Worker", value: 1 },
      ],
      answer: [],
      isMultipleSelection: false,
    },
    {
      question: "RESPONDENT’S HIGHEST EDUCATIONAL LEVEL (Single mention)",
      options: [
        { label: "Primary Incomplete", value: 1 },
        { label: "Primary Complete", value: 1 },
        { label: "Secondary Incomplete", value: 1 },
        { label: "Secondary Complete", value: 2 },
        { label: "University/Poly Incomplete OND", value: 3 },
        { label: "University/Poly Complete HND", value: 4 },
        { label: "Post University Incomplete", value: 3 },
        { label: "Post University Complete", value: 5 },
        { label: "Illiterate/None", value: 0 },
      ],
      answer: [],
      isMultipleSelection: false,
    },
    {
      question: "COOKING (Multiple mention)",
      options: [
        { label: "Gas Cooker", value: 3 },
        { label: "Electric Cooker", value: 3 },
        { label: "Kerosene Stove", value: 2 },
        { label: "Charcoal/Wood", value: 1 },
      ],
      answer: [],
      isMultipleSelection: true,
    },
    {
      question: "TYPE OF TOILET (Single mention)",
      options: [
        { label: "Inside WC", value: 4 },
        { label: "Outside WC", value: 2 },
        { label: "Pit Latrine", value: 1 },
        { label: "None", value: 0 },
      ],
      answer: [],
      isMultipleSelection: false,
    },
    {
      question: "MAIN WATER SOURCE (Single mention)",
      options: [
        { label: "Inside pipe borne tap", value: 4 },
        { label: "Outside pipe borne tap", value: 2 },
        { label: "Borehole", value: 3 },
        { label: "Well", value: 2 },
        { label: "Stream", value: 1 },
      ],
      answer: [],
      isMultipleSelection: false,
    },
    {
      question: "RESIDENTIAL AREA (Single mention)",
      options: [
        { label: "High Density", value: 1 },
        { label: "Medium Density", value: 2 },
        { label: "Low Density", value: 4 },
      ],
      answer: [],
      isMultipleSelection: false,
    },
    {
      question: "TYPE OF HOUSE (Single mention)",
      options: [
        { label: "Villa", value: 6 },
        { label: "Flat", value: 3 },
        { label: "Duplex", value: 5 },
        { label: "Mini Flat", value: 2 },
        { label: "Room and Parlour", value: 1 },
        { label: "Room", value: 1 },
        { label: "Self-occupied bungalow", value: 4 },
        { label: "Others", value: 2 },
      ],
      answer: [],
      isMultipleSelection: false,
    },
  ]);

  const onChange = (value, index, isMultipleSelection) => {
    setRespondent(
      respondent.map((el, id) => {
        if (id == index) {
          if (el.answer?.includes(value)) {
            const new_obj = {
              ...el,
              answer: el.answer?.filter((elem) => {
                return elem != value;
              }),
            };
            return new_obj;
          } else {
            const new_obj = {
              ...el,
              answer: isMultipleSelection ? [...el.answer, value] : [value],
            };
            return new_obj;
          }
        } else {
          return el;
        }
      })
    );
  };

  const handelProceed = () => {
    const sec = sum_of_array_elem(
      respondent.map((el) => {
        return {
          sum: sum_of_array_elem(
            el?.options?.filter((li) => {
              return el.answer?.includes(li.label);
            }),
            "value"
          ),
        };
      }),
      "sum"
    );
    if (sec > 0) {
      setloading(true);
      signup_api({ ...user_data, sec: get_sec_value(sec) })
        .then(async (res) => {
          setloading(false);
          if (res.data.status == 1) {
            console.log(res.data);
            dispatch(set_user_action(res.data.Res));
            dispatch(set_temp_user_action(""));
            dispatch(login_action());
            if (study_id) {
              navigate("/study-details", {
                state: gd_path_data,
              });
            } else {
              navigate("/dashboard");
            }
            // navigate("/dashboard");
          } else {
            dispatch(
              alert_action({
                title: `Invalid Credentials`,
                type: "error",
                msg: res.data.msg,
              })
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          setloading(false);
          dispatch(
            alert_action({
              title: `Invalid Credentials`,
              type: "error",
              msg: "Some error occurred!",
            })
          );
        });
    } else {
      dispatch(
        alert_action({
          type: "error",
          title: "Invalid economic class",
          msg: "Please select atleast one option!",
        })
      );
    }
  };

  return (
    <div
      className="auth_box_cont"
      style={{
        overflowY: "auto",
        padding: "150px 0px",
        alignItems: "start",
        // maxHeight: "60vh",
      }}
    >
      <div
        className="auth_box"
        style={{
          padding: "15px",
          // marginTop: '1200px',
          paddingBottom: "50px",
        }}
      >
        <Auth_header label={"Respondent Authentication"} />

        <span
          className="responsive_width"
          style={{
            fontSize: "14px",
            fontWeight: 400,
            textAlign: "center",
            padding: "25px 0px 0px 0px",
          }}
        >
          We need some more information about you before you can be onboarded to
          Yaarnbox as a Respondent.
        </span>
        {respondent.length > 0 && (
          <>
            {respondent.map((item, index) => {
              return (
                <Question_box
                  answer={item.answer}
                  question={item.question}
                  options={item.options}
                  onChange={onChange}
                  questionIndex={index}
                  isMultipleSelection={item.isMultipleSelection}
                />
              );
            })}
          </>
        )}
        {/* <Question_box
                    question={'What DURABLES do you use (Multiple Answer)'}
                    options={[
                        {
                            label: 'Household help'
                        },
                        {
                            label: 'Car'
                        },
                        {
                            label: 'Fridge'
                        },
                        {
                            label: 'LED TV'
                        },
                    ]}
                /> */}
        <Button
          id="btn_comp"
          style={{
            marginTop: "40px",
          }}
          onClick={() => {
            handelProceed();
          }}
          label={"Proceed"}
          loading={loading}
        >
          Proceed
        </Button>
      </div>
    </div>
  );
};

export default Respondent_authentication;
