import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Carousal_Box = ({ children, itemClass,style }) => {
  const style_obj = style || {};
  return (
    <div style={{ width: "100%", }}>
      <Carousel
        containerClass="carousel-container"
        itemClass={itemClass || ""}
        responsive={responsive}
        
      >
        {children}
      </Carousel>
    </div>
  );
};

export default Carousal_Box;
