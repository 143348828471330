import React from "react";
import { theme_bg, theme_color } from "./colors";

const File_input = ({ onChange, renderItem, disabled }) => {
  return (
    <>
      <input
        type="file"
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.files,e);
          }
        }}
        id="file_input"
        style={{ display: "none" }}
      />
      <div
        onClick={() => {
          if (!disabled) {
            document.getElementById("file_input").click();
          }
        }}
      >
        {renderItem || (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              style={{
                background: theme_bg,
                border: "1px dashed " + theme_color,
                borderRadius: 10,
                padding: "15px 20px",
                cursor: "pointer",
              }}
            >
              <b
                style={{
                  color: theme_color,
                  fontSize: "12px",
                  marginTop: "10px",
                }}
              >
                Upload File
              </b>
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default File_input;
