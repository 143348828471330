// reducers/userReducer.js
const initialState = {
  temp_user: '',
  app_balance:''
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TEMP_USER":
      return {
        ...state,
        temp_user: {
          // ...state.alert_obj,
          ...action.payload,
        },
      };
      case "SET_APP_BALANCE":
        return {
          ...state,
          app_balance:action.payload,
        };
    default:
      return state;
  }
};

export default appReducer;
